import React, { useEffect , useState } from 'react'
import axiosInstance from "../../config/axios";

const UserDeatils = () => {
  const [users , setUser]=useState('');
  useEffect(() => {
    console.log(localStorage.getItem("uid"))
    axiosInstance.get(`/user/` +localStorage.getItem("uid"))
      .then((response) => {
        localStorage.setItem("userDetails",JSON.stringify(response.data)); 
        setUser(response.data);
        console.log(response.data);
      })
      .catch((error) => {
               
      });
  }, []);

  return (
    <div>
      {JSON.stringify(users)}
            I am user Deatils
    </div>
  )
}

export default UserDeatils;
