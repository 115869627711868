import React, { useEffect, useState } from "react";
import { Modal, ModalProps } from "react-bootstrap";

const ModalGeneral = ({
    modalClassName,
    modalShow,
    modalOnHide,
    modalTitle,
    modalHeaderContent,
    modalBody,
    modalFooter,
    modalSize,
}: {
    modalClassName: string;
    modalShow: boolean;
    modalOnHide: () => void;
    modalTitle: React.ReactNode;
    modalHeaderContent: React.ReactNode | string;
    modalBody: React.ReactNode | string;
    modalFooter: React.ReactNode | string;
    modalSize?: ModalProps["size"];
}) => {

    return (
        <Modal 
            className={modalClassName}
            show={modalShow}
            onHide={() => {
                modalOnHide();
            }}
            size={modalSize}
        >
            <Modal.Header
                closeButton={true}
            >
                <Modal.Title>
                    {modalTitle}
                </Modal.Title>
                {modalHeaderContent}
            </Modal.Header>
            <Modal.Body className="ps-md-24 pe-md-24 pt-md-24">
                {modalBody}
            </Modal.Body>
            <Modal.Footer>
                {modalFooter}
            </Modal.Footer>
        </Modal>
    );
}

interface IModalGeneralErrorProps {
    isShowing: boolean;
    setIsShowing: (show: boolean) => void;
    errorMessage: string;
    setErrorMessage: (message: string) => void;
}

const ModalGeneralError = ({
    isShowing,
    setIsShowing,
    errorMessage,
    setErrorMessage,
}: IModalGeneralErrorProps) => {

    return (
        <ModalGeneral
            modalClassName="modal-general-error"
            modalShow={isShowing}
            modalOnHide={() => {
                setIsShowing(false);
                setErrorMessage('');
            }}
            modalTitle={
                <div className="modal-title d-flex">
                    <span className="icon-32">
                        <em className="icon-ban-outline"></em>
                    </span>
                    <span>Error</span>
                </div>
            }
            modalHeaderContent={null}
            modalBody={
                <div className="text-center">
                    <p>{errorMessage}</p>
                    <p>Please try again later and if the problem still exists, please reach out to support.</p>
                </div>
            }
            modalFooter={
                <button
                    className="btn btn-success"
                    onClick={() => {
                        setIsShowing(false);
                        setErrorMessage('');
                    }}
                >
                    Close
                </button>
            }
            // @ts-ignore
            modalSize="md"
        />
    );
}

export {
    ModalGeneral,
    ModalGeneralError,
};