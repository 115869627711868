import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../redux/actions/Auth/Auth";
import Loading from "../../components/Loader";
import { useAppDispatchJS, useAppSelectorJS } from "../../redux/hooks";

const PasswordAndOtp = () => {
  const [loader, setLoader] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const otpMessage = useAppSelectorJS((state) => state.SendOTP);
  const isFirstLogin = JSON.parse(localStorage.getItem("userDetails"))?.is_first_login
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  const dispatch = useAppDispatchJS();
  const navigate = useNavigate();

  useEffect(() => {
    setEmail(localStorage.getItem("Email"));
    setPhoneNumber(
      JSON.parse(localStorage.getItem("userDetails"))?.phone_number
    );
    let newUser = localStorage.getItem("is_new_user");
    if(newUser === "true"){
      validateEmail();
    }

  });

  function validateEmail(){
    dispatch(auth.validateEmail(email, undefined)).then((user) => {
      var value = user;
      if (user.type === "EMAIL_VALID_SUCCESS") {
        localStorage.setItem("userDetails", JSON.stringify(value.data));
        setPhoneNumber(
          value.data?.phone_number
        );
        localStorage.removeItem("is_new_user");
          
      } 
    });
  }

  function handleSubmit(e) {
    setLoader(true);
    e.preventDefault();
    setSubmitted(true);
    let params = { email: email, password: password };

    if (!email || !password) {
      setLoader(false);
      return;
    }
    dispatch(auth.sendOtp(params)).then((response) => {
      setLoader(false);

      if (response.type === "OTP_RECEIVED_SUCCESS") {
        navigate("/verify-code", { state: { password: password } });
      } else {
        if(response?.error?.email){
          setEmailError(true);
        }else{
          setPasswordError(true);
        }
       
      }
    });
  }

  const checkCapsLock = (event) => {
    if (event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  return (
    <>
      {loader && <Loading active={loader} />}
      <form name="form" onSubmit={handleSubmit}>
        <div
          className={`form-group ${
            submitted && !password ? "has-error" : ""
          }  `}
        >
          {isCapsLockOn && <div className="alert alert-warning d-block">Warning: CAPS Lock is on.</div>}
          <label className="form-label">
            {isFirstLogin?"Temporary Password":"Password"} <sup>*</sup>
          </label>
          <div className="password-field">
            <input
              type={passwordShown ? "text" : "password"}
              className="form-control"
              placeholder={isFirstLogin?"Enter temporary password":"Enter password"}
              name="password"
              value={password}
              onKeyUp={checkCapsLock}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError(false);
              }}
            />
            <em
              className={
                passwordShown
                  ? "icon-eye-reveal-filled"
                  : "icon-eye-hide-filled"
              }
              onClick={(e) => {
                passwordShown
                  ? setPasswordShown(false)
                  : setPasswordShown(true);
              }}
            ></em>
          </div>
          {submitted && password && passwordError && (
            <div className="invalid-feedback d-block">
              {otpMessage.error.password[0]}
            </div>
          )}
          {submitted && password && emailError && (
            <div className="invalid-feedback d-block">
              {otpMessage.error.email[0]}
            </div>
          )}
          {submitted && !password && (
            <div className="invalid-feedback d-block">Password is required</div>
          )}
        </div>
        <div className="form-group">
          <label className="form-label">Verification Method</label>
          <input
            type="text"
            className="form-control"
            name="mobile"
            value={`Text Message - *** ***` + phoneNumber}
            disabled
          />
        </div>

        <div className="mb-16">
          <button className="btn btn-success w-100">
            Send Verification Code
          </button>
        </div>

        <div className="text-center mb-8">
          <Link to="/forgot-password-verify" className="btn-link fs-18 lh-32">
            Forgot Password ?
          </Link>
        </div>
      </form>
    </>
  );
};

export default PasswordAndOtp;
