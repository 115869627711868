import React, { useEffect, useState } from "react";
import { history } from "../../config/history";
import { Link, Outlet, useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/logo.svg";
import Close from "../../Assets/Images/close.svg";
import { useLocation } from "react-router-dom";

const LoginLayout = () => {
  const location = useLocation();
  const [headMessage, setHeadMessage] = useState("");
  const [bodyMessage, setbodyMessage] = useState("");
  const [showPasswordMsg, setShowPasswordMsg] = useState(false);
  useEffect(() => {
    setMessage(location.pathname);
    let devtoken = JSON.parse(
      localStorage.getItem("reset-token")
    )?.isPasswordReset;
    console.log(devtoken);
    if (devtoken && location.pathname !== "/reset-forgot-password") {
      setShowPasswordMsg(true);
    } else {
      setShowPasswordMsg(false);
    }
  }, [location]);

  function setMessage(path) {
    if (path === "/forgot-password-verify") {
      setHeadMessage("Forgot Password");
      setbodyMessage("We’ll text you a one-time passcode to change your password.");
    } else if (path === "/forgot-email") {
      setHeadMessage("Forgot Email Address");
      setbodyMessage("");
    } else if (path === "/reset-forgot-password") {
      setHeadMessage("Reset Password");
      setbodyMessage("Please enter your new password below.");
    } else if (path === "/reset-password") {
      setHeadMessage("Reset Password");
      setbodyMessage("Please enter your new password below.");
    } else {
      setHeadMessage("Log In");
      setbodyMessage("");
      // setbodyMessage("It's nice to see you again");
    }
  }
  function onClosePopup(e) {
    //e.preventDefault();
    setShowPasswordMsg(false);
  }
  return (
    <>
      <div className="login-section">
        <div className="container">
          <div className="login-center mx-auto">
            <div className="text-center">
              <a href="#" className="login-logo mb-52 mb-md-120">
                <img src={logo} alt="" />
              </a>
            </div>
            {showPasswordMsg && (
              <div className="alert alert-success mb-32">
                <span>
                  <em className="icon-check-circle-filled text-success cursor-pointer"></em>
                </span>
                <div className="flex-fill d-flex align-items-start justify-content-between">
                  <div>
                    <h6 className="mb-4">Password Reset!</h6>
                    <p>Your password has been successfully reset.</p>
                  </div>
                  <img
                    className="alert-close"
                    src={Close}
                    alt=""
                    onClick={(e) => {
                      console.log("close");
                      setShowPasswordMsg(false);
                    }}
                  />
                </div>
              </div>
            )}
            {!window.location.href.includes("?_branch_match_id") && (
              <div className="login-card">
                <div className="login-heading text-center mb-16">
                  <h2 className="mb-8">{headMessage}</h2>
                  <p className="fs-18 lh-32">{bodyMessage}</p>
                </div>
              </div>
            )}
            <div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginLayout;
