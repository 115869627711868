export const articleConstants = {
  GET_TOPIC_SUCCESS: "GET_TOPIC_SUCCESS",
  GET_TOPIC_FAILURE: "GET_TOPIC_FAILURE",
  GET_ARTICLE_SUCCESS: "GET_ARTICLE_SUCCESS",
  GET_ARTICLE_FAILURE: "GET_ARTICLE_FAILURE",
  GET_RELATED_ARTICLE_SUCCESS: "GET_RELATED_ARTICLE_SUCCESS",
  GET_RELATED_ARTICLE_FAILURE: "GET_RELATED_ARTICLE_FAILURE",
  RETRIEVE_ARTICLE_SUCCESS: "RETRIEVE_ARTICLE_SUCCESS",
  RETRIEVE_ARTICLE_FAILURE: "RETRIEVE_ARTICLE_FAILURE",
  GET_BOOKMARKED_ARTICLE_SUCCESS: "GET_BOOKMARKED_ARTICLE_SUCCESS",
  GET_BOOKMARKED_ARTICLE_FAILURE: "GET_BOOKMARKED_ARTICLE_FAILURE",
  UPDATE_BOOKMARKED_READ_STATUS_SUCCESS: "UPDATE_BOOKMARKED_READ_STATUS_SUCCESS",
  UPDATE_RELATED_READ_STATUS: "UPDATE_RELATED_READ_STATUS",
  UPDATE_RELATED_READ_STATUS_FAILURE: "UPDATE_RELATED_READ_STATUS_FAILURE",
  UPDATE_COMMON_ARTICLE_READ_STATUS_SUCCESS: "UPDATE_COMMON_ARTICLE_READ_STATUS_SUCCESS",
  UPDATE_COMMON_ARTICLE_READ_STATUS_FAILURE: "UPDATE_COMMON_ARTICLE_READ_STATUS_FAILURE",
  UPDATE_BOOKMARKED_READ_STATUS_FAILURE: "UPDATE_BOOKMARKED_READ_STATUS_FAILURE",
  UPDATE_BOOKMARK_STATUS_SUCCESS: "UPDATE_BOOKMARK_STATUS_SUCCESS",
  UPDATE_BOOKMARK_RELATED_STATUS_SUCCESS: "UPDATE_BOOKMARK_RELATED_STATUS_SUCCESS",
  UPDATE_BOOKMARK_RELATED_STATUS_FAILURE: "UPDATE_BOOKMARK_RELATED_STATUS_FAILURE",
  UPDATE_BOOKMARK_STATUS_COMMON_ARTICLE_SUCCESS: "UPDATE_BOOKMARK_STATUS_COMMON_ARTICLE_SUCCESS",
  UPDATE_BOOKMARK_STATUS_COMMON_ARTICLE_FAILURE: "UPDATE_BOOKMARK_STATUS_COMMON_ARTICLE_FAILURE",
  UPDATE_BOOKMARK_STATUS_FAILURE: "UPDATE_BOOKMARK_STATUS_FAILURE",
  UPDATE_READ_STATUS_SINGLE_ARTICLE_SUCCESS: "UPDATE_READ_STATUS_SINGLE_ARTICLE_SUCCESS",
  UPDATE_READ_STATUS_SINGLE_ARTICLE_FAILURE:"UPDATE_READ_STATUS_SINGLE_ARTICLE_FAILURE",
  UPDATE_BOOKMARK_STATUS_SINGLE_ARTICLE_SUCCESS: "UPDATE_BOOKMARK_STATUS_SINGLE_ARTICLE_SUCCESS",
  UPDATE_BOOKMARK_STATUS_SINGLE_ARTICLE_FAILURE: "UPDATE_BOOKMARK_STATUS_SINGLE_ARTICLE_FAILURE",
  GET_TASK_SUCCESS: "GET_TASK_SUCCESS",
  GET_TASK_FAILURE: "GET_TASK_FAILURE",
  RESET_ARTICLES:'RESET_ARTICLES',
  SET_ARTICLES_LIST: 'SET_ARTICLES_LIST',
  GET_ADVISOR_FAILURE: "GET_ADVISOR_FAILURE",
  GET_ADVISOR_SUCCESS: "GET_ADVISOR_SUCCESS",
  GET_MYCLIENT_FAILURE: "GET_MYCLIENT_FAILURE",
  GET_MYCLIENT_SUCCESS: "GET_MYCLIENT_SUCCESS",
  UPDATE_TASK_FAILURE: "UPDATE_TASK_FAILURE",
  UPDATE_TASK_SUCCESS: "UPDATE_TASK_SUCCESS",
  GET_HOUSEHOLD_SUCCESS: "GET_HOUSEHOLD_SUCCESS",
  GET_HOUSEHOLD_FAILURE: "GET_HOUSEHOLD_FAILURE",
  GET_ADVISORYLIST_SUCCESS: "GET_ADVISORYLIST_SUCCESS",
  GET_ADVISORYLIST_FAILURE: "GET_ADVISORYLIST_FAILURE",
  GET_PUBLISH_UPDATE_SUCCESS: "GET_PUBLISH_UPDATE_SUCCESS",
  GET_PUBLISH_UPDATE_FAILURE: "GET_PUBLISH_UPDATE_FAILURE",
  UPDATE_SHARE_COUNT_SUCCESS: "UPDATE_SHARE_COUNT_SUCCESS",
  UPDATE_SHARE_COUNT_FAILURE: "UPDATE_SHARE_COUNT_FAILURE",
  GET_ARTICLECLIENTLIST_SUCCESS: "GET_ARTICLECLIENTLIST_SUCCESS",
  GET_ARTICLECLIENTLIST_FAILURE: "GET_ARTICLECLIENTLIST_FAILURE",
  GET_TEAM_SUCCESS: "GET_TEAM_SUCCESS",
  GET_TEAM_FAILURE: "GET_TEAM_FAILURE",
  GET_REFRESHTIME_SUCCESS: "GET_REFRESHTIME_SUCCESS",
  GET_REFRESHTIME_FAILURE: "GET_REFRESHTIME_FAILURE",
  POST_ARTICLE_LINKED_CLICKED_SUCCESS: "POST_ARTICLE_LINKED_CLICKED_SUCCESS",
  POST_ARTICLE_LINKED_CLICKED_FAILURE: "POST_ARTICLE_LINKED_CLICKED_FAILURE",
};
