import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Switch, Steps, notification, Radio } from "antd";
import Avatar from "../../Assets/Images/Avatar.png";
import Modal from "react-bootstrap/Modal";
import { clientUser } from "../../redux/actions/ClientUser/ClientUser";
import { useAppDispatchJS } from "../../redux/hooks";

const ClientReactivate = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatchJS();

  const [showReactivateUser, setReactivateUserShow] = useState(false);
  const handleReactivateUserClose = () => {
    setReactivateUserShow(false);
  };
  const handleReactivateUserShow = () => setReactivateUserShow(true);

  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState();
  const [selectedEmail, setSelectedEmail] = useState();

  useEffect(() => {
    const { visible, closeModal, isReschedule } = props;
    setReactivateUserShow(visible);
    setSelectedEmail(props?.client?.email);
    setSelectedPhoneNumber(props?.client?.phone_number);
  }, []);

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  function updateUserDetails() {
    let param = {
      user_id: props?.client?.uuid,
      email: selectedEmail,
      phone_number: selectedPhoneNumber,
    };
    dispatch(clientUser.updateUserDetails(param)).then((response) => {
      if (response.type === "UPDATE_USER_SUCCESS") {
        activateUser();
      }
    });
  }

  function activateUser() {
    let param = {
      user_id: props?.client?.uuid,
    };
    dispatch(clientUser.reactivateUser(param)).then((response) => {
      if (response.type === "ACTIVATE_USER_SUCCESS") {
        //setHouseHoldDetails(response?.data);
        const { closeModal } = props;
        closeModal();
        Notify(
          "success",
          `${
            props?.client?.first_name + " " + props?.client?.last_name
          } (${props?.client?.email}) was reactivated.`,
          <em className="icon-check-outline"></em>
        );
      }
    });
  }

  return (
    <>
      <Modal
        className="modal-800"
        show={showReactivateUser}
        onHide={() => {
          handleReactivateUserClose;
          const { closeModal } = props;
          closeModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reactivate User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-md-24">
          <div className="fs-18 lh-32">
            <p className="mb-12">
              Are you sure you want to Reactivate the user for <br />{" "}
              <b className="fw-500">
                {props?.client?.first_name + " " + props?.client?.last_name}?
              </b>
            </p>
            <p>
              They will get an email with a new activation link. They will be
              prompted to create a new password and use SMS verification to
              access their Moneta client user. Please verify the information
              below and proceed.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <span className="d-block mb-16 text-black fw-500">
                Cell Phone
              </span>
              <Radio.Group
                className=""
                onChange={(e) => {
                  setSelectedPhoneNumber(e?.target?.value);
                }}
                value={selectedPhoneNumber}
              >
                {props?.client?.contact?.phone_numbers?.map((phone, index) => (
                  <>
                    <Radio value={phone} key={phone}>
                      {phone}
                    </Radio>
                  </>
                ))}
              </Radio.Group>
            </div>
            <div className="col-md-6">
              <span className="d-block mb-16 text-black fw-500">
                Email Address
              </span>
              <Radio.Group
                className=""
                onChange={(e) => {
                  setSelectedEmail(e?.target?.value);
                }}
                value={selectedEmail}
              >
                {props?.client?.contact?.emails?.map((email, index) => (
                  <>
                    <Radio value={email} key={email}>
                      {email}
                    </Radio>
                  </>
                ))}
              </Radio.Group>
            </div>
          </div>
          <div className="cu-alert mt-28 mb-12">
            To update client data please go to SalesForce. Allow some time for
            your updates to reflect in Moneta portal.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={(e) => {
              handleReactivateUserClose();
              const { closeModal } = props;
              closeModal();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-success"
            onClick={(e) => {
              updateUserDetails();
            }}
          >
            Reactivate User
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientReactivate;
