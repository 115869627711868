import { docuSignConstants } from "../../constants/DocuSignConstant";

const INITIAL_STATE = {
  data: {},
  error: {},
}

const DocuSignCheck = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case docuSignConstants.CHECK_DOCUSIGN_SUCCESS:
    return {...INITIAL_STATE , data: action.data };
  case docuSignConstants.CHECK_DOCUSIGN_FAILURE:
    return {...INITIAL_STATE , error: action.error };
  default:
    return state
  }
}

export default DocuSignCheck;