import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";

// Custom hook for audio handling
const useAudio = (url: string) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);
  const toggle = () => setPlaying(prev => !prev);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle] as const;
};

interface ISoundPlayerProps {
  url: string;
}

// Forwarding a ref to allow external control
const SoundPlayer = forwardRef(({ url }: ISoundPlayerProps, ref) => {
  const [playing, toggle] = useAudio(url);

  // Expose play/pause controls via ref
  useImperativeHandle(ref, () => ({
    play: () => {
      if (!playing) toggle();
    },
    pause: () => {
      if (playing) toggle();
    },
  }));

  return null; // No visible UI
});

export default SoundPlayer;
