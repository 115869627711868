import axiosInstance from "../../../config/axios";
import { messageConstants } from "../../constants/MessageConstant";
import { utility } from "../../../components/Utility";
export const message = {
  getUserDetails,
  removeUserFromChannel,
  InviteUserToChannel,
  MuteChannel,
  updateChannel,
  GetClientList,
  GlobalSearch,
  getRecentMessages,
  getUnreadMessages,
};

function getUserDetails(client_Id) {
  return (dispatch) => {
    return axiosInstance
      .get("user/" + client_Id + "/profile")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: messageConstants.GET_USER_DETAILS_SUCCESS, data };
  }

  function failure(error) {
    return { type: messageConstants.GET_USER_DETAILS_FAILURE, error };
  }
}

function removeUserFromChannel(channelUrl,userId) {
  return (dispatch) => {
    return axiosInstance
      .put("/message/leave_channel",{"channel_url":channelUrl,user_ids:[userId]})
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: messageConstants.REMOVE_USER_SUCCESS, data };
  }

  function failure(error) {
    return { type: messageConstants.REMOVE_USER_FAILURE, error };
  }
}

function updateChannel(channelUrl,name) {
  return (dispatch) => {
    return axiosInstance
      .put("/message/update_group_channel",{"channel_url":channelUrl,"payload":{"name": name}})
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: messageConstants.UPDATE_CHANNELNAME_SUCCESS, data };
  }

  function failure(error) {
    return { type: messageConstants.UPDATE_CHANNELNAME_FAILURE, error };
  }
}

function MuteChannel(channelUrl,status) {
  return (dispatch) => {
    return axiosInstance
      .put("/message/notification_preferences/"+channelUrl,{"push_trigger_option": status})
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: messageConstants.MUTE_CHANNEL_SUCCESS, data };
  }

  function failure(error) {
    return { type: messageConstants.MUTE_CHANNEL_FAILURE, error };
  }
}

function InviteUserToChannel(channelUrl,userIds) {
  return (dispatch) => {
    return axiosInstance
      .post("/message/join_channel",{"channel_url":channelUrl,user_ids:userIds})
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: messageConstants.ADD_USER_SUCCESS, data };
  }

  function failure(error) {
    return { type: messageConstants.ADD_USER_FAILURE, error };
  }
}

function GetClientList() {
  return (dispatch) => {
    return axiosInstance
      .get("/user/advisory_team")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: messageConstants.GET_CLIENT_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: messageConstants.GET_CLIENT_LIST_FAILURE, error };
  }
}

function GlobalSearch(message , userId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/message/search_message?query=${message}&user_id=${userId}`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: messageConstants.GLOBAL_SEARCH_SUCCESS, data };
  }

  function failure(error) {
    return { type: messageConstants.GLOBAL_SEARCH_FAILURE, error };
  }
}

function getRecentMessages(message_url) {
  return (dispatch) => {
    return axiosInstance
      .get(message_url)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: messageConstants.GET_RECENT_MESSAGES_SUCCESS, data };
  }

  function failure(error) {
    return { type: messageConstants.GET_RECENT_MESSAGES_FAILURE, error };
  }
}

function getUnreadMessages() {
  return (dispatch) => {
    return axiosInstance
      .get("message/get_unread_messages_list")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: messageConstants.GET_UNREAD_MESSAGES_SUCCESS, data };
  }

  function failure(error) {
    return { type: messageConstants.GET_UNREAD_MESSAGES_FAILURE, error };
  }
}