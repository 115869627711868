import React, { useEffect, useState } from "react";
import { isMobile, isSafari } from 'react-device-detect';

import Icon from "../../Assets/Images/exclamation.svg";
import Loading from "../../components/Loader";
import Modal from "react-bootstrap/Modal";
import { financial } from '../../redux/actions/Financial/Investment';
import { useDispatch } from "react-redux";
import { useAppDispatchJS } from "../../redux/hooks";

const PreviewIframe = (props) => {
  const [financialUrl, setFinancialUrl] = useState("");
  const [loader, setLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState(true);
  const [postApiCall, setPostAPICall] = useState(false);

  const dispatch = useAppDispatchJS();


  function iframeLogin(status) {
    const data = {
      system: "website",
      status: status,
      orion_household_id: props?.investmentTab?.orion_hh_id,
      orion_household_name: props?.investmentTab?.orion_hh_name
    }
    if (!postApiCall) {
      setPostAPICall(true)
      dispatch(financial.postIframeLogin(data)).then((response) => {
        if (response.type === "POST_FINANCIALLOGINIFRAMEURL_SUCCESS") {
          setPostAPICall(true)
        }
      });
    }
  }

  useEffect(() => {
    if (!isSafari && !isMobile) {
      setLoader(true)
      dispatch(financial.getFinancialUrl(props?.investmentTab?.orion_hh_id)).then((response) => {
        if (response.type === "GET_FINANCIALURL_SUCCESS") {
          setFinancialUrl(response?.data?.redirect_url)
        }
        else {
          iframeLogin('failure')
          setFinancialUrl("error");
        }
      })
    }
    else if (isSafari) {
      setErrorMessage("This integration is not supported by the Safari browser. Please try again using a different browser.")
    }
    else if (isMobile) {
      setErrorMessage("This integration is not currently supported on mobile devices, but we’re working on it and it’s coming soon!")
    }
    else {
      setErrorMessage("We encountered an error while loading your investments data. Please refresh the page, and if the issue persists, contact your advisor for assistance.")
    }
  }, [])

  return (
    <Modal
      className="modal-1500 modal-full"
      show={props?.showIframePreview}
      onHide={() => props?.setShowIframePreview(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h6 className="m-0 pe-8">
              {props?.clientname}
            </h6>
            <p className="text-muted mb-20 fs-14 lh-20">
              This is a preview of what the client will see if the feature is enabled.
            </p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: '0px' }}>
        <div style={{ height: '800px', border: '1px solid' }}>
          {(financialUrl === "error" || isMobile) ?
            <div className="task-noresult-cdc d-flex align-items-center justify-content-center text-center" style={{ height: '800px' }}>
              <div className="max-350">
                <img src={Icon} alt="" className="mb-16" />
                <p className="fs-18 lh-24 m-0 mb-16">{errorMessage}</p>
                {!isSafari && !isMobile &&
                  <button
                    className="btn btn-outline-secondary  d-inline-flex align-items-center" style={{ color: 'white', backgroundColor: 'black' }}
                    onClick={() => props?.setShowIframePreview(false)}
                  >
                    <span className="icon-20 me-8">
                      <em className="icon-x-outline"></em>
                    </span>
                    Close
                  </button>
                }
              </div>
            </div> :
            <>
              {loader && <Loading previewiframe={true} noTimeOut={loader} />}
              {financialUrl &&
                <iframe src={financialUrl} height='100%' width='100%' className="responsive-iframe" onLoad={() => {
                  setTimeout(() => {
                    setLoader(false)
                  }, 8000)
                  iframeLogin('success')
                }
                }
                />}
            </>}
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default PreviewIframe;