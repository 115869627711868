// Tanner Fry
// tfry@monetagroup.com
// File contains the search component within a conversation.

import { useEffect, useState } from "react";
import { convertISOToTime, getInitials } from "../../components/UtilitiesTS";
import { ITwilioConversationsConversationProps } from "../../interfaces/TwilioConversations";

interface ITwilioConversationSearchProps {
    userConversation: ITwilioConversationsConversationProps;
    setShowModalError: (show: boolean) => void;
    setShowModalErrorText: (text: string) => void;
    setShowConversationSearch: (show: boolean) => void;
}

interface ISearchResultEntry {
    author: string;
    authorProfileImage: string;
    message: string;
    messageTimestamp: string;
}

const TwilioConversationSearch = ({
    userConversation,
    setShowModalError,
    setShowModalErrorText,
    setShowConversationSearch,
}: ITwilioConversationSearchProps) => {
    const conversationMessages = userConversation.messages;
    const [transformedChatMessages, setTransformedChatMessages] = useState<ISearchResultEntry[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [conversationSearchResults, setConversationSearchResults] = useState<ISearchResultEntry[]>([]);

    // Take all messages when conversation search loads and transform them into ISearchResultEntry objects
    useEffect(() => {
        if (conversationMessages) {
            const searchResultEntries: ISearchResultEntry[] = conversationMessages.map((result) => {
                console.log('user conversation participant details: ', userConversation.participants.find((participant) => participant.sid === result.participant_sid));
                
                return {
                    // userName: result.userName,
                    author: result.author,
                    // userProfileImage: result.userProfileImage,
                    authorProfileImage: userConversation.participants.find((participant) => participant.sid === result.participant_sid)?.profile_image || '',
                    message: result.body,
                    messageTimestamp: result.created_at,
                };
            });
            setTransformedChatMessages(searchResultEntries);
            console.log('searchResultEntries', searchResultEntries);
            
        }

        console.log('Conversation messages: ', conversationMessages);
        
    }, []);

    return (
        <div className='twilio-conversation-search d-flex' style={{width: '320px'}}>
            <div className='conversation-search-header'>
                <h5 className='conversation-search-title m-0'>Search Conversation</h5>
                <span 
                    className='icon-32 cursor-pointer'
                    onClick={() => {
                        setShowConversationSearch(false);
                    }}
                >
                    <em className='icon-x-outline fs-14'></em>
                </span>
            </div>
            <div className='conversation-search-results'>
                <div className="search-area py-12">
                    <input
                        placeholder="Search Conversation..."
                        className="form-control"
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                            if (e.target.value !== "" && conversationMessages) {
                                let searchResultEntries = transformedChatMessages.filter((message) => {
                                    if (message.author.toLowerCase() === "system") {
                                        return false;
                                    }
                                    // NOTE: We've changed showing numbers in message to showing conversation name as we're assuming most people will
                                    // NOTE: name the conversation after who they're texting. This is also utilized in TwilioMessages for message author.
                                    if (message.author.startsWith('+')) {
                                        message.author = userConversation.friendly_name
                                    }

                                    return message.message.toLowerCase().includes(e.target.value.toLowerCase());
                                });
                                setConversationSearchResults(searchResultEntries);
                            } else {
                                setConversationSearchResults([]);
                            }
                        }}
                    />
                    <button className="search-btn icon-20">
                        <em className="icon-search-outline"></em>
                    </button>
                    {searchValue !== "" && (
                        <button
                            className="close-search-btn icon-20"
                            onClick={(event) => {
                                setSearchValue("");
                                setConversationSearchResults([]);
                            }}
                        >
                            <em className="icon-x-circle-filled"></em>
                        </button>
                    )}
                </div>
                <div className='search-results'>
                    {conversationSearchResults.length > 0 ? (
                        conversationSearchResults.map((result: ISearchResultEntry, index) => {
                            return (
                                <div key={index} className='search-result-item'>
                                    <div className='item-image'>
                                        {result.authorProfileImage ? (
                                            <img src={result.authorProfileImage} alt="Profile" />
                                        ) : (
                                            <span className='user-photo-no-image'>
                                                {getInitials(result.author)}
                                            </span>
                                        )}
                                    </div>
                                    <div className='item-details'>
                                        <div className='details-header'>
                                            <h6>{result.author}</h6>
                                            <div className='message-time'>{convertISOToTime(result.messageTimestamp)}</div>
                                        </div>
                                        <p>{result.message}</p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className='no-search-results'>
                            No search results found
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TwilioConversationSearch;