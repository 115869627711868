import axiosInstance from '../../../config/axios'
import { financialConstants } from '../../constants/FinancialConstant'

export const financial = {
  getFinancialUrl,
  postIframeLogin
};

function getFinancialUrl(household_id) {
  return (dispatch) => {
    return axiosInstance
      .get("/orion-token-generate?householdid=" + household_id)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: financialConstants.GET_FINANCIALURL_SUCCESS, data };
  }

  function failure(error) {
    return { type: financialConstants.GET_FINANCIALURL_FAILURE, error };
  }
}

function postIframeLogin(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/client-investment/iframe_login`, data)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: financialConstants.POST_FINANCIALLOGINIFRAMEURL_SUCCESS, data };
  }

  function failure(error) {
    return { type: financialConstants.POST_FINANCIALLOGINIFRAMEURL_FAILURE, error };
  }
}
