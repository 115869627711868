// Tanner Fry
// tfry@monetagroup.com
// File contains redux actions for Twilio Conversations.

import { Dispatch } from "react";
import { utility } from "../../../components/Utility";
import axiosInstance from "../../../config/axiosTS";
import { devConstantsTS } from "../../constants/DevConstant";

function GetSendTestEmail() {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.get("/twilio/send-test-email")
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("GetSendTestEmail error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: devConstantsTS.GET_SEND_TEST_EMAIL_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: devConstantsTS.GET_SEND_TEST_EMAIL_FAILURE, error };
	}
}

export const DeveloperRedux = {
	GetSendTestEmail,
}