export const messageConstants = {
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",
  REMOVE_USER_SUCCESS: "REMOVE_USER_SUCCESS",
  REMOVE_USER_FAILURE: "REMOVE_USER_FAILURE",

  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",

  MUTE_CHANNEL_SUCCESS: "MUTE_CHANNEL_SUCCESS",
  MUTE_CHANNEL_FAILURE: "MUTE_CHANNEL_FAILURE",

  UPDATE_CHANNELNAME_SUCCESS: "UPDATE_CHANNELNAME_SUCCESS",
  UPDATE_CHANNELNAME_FAILURE: "UPDATE_CHANNELNAME_FAILURE",
  GET_CLIENT_LIST_SUCCESS: "GET_CLIENT_LIST_SUCCESS",
  GET_CLIENT_LIST_FAILURE: "GET_CLIENT_LIST_FAILURE",

  GLOBAL_SEARCH_SUCCESS: "GLOBAL_SEARCH_SUCCESS",
  GLOBAL_SEARCH_FAILURE: "GLOBAL_SEARCH_FAILURE",

  GET_RECENT_MESSAGES_SUCCESS: "GET_RECENT_MESSAGES_SUCCESS",
  GET_RECENT_MESSAGES_FAILURE: "GET_RECENT_MESSAGES_FAILURE",

  GET_UNREAD_MESSAGES_SUCCESS: "GET_UNREAD_MESSAGES_SUCCESS",
  GET_UNREAD_MESSAGES_FAILURE: "GET_UNREAD_MESSAGES_FAILURE"
};
