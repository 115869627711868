export const calendarConstants = {
  CHECK_CALENDAR_SUCCESS: "CHECK_CALENDAR_SUCCESS",
  CHECK_CALENDAR_FAILURE: "CHECK_CALENDAR_FAILURE",

  EVENT_CREATE_SUCCESS: "EVENT_CREATE_SUCCESS",
  EVENT_CREATE_FAILURE: "EVENT_CREATE_FAILURE",

  GET_CLIENT_LIST_SUCCESS: "GET_CLIENT_LIST_SUCCESS",
  GET_CLIENT_LIST_FAILURE: "GET_CLIENT_LIST_FAILURE",

  GET_EVENT_LIST_SUCCESS: "GET_EVENT_LIST_SUCCESS",
  GET_EVENT_LIST_FAILURE: "GET_EVENT_LIST_FAILURE",

  GET_AVABILITY_SUCCESS: "GET_AVABILITY_SUCCESS",
  GET_AVABILITY_FAILURE: "GET_AVABILITY_FAILURE",

  GET_TIMEZONE_SUCCESS: "GET_TIMEZONE_SUCCESS",
  GET_TIMEZONE_FAILURE: "GET_TIMEZONE_FAILURE",

  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  DELETE_EVENT_FAILURE: "DELETE_EVENT_FAILURE",

  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_FAILURE: "GET_EVENT_FAILURE",

  UPDATE_EVENT_SUCCESS: "UPDATE_EVENT_SUCCESS",
  UPDATE_EVENT_FAILURE: "UPDATE_EVENT_FAILURE",

  GET_COPY_LINK_SUCCESS: "GET_COPY_LINK_SUCCESS",
  GET_COPY_LINK_FAILURE: "GET_COPY_LINK_FAILURE",

  ACCEPT_DECLINE_SUCCESS: "ACCEPT_DECLINE_SUCCESS",
  ACCEPT_DECLINE_FAILURE: "ACCEPT_DECLINE_FAILURE",

  AUTH_TEAM_SUCCESS: "AUTH_TEAM_SUCCESS",
  AUTH_TEAM_FAILURE: "AUTH_TEAM_FAILURE",

  GET_ROOM_RESOURCE_SUCCESS: "GET_ROOM_RESOURCE_SUCCESS",
  GET_ROOM_RESOURCE_FAILURE: "GET_ROOM_RESOURCE_FAILURE",
};
