import axios from "axios";
import config from "./Config";

const instance = axios.create({
  baseURL: config.getHost(),
  timeout: 600000,
});

instance.interceptors.request.use(
  async (reqConfig) => {
    // const accessToken = JSON.parse(localStorage.getItem("token"))
    //   ? JSON.parse(localStorage.getItem("token")).key
    //   : "";
    const accessToken = localStorage.getItem("token");
    const is_new_user = localStorage.getItem("is_new_user");
    let headers;
    if (
      accessToken && !is_new_user &&
      reqConfig.url !== "/user/validate_email" &&
      reqConfig.url !== "/user/send_forgot_password_code" &&
      reqConfig.url !== "/user/login" &&
      reqConfig.url !== "/user/send_an_otp" &&
      reqConfig.url !== "/user/validate_forgot_password_code" &&
      reqConfig.url !== "/user/reset_password" &&
      !reqConfig.url.includes("update_signature_image")
    ) {
      headers = {
        Authorization: `token ${accessToken}`,
        "Content-Type": "application/json",
      };
    } else if (reqConfig.url.includes("update_signature_image")) {
      headers = {
        Authorization: `token ${accessToken}`,
        "Content-Type": "text/plain",
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }
    return { ...reqConfig, headers };
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
