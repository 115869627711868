// Tanner Fry
// tfry@monetagroup.com
// Custom hooks.

import { useState } from "react";

export const useEffectOnce = (initCallback: () => void) => {
    const [initialized, setInitialized] = useState(false);

    if (!initialized) {
        initCallback();
        setInitialized(true);
    }
};