export const profileNotificationSetting = {
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILURE: "GET_NOTIFICATION_FAILURE",

  CHANGE_NOTIFICATION_SUCCESS: "CHANGE_NOTIFICATION_SUCCESS",
  CHANGE_NOTIFICATION_FAILURE: "CHANGE_NOTIFICATION_FAILURE",

  GET_PROFILE_INFO_SUCCESS: "GET_PROFILE_INFO_SUCCESS",
  GET_PROFILE_INFO_FAILURE: "GET_PROFILE_INFO_FAILURE",

  OTP_SUCCESS: "OTP_SUCCESS",
  OTP_FAILURE: "OTP_FAILURE",

  OTP_VALID_SUCCESS:"OTP_VALID_SUCCESS",
  OTP_VALID_FAILURE:"OTP_VALID_FAILURE",

  RESET_PASSWORD_SUCCESS : 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE : 'RESET_PASSWORD_FAILURE',
  
  NOTIFICATION_SETTING_SUCCESS : 'NOTIFICATION_SETTING_SUCCESS',
  NOTIFICATION_SETTING_FAILURE : 'NOTIFICATION_SETTING_FAILURE',

  IMAGE_UPLOAD_SUCCESS : 'IMAGE_UPLOAD_SUCCESS',
  IMAGE_UPLOAD_FAILURE : 'IMAGE_UPLOAD_FAILURE',

  NOTIFICATION_SNOOZE_SUCCESS : 'NOTIFICATION_SNOOZE_SUCCESS',
  NOTIFICATION_SNOOZE_FAILURE : 'NOTIFICATION_SNOOZE_FAILURE',

  GET_NOTIFICATION_SNOOZE_SUCCESS : 'GET_NOTIFICATION_SNOOZE_SUCCESS',
  GET_NOTIFICATION_SNOOZE_FAILURE : 'GET_NOTIFICATION_SNOOZE_FAILURE',

  WORKING_HOUR_SUCCESS: "WORKING_HOUR_SUCCESS",
  WORKING_HOUR_FAILURE: "WORKING_HOUR_FAILURE",
};
