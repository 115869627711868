import React, { useState } from "react";
import { utility } from "../../components/Utility";

const TopicDropdownDiv = ({
  dropdowndata,
  setTopic,
  setSelected,
  selected,
  dropDownTopic,
  setDropDownTopic,
  setRemoved,
  ellipsify,
}) => {
  const [topicState, setTopicState] = useState({
    searchText: "",
    filterList: dropdowndata,
  });
  const { searchText, filterList } = topicState;


  return (
    <div className="dropdown-content">
      <div className="px-24 py-md-8 py-12 border-mt-top">
        <div className="search-area">
          <button className="search-btn-cdc icon-20">
            <em className="icon-search-outline"></em>
          </button>
          <input
            className="form-control increase-padding-cdc-dropdown"
            value={searchText}
            onChange={(e) => {
              let lst = dropdowndata?.filter(
                (c) =>
                  e.target.value === "" ||
                  c.name?.toLowerCase().includes(e.target.value?.toLowerCase())
              );
              setTopicState((s) => ({
                ...s,
                searchText: e.target.value,
                filterList: lst,
              }));
            }}
          />
          {searchText !== "" && (
            <button
              className="close-search-btn icon-20"
              onClick={(event) => {
                let lst = dropdowndata;
                setTopicState((s) => ({
                  ...s,
                  searchText: "",
                  filterList: lst,
                }));
              }}
            >
              <em className="icon-x-circle-filled"></em>
            </button>
          )}
        </div>
      </div>
      <ul className="check-list list-unstyled custom-style-scroll">
        {filterList &&
          filterList?.map((topic, indx) => (
            <li
              key={indx + topic?.name}
              className={`${topic.isSelected ? "selected" : ""}`}
            >
              <div className="form-check mb-0 d-flex">
                <input
                  className="form-check-input"
                  type="Checkbox"
                  checked={topic.isSelected}
                  id={topic?.name}
                  onChange={(e) => {
                    let indx = dropdowndata?.findIndex(
                      (c) => c.uuid === topic.uuid
                    );
                    dropdowndata[indx].isSelected = e?.target?.checked;
                    setTopic(dropdowndata);
                    if (dropdowndata[indx].isSelected === true) {
                      setDropDownTopic([
                        ...dropDownTopic,
                        {"name":"  " + utility.decodeEntity(dropdowndata[indx]?.name), "uuid": dropdowndata[indx]?.uuid},
                      ]);
                      setSelected([
                        ...selected,
                        {
                          value: dropdowndata[indx]?.uuid,
                          label: utility.decodeEntity(dropdowndata[indx]?.name),
                        },
                      ]);
                      setRemoved("");
                    } else {
                      var toRemove = "  "+utility.decodeEntity(dropdowndata[indx]?.name);
                      var index = dropDownTopic.findIndex(item => item.name === toRemove);
                      if (index > -1) {
                        //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                        const removed = dropDownTopic.splice(index, 1);
                        setRemoved(removed[0]);
                        setDropDownTopic([...dropDownTopic]);
                      }
                      const filtered = selected.filter((filter) => {
                        return filter.value !== dropdowndata[indx].uuid;
                      });
                      setSelected(filtered);
                    }
                    indx = filterList?.findIndex((c) => c.uuid === topic.uuid);
                    filterList[indx].isSelected = e?.target?.checked;
                    setTopicState((s) => ({
                      ...s,
                      filterList: filterList,
                    }));
                  }}
                />
                <label className="form-check-label ms-16" htmlFor="filtclient2">
                  {searchText
                    ? utility.getHighlightedText(utility.decodeEntity(topic?.name), searchText)
                    : utility.decodeEntity(topic?.name)}
                </label>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TopicDropdownDiv;
