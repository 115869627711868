import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import Header from './Header';
import SideBar from './SideBar';

const Mainlayout = () => {
	const [toggleMobileMenu, settoggleMobileMenu] = useState(false);
	const headerRef = useRef(null);
	const [contentHeight, setContentHeight] = useState(0);
	const togglebtn = () => {
		settoggleMobileMenu(!toggleMobileMenu);
	}
	const location = useLocation();

	useEffect(() => {
		if (headerRef.current) {
			// Set content height 100% - headerRef height
			setContentHeight(window.innerHeight - headerRef.current.clientHeight);
			// Run this every half second to make sure the header height is correct
			// const interval = setInterval(() => {
			// 	setContentHeight(window.innerHeight - headerRef.current.clientHeight);
			// }, 500);

			// return () => clearInterval(interval);
		}
	}, [headerRef, location]);

	return (
		<>
			<Header togglebtn={togglebtn} headerRef={headerRef} />
			<div className='d-flex content-wrap overflow-auto' style={{ height: contentHeight }}>
				<SideBar toggleMobileMenu={toggleMobileMenu} togglebtn={togglebtn} />
				<div className='flex-fill main-content-bg content-right overflow-auto'>
					<Outlet />
				</div>
			</div>
			{/* <Footer /> */}
		</>
	)
}

export default Mainlayout;