import { docuSignConstants } from "../../constants/DocuSignConstant";

const INITIAL_STATE = {
  data: {},
  error: {},
}

const GetSignatureList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case docuSignConstants.GET_PENDING_LIST_SUCCESS:
    return {...INITIAL_STATE , data: action.data };
  case docuSignConstants.GET_SIGNATURE_LIST_FAILURE:
    return {...INITIAL_STATE , error: action.error };
  default:
    return state
  }
}

export default GetSignatureList;