/* eslint-disable no-mixed-operators */
/* eslint-disable indent */
import React, { useEffect, useState, useRef } from "react";
import { Table, Dropdown, Menu, notification, Checkbox, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {useLocation} from 'react-router-dom';
import { shareFile } from "../../redux/actions/ShareFile/ShareFile";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { useReactToPrint } from "react-to-print";
// import { Button, Dropdown } from "react-bootstrap";
import Loading from "../../components/Loader";
import Pdf from "../../Assets/Images/PDF.svg";
import Excel from "../../Assets/Images/Excel.svg";
import FileSvg from "../../Assets/Images/File.svg";
import Word from "../../Assets/Images/Word.svg";
import Image from "../../Assets/Images/Shared-Image.svg";
import Video from "../../Assets/Images/Video.svg";
import PDF from "../../Assets/Images/PDF.svg";
import ZIP from "../../Assets/Images/zip.svg";
import PPT from "../../Assets/Images/PPT.svg";
import CSV from "../../Assets/Images/csv.svg";
import TXT from "../../Assets/Images/text.svg";
import MOV from "../../Assets/Images/mov.svg";
import FileViewer from "react-file-viewer";
import axios from "axios";
import { ConfigProvider, Empty } from "antd";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import useDebounce from "./searchdebounce";
import { useAppDispatchJS, useAppSelectorJS } from "../../redux/hooks";

const SharedTab = (props) => {
  const dispatch = useAppDispatchJS();
  const [loader, setLoader] = useState(false);
  const [allSharedFolder, setAllSharedFolder] = useState({});
  const [show, setShow] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [isActionFromBulk, setActionFromBulk] = useState(false);
  const [isNote, setisNote] = useState(false);
  const [showAddFolder, setAddFolderShow] = useState(false);
  const [showUploadFile, setUploadFileShow] = useState(false);
  const [showRname, setRenameShow] = useState(false);
  const [nameTemp, setNameTemp] = useState("");
  const [showView, showViewFile] = useState(false);
  const [showViewExpand, setShowViewExpand] = useState(false);
  const [showCopy, setCopyShow] = useState(false);
  const [showBulk, setBulkShow] = useState(false);
  const [showSort, setSortShow] = useState(false);
  const [showAction, setActionShow] = useState(false);
  const [currentItem, setCurrentItem] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [valueFilter, setValue] = useState("");
  const [cloneAllShared, setCloneAllShared] = useState({});
  const [isDrag, setIsDrag] = useState(false);
  const [showViewDetail, setViewDetailShow] = useState(false);
  const [showViewAction, setViewActionShow] = useState(false);
  const [fromNotification, setFromNotification] = useState(true);
  const [permissions, setPermissions] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [notCallAPI, setNotCallAPI] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [emptyLoader, setEmptyLoader] = useState(false);

  const location = useLocation();

  const accessControl = useAppSelectorJS((state) => state.GetAccessControl);

  const [selectedRowKeys, setRowKeys] = useState([]);

  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [state, setState] = useState({
    itemDetails: {},
    errors: {},
    FolderName: "",
    // selectedRowKeys: [],
    FolderselectedRowKeys: [],
    selectedFolderName: "",
    breadCrumb: {},
    isReverseProps: false,
    FolderbreadCrumb: {},
    folderList: [],
    SelectedItemListToCopy: [],
    Files: [],
    hasDownload: false,
    hasUploaded: false,
    currentPreviewURL: "",
    EditNameFlag: false,
    EditNoteFlag: false,
    NewFileName: "",
    NewNote: "",
    zoom: 1,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleAddFolderClose = () => setAddFolderShow(false);
  const handleAddFolderShow = () => setAddFolderShow(true);
  const handleUploadFileClose = () => setUploadFileShow(false);
  const handleUploadFileShow = () => {
    setUploadFileShow(true);
    setState((s) => ({
      ...s,
      Files: [],
    }));
  };
  const handleRenameClose = () => setRenameShow(false);
  const handleRenameShow = () => setRenameShow(true);
  const [rename, SetrenameFileExtenstion] = useState("");
  const handleViewDetailClose = () => {
    setViewDetailShow(false);
  };
  const handleViewDetailShow = () => setViewDetailShow(true);

  const handleViewActionClose = () => {
    setViewActionShow(false);
  };
  const handleViewActionShow = () => setViewActionShow(true);

  const user_sharefile_id = JSON.parse(
    localStorage.getItem("shareFile")
  )?.user_id.trim()

  //Create Ref
  let fileUploadRef = React.createRef();
  const handleCopyClose = () => {
    setState((s) => ({
      ...s,
      FolderselectedRowKeys: [],
    }));
    setCopyShow(false);
  };
  const handleFilterClose = () => {
    setState((s) => ({
      ...s,
      FolderselectedRowKeys: [],
    }));

    setShowFilter(false);
  };
  const handleFilterShow = () => setShowFilter(true);
  const handleCopyShow = () => setCopyShow(true);

  const handleBulkClose = () => {
    setBulkShow(false);
  };
  const handleBulkShow = () => setBulkShow(true);

  const handleSortClose = () => {
    setSortShow(false);
  };
  const handleSortShow = () => setSortShow(true);

  const handleActionClose = () => {
    setActionShow(false);
  };
  const handleActionShow = () => setActionShow(true);

  const [FolderbreadCrumb, setFolderBreadCrumb] = useState({});
  const {
    // selectedRowKeys,
    FolderselectedRowKeys,
    selectedFolderName,
    itemDetails,
    folderList,
    SelectedItemListToCopy,
    currentPreviewURL,
    EditNameFlag,
    EditNoteFlag,
    NewFileName,
    NewNote,
    zoom,
  } = state;
  // const [breadCrumb, setBreadCrumb]

  useEffect(()=>{
    if (!searchValue && searchList && searchList?.length>0) {
      setSearchList([]);
    }
  },[searchList])

  const debouncedSearchTerm = useDebounce(searchValue);

  useEffect(
    () => {
      if (
        (debouncedSearchTerm && debouncedSearchTerm.trim().length >= 3)
      ) {
        // Fire off our API call
          getSeachList();
      }
    },
    [debouncedSearchTerm]
  );


  useEffect(() => {
    if (!props?.showFilter && !notCallAPI){
    notification.close("Remove Undo");
    start();
    setState((s) => ({
      ...s,
      isReverseProps: props.isReverseProps,
    }));
    if (location?.state && fromNotification) { 
      setEmptyLoader(true);
      navigateBreadCrumb(location?.state);
      setFromNotification(false);
    } 
    else{
      if (props.props === "2" && !state.isReverseProps) {
        setAllSharedFolder({});
        getAllFavoriteFolder();
      } else if (props.props === "3" && !state.isReverseProps) {
        setAllSharedFolder({});
        getAllPersonalFolder();
      } else if (props.props === "4" && !state.isReverseProps) {
        setAllSharedFolder({});
        getAllRecycleFolder();
      } else if (props.props === "1" && !state.isReverseProps) {
        setAllSharedFolder({});
        getAllSharedFolder("allshared");
        getShareFilePermissions("allshared")
      }
    }

    if (!JSON.parse(localStorage.getItem("shareFileUser"))?.Id) {
      getUserDetails();
    }

    setTabContentHeight();
  }
  else{
    setNotCallAPI(false);
  }
  }, [props]);

  useEffect(() => {
    if (isDrag && !showUploadFile) {
      GetChunkUrl();
      setIsDrag(false);
    }
  }, [state]);

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  const start = () => {
    setRowKeys([]);
  };

  const NotifyDelete = (type, mesage, icons, deletedItems) => {
    notification[type]({
      message: mesage,
      description: (
        <a
          href="javascript:void(0)"
          onClick={() => {
            RestoreFiles(deletedItems);
          }}
        >
          Undo
        </a>
      ),
      icon: icons,
      key: "Remove Undo",
    });
  };
  function setTabContentHeight() {
    //   if(location.pathname ===  "/dashboard/share-file"){
    let headerHeight = document.getElementsByTagName("header")[0]?.clientHeight;
    let headingWrap =
      document.getElementsByClassName("heading-wrap")[0]?.clientHeight;
    let tabWrap = document.getElementsByClassName("tab-wrap")[0].clientHeight;
    let contentHeight = document.getElementsByClassName("content-area")[0];
    let total = headerHeight + headingWrap + tabWrap;
    if (total > 0) {
      //contentHeight.style.height = ( "calc(100vh-213px)");
    }
    // }
  }

  function getSeachList() {
    setSearchLoader(true);
    try{
    const data = {
      "Query":{
        "SearchQuery":searchValue
      },
      "Paging":{
        "Count":100,
        "Skip": 0
      }
    }
    dispatch(shareFile.getFilesSearchList(data)).then((response) => {
      setSearchLoader(false);
      if (response?.type === "GET_SEARCH_SUCCESS") {
      setSearchList(response?.data?.Results.filter(item => {
          if (item.ItemType === 'file' && !item.CanView) {
            return;
          } else {
            return item;
          }
      }));
      } else {
        //setloginView(true);
        setSearchLoader(true);
      }
    });
  }
    catch (error){
      setLoader(false);
    }
  }

  //Get All Shared Folder /Items(allShared)/Children
  function getAllSharedFolder(type) {
    setLoader(true);
    //  e.preventDefault();
    dispatch(shareFile.getSharedFolder(type)).then((response) => {
      if (response.type === "GET_ALL_SHARED_FOLDER_SUCCESS") {
        setAllSharedFolder(response.data);
        // let data = response?.data?.value.filter((f)=>f["odata.metadata"].includes("ShareFile.Api.Models.Folder"));
        setCloneAllShared(response.data);

        if (response.data?.value?.length > 0 && response.data.value[0]?.Id) {
          getBreadCrumb(response.data.value[0].Id);
        }
      } else {
      }
      setLoader(false);
    });
  }

  function getShareFilePermissions(type) {
    setLoader(true);
    //  e.preventDefault();
    dispatch(shareFile.getShareFilePermissions(type)).then((response) => {
      setLoader(false);
      if (response.type === "GET_ALL_SHARE_FILES_PERMISSIONS_SUCCESS") {
        setPermissions(response.data);
      } else {
      }
    });
  }
  
  //Get All Personal Folder /Items/Children
  function getAllPersonalFolder() {
    setLoader(true);
    //  e.preventDefault();
    dispatch(shareFile.getPersonalFolder()).then((response) => {
      setLoader(false);
      if (response.type === "PERSONAL_FOLDER_SUCCESS") {
        setAllSharedFolder(response.data);
        if (response.data?.value?.length > 0 && response.data.value[0]?.Id) {
          getBreadCrumb(response.data.value[0].Id);
        }
      } else {
      }
    });
  }

  //Get All Personal Folder /Items/Children
  function getAllRecycleFolder() {
    setLoader(true);
    //  e.preventDefault();
    dispatch(shareFile.getDeteleFolder()).then((response) => {
      setLoader(false);
      if (response.type === "RECYLCE_FOLDER_SUCCESS") {
        setAllSharedFolder(response.data);
        if (response.data?.value?.length > 0 && response.data.value[0]?.Id) {
          getBreadCrumb(response.data.value[0].Id);
        }
      } else {
      }
    });
  }

  // Get All Favourite Folder /Items/Children
  function getAllFavoriteFolder() {
    setLoader(true);
    //  e.preventDefault();
    let userId = JSON.parse(localStorage.getItem("shareFileUser"))?.Id;
    dispatch(shareFile.getFavouriteFile(userId)).then((response) => {
      setLoader(false);
      if (response.type === "GET_FAVORITE_FOLDER_SUCCESS") {
        let data = [];
        for (let i = 0; i < response.data.value.length; i++) {
          data.push(response.data.value[i].Item);
        }
        setAllSharedFolder({ value: data });

        if (data.length > 0 && data[0].Id) {
          getBreadCrumb(data[0]?.Id);
        }
      } else {
      }
    });
  }

  function handleMenuClick(e) {}
  function getTopFolders() {
    setLoader(true);
    //  e.preventDefault();
    dispatch(shareFile.getTopFolders()).then((response) => {
      setLoader(false);
      if (response.type === "GET_TOPFOLDERS_SUCCESS") {
        FolderbreadCrumb.value = [{ Id: "", FileName: "Folder" }];
        setState((s) => ({
          ...s,
          folderList: response.data?.value.filter((f) =>
            f["odata.metadata"].includes("ShareFile.Api.Models.Folder")
          ),
          FolderbreadCrumb: FolderbreadCrumb,
        }));
        handleCopyShow();
      }
    });
  }
  //  function handleMenuClick(e) {}

  function handleAddNewMenuClick(e) {
    if (e.key === "createFolder") {
      setState((s) => ({
        ...s,
        FolderName: "",
      }));
      handleAddFolderShow();
    } else if (e.key === "uploadFile") {
      handleUploadFileShow();
    }
  }

  function navigateBreadCrumb(record) {
    getFolderById(record);
  }

  //Get Folder by Id
  function getFolderById(record) {
    setLoader(true);
    if (record.FileCount >= 0) {
      dispatch(shareFile.getSharedItemById(record.Id)).then((response) => {
        if (response.type === "GET_SHARED_FOLDER_BY_ID_SUCCESS") {
          setAllSharedFolder(response.data);
          // let data = response?.data?.value.filter((f)=>f["odata.metadata"].includes("ShareFile.Api.Models.Folder"));
          setCloneAllShared(response.data);
          getShareFilePermissions(record.Id);
          if (response.data?.value?.length > 0 && response.data.value[0].Id) {
            getBreadCrumb(response.data.value[0]?.Id);
          } else {
            if (
              record.FileName !==
              state.breadCrumb.value[state.breadCrumb.value.length - 1].FileName
            ) {
              let temp = state?.breadCrumb;
              temp.value.push(record);
              setState((s) => ({
                ...s,
                breadCrumb: temp,
                selectedRowKeys: [],
              }));
              getAccessControls(record);
            }
          }
          props?.onClose();
          setSearchList([]);
          setSearchValue("");
        } else {
        }
        setLoader(false);
      });
    }
    setInterval(
      function(){setEmptyLoader(false);},
      5000
    );
  }

  function getIcon(fileName) {
    let extension = fileName?.substring(fileName?.lastIndexOf(".") + 1);
    if (fileName && ["MP4", "mp4", "webm", "wav", "mp3"].includes(extension)) {
      return Video;
    } else if (fileName && ["jpg", "png", "jpeg", "jfif"].includes(extension)) {
      return Image;
    } else if (fileName && ["doc", "docx", "rtf"].includes(extension)) {
      return Word;
    } else if (fileName && ["xls", "xlsx", "xlm"].includes(extension)) {
      return Excel;
    } else if (fileName && ["pdf"].includes(extension)) {
      return PDF;
    } else if (fileName && ["zip"].includes(extension)) {
      return ZIP;
    } else if (fileName && ["ppt", "pptx"].includes(extension)) {
      return PPT;
    } else if (fileName && ["csv"].includes(extension)) {
      return CSV;
    } else if (fileName && ["txt","text"].includes(extension)) {
      return TXT;
    }else if (fileName && ["mov","MOV"].includes(extension)) {
      return MOV;
    }
    else {
      return FileSvg;
    }
  }

  //Get Access Control
  function getAccessControls(record) {
    setLoader(true);
    let userId = JSON.parse(localStorage.getItem("shareFileUser"))?.Id;
    if (record.FileCount >= 0) {
      dispatch(shareFile.getAccessControls(userId, record.Id)).then(
        (response) => {
          setLoader(false);
          if (response.type === "GET_ACCESS_CONTROLS_SUCCESS") {
            setState((s) => ({
              ...s,
              hasDownload: response?.data?.NotifyOnDownload,
              hasUploaded: response?.data?.NotifyOnUpload,
            }));
          } else {
          }
        }
      );
    }
  }

  function addAccessControls(record, download, upload) {
    setLoader(true);
    let userId = JSON.parse(localStorage.getItem("shareFileUser"))?.Id;
    accessControl.data.NotifyOnDownload = download;
    accessControl.data.NotifyOnUpload = upload;

    dispatch(shareFile.addAccessControls(record.Id, accessControl.data)).then(
      (response) => {
        setLoader(false);
        if (response.type === "ADD_ACCESS_CONTROLS_SUCCESS") {
        } else {
        }
      }
    );
  }

  function hasDownloadedToggle(e) {
    //e.preventDefault();
    let download = state.hasDownload;
    let upload = state.hasUploaded;
    if (state.hasDownload) {
      download = false;
      setState((s) => ({
        ...s,
        hasDownload: false,
      }));
    } else {
      download = true;
      setState((s) => ({
        ...s,
        hasDownload: true,
      }));
    }
    if (
      state?.breadCrumb?.value[state.breadCrumb.value.length - 1].FileName !==
      "Shared Folders"
    ) {
      addAccessControls(
        state?.breadCrumb?.value[state.breadCrumb.value.length - 1],
        download,
        upload
      );
    }
  }

  function hasUploadedToggle(e) {
    // e.preventDefault();
    let download = state.hasDownload;
    let upload = state.hasUploaded;
    if (state.hasUploaded) {
      upload = false;
      setState((s) => ({
        ...s,
        hasUploaded: false,
      }));
    } else {
      upload = true;
      setState((s) => ({
        ...s,
        hasUploaded: true,
      }));
    }
    if (
      state?.breadCrumb?.value[state.breadCrumb.value.length - 1].FileName !==
      "Shared Folders"
    ) {
      addAccessControls(
        state?.breadCrumb?.value[state.breadCrumb.value.length - 1],
        download,
        upload
      );
    }
  }

  function navigateFolderBreadCrumb(Id) {
    getFoldersByIdForCopy(Id);
  }

  //Get Folder by Id
  function getFoldersByIdForCopy(Id) {
    setLoader(true);
    dispatch(shareFile.getSharedItemById(Id)).then((response) => {
      setLoader(false);
      if (response.type === "GET_SHARED_FOLDER_BY_ID_SUCCESS") {
        setState((s) => ({
          ...s,
          folderList: response.data?.value.filter((f) =>
            f["odata.metadata"].includes("ShareFile.Api.Models.Folder")
          ),
        }));
        if (response.data?.value?.length > 0 && response.data.value[0].Id) {
          getFolderBreadCrumb(
            response.data.value[response?.data?.value?.length - 1]?.Id
          );
        } else {
          let temp = state?.FolderbreadCrumb;
          temp.value.unshift({ Id: "", FileName: "Folder" });
          //setState(temp);
          setState((s) => ({
            ...s,
            FolderbreadCrumb: temp,
          }));
        }
      } else {
      }
    });
  }
  const getDetailsById = (record) => {
    setLoader(true);
    dispatch(shareFile.getDetailsById(record.Id)).then((response) => {
      handleShow();
      setLoader(false);
      if (response.type === "GET_DETAILS_BY_ID_SUCCESS") {
        setState((s) => ({
          ...s,
          itemDetails: response.data,
        }));
      } else {
      }
    });
  };

  const RestoreFiles = (items) => {
    setLoader(true);
    dispatch(shareFile.RestoreFiles(items)).then((response) => {
      setLoader(false);
      if (response.type === "RESTORE_FILE_SUCCESS") {
        Notify(
          "success",
          `Recover Successfully`
          // <em className="icon-folder-download-filled"></em>
        );
        if (props.props === "2") {
          getAllFavoriteFolder();
        } else if (props.props === "4") {
          getAllRecycleFolder();
        } else {
          getFolderById(
            state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
          );
        }
      } else {
        Notify(
          "error",
          "Could not restore this item because an item at higher level is still expired."
          // <em className="icon-x-outline"></em>
        );
      }
    });
  };

  const getNameById = (record) => {
    setLoader(true);
    dispatch(shareFile.getDetailsById(record.Id)).then((response) => {
      setNameTemp("");
      handleRenameShow();
      setLoader(false);
      if (response.type === "GET_DETAILS_BY_ID_SUCCESS") {
        SetrenameFileExtenstion(
          response.data.Name.includes(".")
            ? response.data.Name?.substring(
                response.data.Name?.lastIndexOf(".") + 1
              )
            : ""
        );
        setState((s) => ({
          ...s,
          itemDetails: response.data,
        }));
      } else {
      }
    });
  };
  //Get Bread Crumb
  function getBreadCrumb(currentId) {
    setLoader(true);
    //  e.preventDefault();
    dispatch(shareFile.getBreadCrumb(currentId)).then((response) => {
      setLoader(false);
      if (response.type === "GET_BREAD_CRUMB_SUCCESS") {
        start();
        // setBreadCrumb(response.data);
        if (
          response?.data?.value[response.data.value.length - 1]?.FileName ===
          "Team_External"
        ) {
          response.data.value.pop();
        }
        setState((s) => ({
          ...s,
          breadCrumb: response.data,
          selectedRowKeys: [],
        }));
        getAccessControls(
          response?.data?.value[response?.data?.value.length - 1]
        );
      } else {
      }
    });
  }

  //Get Bread Crumb
  function getFolderBreadCrumb(currentId) {
    setLoader(true);
    //  e.preventDefault();
    dispatch(shareFile.getBreadCrumb(currentId)).then((response) => {
      setLoader(false);
      if (response.type === "GET_BREAD_CRUMB_SUCCESS") {
        response.data.value.unshift({ Id: "", FileName: "Folder" });
        setState((s) => ({
          ...s,
          FolderbreadCrumb: response.data,
        }));
      } else {
      }
    });
  }

  function getUserDetails() {
    setLoader(true);
    //  e.preventDefault();
    dispatch(shareFile.getAccountInformation()).then((response) => {
      setLoader(false);
      if (response.type === "GET_ACCOUNT_INFO_SUCCESS") {
        localStorage.setItem("shareFileUser", JSON.stringify(response.data));
      } else {
      }
    });
  }

  function addToFavourite(streamId) {
    setLoader(true);
    let userId = JSON.parse(localStorage.getItem("shareFileUser"))?.Id;
    let param = {
      Item: { StreamID: streamId },
    };
    dispatch(shareFile.addToFavourite(userId, param)).then((response) => {
      setLoader(false);
      if (response.type === "ADD_FAVOURITE_SUCCESS") {
        if (
          state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
            ?.FileName === "Team_External"
        ) {
          getFolderById(
            state?.breadCrumb?.value[state.breadCrumb.value.length - 2]
          );
        } else {
          getFolderById(
            state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
          );
        }
      } else {
      }
    });
  }

  function deleteFromFavourite(streamId) {
    setLoader(true);
    let userId = JSON.parse(localStorage.getItem("shareFileUser"))?.Id;
    dispatch(shareFile.deleteFromFavourite(userId, streamId)).then(
      (response) => {
        setLoader(false);
        if (response.type === "DELETE_FAVOURITE_SUCCESS") {
          if (props.props === "2") {
            getAllFavoriteFolder();
          } else {
            if (
              state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
                ?.FileName === "Team_External"
            ) {
              getFolderById(
                state?.breadCrumb?.value[state.breadCrumb.value.length - 2]
              );
            } else {
              getFolderById(
                state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
              );
            }
          }
        } else {
        }
      }
    );
  }

  const deleteItemById = (Id) => {
    setLoader(true);
    dispatch(shareFile.deleteItemById(Id)).then((response) => {
      setLoader(false);
      if (response.type === "DELETE_ITEM_BY_ID_SUCCESS") {
        NotifyDelete(
          "success",
          "Deleted 1 item",
          <em className="icon-trash-filled"></em>,
          [Id]
        );

        //getAllSharedFolder("allshared");
        getFolderById(
          state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
        );
      } else {
      }
    });
  };

  const deleteMovedItemById = (Id, cnt = 1) => {
    setLoader(true);
    dispatch(shareFile.deleteItemById(Id)).then((response) => {
      setLoader(false);
      if (response.type === "DELETE_ITEM_BY_ID_SUCCESS") {
        Notify(
          "success",
          `Moved ${cnt} item to "${selectedFolderName}"`,
          <em className="icon-folder-download-filled"></em>
        );

        getFolderById(
          state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
        );
      } else {
      }
    });
  };

  const deleteMovedItem = (Id) => {
    dispatch(shareFile.deleteItemById(Id)).then((response) => {
      if (response.type === "DELETE_ITEM_BY_ID_SUCCESS") {
      } else {
      }
    });
  };
  function handleDrop(ev, fromDrag = false) {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    let dragHere = document.getElementsByClassName("drag-here")[0];
    dragHere?.classList?.remove("d-block");
    dragHere?.classList?.add("d-none");

    var fileArray = [];
    setState((s) => ({
      ...s,
      Files: [],
    }));

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (
          ev.dataTransfer.items[i].kind === "file" &&
          ev.dataTransfer.items[i].type !== ""
        ) {
          var file = ev.dataTransfer.items[i].getAsFile();
          fileArray.push(file);
        }
      }
      setState((s) => ({
        ...s,
        Files: [...state.Files, ...fileArray],
      }));
      if (fileArray.length > 0) {
        setIsDrag(true);
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (i = 0; i < ev.dataTransfer.files.length; i++) {}
    }
  }

  function fileUploadOnButton(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files;
    let fileArray = [];

    for (var i = 0; i < file.length; i++) {
      // If dropped items aren't files, reject them

      //var fileTemp = file[i].getAsFile();
      fileArray.push(file[i]);
    }
    setState((s) => ({
      ...s,
      Files: [...state.Files, ...fileArray],
    }));

    //  reader.readAsDataURL(file);
  }

  const BulkDelete = () => {
    setLoader(true);
    if (selectedRowKeys.length > 0) {
      dispatch(
        shareFile.BulkDelete(
          state.breadCrumb?.value?.length > 0
            ? state.breadCrumb.value[state.breadCrumb.value.length - 1].Id
            : "allshared",
          selectedRowKeys
        )
      ).then((response) => {
        setLoader(false);
        if (response.type === "BULK_DELETE_SUCCESS") {
          NotifyDelete(
            "success",
            "Deleted " + selectedRowKeys.length + " Items",
            <em className="icon-check-outline"></em>,
            selectedRowKeys
          );
          setState((s) => ({
            ...s,
            selectedRowKeys: [],
          }));
          if (props.props === "2") {
            getFolderById(
              state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
            );
          } else {
            getFolderById(
              state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
            );
          }
        } else {
        }
      });
    }
  };

  function shareFileWebhook(OperationName, ResourceType, ResourceId, ParentId) {
    let obj = {
      WebhookSubscriptionId: "",
      AccountInfo: {
        AccountId: JSON.parse(
          localStorage.getItem("shareFile")
        )?.user_id.trim(),
        Subdomain: JSON.parse(localStorage.getItem("shareFile"))
          ? JSON.parse(localStorage.getItem("shareFile"))
              ?.subdomain.replace(/"/g, "")
              .trim()
          : "",
        AppControlPlane: JSON.parse(
          localStorage.getItem("shareFile")
        )?.appcp.trim(),
        ApiControlPlane: JSON.parse(
          localStorage.getItem("shareFile")
        )?.apicp.trim(),
      },
      Event: {
        Timestamp: new Date(),
        OperationName: OperationName,
        ResourceType: ResourceType,
        Resource: {
          Id: ResourceId,
          Parent: {
            Id: ParentId,
          },
        },
      },
    };
    dispatch(shareFile.shareFileWebhook(obj)).then((response) => {});
  }

  const BulkDownload = () => {
    setLoader(true);
    if (selectedRowKeys.length > 0) {
      dispatch(
        shareFile.BulkDownload(
          state.breadCrumb?.value?.length > 0
            ? state.breadCrumb.value[state.breadCrumb.value.length - 1].Id
            : "allshared",
          selectedRowKeys
        )
      ).then((response) => {
        if (response.type === "BULK_DOWNLOAD_SUCCESS") {
          const url = response?.data?.DownloadUrl;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", ``);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();
          link.parentNode.removeChild(link);
          setLoader(false);
          Notify(
            "success",
            "Downloaded " + selectedRowKeys.length + " item",
            <em className="icon-download-filled"></em>
          );
        } else {
        }
        setLoader(false);
        callShareFileDownloaduploadReport({
          is_upload: false,
          is_download: true,
          download_count: selectedRowKeys.length,
        });
      });
    }
  };

  const CreateFolder = () => {
    setLoader(true);
    if (state?.FolderName) {
      let parentId =
        FolderselectedRowKeys.length === 0
          ? state.breadCrumb?.value?.length > 0
            ? state.breadCrumb.value[state.breadCrumb.value.length - 1].Id
            : "allshared"
          : FolderselectedRowKeys[0];
      dispatch(shareFile.CreateFolder(state?.FolderName, parentId)).then(
        (response) => {
          setLoader(false);
          if (response.type === "CREATE_FOLDER_SUCCESS") {
            handleAddFolderClose();
            Notify(
              "success",
              "Created new folder",
              <em className="icon-check-outline"></em>
            );
            setState((s) => ({
              ...s,
              FolderName: "",
            }));
            shareFileWebhook("Create", "Folder", response.data.Id, parentId);
            if (FolderselectedRowKeys.length === 0) {
              if (props.props === "2") {
                getFolderById(
                  state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
                );
              } else {
                getFolderById(
                  state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
                );
              }
            } else {
              setCopyShow(true);
            }
          } else {
            handleAddFolderClose();
            Notify(
              "error",
              "Not Authorized"
              // <em className="icon-x-outline"></em>
            );
          }
        }
      );
    }
  };
  const GetChunkUrl = () => {
    setLoader(true);
    let cnt = 0;
    let numItems = state?.Files.length - 1;

    state?.Files.forEach((element, index) => {
      dispatch(
        shareFile.GetChunkUrl(
          state.breadCrumb.value[state.breadCrumb.value.length - 1].Id,
          element
        )
      ).then((response) => {
        if (response.type === "CUNKURL_SUCCESS") {
          var formdata = new FormData();
          formdata.append("Filedata", element, element.name);

          var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
          };

          fetch(response.data.ChunkUri, requestOptions, { mode: "no-cors" })
            .then((response) => response.text())
            .then((result) => {
              callShareFileDownloaduploadReport({
                is_upload: true,
                is_download: false,
              });
              if (index === numItems) {
                handleUploadFileClose();
                setLoader(false);
                Notify(
                  "success",
                  `Uploaded ${state?.Files?.length} item to "${
                    state.breadCrumb.value[state.breadCrumb.value.length - 1]
                      .Name
                  }"`,
                  <em className="icon-download-filled"></em>
                );
                setState((s) => ({
                  ...s,
                  Files: [],
                }));
                if (props.props === "2") {
                  getFolderById(
                    state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
                  );
                } else {
                  getFolderById(
                    state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
                  );
                }
              }
            })
            .catch((error) => {
              setLoader(false);
              handleUploadFileClose();
            });
        } else {
          setLoader(false);
          handleUploadFileClose();
          Notify(
            "error",
            "Not Authorized"
            // <em className="icon-x-outline"></em>
          );
        }
      });
    });
  };

  const UpdateNote = () => {
    setLoader(true);
    dispatch(shareFile.UpdateNote(itemDetails)).then((response) => {
      setLoader(false);
      if (response.type === "UPDATE_NOTE_SUCCESS") {
        handleClose();
        Notify(
          "success",
          "Updated 1 item",
          <em className="icon-download-filled"></em>
        );

        if (props.props === "2") {
          getFolderById(
            state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
          );
        } else {
          getFolderById(
            state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
          );
        }
      } else {
      }
    });
  };

  const UpdateName = () => {
    setLoader(true);
    dispatch(
      shareFile.UpdateName({
        Id: itemDetails?.Id,
        Name: itemDetails?.Name + "." + rename,
      })
    ).then((response) => {
      setLoader(false);
      if (response.type === "UPDATE_NAME_SUCCESS") {
        SetrenameFileExtenstion("");
        handleRenameClose();
        Notify(
          "success",
          "Renamed 1 item",
          <em className="icon-check-outline"></em>
        );
        if (props.props === "2") {
          getFolderById(
            state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
          );
        } else {
          getFolderById(
            state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
          );
        }
        shareFileWebhook(
          "Rename",
          response.data.odata.type === "ShareFile.Api.Models.Folder"
            ? "Folder"
            : "File",
          response.data.Id,
          response.data.Parent.Id
        );
      } else {
      }
    });
  };

  function checkTitle() {
    if (props.props === "4") {
      return "Deleted";
    } else {
      return "Uploaded";
    }
  }

  const UpdateNoteFromPreview = () => {
    setLoader(true);
    dispatch(
      shareFile.UpdateNote({ Id: currentItem?.Id, Description: NewNote })
    ).then((response) => {
      setLoader(false);
      if (response.type === "UPDATE_NOTE_SUCCESS") {
        Notify(
          "success",
          "Updated 1 item",
          <em className="icon-download-filled"></em>
        );

        currentItem.Description = NewNote;
        setState((s) => ({
          ...s,
          EditNoteFlag: !EditNoteFlag,
          currentItem: currentItem,
        }));
      } else {
      }
    });
  };
  const UpdateNameFromPreview = () => {
    setLoader(true);
    dispatch(
      shareFile.UpdateName({ Id: currentItem?.Id, Name: NewFileName })
    ).then((response) => {
      setLoader(false);
      if (response.type === "UPDATE_NAME_SUCCESS") {
        Notify(
          "success",
          "Renamed 1 item",
          <em className="icon-check-outline"></em>
        );
        currentItem.Name = NewFileName;
        setState((s) => ({
          ...s,
          EditNameFlag: !EditNameFlag,
          currentItem: currentItem,
        }));
        shareFileWebhook(
          "Rename",
          response.data.odata.type === "ShareFile.Api.Models.Folder"
            ? "Folder"
            : "File",
          response.data.Id,
          response.data.Parent.Id
        );
      } else {
      }
    });
  };

  function getfileMimeType() {
    let ext = currentItem?.Name?.substring(
      currentItem?.Name?.lastIndexOf(".") + 1
    );
    if (ext === "pdf") {
      return "application/pdf";
    } else if (ext === "doc") {
      return "application/msword";
    } else if (ext === "png") {
      return "image/png";
    } else if (ext === "jpg") {
      return "image/jpg";
    } else if (ext === "ppt") {
      return "application/vnd.ms-powerpoint";
    } else if (ext === "xls") {
      return "application/vnd.ms-excel";
    }
  }

  const CopyBulkItem = () => {
    setLoader(true);
    let cnt = selectedRowKeys.length - 1;
    selectedRowKeys.forEach((element, index) => {
      dispatch(
        shareFile.CopyItem(element, FolderselectedRowKeys[0]),
        isCopy
      ).then((response) => {
        setLoader(false);
        if (response.type === "COPY_ITEM_SUCCESS") {
          if (cnt === index) {
            start();
            handleCopyClose();

            Notify(
              "success",
              (isCopy ? "Copied " : "Moved") +
                ` ${selectedRowKeys.length} item to "${selectedFolderName}"`,
              <em className="icon-document-duplicate-filled"></em>
            );
            shareFileWebhook(
              "Copy",
              response.data.odata.type === "ShareFile.Api.Models.Folder"
                ? "Folder"
                : "File",
              response.data.Id,
              response.data.Parent.Id
            );
            if (props.props === "2") {
              getFolderById(
                state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
              );
            } else {
              getFolderById(
                state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
              );
            }
          }
        } else {
        }
      });
    });
  };
  const CopyItem = () => {
    setLoader(true);
    dispatch(
      shareFile.CopyItem(SelectedItemListToCopy[0], FolderselectedRowKeys[0]),
      isCopy
    ).then((response) => {
      setLoader(false);
      if (response.type === "COPY_ITEM_SUCCESS") {
        handleCopyClose();
        start();
        Notify(
          "success",
          (isCopy ? "Copied " : "Moved") + ` 1 item to "${selectedFolderName}"`,
          <em className="icon-document-duplicate-filled"></em>
        );
        shareFileWebhook(
          "Copy",
          response.data.odata.type === "ShareFile.Api.Models.Folder"
            ? "Folder"
            : "File",
          response.data.Id,
          response.data.Parent.Id
        );
        if (props.props === "2") {
          getFolderById(
            state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
          );
        } else {
          getFolderById(
            state?.breadCrumb?.value[state.breadCrumb.value.length - 1]
          );
        }
      } else {
      }
    });
  };

  const BulkMove = () => {
    setLoader(true);
    dispatch(
      shareFile.MoveItem(selectedRowKeys[0], FolderselectedRowKeys[0]),
      isCopy
    ).then((response) => {
      setLoader(false);
      if (response.type === "COPY_ITEM_SUCCESS") {
        handleCopyClose();

        deleteMovedItemById(SelectedItemListToCopy[0]);
      } else {
      }
    });
  };

  function checkForFavourite(element) {
    addToFavourite(element.StreamID);
  }

  const MoveBulkItem = () => {
    setLoader(true);
    let cnt = selectedRowKeys.length - 1;
    selectedRowKeys.forEach((element, Index) => {
      dispatch(
        shareFile.CopyItem(element, FolderselectedRowKeys[0]),
        isCopy
      ).then((response) => {
        if (response.type === "COPY_ITEM_SUCCESS") {
          let value = allSharedFolder.value.find((x) => element === x.Id);
          if (value.Favorite) {
            checkForFavourite(response.data);
          }
          if (Index === cnt) {
            setLoader(false);
            handleCopyClose();
            deleteMovedItemById(element, selectedRowKeys.length);
          } else {
            deleteMovedItem(element);
          }
          shareFileWebhook(
            "Move",
            response.data.odata.type === "ShareFile.Api.Models.Folder"
              ? "Folder"
              : "File",
            response.data.Id,
            response.data.Parent.Id
          );
        } else {
          setLoader(false);
        }
      });
    });
  };
  const MoveItem = () => {
    setLoader(true);
    dispatch(
      shareFile.CopyItem(SelectedItemListToCopy[0], FolderselectedRowKeys[0]),
      isCopy
    ).then((response) => {
      setLoader(false);
      if (response.type === "COPY_ITEM_SUCCESS") {
        let value = allSharedFolder.value.find(
          (x) => SelectedItemListToCopy[0] === x.Id
        );
        console.log(value);
        if (value.Favorite) {
          checkForFavourite(response.data);
        }

        handleCopyClose();
        deleteMovedItemById(SelectedItemListToCopy[0]);
        shareFileWebhook(
          "Copy",
          response.data.odata.type === "ShareFile.Api.Models.Folder"
            ? "Folder"
            : "File",
          response.data.Id,
          response.data.Parent.Id
        );
      } else {
      }
    });
  };
  const previewById = (record) => {
    if(permissions?.CanView || props.props !== "1"){
    setLoader(true);
    setCurrentItem(record);
    if (
      record?.Name.includes(".doc") ||
      record?.Name.includes(".ppt") ||
      record?.Name.includes(".xls")
    ) {
      dispatch(shareFile.ProtocolLinks(record.Id)).then((response) => {
        setLoader(false);
        showViewFile(true);
        setState((s) => ({
          ...s,
          currentPreviewURL: response?.data?.value[0]?.Link,
        }));
      });
    } else {
      dispatch(shareFile.downloadById(record.Id)).then((response) => {
        setLoader(false);
        showViewFile(true);
        setState((s) => ({
          ...s,
          currentPreviewURL: response?.data?.DownloadUrl,
        }));
      });
    }
    props?.onClose();
    setSearchList([]);
    setSearchValue("");
  }else{
    Notify(
      "error",
      "You are not authorized to view document",
    );
  }
  };
  const downloadById = (record) => {
    setLoader(true);
    dispatch(shareFile.downloadById(record.Id)).then((response) => {
      const url = response?.data?.DownloadUrl;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", ``);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();
      link.parentNode.removeChild(link);
      setLoader(false);
      Notify(
        "success",
        "Downloaded 1 item",
        <em className="icon-download-filled"></em>
      );
      callShareFileDownloaduploadReport({
        is_upload: false,
        is_download: true,
      });
    });
  };

  const callShareFileDownloaduploadReport = (payload) => {
    setLoader(true);
    dispatch(shareFile.shareFileUploadDownloadReport(payload)).then(
      (response) => {
        setLoader(false);
      }
    );
  };

  const searchFilter = [
    {
      title: "Name",
      dataIndex: "Name",
      render: (text, record) => (
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="icon-24 me-8 cursor-pointer fs-18">
              {record?.ItemType !== "folder" ? (
                <img
                  style={{ width: "21px" }}
                  src={getIcon(record?.FileName)}
                  alt=""
                />
              ) : (
                <em className="icon-folder-filled"></em>
              )}
            </span>
            <p
              className={"cursor-pointer m-0"}
              onClick={(e) => {
                e.preventDefault();
                setShowFilter(false);
                if (record?.ItemType !== "folder") {
                  previewById({...record,Name:record?.FileName,Id:record?.ItemID});
                } else {
                  getFolderById({...record,FileCount:1,Id:record?.ItemID});
                }
                setNotCallAPI(true);
              }}
            >
              {record?.FileName.length > 30
                ? record?.FileName?.slice(0, 30) + "..."
                : record?.FileName}
            </p>
          </div>
          <a
            className="fw-500"
            href="javascript:void(0);"
            onClick={(e) => {
              e.preventDefault();
              setShowFilter(false);
              if (record?.ItemType !== "folder") {
                previewById({...record,Name:record?.FileName,Id:record?.ItemID});
              } else {
                getFolderById({...record,FileCount:1,Id:record?.ItemID});
              }
              setNotCallAPI(true);
            }}
          >
            {record?.ItemType !== "folder"
              ? "View File"
              : "View Folder"}
          </a>
        </div>
      ),
    },
  ];

  const columnFilter = [
    {
      title: "Name",
      dataIndex: "Name",
      render: (text, record) => (
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="icon-24 me-8 cursor-pointer fs-18">
              {record["odata.metadata"].includes("Models.File@Element") ? (
                <img
                  style={{ width: "21px" }}
                  src={getIcon(record?.Name)}
                  alt=""
                />
              ) : (
                <em className="icon-folder-filled"></em>
              )}
            </span>
            <p
              className={"cursor-pointer m-0"}
              onClick={(e) => {
                e.preventDefault();
                setShowFilter(false);
                if (record["odata.metadata"].includes("Models.File@Element")) {
                  previewById(record);
                } else {
                  getFolderById(record);
                }
              }}
            >
              {record?.Name.length > 30
                ? record?.Name?.slice(0, 30) + "..."
                : record?.Name}
            </p>
          </div>
          <a
            className="fw-500"
            href="javascript:void(0);"
            onClick={(e) => {
              e.preventDefault();
              setShowFilter(false);
              if (record["odata.metadata"].includes("Models.File@Element")) {
                previewById(record);
              } else {
                getFolderById(record);
              }
            }}
          >
            {record["odata.metadata"].includes("Models.File@Element")
              ? "View File"
              : "View Folder"}
          </a>
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      className: "ps-16",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      render: (text, record) => (
        <div className="d-flex align-items-center justify-content-between justify-content-md-start">
          <div className="d-flex align-items-center">
            {props.props !== "4" && (
              <span
                className="d-none d-md-block icon-24 me-16 cursor-pointer fs-18"
                onClick={(e) => {
                  e.preventDefault();
                  if (props.props === "2") {
                    deleteFromFavourite(record.StreamID);
                  } else {
                    if (record.Favorite) {
                      deleteFromFavourite(record.StreamID);
                    } else {
                      addToFavourite(record.StreamID);
                    }
                  }
                }}
              >
                <em
                  className={
                    record.Favorite || props.props === "2"
                      ? "icon-star-filled text-warning"
                      : "icon-star-outline"
                  }
                ></em>
              </span>
            )}
            <span className="icon-24 me-16 cursor-pointer fs-18">
              {record["odata.metadata"].includes("Models.File@Element") ? (
                <img
                  style={{ width: "21px" }}
                  src={getIcon(record?.Name)}
                  alt=""
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      !record["odata.metadata"].includes("Models.File@Element")
                    ) {
                      if (props.props === "2") {
                        console.log(record?.SemanticPath, record);

                        if (record?.SemanticPath.includes("Personal Folders")) {
                          console.log("This is the changes for personal");
                          props.func(3);
                          getFolderById(record);
                        } else {
                          props.func(1);
                          getFolderById(record);
                          setState((s) => ({
                            ...s,
                            isReverseProps: true,
                            selectedRowKeys: [],
                          }));
                        }
                      } else {
                        getFolderById(record);
                        setState((s) => ({
                          ...s,
                          selectedRowKeys: [],
                        }));
                      }
                    } else {
                      previewById(record);
                    }
                  }}
                />
              ) : (
                <em
                  className="icon-folder-filled"
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      !record["odata.metadata"].includes("Models.File@Element")
                    ) {
                      if (props.props === "2") {
                        console.log(record?.SemanticPath, record);

                        if (record?.SemanticPath.includes("Personal Folders")) {
                          console.log("This is the changes for personal");
                          props.func(3);
                          getFolderById(record);
                        } else {
                          props.func(1);
                          getFolderById(record);
                          setState((s) => ({
                            ...s,
                            isReverseProps: true,
                            selectedRowKeys: [],
                          }));
                        }
                      } else {
                        getFolderById(record);
                        setState((s) => ({
                          ...s,
                          selectedRowKeys: [],
                        }));
                      }
                    } else {
                      previewById(record);
                    }
                  }}
                ></em>
              )}
            </span>
            <div>
              <Tooltip placement="bottomLeft" title={record.Name} overlayStyle={{maxWidth: '600px'}}>
                <p
                  className="cursor-pointer m-0 overflow-text-ant"
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      !record["odata.metadata"].includes("Models.File@Element")
                    ) {
                      if (props.props === "2") {
                        if (record?.SemanticPath.includes("Personal Folders")) {
                          console.log("This is the changes for personal");
                          props.func(3);
                          getFolderById(record);
                        } else {
                          props.func(1);
                          getFolderById(record);
                          setState((s) => ({
                            ...s,
                            isReverseProps: true,
                            selectedRowKeys: [],
                          }));
                        }
                      } else {
                        getFolderById(record);
                        setState((s) => ({
                          ...s,
                          selectedRowKeys: [],
                        }));
                      }
                    } else {
                      previewById(record);
                    }
                  }}
                >
                  {record.Name}
                </p>
              </Tooltip>
              <div className="d-md-none d-flex text-muted sizedate">
                {moment(record.CreationDate).format("ll")}&nbsp;|&nbsp;
                {(record.FileSizeBytes / (1024 * 1024)).toFixed(2) + " MB"}
              </div>
            </div>
          </div>
          <div className="d-md-none d-flex align-items-center ms-12">
            <span
              className="icon-20 cursor-pointer fs-18"
              onClick={(e) => {
                e.preventDefault();
                if (props.props === "2") {
                  deleteFromFavourite(record.StreamID);
                } else {
                  if (record.Favorite) {
                    deleteFromFavourite(record.StreamID);
                  } else {
                    addToFavourite(record.StreamID);
                  }
                }
              }}
            >
              <em
                className={
                  record.Favorite || props.props === "2"
                    ? "icon-star-filled text-warning"
                    : "icon-star-outline"
                }
              ></em>
            </span>
            <button
              className="btn btn-outline-secondary btn-icon-only ms-12"
              onClick={(e) => {
                e.preventDefault();
                setCurrentItem(record);
                handleActionShow();
              }}
              type="button"
            >
              <em className="icon-dots-horizontal-outline"></em>
            </button>
          </div>
        </div>
      ),
    },
    {
      title: "Size",
      className: props.props === "4" ? "d-none" : "",
      dataIndex: "FileSizeBytes",
      render: (FileSizeBytes) =>
        FileSizeBytes
          ? (FileSizeBytes / (1024 * 1024)).toFixed(2) + " MB"
          : "-",
      sorter: (a, b) =>
        a.FileSizeBytes / (1024 * 1024).toFixed(2) -
        b.FileSizeBytes / (1024 * 1024).toFixed(2),
      responsive: ["md"],
    },
    {
      title: checkTitle(),
      dataIndex: "",
      render: (text, record) => (
        <div>
          {props.props === "4"
            ? moment(record.ExpirationDate).format("ll")
            : moment(record.CreationDate).format("ll")}
        </div>
      ),
      sorter: (a, b) =>
        moment(props.props === "4" ? a.ExpirationDate : a.CreationDate).unix() -
        moment(props.props === "4" ? b.ExpirationDate : b.CreationDate).unix(),
      responsive: ["md"],
    },
    {
      title: "Creator",
      className: props.props === "4" ? "d-none" : "",
      dataIndex: ["CreatorFirstName", "CreatorLastName"],
      render: (text, record) =>
        (record.CreatorFirstName ? record.CreatorFirstName : "-") +
        " " +
        (record.CreatorLastName ? record.CreatorLastName : "-"),
      sorter: (a, b) => a?.CreatorFirstName?.localeCompare(b?.CreatorFirstName),
      responsive: ["md"],
    },
    {
      title: "",
      dataIndex: "",
      className: "position-static",
      render: (text, record) => (
        <div>
          {props.props !== "2" && props.props !== "4" && (
            <div className={props.props !== "2" ? "table-action" : ""}>
              {(permissions?.CanView || props.props !== "1") &&
              <a
                href="javascript:void(0)"
                onClick={() => {
                  setisNote(false);
                  getDetailsById(record);
                }}
              >
                View Details
              </a>}
              {(permissions?.CanDownload || props.props !== "1") &&
              <a
                href="javascript:void(0)"
                onClick={() => {
                  downloadById(record);
                }}
              >
                Download
              </a>}
              {(permissions?.CanDeleteChildItems || props.props !== "1" || record?.Creator?.Id === user_sharefile_id) &&
              <a
                href="javascript:void(0)"
                onClick={() => {
                  getNameById(record);
                }}
              >
                Rename
              </a>}
              {(permissions?.CanDeleteChildItems || props.props !== "1") &&
              <a
                href="javascript:void(0)"
                onClick={() => {
                  setActionFromBulk(false);
                  setIsCopy(false);
                  setState((s) => ({
                    ...s,
                    SelectedItemListToCopy: [record.Id],
                  }));
                  getTopFolders();
                }}
              >
                Move
              </a>}
              {(permissions?.CanDownload || props.props !== "1") &&
              <a
                href="javascript:void(0)"
                onClick={() => {
                  setActionFromBulk(false);
                  setIsCopy(true);
                  setState((s) => ({
                    ...s,
                    SelectedItemListToCopy: [record.Id],
                  }));
                  getTopFolders();
                }}
              >
                Copy
              </a>}
              {(permissions?.CanDeleteChildItems || props.props !== "1" || record?.Creator?.Id === user_sharefile_id) &&
              <a
                href="javascript:void(0)"
                onClick={() => {
                  deleteItemById(record.Id);
                }}
              >
                Delete
              </a>}
              <a
                href="javascript:void(0)"
                onClick={() => {
                  setisNote(true);
                  getDetailsById(record);
                }}
              >
                Add Note
              </a>
            </div>
          )}
          {props.props === "4" && (
            <div className="table-action">
              <a
                href="javascript:void(0)"
                onClick={() => {
                  RestoreFiles([record.Id]);
                }}
              >
                Recover
              </a>
            </div>
          )}
        </div>
      ),
      responsive: ["md"],
    },
    {
      title: "",
      dataIndex: "",
      render: (text, record) => (
        <>
          {record?.Description && (
            <Tooltip
              placement="bottomRight"
              title={
                record?.Description.length > 250
                  ? record?.Description?.slice(0, 250) + "..."
                  : record?.Description
              }
            >
              <span className="clipboard-icon icon-32">
                <em className="icon-clipboard-filled text-warning"></em>
              </span>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const menu = (
    <div>
      {props.props !== "4" && (
        <Menu onClick={handleMenuClick}>
          {(props?.props !== "1" || permissions?.CanDownload) &&
          <Menu.Item
            className="d-flex align-items-center"
            key="1"
            icon={
              <span className="drop-icon me-8">
                <em className="icon-download-filled"></em>
              </span>
            }
            onClick={() => {
              BulkDownload();
            }}
          >
            Download {selectedRowKeys.length} items
          </Menu.Item>}
          {(props?.props !== "1" || permissions?.CanDeleteChildItems) &&
          <Menu.Item
            className="d-flex align-items-center"
            key="2"
            icon={
              <span className="drop-icon me-8">
                <em className="icon-folder-download-filled"></em>
              </span>
            }
            onClick={() => {
              setActionFromBulk(true);
              setIsCopy(false);
              getTopFolders();
            }}
          >
            Move {selectedRowKeys.length} items
          </Menu.Item>}
          {(props?.props !== "1" || permissions?.CanDownload) &&
          <Menu.Item
            className="d-flex align-items-center"
            key="3"
            icon={
              <span className="drop-icon me-8">
                <em className="icon-duplicate-filled"></em>
              </span>
            }
            onClick={() => {
              setActionFromBulk(true);
              setIsCopy(true);
              getTopFolders();
            }}
          >
            Copy {selectedRowKeys.length} items
          </Menu.Item>}
          {(props?.props !== "1" || permissions?.CanDeleteChildItems) &&
          <Menu.Item
            className="d-flex align-items-center"
            key="4"
            icon={
              <span className="drop-icon me-8">
                <em className="icon-trash-filled"></em>
              </span>
            }
            onClick={() => {
              BulkDelete();
            }}
          >
            Delete {selectedRowKeys.length} items
          </Menu.Item>}
        </Menu>
      )}

      {props.props === "4" && (
        <Menu onClick={handleMenuClick}>
          <Menu.Item
            className="d-flex align-items-center"
            key="5"
            icon={
              <span className="drop-icon me-8">
                <em className="icon-trash-filled"></em>
              </span>
            }
            onClick={() => {
              RestoreFiles(selectedRowKeys);
            }}
          >
            Recover {selectedRowKeys.length} items
          </Menu.Item>
        </Menu>
      )}
    </div>
  );
  const AddNewmenu = (
    <Menu onClick={handleAddNewMenuClick}>
      <>
        {(props?.props !== "1" || permissions?.CanUpload) &&
        <Menu.Item key="uploadFile">File Upload</Menu.Item>}
        {(props?.props !== "1" || permissions?.CanAddFolder) &&
        <Menu.Item key="createFolder">New Folder</Menu.Item>}
      </>
    </Menu>
  );

  const Foldercolumns = [
    {
      title: "",
      dataIndex: "Name",
      className: "ps-16",
      render: (text, record) => (
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            setState((s) => ({
              ...s,
              FolderselectedRowKeys: [],
            }));
            getFoldersByIdForCopy(record.Id);
          }}
        >
          <span className="icon-24 me-16">
            <em className="icon-folder-filled"></em>
          </span>
          <p className="cursor-pointer m-0 overflow-text-ant">{text}</p>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "Name",
      render: (text, record) => (
        <span
          className="icon-24 ms-auto cursor-pointer fs-10"
          onClick={(e) => {
            e.preventDefault();
            setState((s) => ({
              ...s,
              FolderselectedRowKeys: [],
            }));
            getFoldersByIdForCopy(record.Id);
          }}
        >
          <em className="icon icon-chevron-right-outline"></em>
        </span>
      ),
    },
  ];

  // const rowSelection = {
  //   hideDefaultSelections: true,
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setState((s) => ({
  //       ...s,
  //       selectedRowKeys: selectedRowKeys,
  //       SelectedItemListToCopy: selectedRowKeys,
  //     }));
  //   },
  // };

  const onSelectChange = (selectedRowKeys) => {
    setRowKeys(selectedRowKeys);
    setState((s) => ({
      ...s,
      SelectedItemListToCopy: selectedRowKeys,
    }));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const CopyRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setState((s) => ({
        ...s,
        FolderselectedRowKeys: selectedRowKeys,
        selectedFolderName: selectedRows[0].Name,
      }));
    },
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      {(emptyLoader || loader) && <Loading active={emptyLoader?emptyLoader:loader} startActiveTime={emptyLoader?100:undefined} />}
      <div
        className="card share-card"
        onDragOver={(e) => {
          e.preventDefault();
          if (
            (state?.breadCrumb?.value &&
              state?.breadCrumb?.value[state?.breadCrumb?.value?.length - 1]
                .FileName !== "Shared Folders" &&
              props.props === "1") ||
            props.props === "3"
          ) {
            let dragHere = document.getElementsByClassName("drag-here")[0];
            dragHere.classList.remove("d-none");
            dragHere.classList.add("d-block");
          }
        }}
      >
        <div className="card-header">
          <div className="d-flex  justify-content-between align-items-start mb-md-16 share-tool">
            <div className="share-tool-left">
              <h3 className="mb-0 mb-md-4 ">
                {state?.breadCrumb?.value
                  ? props.props !== "2" && props.props !== "4"
                    ? state?.breadCrumb?.value[
                        state?.breadCrumb?.value.length - 1
                      ].FileName.length > 52
                      ? state?.breadCrumb?.value[
                          state?.breadCrumb?.value.length - 1
                        ].FileName.slice(0, 52) + "..."
                      : state?.breadCrumb?.value[
                          state?.breadCrumb?.value.length - 1
                        ].FileName
                    : props.props === "2"
                    ? "Favorite"
                    : "Recycling"
                  : ""}
              </h3>
              {props.props !== "2" && props.props !== "4" && (
                <ol className="breadcrumb">
                  {state?.breadCrumb?.value
                    ?.slice(1)
                    .map((breadcrumb, index) => (
                      <li key={breadcrumb.FileName} className="breadcrumb-item">
                        {state?.breadCrumb?.value.length - 2 !== index ? (
                          <a
                            className="overflow-text-ant"
                            href="javascript:void(0)"
                            onClick={(e) => {
                              e.preventDefault();
                              navigateBreadCrumb(breadcrumb);
                            }}
                          >
                            {breadcrumb.FileName}
                          </a>
                        ) : (
                          <span className="overflow-text-ant">
                            {breadcrumb.FileName}
                          </span>
                        )}
                      </li>
                    ))}
                </ol>
              )}
              {userDetails?.user_type === "advisors" &&
                props.props === "1" &&
                state?.breadCrumb?.value &&
                state?.breadCrumb?.value.length === 3 && (
                  <span
                    className="filt-by-client d-none text-success fw-500 cursor-pointer mt-16 d-md-flex align-items-center"
                    onClick={() => {
                      setValue("");
                      setShowFilter(true);
                    }}
                  >
                    <span className="icon-20 me-8">
                      <em className="icon-adjustments-outline"></em>
                    </span>
                    Filter By Client
                  </span>
                )}
            </div>
            {selectedRowKeys.length > 0 && (
              <div className="share-items-selected d-md-none d-flex align-items-center">
                <span className="icon-24 me-16">
                  <em className="icon-x-outline"></em>
                </span>

                <span className="fs-18 lh-24 fw-500 ms-16">
                  {selectedRowKeys.length > 0
                    ? selectedRowKeys.length === 1
                      ? selectedRowKeys.length + " item selected"
                      : selectedRowKeys.length + " items selected"
                    : ""}
                </span>
              </div>
            )}

            <div className="d-flex align-items-start share-tool-right">
              {/* <div className="dropdown me-16 d-none d-md-block">
                <button
                  className="btn btn-outline-secondary btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Bulk Actions
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </div> */}
              {selectedRowKeys.length > 0 &&  (props?.props !== "1" || permissions?.CanDownload || permissions?.CanDeleteChildItems) &&(
                <Dropdown
                  className="d-none d-md-flex align-items-center"
                  overlay={menu}
                >
                  <button className="btn btn-outline-secondary btn-sm">
                    Bulk Actions{" "}
                    <span className="dropbtn-arrow ms-12">
                      <em className="icon-chevron-down-outline"></em>
                    </span>
                  </button>
                </Dropdown>
              )}

              {(state?.breadCrumb?.value &&
                state?.breadCrumb?.value[state?.breadCrumb?.value?.length - 1]
                  .FileName !== "Shared Folders" &&
                state?.breadCrumb?.value[state?.breadCrumb?.value?.length - 1]
                  ?.FileName !== "Shared With Me" &&
                state?.breadCrumb?.value[state?.breadCrumb?.value?.length - 1]
                  ?.FileName !== "Folders" &&
                props.props !== "2" &&
                props.props !== "4") &&(
                  (props?.props !== "1" || permissions?.CanUpload || permissions?.CanAddFolder) &&
                  <Dropdown
                    className="d-none d-md-flex align-items-center"
                    overlay={AddNewmenu}
                  >
                    <button className="btn btn-success btn-sm">
                      Add New{" "}
                      <span className="dropbtn-arrow ms-12">
                        <em className="icon-chevron-down-outline"></em>
                      </span>
                    </button>
                  </Dropdown> 
                )}
              {selectedRowKeys.length > 0 && (
                <button
                  className="btn btn-outline-secondary btn-icon-only me-24 d-md-none"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleBulkShow();
                  }}
                >
                  <em className="icon-dots-horizontal-outline"></em>
                </button>
              )}
              <button
                className="btn btn-success btn-icon-only d-md-none"
                type="button"
                key="createFolder"
                onClick={(e) => {
                  handleAddFolderShow();
                }}
              >
                <em className="icon-add-outline"></em>
              </button>
              <button
                className="btn btn-outline-secondary text-muted btn-icon-only d-md-none"
                type="button"
              >
                <em className="icon-add-outline"></em>
              </button>
            </div>
          </div>
          {props.props === "1" &&
            state?.breadCrumb?.value &&
            state?.breadCrumb?.value[state?.breadCrumb?.value?.length - 1]
              .FileName !== "Shared Folders" && (
              <div className="d-none d-md-flex align-items-center">
                <span className="fw-500 me-16 fs-14 lh-24">
                  Email me when a file:
                </span>
                <div>
                  <div className="form-check form-check-inline">
                    <Checkbox
                      checked={state.hasDownload}
                      onChange={hasDownloadedToggle}
                      id="inlineCheckbox1"
                    ></Checkbox>
                    <label
                      className="form-check-label"
                      htmlFor="inlineCheckbox1"
                    >
                      has been downloaded
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <Checkbox
                      checked={state.hasUploaded}
                      id="inlineCheckbox2"
                      onChange={hasUploadedToggle}
                    ></Checkbox>

                    <label
                      className="form-check-label"
                      htmlFor="inlineCheckbox2"
                      // eslint-disable-next-line indent
                    >
                      has been uploaded
                    </label>
                  </div>
                </div>
              </div>
            )}
        </div>

        <div className="card-body p-0">
          {selectedRowKeys.length > 0 && (
            <div className="share-items-selected d-none d-md-flex">
              <span className="fw-500">
                {selectedRowKeys.length > 0
                  ? selectedRowKeys.length === 1
                    ? selectedRowKeys.length + " item selected"
                    : selectedRowKeys.length + " items selected"
                  : ""}
              </span>
            </div>
          )}
          <div className="position-relative">
            <div className="sorting-bar d-md-none d-flex">
              <button
                type="button"
                className="sort-by-btn"
                onClick={(e) => {
                  e.preventDefault();
                  handleSortShow();
                }}
              >
                Sort By{" "}
                <span className="icon-24 ms-8 text-success">
                  <em className="icon-sort-descending-outline"></em>
                </span>
              </button>
              <button
                type="button"
                className="filt-by-btn"
                onClick={() => {
                  setShowFilter(true);
                }}
              >
                {" "}
                <span className="icon-24">
                  <em className="icon-adjustments-outline"></em>
                </span>
              </button>
            </div>

            <div
              className={
                props.props !== "2"
                  ? "custom-table table-striped share-table"
                  : "custom-table table-striped share-table-fav"
              }
            >
              <ConfigProvider
                renderEmpty={() => (
                  (!emptyLoader ? (
                  <Empty
                    description={
                      <div className="empty-table-block mx-auto">
                      {(props.props === "1" || props.props === "3") && (
                        ((props?.props==="1" && permissions?.CanView)|| props.props === "3") ?
                        <>
                          <span className="upload-icon mx-auto">
                            <em className="icon-upload-filled"></em>
                          </span>
                          <span className="d-block fs-18 lh-32 mb-16">
                            Drag files here
                          </span>
                          <span className="d-block fs-18 lh-24">
                            This folder is empty. Drag and drop files or use
                            the Add New button
                          </span>
                        </>:
                         <span className="d-block fs-18 lh-24">
                         You only have access to upload files to this folder.
                       </span>
                      )}
                      {props.props !== "1" && (
                        <span className="d-block fs-18 lh-24"></span>
                      )}
                    </div>
                    }
                  />
                ):
                <div className="empty-table-block mx-auto"></div>
                )
                )}
              >
                <div className="shared-folder-table">
                  <Table
                    rowKey="Id"
                    showSorterTooltip={false}
                    rowSelection={
                      state?.breadCrumb?.value &&
                      state?.breadCrumb?.value[
                        state?.breadCrumb?.value?.length - 1
                      ].FileName !== "Shared Folders" &&
                      props.props !== "2"
                        ? rowSelection
                        : ""
                    }
                    columns={columns}
                    dataSource={(props?.props === "1" && !permissions?.CanView)?allSharedFolder?.value?.filter((ele) =>
                    ele["odata.metadata"].includes("Models.Folder@Element")
                  ):allSharedFolder?.value}
                    pagination={false}
                  />
                </div>
              </ConfigProvider>
            </div>
          </div>
        </div>
        <div
          className="drag-here d-none align-items-center justify-content-center"
          onDrop={(e) => {
            setState((s) => ({
              ...s,
              Files: [],
            }));
            handleDrop(e, true);
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          <div className="drag-block mx-auto">
            <span className="upload-icon bg-success text-white mx-auto">
              <em className="icon-upload-filled"></em>
            </span>
            <span className="d-none d-lg-block fs-18 lh-24">
              Drag files here
            </span>
          </div>
        </div>
        {/* View Model */}
        <Modal className="modal-full" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{!isNote ? "View Details" : "Add Note"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="form-label">File Name</label>
              <div className="d-flex align-items-center cursor-pointer">
                <span className="icon-24 me-8 cursor-pointer text-success ">
                  <em
                    className={
                      itemDetails.Hash ? (
                        <img
                          style={{ width: "21px" }}
                          src={getIcon(itemDetails?.FileName)}
                          alt=""
                        />
                      ) : (
                        "icon-folder-filled"
                      )
                    }
                  ></em>
                </span>
                <p className="m-0 overflow-text-ant">{itemDetails?.FileName}</p>
              </div>
            </div>
            {!isNote && (
              <div className="form-group">
                <label className="form-label">File Size</label>
                <span className="d-block mt-8">
                  {(itemDetails?.FileSizeBytes / (1024 * 1024)).toFixed(2) +
                    " MB"}
                </span>
              </div>
            )}
            {!isNote && (
              <div className="form-group">
                <label className="form-label">Uploaded Date</label>
                <span className="d-block mt-8">
                  {moment(itemDetails?.CreationDate).format("ll")}
                </span>
              </div>
            )}
            {!isNote && (
              <div className="form-group">
                <label className="form-label">Creator</label>
                <span className="d-block mt-8">
                  {itemDetails?.CreatorFirstName +
                    " " +
                    itemDetails?.CreatorLastName}
                </span>
              </div>
            )}
            <div className="form-group mb-0">
              <label
                className="form-label"
                htmlFor="exampleFormControlTextarea1"
              >
                Note
              </label>
              <textarea
                className="form-control textarea-124"
                id="exampleFormControlTextarea1"
                placeholder="Enter a Note"
                maxLength={4000}
                rows="5"
                value={itemDetails?.Description}
                onChange={(e) => {
                  itemDetails.Description = e.target.value;
                  setState((s) => ({
                    ...s,
                    itemDetails: itemDetails,
                  }));
                }}
              />
              <div className="text-end pt-4">
                {(4000 - itemDetails?.Description?.length).toLocaleString()}{" "}
                character limit
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-secondary"
              variant="secondary"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              disabled={itemDetails?.Description === ""}
              className="btn btn-success"
              variant="primary"
              onClick={UpdateNote}
            >
              {!isNote ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>

        {/* Add Upload file Model */}
        <Modal
          className="modal-full upload-modal"
          size="lg"
          show={showUploadFile}
          onHide={handleUploadFileClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className={
                state.Files.length > 0
                  ? "upload-area d-none align-items-center justify-content-center"
                  : "upload-area d-flex align-items-center justify-content-center"
              }
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              <div className="drag-block mx-auto">
                <span className="upload-icon mx-auto">
                  <em className="icon-upload-filled"></em>
                </span>
                <span className="d-none d-lg-block fs-18 lh-24">
                  Drag files here
                </span>
                <span className="or-line d-none d-lg-block">
                  <span>Or</span>
                </span>
                <div className="file-upload">
                  <input
                    type="file"
                    ref={fileUploadRef}
                    onChange={(e) => {
                      fileUploadOnButton(e);
                    }}
                    multiple
                  />
                  <span className="file-upload-link">Browse Files</span>
                </div>
              </div>
            </div>

            {state?.Files.length > 0 && (
              <div
                className="upload-area custom-style-scroll"
                onDrop={(e) => handleDrop(e)}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="upload-toolbar d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center upld-items fs-18 lh-24 me-32">
                      <span>{state?.Files.length}</span>
                      items
                    </div>
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => {
                        e.preventDefault();
                        setState((s) => ({
                          ...s,
                          Files: [],
                        }));
                      }}
                    >
                      Clear All
                    </a>
                  </div>
                  <button
                    type="button"
                    className="btn btn-link btn-with-icon fs-16"
                    onClick={() => fileUploadRef.current.click()}
                  >
                    <span className="icon-20">
                      <em className="icon-add-outline"></em>
                    </span>{" "}
                    Add More
                  </button>
                </div>
                <div className="upload-table">
                  <table className="table">
                    <tbody>
                      {state?.Files?.map((file, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center text-black">
                              <span className="icon-24 mr-8">
                                <img
                                  style={{ width: "21px" }}
                                  src={getIcon(file?.name)}
                                  alt=""
                                />
                              </span>
                              <span className="overflow-text-ant">
                                {file.name}
                              </span>
                            </div>
                          </td>
                          <td>
                            {(file.size / (1024 * 1024)).toFixed(2) + " MB"}
                          </td>
                          <td>
                            <span
                              className="icon-24 cursor-pointer ms-auto"
                              onClick={(e) => {
                                e.preventDefault();
                                state.Files.splice(index, 1);
                                setState((s) => ({
                                  ...s,
                                  Files: state.Files,
                                }));
                              }}
                            >
                              <em className="icon-x-outline"></em>
                            </span>
                          </td>
                        </tr>
                      ))}
                      {/* <tr>
                      <td>
                        <div className="d-flex align-items-center text-black">
                          <span className="icon-24 mr-8">
                            <em className="icon-document-text-filled text-success"></em>
                          </span>
                          Document_name.pdf
                        </div>
                      </td>
                      <td>10 KB</td>
                      <td>
                        <span className="icon-24 cursor-pointer ms-auto">
                          <em className="icon-x-outline"></em>
                        </span>
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-secondary"
              variant="secondary"
              onClick={handleUploadFileClose}
            >
              Cancel
            </button>

            <button
              className="btn btn-success"
              variant="primary"
              disabled={state?.Files.length === 0}
              onClick={GetChunkUrl}
            >
              Upload
            </button>
          </Modal.Footer>
        </Modal>
        {/* Add New Folder Model */}
        <Modal
          className="modal-full"
          show={showAddFolder}
          onHide={handleAddFolderClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>New Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body className="flex-grow-0">
            <div className="form-group mb-0">
              <label className="form-label">Folder Name</label>
              <input
                type="text"
                className="form-control"
                value={state?.FolderName}
                maxLength="240"
                onChange={(e) => {
                  setState((s) => ({
                    ...s,
                    FolderName: e.target.value,
                  }));
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-secondary"
              variant="secondary"
              onClick={(e) => {
                handleAddFolderClose();
                if (FolderselectedRowKeys.length > 0) {
                  setState((s) => ({
                    ...s,
                    FolderselectedRowKeys: [],
                  }));
                  setCopyShow(true);
                }
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-success"
              variant="primary"
              disabled={state.FolderName === "" ? true : false}
              onClick={CreateFolder}
            >
              Create
            </button>
          </Modal.Footer>
        </Modal>

        {/* Rename Model */}
        <Modal show={showRname} onHide={handleRenameClose}>
          <Modal.Header closeButton>
            <Modal.Title>Rename</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group mb-0">
              <label className="form-label">File Name</label>
              <input
                type="text"
                className="form-control"
                value={
                  nameTemp?.length === 0 && itemDetails?.Name?.includes(".")
                    ? itemDetails?.Name?.substring(
                        0,
                        itemDetails?.Name?.lastIndexOf(".")
                      )
                    : itemDetails?.Name
                }
                onChange={(e) => {
                  itemDetails.Name = e.target.value;
                  setNameTemp(e.target.value);
                  setState((s) => ({
                    ...s,
                    itemDetails: itemDetails,
                  }));
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-secondary"
              variant="secondary"
              onClick={handleRenameClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-success"
              variant="primary"
              onClick={UpdateName}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>

        {/* Copy Model */}
        <Modal
          className="modal-lmd modal-full copy-modal"
          show={showCopy}
          onHide={handleCopyClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {(isCopy ? "Copy " : "Move ") +
                SelectedItemListToCopy.length +
                " item to..."}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ol className="breadcrumb">
              {state?.FolderbreadCrumb?.value
                ?.slice(1)
                .map((breadcrumb, index) => (
                  <li key={breadcrumb.FileName} className="breadcrumb-item">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateFolderBreadCrumb(breadcrumb.Id);
                      }}
                    >
                      {" "}
                      {breadcrumb.FileName}
                    </a>
                  </li>
                ))}
            </ol>
            <div className="custom-table copy-item-table">
              <Table
                rowKey="Id"
                rowSelection={{
                  type: "radio",
                  ...CopyRowSelection,
                }}
                columns={Foldercolumns}
                dataSource={folderList}
                pagination={false}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-between align-items-center flex-md-row-reverse">
            <div className="d-md-flex align-items-center">
              <button
                className="btn btn-outline-secondary"
                variant="secondary"
                onClick={handleCopyClose}
              >
                Cancel
              </button>
              <button
                disabled={FolderselectedRowKeys.length === 0}
                className="btn btn-success"
                variant="primary"
                onClick={
                  isCopy
                    ? isActionFromBulk
                      ? CopyBulkItem
                      : CopyItem
                    : isActionFromBulk
                    ? MoveBulkItem
                    : MoveItem
                }
              >
                {isCopy ? "Copy" : "Move"}
              </button>
            </div>
            <div className="text-center pt-16 pt-md-0 text-md-start">
              {FolderselectedRowKeys.length > 0 && (
                <a
                  href="#"
                  onClick={(e) => {
                    setCopyShow(false);
                    handleAddFolderShow();
                  }}
                  className="fw-500"
                >
                  Create New Folder
                </a>
              )}
              {FolderselectedRowKeys.length === 0 && (
                <a disabled="true" href="#" className="fw-500">
                  Create New Folder
                </a>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        {/* Bulk Model */}
        <Modal className="modal-xsm" show={showBulk} onHide={handleBulkClose}>
          <Modal.Header closeButton>
            <Modal.Title>Actions</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <ul className="list-unstyled bulk-list m-0">
              <li
                onClick={() => {
                  downloadById(currentItem);
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-download-filled"></em>
                </span>
                Download
              </li>
              <li
                onClick={() => {
                  showViewFile(false);
                  setActionFromBulk(false);
                  setIsCopy(false);
                  setState((s) => ({
                    ...s,
                    SelectedItemListToCopy: [currentItem.Id],
                  }));
                  getTopFolders();
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-folder-download-filled"></em>
                </span>
                Move
              </li>
              <li
                onClick={() => {
                  showViewFile(false);
                  setActionFromBulk(false);
                  setIsCopy(true);
                  setState((s) => ({
                    ...s,
                    SelectedItemListToCopy: [currentItem.Id],
                  }));
                  getTopFolders();
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-duplicate-filled"></em>
                </span>
                Copy
              </li>
              <li
                onClick={() => {
                  handleBulkClose();
                  showViewFile(false);
                  deleteItemById(currentItem.Id);
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-trash-filled"></em>
                </span>
                Delete
              </li>
            </ul>
          </Modal.Body>
        </Modal>
        {/* Sort Model */}
        <Modal className="modal-full" show={showSort} onHide={handleSortClose}>
          <Modal.Header closeButton>
            <Modal.Title>Sort By</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <ul className="list-unstyled sort-ul m-0">
              <li className="active">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sortby"
                    id="az"
                  />
                  <label className="form-check-label" htmlFor="az">
                    Name (A - Z)
                  </label>
                </div>
                <span className="icon-24 ms-8">
                  <em className="icon-check-circle-filled"></em>
                </span>
              </li>
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sortby"
                    id="za"
                  />
                  <label className="form-check-label" htmlFor="za">
                    Name (Z - A)
                  </label>
                </div>
                <span className="icon-24 ms-8">
                  <em className="icon-check-circle-filled"></em>
                </span>
              </li>
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sortby"
                    id="smallest"
                  />
                  <label className="form-check-label" htmlFor="smallest">
                    File Size (Smallest)
                  </label>
                </div>
                <span className="icon-24 ms-8">
                  <em className="icon-check-circle-filled"></em>
                </span>
              </li>
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sortby"
                    id="largest"
                  />
                  <label className="form-check-label" htmlFor="largest">
                    File Size (Largest)
                  </label>
                </div>
                <span className="icon-24 ms-8">
                  <em className="icon-check-circle-filled"></em>
                </span>
              </li>
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sortby"
                    id="newest"
                  />
                  <label className="form-check-label" htmlFor="newest">
                    Uploaded (Newest)
                  </label>
                </div>
                <span className="icon-24 ms-8">
                  <em className="icon-check-circle-filled"></em>
                </span>
              </li>
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sortby"
                    id="oldest"
                  />
                  <label className="form-check-label" htmlFor="oldest">
                    Uploaded (Oldest)
                  </label>
                </div>
                <span className="icon-24 ms-8">
                  <em className="icon-check-circle-filled"></em>
                </span>
              </li>
            </ul>
          </Modal.Body>
        </Modal>

        {/* Sort Modal */}
        <Modal size="sm" show={showAction} onHide={handleActionClose}>
          <Modal.Header closeButton>
            <Modal.Title>Actions</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="py-8 px-24 d-flex align-items-center border-bottom">
              <span className="icon-24 fs-18">
                <em className="icon-folder-filled"></em>
              </span>
              <div className="ps-16">
                <span className="d-block">{currentItem?.Name}</span>
                <span className="d-md-none d-flex text-muted sizedate">
                  {" "}
                  {moment(currentItem.CreationDate).format("ll")}&nbsp;|&nbsp;
                  {(currentItem.FileSizeBytes / (1024 * 1024)).toFixed(2) +
                    " MB"}
                  &nbsp;|&nbsp;{" "}
                  {currentItem?.CreatorFirstName +
                    " " +
                    currentItem?.CreatorLastName}
                </span>
              </div>
            </div>
            <div className="d-md-none py-16 px-24 border-bottom">
              <span className="d-block fw-500 mb-8 fs-14 lh-24">
                Email me when a file:
              </span>
              <div>
                <div className="form-check mb-16">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="mobileDownload"
                    value="option1"
                  />
                  <label className="form-check-label" htmlFor="mobileDownload">
                    has been downloaded
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="MobileUpload"
                    value="option2"
                  />
                  <label className="form-check-label" htmlFor="MobileUpload">
                    has been uploaded
                  </label>
                </div>
              </div>
            </div>
            <ul className="list-unstyled bulk-list action-list m-0">
              {(permissions?.CanView || props.props !== "1") &&
              <li
                href="javascript:void(0)"
                onClick={() => {
                  handleActionClose();
                  setisNote(false);
                  getDetailsById(currentItem);
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-info-circle-filled"></em>
                </span>
                View Details
              </li>}
              {(permissions?.CanDownload || props.props !== "1") &&
              <li
                href="javascript:void(0)"
                onClick={() => {
                  handleActionClose();
                  downloadById(currentItem);
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-download-filled"></em>
                </span>
                Download
              </li>}
              <li
                onClick={(e) => {
                  e.preventDefault();
                  handleActionClose();
                  if (props.props === "2") {
                    deleteFromFavourite(currentItem.StreamID);
                  } else {
                    if (currentItem.Favorite) {
                      deleteFromFavourite(currentItem.StreamID);
                    } else {
                      addToFavourite(currentItem.StreamID);
                    }
                  }
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-star-filled"></em>
                </span>
                Favorite
              </li>
              {(permissions?.CanDeleteChildItems || props.props !== "1" || currentItem?.Creator?.Id === user_sharefile_id) &&
              <li
                href="javascript:void(0)"
                onClick={() => {
                  handleActionClose();
                  getNameById(currentItem);
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-pencil-filled"></em>
                </span>
                Rename
              </li>}
              {(permissions?.CanDeleteChildItems || props.props !== "1") &&
              <li
                href="javascript:void(0)"
                onClick={() => {
                  handleActionClose();
                  setActionFromBulk(false);
                  setIsCopy(false);
                  setState((s) => ({
                    ...s,
                    SelectedItemListToCopy: [currentItem.Id],
                  }));
                  getTopFolders();
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-folder-download-filled"></em>
                </span>
                Move
              </li>}
              {(permissions?.CanDownload || props.props !== "1") &&
              <li
                href="javascript:void(0)"
                onClick={() => {
                  setActionFromBulk(false);
                  handleActionClose();
                  setIsCopy(true);
                  setState((s) => ({
                    ...s,
                    SelectedItemListToCopy: [currentItem.Id],
                  }));
                  getTopFolders();
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-duplicate-filled"></em>
                </span>
                Copy
              </li>}
              {(permissions?.CanDeleteChildItems || props.props !== "1" || currentItem?.Creator?.Id === user_sharefile_id) &&
              <li
                href="javascript:void(0)"
                onClick={() => {
                  handleActionClose();
                  deleteItemById(currentItem.Id);
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-trash-filled"></em>
                </span>
                Delete
              </li>}
              <li
                href="javascript:void(0)"
                onClick={() => {
                  handleActionClose();
                  setisNote(true);
                  getDetailsById(currentItem);
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-pencil-alt-filled"></em>
                </span>
                Add Note
              </li>
            </ul>
          </Modal.Body>
        </Modal>
        {/* FIlter Model */}
        <Modal
          className="modal-full"
          show={showFilter}
          onHide={() => {
            setShowFilter(false);
            setValue("");
            setCloneAllShared(allSharedFolder);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter By Client</Modal.Title>
          </Modal.Header>
          <Modal.Body className="ps-0 pe-0 pb-8 pt-0 pt-md-24">
            <div className="px-24 py-md-8 py-12 border-md-top border-bottom">
              <div className="search-area">
                <input
                  placeholder="Search clients..."
                  value={valueFilter}
                  className="form-control"
                  onChange={(e) => {
                    let cloneList = cloneAllShared.value.map(
                      (item, index) => item
                    );
                    const currValue = e.target.value;
                    setValue(currValue);
                    const filteredData = cloneList.filter((entry) =>
                      entry.FileName.includes(currValue)
                    );
                    //const filter=filteredData.filter((f)=>f["odata.metadata"].includes("ShareFile.Api.Models.Folder"))
                    if (currValue.length > 0) {
                      setCloneAllShared({ value: filteredData });
                    } else {
                      //let data = allSharedFolder.value.filter((f)=>f["odata.metadata"].includes("ShareFile.Api.Models.Folder"))
                      setCloneAllShared(allSharedFolder);
                    }
                  }}
                />
                <button
                  className="search-btn icon-20"
                  onClick={(e) => {
                    if (valueFilter.length > 0) {
                      setValue("");
                      setCloneAllShared(allSharedFolder);
                    }
                  }}
                >
                  <em
                    className={
                      valueFilter === ""
                        ? "icon-search-outline"
                        : "icon-x-circle-outline"
                    }
                  ></em>
                </button>
              </div>
            </div>
            <div className="custom-table filter-item-table">
              <Table
                rowKey="Id"
                columns={columnFilter}
                dataSource={cloneAllShared.value}
                pagination={false}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/* View doc */}
        <div
          className={`view-document  align-items-stretch ${
            showView ? "d-flex" : "d-none"
          } ${showViewExpand ? "expand-full" : ""}`}
        >
          <div className="view-doc-left flex-fill">
            <div className="view-doc-head d-flex justify-content-between border-bottom align-items-center">
              <div className="d-flex align-items-center">
                <span
                  className="icon-32 fs-20 me-8 me-md-16 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    showViewFile(false);
                    setState((s) => ({
                      ...s,
                      currentPreviewURL: "",
                    }));
                  }}
                >
                  <em className="icon-chevron-left-outline"></em>
                </span>
                <div className="d-flex align-items-center">
                  <span className="icon-32 me-16 cursor-pointer">
                    <img style={{ width: "32px" }} src={getIcon(currentItem?.Name)} alt="" />
                  </span>
                  <div>
                    <ol className="breadcrumb">
                      {state?.breadCrumb?.value
                        ?.slice(1)
                        .map((breadcrumb, index) => (
                          <li
                            key={breadcrumb.FileName}
                            className="breadcrumb-item"
                          >
                            <a
                              href="javascript:void(0)"
                              onClick={(e) => {
                                e.preventDefault();
                                showViewFile(false);
                                navigateBreadCrumb(breadcrumb);
                              }}
                            >
                              {breadcrumb.FileName}
                            </a>
                          </li>
                        ))}
                    </ol>
                    <p className="m-0 lh-20 pdf-name">{currentItem?.Name}</p>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="icon-32 fs-20 cursor-pointer d-none d-md-flex">
                  {showViewExpand && (
                    <em
                      className="icon-resize-expand-outline"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowViewExpand(false);
                      }}
                    ></em>
                  )}
                  {!showViewExpand && (
                    <em
                      className="icon-resize-collapse-outline"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowViewExpand(true);
                      }}
                    ></em>
                  )}
                </span>
                <span
                  className="icon-24 fs-18 cursor-pointer d-md-none"
                  onClick={(e) => {
                    e.preventDefault();
                    handleBulkShow();
                  }}
                >
                  <em className="icon-info-circle-filled"></em>
                </span>
                <span
                  className="icon-24 fs-18 ms-16 cursor-pointer d-md-none"
                  onClick={(e) => {
                    e.preventDefault();
                    setState((s) => ({
                      ...s,
                      itemDetails: currentItem,
                    }));
                    handleShow();
                  }}
                >
                  <em className="icon-dots-horizontal-outline"></em>
                </span>
              </div>
            </div>
            <div className="view-doc-body">
              {currentPreviewURL && (
                <div className="mx-auto h-100" ref={componentRef}>
                  {" "}
                  {!currentItem?.Name.includes(".doc") &&
                    !currentItem?.Name.includes(".ppt") &&
                    !currentItem?.Name.includes(".xls") &&
                    !currentItem?.Name.includes(".txt") && (
                      <FileViewer
                        fileType={currentItem?.FileName?.substr(
                          currentItem?.FileName?.lastIndexOf(".") + 1
                        )}
                        filePath={currentPreviewURL}
                      />
                    )}
                  {(currentItem?.Name.includes(".doc") ||
                    currentItem?.Name.includes(".ppt") ||
                    currentItem?.Name.includes(".xls")) && (
                    <iframe
                      src={currentPreviewURL}
                      width="100%"
                      height="100%"
                    ></iframe>
                  )}
                  {currentItem?.Name.includes(".txt") && (
                    <DocViewer
                      pluginRenderers={DocViewerRenderers}
                      documents={[
                        {
                          uri: currentPreviewURL,
                          fileType: "text/plain",
                        },
                      ]}
                      style={{ height: 500 }}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="view-doc-footer border-top d-flex justify-content-between align-items-center">
              <div className="view-footer-left"></div>
              {/* <div className="view-footer-center d-flex align-items-center">
                <span className="me-8 text-nowrap d-none d-md-block">Page</span>
                <input
                  type="text"
                  className="form-control d-none d-md-block"
                  value={1}
                />
                <span className="ms-8 me-16 text-nowrap d-none d-md-block">
                  of 3
                </span>
                <button
                  type="button"
                  className="btn btn-outline-secondary me-16 d-none d-md-flex"
                >
                  <em className="icon-arrow-up-outline"></em>
                </button>
                <button
                  type="button"
                  className="btn btn-success d-none d-md-flex"
                >
                  <em className="icon-arrow-down-outline"></em>
                </button>

                <span className="me-8 text-nowrap d-md-none">Page 1 of 3</span>
              </div> */}
              <div className="view-footer-right d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    let Newzoom = zoom - 0.1;
                    if (Newzoom > 0) {
                      setState((s) => ({
                        ...s,
                        zoom: Newzoom,
                      }));
                      document.getElementById("pg-viewer").style.transform =
                        "scale(" + Newzoom + ")";
                    }
                  }}
                >
                  <em className="icon-subtract-outline"></em>
                </button>
                <span className="mx-16 text-nowrap">
                  {parseInt(zoom * 100)}%
                </span>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    let Newzoom = zoom + 0.1;
                    setState((s) => ({
                      ...s,
                      zoom: Newzoom,
                    }));
                    document.getElementById("pg-viewer").style.transform =
                      "scale(" + Newzoom + ")";
                  }}
                >
                  <em className="icon-add-outline"></em>
                </button>
              </div>
            </div>
          </div>
          <div className="view-doc-side border-left">
            <div className="custom-style-scroll view-doc-in">
              <div className="expanded">
                <div className="d-flex align-items-center mb-24">
                  <span className="icon-32 fs-24 me-16">
                    <em className="icon-info-circle-filled"></em>
                  </span>
                  <h3 className="m-0">Details</h3>
                </div>
                <div className="mb-16">
                  <div className="fs-14 mb-4 d-flex align-items-center justify-content-between">
                    <span className="fw-500">File Name</span>
                    <div className="d-flex align-items-center">
                      {!EditNameFlag && (
                        <a
                          className="ms-16"
                          href="#"
                          onClick={() => {
                            setState((s) => ({
                              ...s,
                              EditNameFlag: !EditNameFlag,
                              NewFileName:
                                NewFileName === ""
                                  ? currentItem?.FileName
                                  : NewFileName,
                            }));
                          }}
                        >
                          Edit Name
                        </a>
                      )}
                      {EditNameFlag && (
                        <a
                          className="ms-16"
                          onClick={() => {
                            UpdateNameFromPreview();
                          }}
                        >
                          Save
                        </a>
                      )}
                      {EditNameFlag && (
                        <a
                          className="ms-16 text-muted"
                          href="#"
                          onClick={() => {
                            setState((s) => ({
                              ...s,
                              EditNameFlag: !EditNameFlag,
                            }));
                          }}
                        >
                          Cancel
                        </a>
                      )}
                    </div>
                  </div>
                  {!EditNameFlag && (
                    <div className="d-flex align-items-center pt-4">
                      <span className="icon-24 me-16 cursor-pointer">
                        <img
                          style={{ width: "21px" }}
                          src={getIcon(currentItem?.FileName)}
                          alt=""
                        />
                      </span>
                      {currentItem?.Name}
                    </div>
                  )}
                  {EditNameFlag && (
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        value={NewFileName}
                        onChange={(e) => {
                          setState((s) => ({
                            ...s,
                            NewFileName: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="mb-16">
                  <div className="fs-14 mb-4">
                    <span className="fw-500">Size</span>
                  </div>
                  {currentItem?.FileSizeBytes
                    ? (currentItem?.FileSizeBytes / (1024 * 1024)).toFixed(2) +
                      " MB"
                    : "-"}
                </div>
                <div className="mb-16">
                  <div className="fs-14 mb-4">
                    <span className="fw-500">Uploaded</span>
                  </div>
                  {moment(currentItem?.CreationDate).format("ll")}
                </div>
                <div className="mb-16">
                  <div className="fs-14 mb-4">
                    <span className="fw-500">Creator</span>
                  </div>
                  {(currentItem?.CreatorFirstName
                    ? currentItem?.CreatorFirstName
                    : "-") +
                    " " +
                    (currentItem?.CreatorLastName
                      ? currentItem?.CreatorLastName
                      : "-")}
                </div>
                <ul className="view-doc-action d-flex flex-wrap text-center list-unstyled">
                  <li
                    onClick={() => {
                      downloadById(currentItem);
                    }}
                  >
                    <span className="icon-24 mb-8 mx-auto">
                      <em className="icon-download-filled"></em>
                    </span>
                    <span className="d-block fs-14">Download</span>
                  </li>
                  <li onClick={handlePrint}>
                    <span className="icon-24 mb-8 mx-auto">
                      <em className="icon-printer-filled"></em>
                    </span>
                    <span className="d-block fs-14">Print</span>
                  </li>
                  <li
                    onClick={() => {
                      showViewFile(false);
                      deleteItemById(currentItem.Id);
                    }}
                  >
                    <span className="icon-24 mb-8 mx-auto">
                      <em className="icon-trash-filled"></em>
                    </span>
                    <span className="d-block fs-14">Delete</span>
                  </li>
                  <li
                    onClick={() => {
                      showViewFile(false);
                      setActionFromBulk(false);
                      setIsCopy(true);
                      setState((s) => ({
                        ...s,
                        SelectedItemListToCopy: [currentItem.Id],
                      }));
                      getTopFolders();
                    }}
                  >
                    <span className="icon-24 mb-8 mx-auto">
                      <em className="icon-duplicate-filled"></em>
                    </span>
                    <span className="d-block fs-14">Copy</span>
                  </li>
                  <li
                    onClick={() => {
                      showViewFile(false);
                      setActionFromBulk(false);
                      setIsCopy(false);
                      setState((s) => ({
                        ...s,
                        SelectedItemListToCopy: [currentItem.Id],
                      }));
                      getTopFolders();
                    }}
                  >
                    <span className="icon-24 mb-8 mx-auto">
                      <em className="icon-folder-download-filled"></em>
                    </span>
                    <span className="d-block fs-14">Move</span>
                  </li>
                </ul>
                <div className="mb-16">
                  <div className="fs-14 mb-4 d-flex align-items-center justify-content-between">
                    <span className="fw-500">Note</span>
                    <div className="d-flex align-items-center">
                      {!EditNoteFlag && (
                        <a
                          onClick={() => {
                            setState((s) => ({
                              ...s,
                              EditNoteFlag: !EditNoteFlag,
                              NewNote:
                                NewNote === ""
                                  ? currentItem?.Description
                                  : NewNote,
                            }));
                          }}
                        >
                          Edit Note
                        </a>
                      )}
                      {EditNoteFlag && (
                        <a
                          className="ms-16"
                          onClick={() => {
                            UpdateNoteFromPreview();
                          }}
                        >
                          Save
                        </a>
                      )}
                      {EditNoteFlag && (
                        <a
                          className="ms-16 text-muted"
                          onClick={() => {
                            setState((s) => ({
                              ...s,
                              EditNoteFlag: !EditNoteFlag,
                            }));
                            setState((s) => ({
                              ...s,
                              NewNote: "",
                            }));
                          }}
                        >
                          Cancel
                        </a>
                      )}
                    </div>
                  </div>
                  {!EditNoteFlag && currentItem?.Description}
                  {EditNoteFlag && (
                    <div>
                      <textarea
                        className="form-control"
                        rows="4"
                        value={NewNote}
                        placeholder="Enter a Note"
                        onChange={(e) => {
                          setState((s) => ({
                            ...s,
                            NewNote: e.target.value,
                          }));
                        }}
                      ></textarea>
                      <div className="text-end pt-4">4,000 character limit</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="collpased">
                <div className="d-flex align-items-center mb-24">
                  <span className="icon-32 fs-24">
                    <em
                      className="icon-info-circle-filled"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowViewExpand(false);
                      }}
                    ></em>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sort Modal */}
        <Modal
          className="modal-full"
          show={showViewDetail}
          onHide={handleViewDetailClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>View Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="form-label">File Name</label>
              <input
                type="text"
                className="form-control"
                value="Sample Membership Agree.pdf"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Size</label>
              <span className="d-block mt-8">2 KB</span>
            </div>
            <div className="form-group">
              <label className="form-label">Uploaded</label>
              <span className="d-block mt-8">Feburary 2, 2022</span>
            </div>
            <div className="form-group">
              <label className="form-label">Creator</label>
              <span className="d-block mt-8">Jeremy Davies</span>
            </div>
            <div className="form-group mb-0">
              <label className="form-label">Note</label>
              <textarea className="form-control textarea-124" id="" rows="5" />
              <div className="text-end pt-4">4,000 character limit</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleViewDetailClose}
              className="btn btn-outline-secondary"
              variant="secondary"
            >
              Cancel
            </button>
            <button className="btn btn-success" variant="primary">
              Save
            </button>
          </Modal.Footer>
        </Modal>
        {/* Bulk Model */}
        <Modal
          className="modal-xsm"
          show={showViewAction}
          onHide={handleViewActionClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Actions</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <ul className="list-unstyled bulk-list m-0">
              <li>
                <span className="icon-20 me-8">
                  <em className="icon-download-filled"></em>
                </span>
                Download
              </li>
              <li>
                <span className="icon-20 me-8">
                  <em className="icon-folder-download-filled"></em>
                </span>
                Move
              </li>
              <li>
                <span className="icon-20 me-8">
                  <em className="icon-duplicate-filled"></em>
                </span>
                Copy
              </li>
              <li>
                <span className="icon-20 me-8">
                  <em className="icon-trash-filled"></em>
                </span>
                Delete
              </li>
            </ul>
          </Modal.Body>
        </Modal>
        <Modal
          className="modal-full"
          show={props?.showFilter}
          onHide={() => {
            setNotCallAPI(true);
            setSearchValue("");
            props?.onClose();
            setSearchList([]);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Search Files and Folders</Modal.Title>
          </Modal.Header>
          <Modal.Body className="ps-0 pe-0 pb-8 pt-0 pt-md-24">
            <div className="px-24 py-md-8 py-12 border-md-top border-bottom">
              <div className="search-area">
              <input
                placeholder="Search files and folder..."
                className="form-control"
                value={searchValue}
                onChange={(e) => {
                  e.preventDefault();
                  setSearchValue(e.target.value);
                }}
              />
                <button
                  className="search-btn icon-20"
                  onClick={(e) => {
                    e.preventDefault();
                    setSearchValue("");
                    // setNewSearchValue("");
                    setSearchList([]);
                  }}
                >
                  <em
                    className={
                      searchValue === ""
                        ? "icon-search-outline"
                        : "icon-x-circle-outline"
                    }
                  ></em>
                </button>
              </div>
            </div>
            <div className="custom-table filter-item-table">
              <Table
                rowKey="Id"
                columns={searchFilter}
                dataSource={searchList}
                pagination={false}
                locale={{
                  emptyText: (
                    <div className="task-noresult-cdc d-flex align-items-center justify-content-center text-center">
                      <div className="max-258">
                        {searchValue && !searchLoader ? (
                          <>
                            <span className="task-nores-icon mb-16 mx-auto">
                              <em className="icon-exclamation-circle-filled text-danger"></em>
                            </span>
                            <p className="fs-18 lh-24 m-0">No results found.</p>
                          </>
                        ) : (
                          <>
                            <span className="task-nores-icon mb-16 mx-auto">
                              <em className="icon-search-outline"></em>
                            </span>
                            <p className="fs-18 lh-24 m-0">
                              {searchLoader
                                ? "Searching result..."
                                : "Searched files and folders will appear here."}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  ),
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default SharedTab;
