import React, { useState } from "react";
import { utility } from "../../components/Utility";
import { useDispatch } from "react-redux";
import { dashboard } from "../../redux/actions/Dashboard/dashboard";
import { useEffect } from "react";
import { useAppDispatchJS } from "../../redux/hooks";

const FilterRelatedDropdown = ({
  dropdowndata,
  setClientData,
  setRelatedSelected,
  relatedSelected,
  dropDownRelatedto,
  setDropDownRelatedto,
  setRemovedRelated,
  setClientNext,
  scrollrelateddd,
  setRelatedState,
  relatedstate,
}) => {
  const dispatch = useAppDispatchJS();
  const { searchText, filterList } = relatedstate;
  const [lst, setLst] = useState([]);
  const [target, setTarget] = useState(false);


  useEffect(() => {
    if (target !== false) {
      setrelatedtask();
    }
  }, [lst]);


  function setrelatedtask() {
    setRelatedState((s) => ({
      ...s,
      searchText: target,
      filterList: lst,
    }));
  }

  function updateResponseData(responseData){
    const allData = []
    const uuidList = []
    for (var i=0;i<=dropDownRelatedto.length-1;i++){
      uuidList.push(dropDownRelatedto[i].uuid)
    }

    for (var j=0;j<=responseData.length-1;j++){
      if (uuidList.includes(responseData[j].uuid)){
        responseData[j].isSelected = true
        allData.push(responseData[j])
      }
      else{
        allData.push(responseData[j])
      }
    }
    setLst(allData);
  }

  return (
    <div className="dropdown-content mt-4">
      <div className="px-24 py-md-8 py-12 border-mt-top">
        <div className="search-area">
          <input
            placeholder="Search clients..."
            className="form-control"
            value={searchText}
            id = "search-input-field"
            onChange={(e) => {
              setTarget(e.target.value);
              setRelatedState((s) => ({
                ...s,
                searchText: e.target.value,
                filterList: lst,
              }));
              var household_url = "";
              e.target.value === ""
                ? (household_url = "household")
                : (household_url = "household?search=" + e.target.value);

              dispatch(dashboard.getHousehold(household_url)).then(
                (response) => {
                  if (response.type === "GET_HOUSEHOLD_SUCCESS") {
                    setClientNext(response.data.next);
                    setClientData(response.data.results)
                    updateResponseData(response.data.results)
                  }
                }
              );
            }}
          />
          {searchText === "" && (
            <button className="search-btn icon-20">
              <em className="icon-search-outline"></em>
            </button>
          )}
          {searchText !== "" && (
            <button
              className="close-search-btn icon-20"
              onClick={(event) => {
                let lst = dropdowndata;
                setRelatedState((s) => ({
                  ...s,
                  searchText: "",
                  filterList: lst,
                }));
              }}
            >
              <em className="icon-x-circle-filled"  onClick={() => {
                dispatch(dashboard.getHousehold("household")).then(
                  (response) => {
                    if (response.type === "GET_HOUSEHOLD_SUCCESS") {
                      setTarget("");
                      setClientNext(response.data.next);
                      setClientData(response.data.results);
                      updateResponseData(response.data.results)
                    }
                  }
                );
              }}></em>
            </button>
          )}
        </div>
      </div>
      <ul
        className="check-list list-unstyled custom-style-scroll"
        onScroll={scrollrelateddd}
      >
        {filterList &&
          filterList?.map((client, indx) => (
            <li
              key={indx + client?.household_name}
              id={filterList}
              className={`${client.isSelected ? "selected" : ""}`}
            >
              <div className="form-check mb-0 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="Checkbox"
                  checked={client.isSelected}
                  id={client.household_name}
                  onChange={(e) => {
                    let indx = dropdowndata?.findIndex(
                      (c) => c.uuid === client.uuid
                    );
                    dropdowndata[indx].isSelected = e?.target?.checked;
                    setClientData(dropdowndata);
                    if (dropdowndata[indx].isSelected === true) {
                      setDropDownRelatedto([
                        ...dropDownRelatedto,
                        {"name":" " + dropdowndata[indx]?.household_name,"uuid":dropdowndata[indx]?.uuid},
                      ]);
                      setRelatedSelected([
                        ...relatedSelected,
                        {
                          value: dropdowndata[indx]?.uuid,
                          label: dropdowndata[indx]?.household_name,
                        },
                      ]);
                      setRemovedRelated("");
                    } else {

                      var toRemove = " " + dropdowndata[indx]?.household_name;
                      var index = dropDownRelatedto.findIndex(item => item.name === toRemove);
                      if (index > -1) {
                        //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                        const removed = dropDownRelatedto.splice(index, 1);
                        setRemovedRelated(removed[0]);
                        setDropDownRelatedto([...dropDownRelatedto]);
                      }
                      const filtered = relatedSelected.filter((filter) => {
                        return filter.value !== dropdowndata[indx].uuid;
                      });
                      setRelatedSelected([...filtered]);
                    }
                    indx = filterList?.findIndex((c) => c.uuid === client.uuid);
                    filterList[indx].isSelected = e?.target?.checked;
                    setRelatedState((s) => ({
                      ...s,
                      filterList: filterList,
                    }));
                  }}
                />
                <label className="form-check-label ms-16" htmlFor="filtclient2">
                  {searchText
                    ? utility.getHighlightedText(
                      client?.household_name,
                      searchText
                    )
                    : client?.household_name}
                </label>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default FilterRelatedDropdown;
