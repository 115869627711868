import React, { useState } from "react";
import { utility } from "../../components/Utility";


const TeamDropdownDiv = ({
  teamListData,
  setTeamListData,
  setSelectedTeam,
  selectedTeam,
  setDropDownTeam,
  dropDownTeam,
  setRemovedTeam,
  ellipsify,
}) => {
  const [teamState, setTeamState] = useState({
    searchText: "",
    filterList: teamListData,
  });
  const { searchText, filterList } = teamState;


  return (
    <div className="dropdown-content">
      <div className="px-24 py-md-8 py-12 border-mt-top">
        <div className="search-area">
          <button className="search-btn-cdc icon-20">
            <em className="icon-search-outline"></em>
          </button>
          <input
            className="form-control increase-padding-cdc-dropdown"
            value={searchText}
            onChange={(e) => {
              let lst = teamListData?.filter(
                (c) =>
                  e.target.value === "" ||
                  c.name?.toLowerCase().includes(e.target.value?.toLowerCase())
              );
              setTeamState((s) => ({
                ...s,
                searchText: e.target.value,
                filterList: lst,
              }));
            }}
          />
          {searchText !== "" && (
            <button
              className="close-search-btn icon-20"
              onClick={(event) => {
                let lst = teamListData;
                setTeamState((s) => ({
                  ...s,
                  searchText: "",
                  filterList: lst,
                }));
              }}
            >
              <em className="icon-x-circle-filled"></em>
            </button>
          )}
          <span className="cdc-span fs-14 .lh-24">
            {dropDownTeam.length} selected
          </span>
        </div>
      </div>
      <ul className="check-list list-unstyled custom-style-scroll">
        {filterList &&
          filterList?.map((team, indx) => (
            <li
              key={indx + team?.name}
              className={`${team.isSelected ? "selected" : ""}`}
            >
              <div className="form-check mb-0 d-flex">
                <input
                  className="form-check-input"
                  type="Checkbox"
                  checked={team.isSelected}
                  id={team?.name}
                  onChange={(e) => {
                    let indx = teamListData?.findIndex(
                      (c) => c.id === team.id
                    );
                    teamListData[indx].isSelected = e?.target?.checked;
                    setTeamListData(teamListData);
                    if (teamListData[indx].isSelected === true) {
                      setDropDownTeam([
                        ...dropDownTeam,
                        {"name":"  " + teamListData[indx]?.name, "uuid": teamListData[indx]?.id},
                      ]);
                      setSelectedTeam([
                        ...selectedTeam,
                        {
                          value: teamListData[indx]?.id,
                          label: teamListData[indx]?.name,
                        },
                      ]);
                      setRemovedTeam("");
                    } else {
                      var toRemove = "  "+teamListData[indx]?.name;
                      var index = dropDownTeam.findIndex(item => item.name === toRemove);
                      if (index > -1) {
                        //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                        const removed = dropDownTeam.splice(index, 1);
                        setRemovedTeam(removed[0]);
                        setDropDownTeam([...dropDownTeam]);
                      }
                      const filtered = selectedTeam.filter((filter) => {
                        return filter.value !== teamListData[indx].id;
                      });
                      setSelectedTeam(filtered);
                    }
                    indx = filterList?.findIndex((c) => c.id === team.id);
                    filterList[indx].isSelected = e?.target?.checked;
                    setTeamState((s) => ({
                      ...s,
                      filterList: filterList,
                    }));
                  }}
                />
                <label className="form-check-label ms-16" htmlFor="filtclient2">
                  {searchText
                    ? utility.getHighlightedText(utility.decodeEntity(team?.name), searchText)
                    : utility.decodeEntity(team?.name)}
                </label>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TeamDropdownDiv;
