import { articleConstants } from '../../constants/ArticleConstant'

const INITIAL_STATE = {
  bookmarkedArticles: [],
  articles: [],
  relatedArticles: [],
  singleArticle: {}
}

const articleInfoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case articleConstants.SET_ARTICLES_LIST:
    state.articles = []
    return { ...state, articles: action.data}

  case articleConstants.RESET_ARTICLES:
    state.articles = []
    return {...state, articles: []}

  case articleConstants.GET_ARTICLE_FAILURE:
    return {}

  case articleConstants.GET_RELATED_ARTICLE_SUCCESS:
    return { ...state, relatedArticles: action.data }

  case articleConstants.GET_BOOKMARKED_ARTICLE_SUCCESS:
    return { ...state, bookmarkedArticles: action.data }

  case articleConstants.GET_BOOKMARKED_ARTICLE_FAILURE:
    return {}

  case articleConstants.RETRIEVE_ARTICLE_SUCCESS:
    return { ...state, singleArticle: action.data }
  
  case articleConstants.RETRIEVE_ARTICLE_FAILURE:
    return {}

  case articleConstants.UPDATE_RELATED_READ_STATUS:
    var relatedArticle = state.relatedArticles.filter((article) => {
      return article.uuid === action.data.article.uuid
    }
    );
    var relatedIndex = state.relatedArticles.findIndex(article => article.uuid === action.data.article.uuid)
    if (relatedArticle[0].article_actions[0] === undefined) {
      relatedArticle[0].article_actions[0] = {"is_read": false}
    }
    relatedArticle[0].article_actions[0].is_read = action.data.is_read
    state.relatedArticles[relatedIndex] = relatedArticle[0]

    return {
      ...state,
      relatedArticles: state.relatedArticles
    }

  case articleConstants.UPDATE_RELATED_READ_STATUS_FAILURE:
    return {}

  case articleConstants.UPDATE_BOOKMARKED_READ_STATUS_SUCCESS:
    var bookmarkedArticleInfo = state.bookmarkedArticles.filter((article) => {
      return article.uuid === action.data.article.uuid
    }
    );
    var index = state.bookmarkedArticles.findIndex(article => article.uuid === action.data.article.uuid)
    if (bookmarkedArticleInfo[0].article_actions[0] === undefined) {
      bookmarkedArticleInfo[0].article_actions[0] = { "is_read": false }
    }
    bookmarkedArticleInfo[0].article_actions[0].is_read = action.data.is_read
    state.bookmarkedArticles[index] = bookmarkedArticleInfo[0]

    return {
      ...state,
      bookmarkedArticles: state.bookmarkedArticles,
    }

  case articleConstants.UPDATE_COMMON_ARTICLE_READ_STATUS_SUCCESS:
    var filteredArticle = state.articles.filter((article) => {
      return article.uuid === action.data.article.uuid
    })
    var articleIndex = state.articles.findIndex(article => article.uuid === action.data.article.uuid)
    if (filteredArticle[0].article_actions[0] === undefined) {
      filteredArticle[0].article_actions[0] = { "is_read": false }
    }
    filteredArticle[0].article_actions[0].is_read = action.data.is_read
    state.articles[articleIndex] = filteredArticle[0]

    return {
      ...state,
      articles: state.articles,
    }

  case articleConstants.UPDATE_COMMON_ARTICLE_READ_STATUS_FAILURE:
    return {}

  case articleConstants.UPDATE_BOOKMARKED_READ_STATUS_FAILURE:
    return {}

  case articleConstants.UPDATE_BOOKMARK_STATUS_SUCCESS:

    var bookmarkedArticle = state.bookmarkedArticles.filter((article) => {
      return article.uuid === action.data.article.uuid
    }
    );
    var bookmarkArticleIndex = state.bookmarkedArticles.findIndex(article => article.uuid === action.data.article.uuid)
    if (bookmarkedArticle[0].article_actions[0] === undefined) {
      bookmarkedArticle[0].article_actions[0] = { "is_bookmark": false }
    }
    bookmarkedArticle[0].article_actions[0].is_bookmark = action.data.is_bookmark
    state.bookmarkedArticles[bookmarkArticleIndex] = bookmarkedArticle[0]
    if (!action.data.is_bookmark) {
      const remainingArticles = state.bookmarkedArticles.filter(article => article.uuid !== action.data.article.uuid)
      state.bookmarkedArticles = remainingArticles
    }
    return {
      ...state,
      bookmarkedArticles: state.bookmarkedArticles
    }

  case articleConstants.UPDATE_BOOKMARK_STATUS_FAILURE:
    return {}

  case articleConstants.UPDATE_BOOKMARK_RELATED_STATUS_SUCCESS:

    var bookmarkRelatedArticle = state.relatedArticles.filter((article) => {
      return article.uuid === action.data.article.uuid
    }
    );
    var bookmarkRelatedIndex = state.relatedArticles.findIndex(article => article.uuid === action.data.article.uuid)
    if (bookmarkRelatedArticle[0].article_actions[0] === undefined) {
      bookmarkRelatedArticle[0].article_actions[0] = { "is_bookmark": false }
    }
    bookmarkRelatedArticle[0].article_actions[0].is_bookmark = action.data.is_bookmark
    state.relatedArticles[bookmarkRelatedIndex] = bookmarkRelatedArticle[0]

    return {
      ...state,
      bookmarkedArticles: state.bookmarkedArticles,
      relatedArticles: state.relatedArticles
    }


  case articleConstants.UPDATE_BOOKMARK_RELATED_STATUS_FAILURE:
    return {}

  case articleConstants.UPDATE_BOOKMARK_STATUS_COMMON_ARTICLE_SUCCESS:

    var bookmarkedCommonArticle = state.articles.filter((article) => {
      return article.uuid === action.data.article.uuid
    });

    var bookmarkedCommonArticleIndex = state.articles.findIndex(article => article.uuid === action.data.article.uuid)
    if (bookmarkedCommonArticle[0].article_actions[0] === undefined) {
      bookmarkedCommonArticle[0].article_actions[0] = { "is_bookmark": false }
    }
    bookmarkedCommonArticle[0].article_actions[0].is_bookmark = action.data.is_bookmark
    state.articles[bookmarkedCommonArticleIndex] = bookmarkedCommonArticle[0]

    return {
      ...state,
      articles: state.articles
    }

  case articleConstants.UPDATE_BOOKMARK_STATUS_COMMON_ARTICLE_FAILURE:
    return {}

  case articleConstants.UPDATE_BOOKMARK_STATUS_SINGLE_ARTICLE_SUCCESS:
    if (state.singleArticle.article_actions[0] === undefined) {
      state.singleArticle.article_actions[0] = { "is_bookmark": false }
    }
    state.singleArticle.article_actions[0].is_bookmark = action.data.is_bookmark

    return {
      ...state,
      singleArticle: state.singleArticle
    }

  case articleConstants.UPDATE_BOOKMARK_STATUS_SINGLE_ARTICLE_FAILURE:
    return {}

  case articleConstants.UPDATE_READ_STATUS_SINGLE_ARTICLE_SUCCESS:
    if (state.singleArticle.article_actions[0] === undefined) {
      state.singleArticle.article_actions[0] = { "is_read": false }
    }
    state.singleArticle.article_actions[0].is_read = action.data.is_read

    return {
      ...state,
      singleArticle: state.singleArticle
    }

  case articleConstants.UPDATE_READ_STATUS_SINGLE_ARTICLE_FAILURE:
    return {}

  default:
    return state
  }
}

export default articleInfoReducer
