import { profileNotificationSetting } from '../../constants/ProfileSettingConstant';

const INITIAL_STATE = {
  data: {},
  error: {},
}

const ImageUpload = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case profileNotificationSetting.IMAGE_UPLOAD_SUCCESS:
    return {...INITIAL_STATE , data: action.data };
  case profileNotificationSetting.IMAGE_UPLOAD_FAILURE:
    return {...INITIAL_STATE , error: action.error };
  default:
    return state
  }
}

export default ImageUpload;