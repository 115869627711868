// Tanner Fry
// tfry@monetagroup.com
// File contains redux actions for Twilio Conversations.

import { Dispatch } from "react";
import axiosInstance from "../../../config/axiosTS";
import { complianceConstants } from "../../constants/ComplianceConstant";

function DeleteAlert(alert_uuid: string) {
    return (dispatch: Dispatch<any>): Promise<void> => {
        return axiosInstance
            .delete("/compliance/delete-alert/" + alert_uuid)
            .then((response) => {
                return dispatch(success(response.data.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 400 ||
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    // NOTE: Disabled since we don't want to log out the user if they don't have compliance access
                    // utility.handleAuthError();
                }
                if (error.response) {
                    console.log("DeleteAlert error: ", error.response.data);
                    throw failure(error.response.data);
                }
            });
    };
    function success(data: any) {
        return { type: complianceConstants.DELETE_ALERT_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: complianceConstants.DELETE_ALERT_FAILURE, error };
    }
}

function GetComplianceAuth() {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.get("/compliance/auth")
			.then((response) => {
				return dispatch(success(response.data.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
                    // NOTE: Disabled since we don't want to log out the user if they don't have compliance access
					// utility.handleAuthError();
				}
				if (error.response) {
					console.log("GetComplianceAuth error: ", error.response.data);
					throw failure(error.response.data);
				}
			});
	};
	function success(data: any) {
		return { type: complianceConstants.GET_AUTH_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: complianceConstants.GET_AUTH_FAILURE, error };
	}
}

function GetAllInitialConversationData() {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.get("/compliance/all-initial-conversation-data")
			.then((response) => {
				return dispatch(success(response.data.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
                    // NOTE: Disabled since we don't want to log out the user if they don't have compliance access
					// utility.handleAuthError();
				}
				if (error.response) {
					console.log("GetAllInitialConversationData error: ", error.response.data);
					throw failure(error.response.data);
				}
			});
	};
	function success(data: any) {
		return { type: complianceConstants.GET_ALL_INITIAL_CONVERSATION_DATA_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: complianceConstants.GET_ALL_INITIAL_CONVERSATION_DATA_FAILURE, error };
	}
}

function GetAllConversationDataByPage(page: number, limit: number) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/compliance/all-conversation-data-by-page", { page, limit })
			.then((response) => {
				return dispatch(success(response.data.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
                    // NOTE: Disabled since we don't want to log out the user if they don't have compliance access
					// utility.handleAuthError();
				}
				if (error.response) {
					console.log("GetAllConversationDataByPage error: ", error.response.data);
					throw failure(error.response.data);
				}
			});
	};
	function success(data: any) {
		return { type: complianceConstants.GET_ALL_CONVERSATION_DATA_BY_PAGE_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: complianceConstants.GET_ALL_CONVERSATION_DATA_BY_PAGE_FAILURE, error };
	}
}

function GetActiveAlerts() {
    return (dispatch: Dispatch<any>): Promise<void> => {
        return axiosInstance
            .get("/compliance/active-alerts")
            .then((response) => {
                return dispatch(success(response.data.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    // NOTE: Disabled since we don't want to log out the user if they don't have compliance access
                    // utility.handleAuthError();
                }
                if (error.response) {
                    console.log("GetActiveAlerts error: ", error.response.data);
                    throw failure(error.response.data);
                }
            });
    };
    function success(data: any) {
        return { type: complianceConstants.GET_ACTIVE_ALERTS_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: complianceConstants.GET_ACTIVE_ALERTS_FAILURE, error };
    }
}

function GetAlertContext(alert_uuid: string) {
    return (dispatch: Dispatch<any>): Promise<void> => {
        return axiosInstance
            .get("/compliance/alert-context/" + alert_uuid)
            .then((response) => {
                return dispatch(success(response.data.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    // NOTE: Disabled since we don't want to log out the user if they don't have compliance access
                    // utility.handleAuthError();
                }
                if (error.response) {
                    console.log("GetAlertContext error: ", error.response.data);
                    throw failure(error.response.data);
                }
            });
    };
    function success(data: any) {
        return { type: complianceConstants.GET_ALERT_CONTEXT_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: complianceConstants.GET_ALERT_CONTEXT_FAILURE, error };
    }
}

function GetTotalMessageCount() {
    return (dispatch: Dispatch<any>): Promise<void> => {
        return axiosInstance
            .get("/compliance/total-message-count")
            .then((response) => {
                return dispatch(success(response.data.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    // NOTE: Disabled since we don't want to log out the user if they don't have compliance access
                    // utility.handleAuthError();
                }
                if (error.response) {
                    console.log("GetTotalMessageCount error: ", error.response.data);
                    throw failure(error.response.data);
                }
            });
    };
    function success(data: any) {
        return { type: complianceConstants.GET_TOTAL_MESSAGE_COUNT_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: complianceConstants.GET_TOTAL_MESSAGE_COUNT_FAILURE, error };
    }
}

function PostLexiconTerm(term: string, definition: string, type: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/compliance/create-lexicon-term", { term, definition, type })
			.then((response) => {
				return dispatch(success(response.data.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
                    // NOTE: Disabled since we don't want to log out the user if they don't have compliance access
					// utility.handleAuthError();
				}
				if (error.response) {
					console.log("PostLexiconTerm error: ", error.response.data);
					throw failure(error.response.data);
				}
			});
	};
	function success(data: any) {
		return { type: complianceConstants.POST_LEXICON_TERM_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: complianceConstants.POST_LEXICON_TERM_FAILURE, error };
	}
}


export const ComplianceRedux = {
    DeleteAlert,
	GetComplianceAuth,
    GetAllInitialConversationData,
    GetAllConversationDataByPage,
    GetActiveAlerts,
    GetAlertContext,
    GetTotalMessageCount,
    PostLexiconTerm,
}