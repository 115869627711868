import { shareFileConstants } from '../../constants/ShareFileConstant';

const INITIAL_STATE = {
  data: {},
  error: {},
}

const DeleteFromFavourite = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case shareFileConstants.DELETE_FAVOURITE_SUCCESS:
    return {...INITIAL_STATE , data: action.data };
  case shareFileConstants.DELETE_FAVOURITE_FAILURE:
    return {...INITIAL_STATE , error: action.error };
  default:
    return state
  }
}

export default DeleteFromFavourite;