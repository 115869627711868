import { datadogRum } from "@datadog/browser-rum";

// console.log(process.env.REACT_APP_DD_Client_Token , "THis is client token");
datadogRum.init({
  applicationId: process.env.REACT_APP_DD_Application_ID ,
  clientToken: process.env.REACT_APP_DD_Client_Token  ,
  site: process.env.REACT_APP_DD_Site ,
  service: process.env.REACT_APP_DD_Service ,

  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();
