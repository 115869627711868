// Tanner Fry
// tfry@monetagroup.com
// File contains constants for Compliance Dashboard.

export const complianceConstants = {
    DELETE_ALERT_SUCCESS: "DELETE_ALERT_SUCCESS",
    DELETE_ALERT_FAILURE: "DELETE_ALERT_FAILURE",
    GET_AUTH_SUCCESS: "GET_AUTH_SUCCESS",
    GET_AUTH_FAILURE: "GET_AUTH_FAILURE",
    GET_ALL_INITIAL_CONVERSATION_DATA_SUCCESS: "GET_ALL_INITIAL_CONVERSATION_DATA_SUCCESS",
    GET_ALL_INITIAL_CONVERSATION_DATA_FAILURE: "GET_ALL_INITIAL_CONVERSATION_DATA_FAILURE",
    GET_ALL_CONVERSATION_DATA_BY_PAGE_SUCCESS: "GET_ALL_CONVERSATION_DATA_BY_PAGE_SUCCESS",
    GET_ALL_CONVERSATION_DATA_BY_PAGE_FAILURE: "GET_ALL_CONVERSATION_DATA_BY_PAGE_FAILURE",
    GET_ACTIVE_ALERTS_SUCCESS: "GET_ACTIVE_ALERTS_SUCCESS",
    GET_ACTIVE_ALERTS_FAILURE: "GET_ACTIVE_ALERTS_FAILURE",
    GET_ALERT_CONTEXT_SUCCESS: "GET_ALERT_CONTEXT_SUCCESS",
    GET_ALERT_CONTEXT_FAILURE: "GET_ALERT_CONTEXT_FAILURE",
    GET_TOTAL_MESSAGE_COUNT_SUCCESS: "GET_TOTAL_MESSAGE_COUNT_SUCCESS",
    GET_TOTAL_MESSAGE_COUNT_FAILURE: "GET_TOTAL_MESSAGE_COUNT_FAILURE",
    POST_LEXICON_TERM_SUCCESS: "POST_LEXICON_TERM_SUCCESS",
    POST_LEXICON_TERM_FAILURE: "POST_LEXICON_TERM_FAILURE",
}