// Tanner Fry
// tfry@monetagroup.com
// File contains the component to view member info for members of a conversation.

import { useEffect, useRef, useState } from "react";
import { useEffectOnce } from "../../components/Hooks";
import { getInitials, ppJson } from "../../components/UtilitiesTS";
import { AdminType, EmployeeRole, IUserDetailsProps } from "../../interfaces/General";
import { ITwilioConversationsParticipantProps } from "../../interfaces/TwilioConversations";
import TCUtilities from "./TwilioConversationUtilities";

interface ITwilioConversationViewMemberInfoProps {
    memberInfo: ITwilioConversationsParticipantProps;
    setShowConversationMemberInfo: (show: boolean) => void;
    setCurrentlySelectedMember: (member: ITwilioConversationsParticipantProps | null) => void;
}

const TwilioConversationViewMemberInfo = ({
    memberInfo,
    setShowConversationMemberInfo,
    setCurrentlySelectedMember,
}: ITwilioConversationViewMemberInfoProps) => {
    const [userDetails, setUserDetails] = useState<IUserDetailsProps | null>(null);
    const infoContainerRef = useRef<HTMLDivElement>(null);
    const infoDeveloperHelpRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (infoDeveloperHelpRef.current) {
            // Set the height of the developer help box to 40% of the height of the info container
            infoDeveloperHelpRef.current.style.height = `${infoContainerRef.current?.clientHeight ? infoContainerRef.current.clientHeight * 0.4 : 0}px`;
        }
            
    }, [memberInfo]);

    useEffectOnce(() => {
        setUserDetails(localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails') || '{}') : null);
        ppJson("User Details: ", localStorage.getItem('userDetails'));
        ppJson("Member Info: ", memberInfo);
        console.log("Member active status: ", memberInfo.is_active);
    });

    return (
        <div className="twilio-conversation-view-member-info">
            <div className="member-info-options">
                {/* Back carrot */}
                <span 
                    className="icon-32 cursor-pointer"
                    onClick={() => {
                        setShowConversationMemberInfo(false);
                        setCurrentlySelectedMember(null);
                    }}
                >
                    <em className="icon-arrow-circle-left-outline fs-24"></em>
                </span>
            </div>
            <div className="info" ref={infoContainerRef}>
                {/* Image */}
                <div className="info-image">
                    {memberInfo.profile_image && memberInfo.name ? (
                        <img src={memberInfo.profile_image} alt={memberInfo.name} />
                    ) : (
                        <div className="conversation-initials">
                            {memberInfo.messaging_binding ? TCUtilities.getLastFourOfPhoneNumber(memberInfo.messaging_binding.address) : getInitials(memberInfo.name)}
                        </div>
                    )}
                </div>
                {/* Name */}
                <div className="info-name">
                    {memberInfo.messaging_binding ? TCUtilities.formatPhoneNumber(memberInfo.messaging_binding.address) : memberInfo.name} ({memberInfo.is_active ? 'Active' : 'Inactive'})
                </div>
                {/* Actions - Email, phone */}
                <div className="info-actions">
                    {memberInfo.identity !== null ? (
                        <>
                            <a href={"mailto:" + memberInfo.identity} className="info-action">
                                <em className="icon-mail-filled" />
                                <span className="value">
                                    {memberInfo.identity !== null ? memberInfo.identity : 'No email found. Contact EST.'}
                                </span>
                            </a>
                            <br/>
                            <a href={"tel:" + memberInfo.identity} className="info-action">
                                {/* TODO: Add user data contact information to chat participants */}
                                <em className="icon-phone-outgoing-filled" />
                                <span className="value">
                                    {TCUtilities.formatPhoneNumber(
                                        memberInfo.messaging_binding && memberInfo.messaging_binding.address ? (
                                            memberInfo.messaging_binding.address
                                        ) : 'No phone number found. Contact EST.'
                                    )}
                                </span>
                            </a>
                        </>
                    ) : (
                        <>
                            <a href={"mailto:" + memberInfo.identity} className="info-action">
                                {/* TODO: Add user data contact information to text participants and modify the identity utilized below */}
                                <em className="icon-mail-filled" />
                                <span className="value">
                                    {memberInfo.identity !== null ? memberInfo.identity : 'No email found. Contact EST.'}
                                </span>
                            </a>
                            <br/>
                            <a href={"tel:" + memberInfo.identity} className="info-action">
                                <em className="icon-phone-outgoing-filled" />
                                <span className="value">
                                    {TCUtilities.formatPhoneNumber(
                                        memberInfo.messaging_binding && memberInfo.messaging_binding.address ? (
                                            memberInfo.messaging_binding.address
                                        ) : 'No phone number found. Contact EST.'
                                    )}
                                </span>
                            </a>
                        </>
                    )}
                </div>
                <div className="info-misc">
                    {/* Role */}

                    {/* Email */}

                    {/* Phone */}

                </div>
                {/* Developer help */}
                {/* TODO: Create this dev box into a utility that can take in X properties and has an open/close feature. */}
                {memberInfo.identity === null && memberInfo.messaging_binding && userDetails?.employee?.role === EmployeeRole.STAFF 
                && userDetails.admin_type === AdminType.FIRM_ADMIN && userDetails.email === "tfry@monetagroup.com" && (
                    <div className="info-developer-help text-participant" ref={infoDeveloperHelpRef}>
                        <div className="content-container">
                            <div className="title">Dev Box - Text Participant</div>
                            <div className="data-wrapper">
                                <div className="property-name">Participant SID:</div>
                                <div className="property-value">{memberInfo.sid}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Identity (Should be empty):</div>
                                <div className="property-value">{memberInfo.identity}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Type:</div>
                                <div className="property-value">{memberInfo.messaging_binding.type.toUpperCase()}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Phone Number:</div>
                                <div className="property-value">{TCUtilities.formatPhoneNumber(memberInfo.messaging_binding.address)}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Conversation Proxy Address:</div>
                                <div className="property-value">{TCUtilities.formatPhoneNumber(memberInfo.messaging_binding.proxy_address)}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Created At:</div>
                                <div className="property-value">{memberInfo.created_at}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Modified At:</div>
                                <div className="property-value">{memberInfo.modified_at}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">UUID:</div>
                                <div className="property-value">{memberInfo.uuid}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Conversation SID:</div>
                                <div className="property-value">{memberInfo.conversation_sid}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Conversation Role:</div>
                                <div className="property-value">{memberInfo.role_sid}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Active Status for Conversation:</div>
                                <div className="property-value">{memberInfo.is_active ? 'True' : 'False'}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Deleted Status for Conversation:</div>
                                <div className="property-value">{memberInfo.is_deleted ? 'True' : 'False'}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Opt-in Status:</div>
                                <div className="property-value">{memberInfo.opt_in_status ? 'True' : 'False'}</div>
                            </div>
                        </div>
                    </div>
                )}
                {memberInfo.identity !== null && userDetails?.employee?.role === EmployeeRole.STAFF 
                && userDetails.admin_type === AdminType.FIRM_ADMIN && userDetails.email === "tfry@monetagroup.com" && (
                    <div className="info-developer-help chat-participant" ref={infoDeveloperHelpRef}>
                        <div className="content-container">
                            <div className="title">Dev Box - Chat Participant</div>
                            <div className="data-wrapper">
                                <div className="property-name">Identity:</div>
                                <div className="property-value">{memberInfo.identity}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Participant SID:</div>
                                <div className="property-value">{memberInfo.sid}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Created At:</div>
                                <div className="property-value">{memberInfo.created_at}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Modified At:</div>
                                <div className="property-value">{memberInfo.modified_at}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">UUID:</div>
                                <div className="property-value">{memberInfo.uuid}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Conversation SID:</div>
                                <div className="property-value">{memberInfo.conversation_sid}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Conversation Role:</div>
                                <div className="property-value">{memberInfo.role_sid}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Active Status for Conversation:</div>
                                <div className="property-value">{memberInfo.is_active ? 'True' : 'False'}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Deleted Status for Conversation:</div>
                                <div className="property-value">{memberInfo.is_deleted ? 'True' : 'False'}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="property-name">Opt-in Status:</div>
                                <div className="property-value">{memberInfo.opt_in_status ? 'True' : 'False'}</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TwilioConversationViewMemberInfo;