import React from "react";
import logo from "../../Assets/Images/logo.svg";
const NotFound = () => {
  return (
    <>
      <div className="login-section">
        <div className='container'>
          <div className='login-center mx-auto'>
            <div className='text-center'>
              <a href='#' className='login-logo mb-52 mb-md-120'>
                <img src={logo} alt="" />
              </a>
            </div>
            <div className='login-card'>
              <div className='login-heading text-center mb-16'>
                <h2 className='mb-8'>404 Page Not Found</h2>
                
              </div>
            </div>
            <div>
              
            </div>
          </div>
        </div>
      </div> 
    </>
  );
};

export default NotFound;
