import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import Avatar from "../../Assets/Images/Avatar.png";
import Modal from "react-bootstrap/Modal";
import { clientUser } from "../../redux/actions/ClientUser/ClientUser";
import { useAppDispatchJS } from "../../redux/hooks";

const ClientDeactivate = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatchJS();

  const [showDeactivateUser, setDeactivateUserShow] = useState(false);
  const handleDeactivateUserClose = () => {
    setDeactivateUserShow(false);
  };
  const handleDeactivateUserShow = () => setDeactivateUserShow(true);

  useEffect(() => {
    const { visible, closeModal, isReschedule } = props;

    setDeactivateUserShow(visible);
  }, []);

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };


  function deactivateUser() {
    let param = {
      user_id: props?.client?.uuid,
    };
    dispatch(clientUser.deactivateUser(param)).then(
      (response) => {
        if (response.type === "DEACTIVATE_USER_SUCCESS") {
          const { closeModal } = props;
          closeModal();
          Notify(
            "success",
            `Moneta user for ${props?.client?.first_name+" "+props?.client?.last_name} has been deactivated. `,
            <em className="icon-check-outline"></em>
          );
        }
      }
    );
  }

  return (
    <>
      <Modal
        show={showDeactivateUser}
        onHide={() => {
          handleDeactivateUserClose;
          const { closeModal } = props;
          closeModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deactivate User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-md-24">
          <div className="fs-18 lh-32">
            <p className="mb-12">
              Are you sure you want to deactivate the user for <br />{" "}
              <b className="fw-500">{props?.client?.first_name+" "+props?.client?.last_name}?</b>
            </p>
            <p className="mb-12">
              This will revoke their access to Moneta portal but preserve
              historical activity and records.
            </p>
            <p className="mb-0 mb-md-24">
              Moneta users can be reactivated until the client record is
              terminated in Salesforce.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={(e) => {
              const { closeModal } = props;
              closeModal();
            }}
          >
            Cancel
          </button>
          <button className="btn btn-danger" onClick={(e)=>{deactivateUser()}}>Deactivate User</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientDeactivate;
