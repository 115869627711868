import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../redux/actions/Auth/Auth";
import Loading from "../../components/Loader";
import { useAppDispatchJS, useAppSelectorJS } from "../../redux/hooks";
const PasswordOnly = () => {
  const [loader, setLoader] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [deviceToken, setDeviceToken] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const location = useLocation();
  const dispatch = useAppDispatchJS();
  const navigate = useNavigate();
  const authentication = useAppSelectorJS((state) => state.authentication);

  useEffect(() => {
    setEmail(localStorage.getItem("Email"));
    let deviceToken = JSON.parse(
      localStorage.getItem("userDetails")
    )?.device_token;
    setDeviceToken(deviceToken);
    localStorage.setItem("user", false);

    if (!deviceToken || !email) {
      //navigate("/");
    }
  }, []);

  function handleSubmit(e) {
    setLoader(true);
    e.preventDefault();
    setSubmitted(true);
    let devtoken = JSON.parse(
      localStorage.getItem("reset-token")
    )?.device_token;
   
    if (devtoken) {
      setDeviceToken(devtoken);
    }

    let params = {
      email: email,
      password: password,
      device_token: deviceToken ? deviceToken : devtoken,
    };
    if (!password) {
      setLoader(false);
      return;
    }
    dispatch(auth.login(params)).then((response) => {
      setLoader(false);
      if (response.type === "USERS_LOGIN_SUCCESS") {
        localStorage.removeItem("reset-token");
        localStorage.setItem("userDetails", JSON.stringify(response.data));
        localStorage.setItem("user", true);
        localStorage.setItem(
          "token",
          JSON.stringify(response.data.token).replace(/"/g, "").trim()
        );
        navigate("/dashboard");
      } else {
        setIsPasswordIncorrect(true);
      }
    });
  }

  const checkCapsLock = (event) => {
    if (event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };


  return (
    <>
      {loader && <Loading active={loader} />}
      <form name="form" onSubmit={handleSubmit}>
        <div
          className={`form-group ${
            submitted && !password ? "has-error" : ""
          }  `}
        >
          {isCapsLockOn && <div className="alert alert-warning d-block">Warning: CAPS Lock is on.</div>}
          <label className="form-label">
            Password <sup>*</sup>
          </label>
          <div className="password-field">
            <input
              type={passwordShown ? "text" : "password"}
              className="form-control"
              placeholder="Enter password"
              name="password"
              value={password}
              onKeyUp={checkCapsLock}
              onChange={(e) => {
                setPassword(e.target.value);
                setIsPasswordIncorrect(false);
                setSubmitted(false);
              }}
            />
            <em
              className={
                passwordShown
                  ? "icon-eye-reveal-filled"
                  : "icon-eye-hide-filled"
              }
              onClick={(e) => {
                passwordShown
                  ? setPasswordShown(false)
                  : setPasswordShown(true);
              }}
            ></em>
          </div>
          {submitted && !password && (
            <div className="invalid-feedback d-block">Password is required</div>
          )}
          {submitted && password && isPasswordIncorrect && (
            <div className="invalid-feedback d-block">
              {authentication.error.detail}
            </div>
          )}
        </div>
        <div className="mb-16">
          <button className="btn btn-success w-100">Log In</button>
        </div>
        <div className="text-center mb-8">
          <Link
            to="/forgot-password-verify"
            onClick={(e) => {
              let resetToken = JSON.parse(localStorage.getItem("reset-token"));
              resetToken["isPasswordReset"] = false;
              localStorage.setItem("reset-token", JSON.stringify(resetToken));
            }}
            className="btn-link fs-18 lh-32"
          >
            Forgot Password ?
          </Link>
        </div>
        <div className="text-center mb-8">
          <Link
            to="/"
            onClick={(e) => {
              let resetToken = JSON.parse(localStorage.getItem("reset-token"));
              resetToken["isPasswordReset"] = false;
              localStorage.setItem("reset-token", JSON.stringify(resetToken));
            }}
            className="btn-link fs-18 lh-32"
          >
            Back to Login
          </Link>
        </div>
      </form>
    </>
  );
};

export default PasswordOnly;
