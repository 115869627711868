import { userConstants } from '../../constants';

const INITIAL_STATE = {
  data: {},
  error: {},
}

const authentication = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case userConstants.LOGIN_SUCCESS:
    return {...INITIAL_STATE , data: action.data };
  case userConstants.LOGIN_FAILURE:
    return {...INITIAL_STATE , error: action.error };
  default:
    return state
  }
}

export default authentication;