/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Dropdown, Menu, notification, Checkbox } from "antd";
import Loading from "../../components/Loader";
import Modal from "react-bootstrap/Modal";
import { docuSign } from "../../redux/actions/DocuSign/DocuSign";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../Assets/Images/Avatar.png";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import moment from "moment";
import { PresetColorTypes } from "antd/lib/_util/colors";
import { Button, message, Space, Tooltip } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { ConfigProvider, Empty } from "antd";
import Drawing, { brushText } from "react-drawing";
import { upload } from "@testing-library/user-event/dist/upload";
import { notificationService } from "../../redux/actions/Notification/Notification";
import { useAppDispatchJS } from "../../redux/hooks";

const ActionRequired = (props) => {
  const dispatch = useAppDispatchJS();
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState({});
  const [securityRequest, setSecurityRequest] = useState(false);
  const [documentDetail, setDocumentDetail] = useState(false);
  const [SignerList, setSignerList] = useState("");
  const [mySignature, setMySignature] = useState(false);
  const [showSort, setSortShow] = useState(false);
  const [showYouSigned, setYouSignedShow] = useState(false);
  const [text, setText] = useState(".");
  const [key, setKey] = useState(1);
  const [isCreateSignature, setIsCreateSignature] = useState(true);
  const [sendOtpModal, setIsSendOtpModal] = useState(false);
  const [receiveOtpModal, setIsreceiveOtpModal] = useState(false);
  const [EmbededUrl, setEmbededUrl] = useState("");
  const [NotificationEnvelope, setNotificationEnvelope] = useState("");
  const [searchText, setsearchText] = useState("");
  const [signatureList, setSignatureList] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [selectRow, setSelectRow] = useState();
  const [clientList, setClientList] = useState();
  const [cloneList, setCloneList] = useState({});
  const [docuSignInfo, setDocuSignInfo] = useState(
    JSON.parse(localStorage.getItem("docuSign"))
  );
  const [initialImage, setInitialImage] = useState();
  const [selectedFont, setselectedFont] = useState();
  const [signImage, setSignImage] = useState();
  const [filterClient, setfilterClient] = useState([]);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [docuSignDetails, setdocuSignDetails] = useState(
    JSON.parse(localStorage.getItem("docuSign"))
  );
  const drawingRefInitial = useRef();
  const drawingRef = useRef();
  const [showAction, setActionShow] = useState(false);
  const [showView, showViewFile] = useState(false);
  const [state, setState] = useState({
    zoom: 1,
    emailAsc: false,
    emailDesc: false,
    dateAsc: false,
    dateDesc: false,
    nameAsc: false,
    nameDesc: false,
  });
  const { zoom } = state;
  const handleActionClose = () => {
    setActionShow(false);
  };

  const [resendDocumnet, setResendDocumnet] = useState(false);
  const handleResendDocumnetClose = () => setResendDocumnet(false);
  const handleResendDocumnetShow = () => setResendDocumnet(true);

  const handleActionShow = () => setActionShow(true);
  const handleFilterClose = () => {
    //clientList?.data?.map((c) => (c.isSelected = false));
    setClientList(clientList);
    getSelectValue();
    setShowFilter(false);
  };
  const handleFilterShow = () => {
    setShowFilter(true);
    getSelectValue();
  };
  useEffect(() => {
    if (location?.href.includes("status")) {
      var newURL = location.href.split("?")[0];
      window.history.replaceState({}, document.title, newURL);
    }

    if (props.props === "1") {
      setList({});
      getAllDocuments("awaiting_my_signature");
    } else if (props.props === "2") {
      setList({});
      getAllDocuments("completed");
    } else if (props.props === "3") {
      setList({});
      getAllDocuments("waiting_for_others");
    }
  }, [props.props]);

  useEffect(() => {
    //getClientList();
    if (props.mySignature) {
      setInitialImage();
      setSignImage();
      handleMySignatureShow();
    }
    if (props.viewFile) {
      getEmbededSignature(props.selectedEnv);
    }
  }, [props.mySignature, props.viewFile]);

  useEffect(() => {
    getClientList();
  }, []);

  const handleSortClose = () => {
    setSortShow(false);
  };
  const handleSortShow = () => setSortShow(true);

  const handleYouSignedClose = () => {
    setYouSignedShow(false);
  };
  const handleYouSignedShow = () => setYouSignedShow(true);

  const handleSendOtpModalClose = () => {
    setIsSendOtpModal(false);
  };
  const sendOtpModalShow = () => setIsSendOtpModal(true);

  // const handleReceiveOtpModalClose = () => {
  //   setIsSendOtpModal(false);
  // };
  // const handleReceiveOtpModalClose = () => setIsreceiveOtpModal(true);

  //Get All Pending Documents
  function getAllDocuments(fileType) {
    setLoader(true);
    let userId = docuSignInfo?.user_info?.sub;
    dispatch(docuSign.getFileList(userId, fileType)).then((response) => {
      setLoader(false);
      if (response.type === "GET_PENDING_LIST_SUCCESS") {
        setList(response.data);
        setCloneList(response.data);
        if (fileType === "awaiting_my_signature") {
          let data = { actionTab: response.data.result_set_size };
          props.func(data);
        } else if (fileType === "completed") {
          let data = { complete: response.data.result_set_size };
          props.func(data);
        } else {
          let data = { clientAction: response.data.result_set_size };
          props.func(data);
        }
      }
    });
  }

  const handleSecurityRequestClose = () => {
    setSecurityRequest(false);
  };
  const handleDocumentDetail = () => {
    setDocumentDetail(false);
  };
  const handleSecurityRequestShow = () => setSecurityRequest(true);

  const handleMySignatureClose = () => {
    setMySignature(false);
    props.func(false);
  };

  const handleMySignatureShow = () => {
    setMySignature(true);
    setIsCreateSignature(true);
    getListOfSignature();
  };
  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
    });
  };

  function getListOfSignature() {
    setLoader(true);
    dispatch(docuSign.getListOfSignature()).then((response) => {
      setLoader(false);
      if (response.type === "GET_SIGNATURE_LIST_SUCCESS") {
        setSignatureList(response.data);
        //setMySignature(true);
      }
    });
  }

  function deleteSignature(signature) {
    dispatch(docuSign.deleteSignature(signature.signature_id)).then(
      (response) => {
        setLoader(false);
        if (response.type === "DELETE_SIGNATURE_SUCCESS") {
          getListOfSignature();
        }
      }
    );
  }

  function resendEnv(env) {
    const envelop_id = env.envelope_id ? env.envelope_id : env?.envelopeId
    setLoader(true);
    dispatch(docuSign.resendDocumnet(envelop_id)).then((response) => {
      setLoader(false);
      if (response.type === "RESEND_ENV_SUCCESS") {
        Notify(
          "success",
          "Successfully resent " +
            env.email_subject +
            " to " +
            env.sender.user_name,
          <em className="icon-folder-download-filled"></em>
        );
      } else {
        Notify("error", response.error.message);
      }
    });
  }

  function handleButtonClick(e) {
    message.info("Click on left button.");
  }

  function handleMenuClick(e) {
    if (e.key === "4") {
      // console.log(selectRow);
      //resendEnv(selectRow);
      setResendDocumnet(true);
    }
    if (e.key === "1") {
      //console.log(selectRow);
      getEmbededSignature();
    }
    if (e.key === "5") {
      removeDocumnet();
    }
  }
  function getEmbededSignature(record) {
    let value = record?.envelope_id ? record : selectRow;
    let envelop_id = value?.envelope_id
    if (!envelop_id){
      envelop_id = record?.envelopeId
    }
    setLoader(true);
    dispatch(docuSign.getEmbededSignUrl(envelop_id)).then(
      (response) => {
        setLoader(false);
        if (response.type === "GET_EMBEDED_SIGN_SUCCESS") {
          showViewFile(true);
          window.document
            .getElementById("documentviewer")
            .classList.remove("d-none");
          window.document
            .getElementById("documentviewer")
            .classList.add("d-block");
          setTimeout(() => {
            let windowHeight = window.innerHeight;
            let element = document.getElementsByClassName("view-doc-body")[0];
            let headHeight =
              document.getElementsByClassName("view-doc-head")[0].offsetHeight;
            let bottomHeight =
              document.getElementsByClassName("view-doc-footer")[0]
                .offsetHeight;
            console.log(element.offsetHeight, headHeight, bottomHeight);
            element.style.height =
              windowHeight - (headHeight + bottomHeight) + "px";
          }, 100);

          setEmbededUrl(response?.data?.url);
          setNotificationEnvelope(value);
        }else{
          Notify(
            "error",
            response?.error?.message
  
          );
        }
      }
    );
  }
  function removeDocumnet() {
    setLoader(true);
    let param = { envelopeIds: [selectRow?.envelope_id] };
    dispatch(docuSign.removeDocumnet(param)).then((response) => {
      setLoader(false);
      if (response.type === "REMOVE_DOCUMNET_SUCCESS") {
        getAllDocuments("waiting_for_others");
      }
    });
  }

  const menu = (
    <div>
      {userDetails?.user_type !== "advisors" && (
        <Menu>
          <Menu.Item key="1" onClick={handleMenuClick}>
            Sign
          </Menu.Item>
          {/* <Menu.Item key="2">Decline</Menu.Item> */}
        </Menu>
      )}
      {props.props === "3" && (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="3" onClick={getEmbededSignature}>
            View Document
          </Menu.Item>
          <Menu.Item key="5">Remove</Menu.Item>
          <Menu.Item key="4">Resend</Menu.Item>
        </Menu>
      )}
    </div>
  );

  function handleMenuFontClick(key) {}

  function createSignature() {
    //console.log(drawingRef.current.toDataURL());
    let param;
    if (parseInt(key) === 1 || parseInt(key) === 3) {
      param = {
        userSignatures: [
          {
            signatureName:
              docuSignInfo?.user?.first_name +
              " " +
              docuSignInfo?.user?.last_name,
            signatureInitials:
              docuSignInfo?.user?.first_name.charAt(0).toUpperCase() +
              "" +
              docuSignInfo?.user?.last_name.charAt(0).toUpperCase(),
            signatureType: key === 1 ? "Drawn" : "Imported",
          },
        ],
      };
    } else if (parseInt(key) === 2) {
      param = {
        userSignatures: [
          {
            signatureFont: selectedFont,
            signatureName:
              docuSignInfo?.user?.first_name +
              " " +
              docuSignInfo?.user?.last_name,
            signatureInitials:
              docuSignInfo?.user?.first_name.charAt(0).toUpperCase() +
              "" +
              docuSignInfo?.user?.last_name.charAt(0).toUpperCase(),
            signatureType: "RubberStamp",
          },
        ],
      };
    }

    dispatch(docuSign.createSignature(param)).then((response) => {
      setLoader(false);
      if (response.type === "CREATE_SIGNATURE_SUCCESS") {
        if (parseInt(key) !== 2) {
          setSignatureFull(response.data.user_signatures[0].signature_id);
        } else {
          setSignatureList(response.data);
          handleMySignatureClose();
        }

        //  setSignatureInitial(response.data.user_signatures[0].signature_id);
        //setMySignature(true);
      }
    });
  }

  function setSignatureFull(id) {
    let image;
    if (parseInt(key) === 1) {
      image = drawingRef.current.toDataURL();
    } else {
      image = signImage;
    }

    dispatch(docuSign.createSignatureFull(id, image)).then((response) => {
      setLoader(false);
      if (response.type === "CREATE_SIGNATURE_FULL_SUCCESS") {
        setSignatureInitial(response.data.signatureId);
      }
    });
  }

  function setSignatureInitial(id) {
    let image;
    if (parseInt(key) === 1) {
      image = drawingRefInitial.current.toDataURL();
    } else {
      image = initialImage;
    }
    dispatch(docuSign.createSignatureInitial(id, image)).then((response) => {
      setLoader(false);
      if (response.type === "CREATE_SIGNATURE_INITIAL_SUCCESS") {
        setIsCreateSignature(true);
        handleMySignatureClose();
        //getListOfSignature();
      }
    });
  }

  function handleChange(value) {}
  function uploadImage(e, type) {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      if (type !== "full") {
        setInitialImage(e.currentTarget.result);
      } else {
        setSignImage(e.currentTarget.result);
      }
    };
    reader.readAsDataURL(file);
  }
  function toggleTitle() {
    if (userDetails?.user_type === "advisors") {
      return "Client Name";
    } else {
      return "Sender Name";
    }
  }

  function GetSignerName(signers)
  {
    let signer=signers?.sort((a,b) => (a.routing_order > b.routing_order) ? 1 : ((b.routing_order > a.routing_order) ? -1 : 0))?.find(s=>s.status==="sent")
    if(signer!==undefined)
    {
      return signer?.name;
    }
    return signers[0]?.name;
  }

  function toggleTitleForMobile(length) {
    if (userDetails?.user_type === "advisors") {
      if(length>1)
      {
        return "Clients";
      }
        return "Client";
    } else {
      return "From";
    }
  }

  function toggleRevivedColumnTitle() {
    if(props.props === "2")
    {
      return "Completed Date";
    } else {
      return "Receive Date";
    }
  }

  function sortBy(e) {
    let value = JSON.parse(e.target.value);
    let folderType = "";
    let orderType = value.orderBy;
    let order = value.order;
    let userId = docuSignInfo?.user_info?.sub;
    setState({
      dateAsc: false,
      dateDesc: false,
      nameAsc: false,
      nameDesc: false,
      emailAsc: false,
      emailDesc: true,
    });
    if (props.props === "2") {
      folderType = "completed";
    }
    if (orderType === "email_subject") {
      if (order === "asc") {
        setState({ emailAsc: true });
        let listSorted = list.envelopes.sort(function (a, b) {
          return a.email_subject.localeCompare(b.email_subject);
        });
        setList({ envelopes: listSorted });
      } else {
        setState({ emailDesc: true });
        let listSorted = list.envelopes.sort(function (a, b) {
          return b.email_subject.localeCompare(a.email_subject);
        });
        setList({ envelopes: listSorted });
      }
    }
    if (orderType === "name") {
      if (order === "asc") {
        setState({ nameAsc: true });
        let listSorted = list.envelopes.sort(function (a, b) {
          if (userDetails?.user_type === "advisors") {
            return a?.recipients?.signers[0]?.name.localeCompare(
              b?.recipients?.signers[0]?.name
            );
          } else {
            return a.sender.user_name.localeCompare(b.sender.user_name);
          }
        });
        setList({ envelopes: listSorted });
      } else {
        setState({ nameDesc: true });
        let listSorted = list.envelopes.sort(function (a, b) {
          if (userDetails?.user_type === "advisors") {
            return b.recipients.signers[0].name.localeCompare(
              a.recipients.signers[0].name
            );
          } else {
            return b.sender.user_name.localeCompare(a.sender.user_name);
          }
        });
        setList({ envelopes: listSorted });
      }
    }
    if (orderType === "date") {
      if (order === "asc") {
        setState({ dateAsc: true });
        let listSorted = list.envelopes.sort(function (a, b) {
          return (a, b) =>
            moment(a.sent_date_time).unix() - moment(b.sent_date_time).unix();
        });
        setList({ envelopes: listSorted });
      } else {
        setState({ dateDesc: true });
        let listSorted = list.envelopes.sort(function (a, b) {
          return (a, b) =>
            moment(b.sent_date_time).unix() - moment(a.sent_date_time).unix();
        });
        setList({ envelopes: listSorted });
      }
    }
  }

  function getClientList() {
    setLoader(true);
    dispatch(docuSign.getClientList()).then((response) => {
      setLoader(false);
      if (response.type === "GET_CLIENT_LIST_SUCCESS") {
        setClientList(response.data);
      }
    });
  }

  function getSelectValue(e) {
    let arr = clientList?.data
      ?.filter((c) => c.isSelected)
      .map((c) => c.first_name + " " + c.last_name);
    setfilterClient(arr);
  }

  function applyFilter() {
    if(props.props === "1"){
      let cloneList = list?.envelopes?.map((item, index) => item);
      let arr = clientList?.data
      ?.filter((c) => c.isSelected)
      .map((c) => c.first_name + " " + c.last_name);
      let filterActionList = cloneList?.filter((element) => 
      arr?.includes(element?.sender?.user_name) === true
      );
      setList({ envelopes: filterActionList });
      handleFilterClose();
    }else{
      let cloneList = list?.envelopes?.map((item, index) => item);
      let arr = clientList?.data
      ?.filter((c) => c.isSelected)
      .map((c) => c.first_name + " " + c.last_name);
      let filterActionList = cloneList?.filter((element) => 
      arr?.includes(GetSignerName(element?.recipients?.signers)) === true
      );
      setList({ envelopes: filterActionList });
      handleFilterClose();
    }

  }

  function closeSelectedList(name) {
    let indx = clientList?.data?.findIndex(
      (c) => c?.first_name +" "+c?.last_name === name
    );
    clientList.data[indx].isSelected = false
    setClientList(clientList);
    var filteredArray = filterClient?.filter((e) => e !== name);
    setfilterClient(filteredArray);
    setList(list);
    if (filteredArray.length > 0) {
      applyFilter();
    } else {
      setList(cloneList);
    }
  }

  function getName(record) {
    let list = record?.recipients?.signers.filter((a) => {
      if (a.status === "sent") {
        return a;
      }
    });
    return list[0]?.name;
  }
  function complate_docusign_action() {
    dispatch(
      notificationService.complate_docusign_action(
        NotificationEnvelope?.envelope_id,
        docuSignDetails?.user_info?.sub,
        userDetails?.first_name + " " + userDetails?.last_name,
        NotificationEnvelope?.email_subject?.replace("Please DocuSign: ", "")
      )
    ).then((response) => {});
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "email_subject",
      sorter: (a, b) => a?.email_subject.localeCompare(b?.email_subject),
      render: (text, record) => (
        <div className="d-flex align-items-center justify-content-between justify-content-md-start">
          <div className="d-flex align-items-center">
            <span className="icon-24 me-16 cursor-pointer fs-18">
              <em
                className={
                  props.props === "1"
                    ? "icon-exclamation-circle-filled text-info"
                    : props.props === "3"
                    ? "icon-clock-outline text-info"
                    : "icon-check-circle-filled text-success"
                }
              ></em>
            </span>
            <div>
              {record?.email_subject}
              <div className="d-md-none fs-14 lh-20 text-muted">
                <span className="d-block">
                  Received: {moment(record?.sent_date_time).format("ll")}
                </span>
                <span className="d-block">
                  {toggleTitleForMobile(record?.recipients?.signers?.length)}:{" "}
                  {userDetails?.user_type === "advisors" ? (
                    record?.recipients?.signers?.length > 1 ? (
                      <>
                        {" "}
                        <span
                          className="text-underline"
                          onClick={(e) => {
                            e.preventDefault();
                            setSignerList(record);
                            setDocumentDetail(true);
                            console.log(SignerList);
                          }}
                        >
                          {GetSignerName(record?.recipients?.signers)}
                        </span>
                        <span
                          className="icon-24 ms-4"
                          onClick={(e) => {
                            e.preventDefault();
                            setSignerList(record);
                            setDocumentDetail(true);
                          }}
                        >
                          <em className="icon-group-filled"> </em>
                        </span>
                      </>
                    ) : (
                      record?.recipients?.signers[0]?.name
                    )
                  ) : (
                    record?.sender?.user_name
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="d-md-none d-flex align-items-center ms-12">
            <button
              className="btn btn-outline-secondary btn-icon-only ms-12"
              onClick={(e) => {
                e.preventDefault();
                handleActionShow();
              }}
              type="button"
            >
              <em className="icon-dots-horizontal-outline"></em>
            </button>
          </div>
        </div>
      ),
    },
    {
      title: toggleRevivedColumnTitle(),
      dataIndex: props.props !== "2"?"sent_date_time":"completed_date_time",
      responsive: ["md"],
      sorter: (a, b) =>
        (moment(props.props !== "2"? a.sent_date_time:a.completed_date_time).unix() - moment(props.props !== "2"?b.sent_date_time:b.completed_date_time).unix()),
      render: (text, record) => (
        <div>{moment(props.props !== "2"?record?.sent_date_time:record?.completed_date_time).format("ll")}</div>
      ),
    },
    {
      title: toggleTitle(),
      dataIndex: "sender",
      responsive: ["md"],
      sorter: (a, b) => userDetails?.user_type === "advisors"?(GetSignerName(a.recipients?.signers).localeCompare(GetSignerName(b.recipients?.signers))):(a.sender.user_name.localeCompare(b.sender.user_name)),
      render: (text, record) => (
        <>
          {" "}
          <>
            {userDetails?.user_type === "advisors" ? props.props === "1" ?  record?.sender?.user_name : (
              record?.recipients?.signers[0]?.name ? (
                record?.recipients?.signers?.length > 1 ? (
                  <div className="d-inline-flex align-items-center text-success cursor-pointer fw-500">
                    <span
                      className="text-underline"
                      onClick={(e) => {
                        e.preventDefault();
                        setSignerList(record);
                        setDocumentDetail(true);
                        console.log(SignerList);
                      }}
                    >
                      {GetSignerName(record?.recipients?.signers)}
                    </span>
                    <span
                      className="icon-24 ms-4"
                      onClick={(e) => {
                        e.preventDefault();
                        setSignerList(record);
                        setDocumentDetail(true);
                      }}
                    >
                      <em className="icon-group-filled"> </em>
                    </span>
                  </div>
                ) : (
                  record?.recipients?.signers[0]?.name
                )
              ) : (
                "-"
              )
            ) : (
              record?.sender?.user_name
            )}
          </>
          {/* {props.props === "1" && (
            <div>
              {userDetails?.user_type === "advisors"
                ? getName(record)
                  ? getName(record)
                  : "-"
                : record?.sender?.user_name}{" "}
            </div>
          )} */}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "",
      responsive: ["md"],
      render: (text, record) => (
        <div
          onClick={(e) => {
            e.preventDefault();
            setSelectRow(record);
          }}
        >
          {userDetails?.user_type === "clients" && props.props !== "2" && props?.props !== "1" && (
            <Dropdown
              onClick={() => {
                setSelectRow(record);
              }}
              placement="bottomRight"
              trigger={["click"]}
              overlay={menu}
            >
              <button
                type="button"
                className="btn btn-outline-secondary btn-icon-only ms-auto"
              >
                <em className="icon-dots-horizontal-outline"></em>
              </button>
            </Dropdown>
          )}
          {props.props === "1" && (
            <button
              className="btn btn-success"
              onClick={(e) => {
                getEmbededSignature(record);
              }}
            >
              Sign
            </button>
          )}
          {props.props === "2" && (
            <button
              className="btn btn-success"
              onClick={(e) => {
                getEmbededSignature(record);
              }}
            >
              View
            </button>
          )}
          {props.props !== "1" &&
            props.props !== "2" &&
            userDetails?.user_type === "advisors" && (
              <Dropdown
                placement="bottomRight"
                trigger={["click"]}
                overlay={menu}
              >
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-icon-only ms-auto"
                >
                  <em className="icon-dots-horizontal-outline"></em>
                </button>
              </Dropdown>
            )}
        </div>
      ),
    },
  ];
  const setContentRef = useRef();
  useEffect(() => {
      if(setContentRef.current.src ){
        window.parent.document.getElementById("btnReLodaData").click();
      }
    if (
      setContentRef.current.src.includes("/docu-sign?event=viewing_complete") ||
      setContentRef.current.src.includes("/docu-sign?state=1")
    ) {
      if (setContentRef.current.src.includes("event=signing_complete")) {
        window.parent.document.getElementById("btnCompleteReLodaData").click();
      } else {
        window.parent.document.getElementById("btnReLodaData").click();
      }
    }
  }, [setContentRef]);

  function ReloadList() {
    if (props.props === "1") {
      getAllDocuments("awaiting_my_signature");
    } else if (props.props === "2") {
      getAllDocuments("completed");
    } else if (props.props === "3") {
      getAllDocuments("waiting_for_others");
    }
    window.parent.document
      .getElementById("documentviewer")
      .classList.add("d-none");
  }

  function CompleteReloadList() {
    complate_docusign_action();
    if (props.props === "1") {
      getAllDocuments("awaiting_my_signature");
    } else if (props.props === "2") {
      getAllDocuments("completed");
    } else if (props.props === "3") {
      getAllDocuments("waiting_for_others");
    }
    window.parent.document
      .getElementById("documentviewer")
      .classList.add("d-none");
  }

  const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === highlight.toLowerCase() ? "mark" : ""
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };
  return (
    <>
      {loader && <Loading active={loader} />}
      <button id="btnReLodaData" hidden onClick={ReloadList}></button>
      <button
        id="btnCompleteReLodaData"
        hidden
        onClick={CompleteReloadList}
      ></button>
      <div className="card share-card">
        <div className="card-header">
          <div className="d-lg-flex justify-content-between align-items-start mb-md-16 share-tool">
            <div className="share-tool-left">
              <h4 className="mb-0 mb-md-4">
                {props.props === "1" && "Action Required"}
                {props.props === "3" && "Client Actions"}
                {props.props === "2" && "Completed"}
              </h4>
              {userDetails?.user_type === "advisors" && (
                <div className="d-flex">
                  <span
                    className="filt-by-client d-none text-success fw-500 cursor-pointer mt-16 d-md-flex align-items-center text-nowrap"
                    onClick={() => {
                      handleFilterShow();
                    }}
                  >
                    <span className="icon-20 me-8">
                      <em className="icon-adjustments-outline"></em>
                    </span>
                    Filter By Client
                  </span>
                  {console.log(filterClient)}
                  {filterClient?.map((name, index) => (
                    <div
                      className="ms-md-16 mt-16 d-flex align-items-center flex-wrap"
                      key={index}
                    >
                      <span className="filt-badge">
                        {name}
                        <em
                          className="icon-x-outline cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();

                            closeSelectedList(name);
                          }}
                        ></em>
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="position-relative">
            <div className="sorting-bar d-md-none d-flex">
              <button
                type="button"
                className="sort-by-btn"
                onClick={(e) => {
                  e.preventDefault();
                  handleSortShow();
                }}
              >
                Sort By{" "}
                <span className="icon-24 ms-8 text-success">
                  <em className="icon-sort-descending-outline"></em>
                </span>
              </button>
              <button
                type="button"
                className="filt-by-btn"
                onClick={() => {
                  handleFilterShow();
                }}
              >
                {" "}
                <span className="icon-24">
                  <em className="icon-adjustments-outline"></em>
                </span>
              </button>
            </div>
            <div className="custom-table table-striped share-table-fav">
              <ConfigProvider
                renderEmpty={() => (
                  <Empty
                    description={
                      props.props === "1"
                        ? "Documents requiring your signature will appear here."
                        : props.props === "3"
                        ? "Documents requiring your clients' signatures will appear here."
                        : "Signed documents will appear here."
                    }
                  />
                )}
              >
                <Table
                  rowKey="Id"
                  showSorterTooltip={false}
                  columns={columns}
                  dataSource={
                    list?.envelopes?.length > 0 ? [...list.envelopes] : []
                  }
                  pagination={false}
                />
              </ConfigProvider>
            </div>
          </div>
          {}

          {/* Security Model */}
          <Modal
            className="modal-724 modal-full"
            show={securityRequest}
            onHide={handleSecurityRequestClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Security Requests From Sender</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center mb-24">
                <div className="secure-photo me-16">
                  <img src={Avatar} alt="" />
                </div>
                <div className="lh-20">
                  Jeremy Davis
                  <span className="fw-500 text-muted d-block">Moneta</span>
                </div>
              </div>
              {/*  */}
              <div>
                <h5 className="mb-8">SMS Authentication</h5>
                <ol>
                  <li>
                    Choose a phone number below and select the “Send SMS” button
                    to receive a text message.
                  </li>
                  <li>Enter the access code on the following screen.</li>
                </ol>
                <p>
                  Authenticating Signer Name:{" "}
                  <span className="fw-500">Helen Pierce</span>
                </p>
                <p>
                  Please select a phone that can receive text messages so you
                  can authenticate:
                </p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="number"
                    id="number"
                  />
                  <label className="form-check-label" htmlFor="number">
                    618-230-9999
                  </label>
                </div>
              </div>
              {/*  */}
              <div>
                <h5 className="mb-8">SMS Authentication</h5>
                <p>
                  An SMS message has been sent to your mobile phone. You should
                  receive it momentarily.
                </p>
                <p>
                  Enter the code you received in the SMS message in the field
                  below and click Confirm Code.
                </p>
                <div className="mb-8 w-50">
                  <input type="text" className="form-control" />
                </div>
                <p className="mb-0">
                  If you do not receive an SMS message, tap “Cancel”, verify the
                  phone number and try sending the SMS again.
                </p>
              </div>
              {/*  */}
              <div>
                <h5 className="mb-8">Knowledge Authentication</h5>
                <h6 className="fw-500 mb-8">ID Check - Personal Information</h6>
                <div className="row">
                  <div className="col-md-8">
                    <p>
                      Enter your home address. This information, along with your
                      name will be used to generate a list of questions to
                      verify your identity.
                    </p>
                  </div>
                </div>
                <form action="">
                  <div className="pb-32">
                    <h6 className="fw-500 mb-8">
                      Required Information (Home Address)
                    </h6>
                    <p>Name: Rebecca Newman</p>
                    <div className="row gx-20">
                      <div className="col-md-6">
                        <div className="mb-16">
                          <label className="form-label">Street 1</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-16">
                          <label className="form-label">Street 2</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-16">
                          <label className="form-label">City</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row gx-16">
                          <div className="col-6">
                            <div className="mb-16">
                              <label className="form-label">State</label>
                              <select className="form-select">
                                <option>GJ</option>
                                <option>MH</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-16">
                              <label className="form-label">Zip Code</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h6 className="fw-500 mb-16">Optional Information</h6>
                    <div className="row gx-20">
                      <div className="col-md-6">
                        <div className="mb-16">
                          <label className="form-label">
                            Last 4 digits of SSN
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                    </div>
                    <div className="row gx-20">
                      <div className="col-md-6">
                        <div className="mb-16">
                          <label className="form-label">Date of Birth</label>
                          <div className="row gx-16">
                            <div className="col-4">
                              <input type="text" className="form-control" />
                            </div>
                            <div className="col-4">
                              <input type="text" className="form-control" />
                            </div>
                            <div className="col-4">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <span className="pt-4 mb-8 d-block">MM/DD/YYYY</span>
                        </div>
                      </div>
                    </div>
                    <div className="text-danger fw-500">
                      You must enter required and valid information before you
                      can continue
                    </div>
                  </div>
                </form>
              </div>
              {/*  */}
              <div>
                <h5 className="mb-24">Knowledge</h5>
                <h6 className="mb-8">ID Check - Personal Information</h6>
                <p className="mb-24">
                  These questions are being generated as a means of an identity
                  check requested by the document sender. None of this
                  information is provided to the document sender or to anyone
                  except you.
                </p>
                <div>
                  <div className="pb-8">
                    <h6 className="mb-16">
                      Which of the following corporations have you ever been
                      associated with?
                    </h6>
                    <div className="form-check lh-20 mb-16">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="asso"
                        id="asso1"
                      />
                      <label className="form-check-label" htmlFor="asso1">
                        Child Development Service
                      </label>
                    </div>
                    <div className="form-check lh-20 mb-16">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="asso"
                        id="asso2"
                      />
                      <label className="form-check-label" htmlFor="asso2">
                        Pro Pdr Of Georgia
                      </label>
                    </div>
                  </div>
                  <div className="pb-8">
                    <h6 className="mb-16">
                      Which of the following people have you known
                    </h6>
                    <div className="form-check lh-20 mb-16">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="know"
                        id="know1"
                      />
                      <label className="form-check-label" htmlFor="know1">
                        Byron Saintsong
                      </label>
                    </div>
                    <div className="form-check lh-20 mb-16">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="know"
                        id="know2"
                      />
                      <label className="form-check-label" htmlFor="know2">
                        Scott Sampler
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-outline-secondary" variant="secondary">
                Cancel
              </button>
              <button className="btn btn-success" variant="primary">
                Send SMS
              </button>
            </Modal.Footer>
          </Modal>
          {/* Sort Model */}
          <Modal
            className="modal-full"
            show={showSort}
            onHide={handleSortClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Sort By</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <ul className="list-unstyled sort-ul m-0">
                <li className="">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sortby"
                      value={JSON.stringify({
                        order: "asc",
                        orderBy: "email_subject",
                      })}
                      id="az"
                      onChange={(e) => {
                        //e.preventDefault();
                        sortBy(e);
                      }}
                      checked={state.emailAsc}
                    />
                    <label className="form-check-label" htmlFor="az">
                      Documet Name (A - Z)
                    </label>
                  </div>
                  <span className="icon-24 ms-8">
                    <em className="icon-check-circle-filled"></em>
                  </span>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sortby"
                      id="za"
                      value={JSON.stringify({
                        order: "desc",
                        orderBy: "email_subject",
                      })}
                      onChange={(e) => {
                        //e.preventDefault();
                        sortBy(e);
                      }}
                      checked={state.emailDesc}
                    />
                    <label className="form-check-label" htmlFor="za">
                      Documet Name (Z - A)
                    </label>
                  </div>
                  <span className="icon-24 ms-8">
                    <em className="icon-check-circle-filled"></em>
                  </span>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sortby"
                      id="smallest"
                      value={JSON.stringify({
                        order: "asc",
                        orderBy: "date",
                      })}
                      onChange={(e) => {
                        //e.preventDefault();
                        sortBy(e);
                      }}
                      checked={state.dateAsc}
                    />
                    <label className="form-check-label" htmlFor="smallest">
                      Date (Newest)
                    </label>
                  </div>
                  <span className="icon-24 ms-8">
                    <em className="icon-check-circle-filled"></em>
                  </span>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sortby"
                      id="largest"
                      value={JSON.stringify({
                        order: "desc",
                        orderBy: "date",
                      })}
                      onChange={(e) => {
                        e.preventDefault();
                        sortBy(e);
                      }}
                      checked={state.dateDesc}
                    />
                    <label className="form-check-label" htmlFor="largest">
                      Date (Oldest)
                    </label>
                  </div>
                  <span className="icon-24 ms-8">
                    <em className="icon-check-circle-filled"></em>
                  </span>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sortby"
                      id="newest"
                      value={JSON.stringify({
                        order: "asc",
                        orderBy: "name",
                      })}
                      onChange={(e) => {
                        // e.preventDefault();
                        sortBy(e);
                      }}
                    />
                    <label className="form-check-label" htmlFor="newest">
                      Name (A - Z)
                    </label>
                  </div>
                  <span className="icon-24 ms-8">
                    <em className="icon-check-circle-filled"></em>
                  </span>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sortby"
                      id="oldest"
                      value={JSON.stringify({
                        order: "desc",
                        orderBy: "name",
                      })}
                      onChange={(e) => {
                        e.preventDefault();
                        sortBy(e);
                      }}
                    />
                    <label className="form-check-label" htmlFor="oldest">
                      Name (Z - A)
                    </label>
                  </div>
                  <span className="icon-24 ms-8">
                    <em className="icon-check-circle-filled"></em>
                  </span>
                </li>
              </ul>
            </Modal.Body>
          </Modal>

          {/* My Signature Module       */}
          <Modal
            size="lg"
            className="modal-full sign-modal"
            show={mySignature}
            onHide={handleMySignatureClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>My Signature</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-0">
              {isCreateSignature && (
                <div className="min-my-sign">
                  <div className="border-bottom">
                    <div className="d-flex align-items-center py-16 px-24 border-top">
                      <span className="icon-24 me-16">
                        <em className="icon-user-filled"></em>
                      </span>
                      <div>
                        <div>
                          {docuSignInfo?.user?.first_name +
                            " " +
                            docuSignInfo?.user?.last_name}
                        </div>
                        <p className="m-0 fs-14 text-muted">
                          {docuSignInfo?.user?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-24 sign-form">
                    <div className="row gx-16">
                      <div className="col-md-8">
                        <div className="mb-16">
                          <label className="form-label">Signature</label>
                          {signatureList?.user_signatures.map(
                            (signature, index) => (
                              <div className="position-relative" key={index}>
                                <input
                                  type="text"
                                  value={signature.signature_name}
                                  className="form-control"
                                />
                                <button
                                  className="btn btn-outline-secondary btn-1"
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsCreateSignature(false);
                                  }}
                                >
                                  <em className="icon-pencil-filled"></em>
                                </button>
                                <button
                                  className="btn btn-outline-secondary btn-2"
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteSignature(signature);
                                  }}
                                >
                                  <em className="icon-trash-filled"></em>
                                </button>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="">
                          <label className="form-label">Initials</label>
                          {signatureList?.user_signatures.map(
                            (signature, index) => (
                              <div className="position-relative" key={index}>
                                <input
                                  type="text"
                                  value={signature.signature_initials}
                                  className="form-control"
                                />
                                <button
                                  className="btn btn-outline-secondary btn-1"
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsCreateSignature(false);
                                  }}
                                >
                                  <em className="icon-pencil-filled"></em>
                                </button>
                                <button
                                  className="btn btn-outline-secondary btn-2"
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteSignature(signature);
                                  }}
                                >
                                  <em className="icon-trash-filled"></em>
                                </button>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!isCreateSignature && (
                <div className="px-24 pt-16 border-top border-bottom">
                  <div className="mb-24">
                    <h5 className="mb-16 fw-normal">Set up signature</h5>
                    <div className="sign-tab-group">
                      <Tabs
                        id="controlled-tab-example"
                        className="sign-tabs mb-16"
                        activeKey={key}
                        onSelect={(k) => {
                          setKey(k);
                        }}
                      >
                        <Tab eventKey={2} title="Choose">
                          <div className="fontchoose-block">
                            <ul className="fontchoose-list list-unstyled custom-style-scroll">
                              <li>
                                <div className="form-check no-text-form-check me-16">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fontface"
                                    id="fontface1"
                                    onChange={(e) => {
                                      setselectedFont("Mistral");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fontface1"
                                  ></label>
                                </div>
                                <div className="flex-fill">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block mistral">
                                    {docuSignInfo?.user?.first_name +
                                      " " +
                                      docuSignInfo?.user?.last_name}
                                  </span>
                                </div>
                                <div className="ms-auto short-sign">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block mistral">
                                    {docuSignInfo?.user?.first_name
                                      .charAt(0)
                                      .toUpperCase() +
                                      "" +
                                      docuSignInfo?.user?.last_name
                                        .charAt(0)
                                        .toUpperCase()}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="form-check no-text-form-check me-16">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fontface"
                                    id="fontface2"
                                    onChange={(e) => {
                                      setselectedFont("Rage Italic");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fontface2"
                                  ></label>
                                </div>
                                <div className="flex-fill">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block rage">
                                    {docuSignInfo?.user?.first_name +
                                      " " +
                                      docuSignInfo?.user?.last_name}
                                  </span>
                                </div>
                                <div className="ms-auto short-sign">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block rage">
                                    {docuSignInfo?.user?.first_name
                                      .charAt(0)
                                      .toUpperCase() +
                                      "" +
                                      docuSignInfo?.user?.last_name
                                        .charAt(0)
                                        .toUpperCase()}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="form-check no-text-form-check me-16">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fontface"
                                    id="fontface3"
                                    onChange={(e) => {
                                      setselectedFont("1_DocuSign");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fontface3"
                                  ></label>
                                </div>
                                <div className="flex-fill">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_1">
                                    {docuSignInfo?.user?.first_name +
                                      " " +
                                      docuSignInfo?.user?.last_name}
                                  </span>
                                </div>
                                <div className="ms-auto short-sign">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_1">
                                    {docuSignInfo?.user?.first_name
                                      .charAt(0)
                                      .toUpperCase() +
                                      "" +
                                      docuSignInfo?.user?.last_name
                                        .charAt(0)
                                        .toUpperCase()}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="form-check no-text-form-check me-16">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fontface"
                                    id="fontface4"
                                    onChange={(e) => {
                                      setselectedFont("2_DocuSign");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fontface4"
                                  ></label>
                                </div>
                                <div className="flex-fill">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_2">
                                    {docuSignInfo?.user?.first_name +
                                      " " +
                                      docuSignInfo?.user?.last_name}
                                  </span>
                                </div>
                                <div className="ms-auto short-sign">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_2">
                                    {docuSignInfo?.user?.first_name
                                      .charAt(0)
                                      .toUpperCase() +
                                      "" +
                                      docuSignInfo?.user?.last_name
                                        .charAt(0)
                                        .toUpperCase()}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="form-check no-text-form-check me-16">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fontface"
                                    id="fontface5"
                                    onChange={(e) => {
                                      setselectedFont("3_DocuSign");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fontface5"
                                  ></label>
                                </div>
                                <div className="flex-fill">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_3">
                                    {docuSignInfo?.user?.first_name +
                                      " " +
                                      docuSignInfo?.user?.last_name}
                                  </span>
                                </div>
                                <div className="ms-auto short-sign">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_3">
                                    {docuSignInfo?.user?.first_name
                                      .charAt(0)
                                      .toUpperCase() +
                                      "" +
                                      docuSignInfo?.user?.last_name
                                        .charAt(0)
                                        .toUpperCase()}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="form-check no-text-form-check me-16">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fontface"
                                    id="fontface6"
                                    onChange={(e) => {
                                      setselectedFont("4_DocuSign");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fontface6"
                                  ></label>
                                </div>
                                <div className="flex-fill">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_4">
                                    {docuSignInfo?.user?.first_name +
                                      " " +
                                      docuSignInfo?.user?.last_name}
                                  </span>
                                </div>
                                <div className="ms-auto short-sign">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_4">
                                    {docuSignInfo?.user?.first_name
                                      .charAt(0)
                                      .toUpperCase() +
                                      "" +
                                      docuSignInfo?.user?.last_name
                                        .charAt(0)
                                        .toUpperCase()}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="form-check no-text-form-check me-16">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fontface"
                                    id="fontface7"
                                    onChange={(e) => {
                                      setselectedFont("5_DocuSign");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fontface7"
                                  ></label>
                                </div>
                                <div className="flex-fill">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_5">
                                    {docuSignInfo?.user?.first_name +
                                      " " +
                                      docuSignInfo?.user?.last_name}
                                  </span>
                                </div>
                                <div className="ms-auto short-sign">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_5">
                                    {docuSignInfo?.user?.first_name
                                      .charAt(0)
                                      .toUpperCase() +
                                      "" +
                                      docuSignInfo?.user?.last_name
                                        .charAt(0)
                                        .toUpperCase()}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="form-check no-text-form-check me-16">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fontface"
                                    id="fontface8"
                                    onChange={(e) => {
                                      setselectedFont("6_DocuSign");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fontface8"
                                  ></label>
                                </div>
                                <div className="flex-fill">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_6">
                                    {docuSignInfo?.user?.first_name +
                                      " " +
                                      docuSignInfo?.user?.last_name}
                                  </span>
                                </div>
                                <div className="ms-auto short-sign">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_6">
                                    {docuSignInfo?.user?.first_name
                                      .charAt(0)
                                      .toUpperCase() +
                                      "" +
                                      docuSignInfo?.user?.last_name
                                        .charAt(0)
                                        .toUpperCase()}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="form-check no-text-form-check me-16">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fontface"
                                    id="fontface9"
                                    onChange={(e) => {
                                      setselectedFont("7_DocuSign");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fontface9"
                                  ></label>
                                </div>
                                <div className="flex-fill">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_7">
                                    {docuSignInfo?.user?.first_name +
                                      " " +
                                      docuSignInfo?.user?.last_name}
                                  </span>
                                </div>
                                <div className="ms-auto short-sign">
                                  <span className="d-block mb-4 lh-20 fs-14">
                                    Signed by:
                                  </span>
                                  <span className="fs-24 lh-32 d-block docusign_7">
                                    {docuSignInfo?.user?.first_name
                                      .charAt(0)
                                      .toUpperCase() +
                                      "" +
                                      docuSignInfo?.user?.last_name
                                        .charAt(0)
                                        .toUpperCase()}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </Tab>
                        <Tab eventKey={1} title="Draw">
                          <div className="sign-div">
                            <Drawing
                              ref={drawingRef}
                              brush={brushText({
                                fillStyle: "black",
                                size: 25,
                                text,
                              })}
                              height={158}
                              width={648}
                            />
                          </div>
                        </Tab>

                        <Tab eventKey={3} title="Upload Photo">
                          <div className="upload-area d-flex align-items-center justify-content-center">
                            <div
                              className={
                                signImage?.length > 0
                                  ? "drag-block mx-auto d-none"
                                  : "drag-block mx-auto"
                              }
                            >
                              <span className="upload-icon mx-auto">
                                <em className="icon-upload-filled"></em>
                              </span>
                              <span className="d-none d-lg-block fs-18 lh-24">
                                Drag files here
                              </span>
                              <span className="or-line d-none d-lg-block">
                                <span>Or</span>
                              </span>
                              <div className="file-upload">
                                <input
                                  type="file"
                                  onChange={(e) => {
                                    uploadImage(e, "full");
                                  }}
                                />
                                <span className="file-upload-link">
                                  Browse Files
                                </span>
                              </div>
                            </div>
                            <img
                              src={signImage}
                              className="mh-100 mw-100"
                              alt=""
                            />
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                  <div className="mb-24">
                    {key !== "2" && (
                      <h5 className="mb-16 fw-normal">Set up Initials</h5>
                    )}
                    <div className="sign-tab-group">
                      {key !== "2" && (
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key}
                          onSelect={(k) => {
                            setKey(k);
                          }}
                          className="sign-tabs mb-16"
                        >
                          <Tab eventKey={1} title="Draw">
                            <div className="sign-div">
                              <Drawing
                                ref={drawingRefInitial}
                                brush={brushText({
                                  fillStyle: "black",
                                  size: 25,
                                  text,
                                })}
                                height={158}
                                width={648}
                              />
                            </div>
                          </Tab>

                          <Tab eventKey={3} title="Upload">
                            <div className="upload-area d-flex align-items-center justify-content-center">
                              <div
                                className={
                                  initialImage?.length > 0
                                    ? "drag-block mx-auto d-none"
                                    : "drag-block mx-auto"
                                }
                              >
                                <span className="upload-icon mx-auto">
                                  <em className="icon-upload-filled"></em>
                                </span>
                                <span className="d-none d-lg-block fs-18 lh-24">
                                  Drag files here
                                </span>
                                <span className="or-line d-none d-lg-block">
                                  <span>Or</span>
                                </span>
                                <div className="file-upload">
                                  <input
                                    type="file"
                                    onChange={(e) => {
                                      uploadImage(e, "initial");
                                    }}
                                  />
                                  <span className="file-upload-link">
                                    Browse Files
                                  </span>
                                </div>
                              </div>
                              <img
                                src={initialImage}
                                className="mw-100 mh-100"
                                alt=""
                              />
                            </div>
                          </Tab>
                        </Tabs>
                      )}
                    </div>
                  </div>
                  <p className="mb-24">
                    By tapping <strong className="fw-500">Create</strong>, I
                    agree that the signature will be the electronic
                    representation of my signature for all purposes when I (or
                    my agent) use them on documents, including legally binding
                    contracts – just the same as a pen-and-paper signature.
                  </p>
                </div>
              )}
            </Modal.Body>

            {isCreateSignature && (
              <Modal.Footer className="">
                <button
                  className="btn btn-outline-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleMySignatureClose();
                  }}
                >
                  Cancel
                </button>
                {signatureList && (
                  <button
                    className="btn btn-success"
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("Clicked on Done button");
                      if (signatureList?.user_signatures.length > 0) {
                        handleMySignatureClose();
                        setMySignature(false);
                      } else {
                        setIsCreateSignature(false);
                      }
                    }}
                  >
                    {signatureList?.user_signatures.length > 0
                      ? "Done"
                      : "Create Your Signature and Initials"}
                  </button>
                )}
              </Modal.Footer>
            )}
            {!isCreateSignature && (
              <Modal.Footer className="">
                <button
                  className="btn btn-outline-secondary"
                  onClick={(e) => {
                    setIsCreateSignature(false);
                    handleMySignatureClose();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    e.preventDefault();
                    //setIsCreateSignature(true);
                    createSignature();
                  }}
                >
                  Create
                </button>
              </Modal.Footer>
            )}
          </Modal>
          {/* Sort Modal */}
          <Modal size="sm" show={showAction} onHide={handleActionClose}>
            <Modal.Header closeButton>
              <Modal.Title>Actions</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <div className="py-8 px-24 d-flex align-items-center border-bottom">
                <span className="icon-24 fs-18">
                  <em className="icon-exclamation-circle-filled text-info"></em>
                </span>
                <div className="ps-16">
                  <span className="d-block">Roth IRA Agreement 2022</span>
                  <div className="d-md-none fs-14 lh-20 text-muted">
                    <span className="d-block">Received: Jan 5, 2022</span>
                    <span className="d-block">From: Jeremy Davies</span>
                  </div>
                </div>
              </div>

              <ul className="list-unstyled bulk-list action-list m-0">
                <li>Sign</li>
                <li>Decline</li>
              </ul>
            </Modal.Body>
          </Modal>
          {/* FIlter Model */}
          <Modal
            className="modal-full"
            show={showFilter}
            onHide={handleFilterClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter By Client</Modal.Title>
              <a
                href="#"
                className="reset-link d-md-none fw-500 text-muted"
                onClick={(e) => {
                  setsearchText("");
                  setClientList(
                    clientList?.data?.map((c) => (c.isSelected = false))
                  );
                }}
              >
                Reset
              </a>
            </Modal.Header>
            <Modal.Body className="ps-0 pe-0 pb-8 pt-0 pt-md-24">
              <div className="px-24 py-md-8 py-12 border-md-top border-bottom">
                <div className="search-area">
                  <input
                    placeholder="Search clients..."
                    className="form-control"
                    value={searchText}
                    onChange={(e) => {
                      setsearchText(e.target.value);
                    }}
                  />
                  <button
                  className="search-btn icon-20"
                  onClick={(e) => {
                    if (searchText.length > 0) {
                      setsearchText("");
                      //setfilterClient([]);
                      //clientList?.data?.map((c) => (c.isSelected = false));
                      //setClientList(clientList);
                    }
                  }}
                >
                  <em
                    className={
                      searchText === ""
                        ? "icon-search-outline"
                        : "icon-x-circle-outline"
                    }
                  ></em>
                </button>
                </div>
              </div>
              <ul className="check-list list-unstyled custom-style-scroll">
                {clientList?.data
                  ?.filter(
                    (c) =>
                      searchText === "" ||
                      (
                        c.last_name?.toLowerCase() +
                        ", " +
                        c.first_name?.toLowerCase()
                      ).includes(searchText?.toLowerCase())
                  )
                  ?.map((client, index) => (
                    <li key={index}>
                      <div className="form-check mb-0">
                        {client?.isSelected && (
                          <input
                            className="form-check-input"
                            type="Checkbox"
                            id={client.first_name}
                            checked={true}
                            onChange={(e) => {
                              // e.preventDefault();
                              let indx = clientList?.data?.findIndex(
                                (c) => c.uuid === client.uuid
                              );
                              clientList.data[indx].isSelected =
                                e?.target?.checked;
                              setClientList(clientList);
                              getSelectValue(e);
                            }}
                          />
                        )}
                        {!client?.isSelected && (
                          <input
                            className="form-check-input"
                            type="Checkbox"
                            id={client.first_name}
                            checked={false}
                            onChange={(e) => {
                              // e.preventDefault();
                              let indx = clientList?.data?.findIndex(
                                (c) => c.uuid === client.uuid
                              );
                              clientList.data[indx].isSelected =
                                e?.target?.checked;
                              setClientList(clientList);
                              getSelectValue(e);
                            }}
                          />
                        )}
                        <label
                          className="form-check-label"
                          htmlFor="filtclient2"
                        >
                          {searchText
                            ? getHighlightedText(
                                client.last_name + ", " + client.first_name,
                                searchText
                              )
                            : client.last_name + ", " + client.first_name}
                        </label>
                      </div>
                    </li>
                  ))}
              </ul>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <div className="text-center d-none d-md-block pt-16 pt-md-0 text-md-start">
                <a
                  href="#"
                  className={`fw-500 ${
                    clientList?.data?.filter((c) => c?.isSelected)?.length === 0
                      ? "text-AAB1B7"
                      : ""
                  }`}
                  onClick={(e) => {
                    setsearchText("");
                    setfilterClient([]);
                    clientList?.data?.map((c) => (c.isSelected = false));
                    setClientList(clientList);
                  }}
                >
                  Reset
                </a>
              </div>
              <div className="d-md-flex align-items-center">
                <button
                  className="btn btn-outline-secondary d-none d-md-block"
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleFilterClose();
                    clientList?.data?.map((c) => (c.isSelected = false));
                    setClientList(clientList);
                    getSelectValue();
                    setsearchText("");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success mt-0"
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    applyFilter();
                  }}
                >
                  Apply
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          <Modal
            centered
            className="modal-486"
            show={documentDetail}
            onHide={handleDocumentDetail}
          >
            <Modal.Header closeButton className="border-0">
              <Modal.Title>Document Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0 pt-md-24">
              <div className="doc-mod-detail d-flex align-items-center border-bottom border-top">
                <span className="icon-24 text-info fs-20">
                  {SignerList?.status === "completed" && (
                    <em className="icon-check-circle-filled text-success"></em>
                  )}
                  {SignerList?.status === "sent" && (
                    <em className="icon-clock-filled text-4A5568"></em>
                  )}
                  {(SignerList?.status !== "sent" && SignerList?.status !== "completed") && (
                    <em className="icon-ban-outline text-4A5568"></em>
                  )}
                </span>
                <div className="ps-16">
                  <div>{SignerList?.email_subject}</div>
                  <span className="d-block fs-14 lh-24 text-muted">
                    Received: {moment(SignerList?.sent_date_time).format("ll")}
                  </span>
                </div>
              </div>
              {SignerList?.recipients?.signers?.map((signer, index) => (
                <div
                  key={index}
                  className="py-16 d-flex justify-content-between align-items-center"
                >
                  <div>{signer?.name}</div>
                  {(signer?.status === "sent"|| signer?.status === "created") && (
                    <div className="d-flex align-items-center">
                      <span>Pending</span>
                      <span className="icon-24 ms-8">
                        <em className="icon-clock-filled text-4A5568"></em>
                      </span>
                    </div>
                  )}
                  {signer?.status === "completed" && (
                    <div className="d-flex align-items-center">
                      <span>Signed</span>
                      <span className="icon-24 ms-8">
                        <em className="icon-check-circle-filled text-success"></em>
                      </span>
                    </div>
                  )}
                   {(signer?.status !== "sent" && signer?.status !== "created" && signer?.status !== "completed") && (
                    <div className="d-flex align-items-center">
                      <span>Declined</span>
                      <span className="icon-24 ms-8">
                        <em className="icon-ban-outline text-success"></em>
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </Modal.Body>
          </Modal>
        </div>

        <div
          id="documentviewer"
          className={`view-document  align-items-stretch ${
            showView ? "d-flex" : "d-none"
          }`}
        >
          <div className="view-doc-left flex-fill">
            <div className="view-doc-head d-flex justify-content-between border-bottom align-items-center">
              <div className="d-flex align-items-center">
                <span
                  className="icon-32 fs-20 me-8 me-md-16 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    showViewFile(false);
                    props.func(false);
                    setEmbededUrl("");
                  }}
                >
                  <em className="icon-chevron-left-outline"></em>
                </span>
                <div className="d-flex align-items-center">
                  <div>
                    <p className="m-0 lh-20 pdf-name">
                      {selectRow?.email_subject}
                    </p>
                  </div>
                </div>
              </div>
              {/* <button
                type="button"
                className="btn btn-success"
                onClick={(e) => {
                  handleYouSignedShow();
                }}
              >
                You’ve signed!
              </button> */}
            </div>
            <div className="view-doc-body">
              <iframe
                id="iframe-view-doc"
                ref={setContentRef}
                className="w-100 h-100"
                src={EmbededUrl}
              ></iframe>
            </div>
            <div className="view-doc-footer border-top d-flex justify-content-between align-items-center">
              <div className="view-footer-left"></div>
              {/* <div className="view-footer-center d-flex align-items-center">
                <span className="me-8 text-nowrap d-none d-md-block">Page</span>
                <input
                  type="text"
                  className="form-control d-none d-md-block"
                  value={1}
                />
                <span className="ms-8 me-16 text-nowrap d-none d-md-block">
                  of 3
                </span>
                <button
                  type="button"
                  className="btn btn-outline-secondary me-16 d-none d-md-flex"
                >
                  <em className="icon-arrow-up-outline"></em>
                </button>
                <button
                  type="button"
                  className="btn btn-success d-none d-md-flex"
                >
                  <em className="icon-arrow-down-outline"></em>
                </button>

                <span className="me-8 text-nowrap d-md-none">Page 1 of 3</span>
              </div> */}
              {/* <div className="view-footer-right d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    let Newzoom = zoom - 0.1;
                    if (Newzoom > 0) {
                      setState((s) => ({
                        ...s,
                        zoom: Newzoom,
                      }));
                      document.getElementById("file-viwer").style.transform =
                        "scale(" + Newzoom + ")";
                    }
                  }}
                >
                  <em className="icon-subtract-outline"></em>
                </button>
                <span className="mx-16 text-nowrap">
                  {parseInt(zoom * 100)}%
                </span>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    let Newzoom = zoom + 0.1;
                    setState((s) => ({
                      ...s,
                      zoom: Newzoom,
                    }));
                    document.getElementById("pg-viewer").style.transform =
                      "scale(" + Newzoom + ")";
                  }}
                >
                  <em className="icon-add-outline"></em>
                </button>
              </div> */}
            </div>
          </div>
          {/* singned popup */}
          <Modal
            className="modal-356 you-sign-modal"
            show={showYouSigned}
            onHide={handleYouSignedClose}
          >
            <Modal.Body className="text-center">
              <div className="check-green text-success">
                <em className="icon-check-circle-filled"></em>
              </div>
              <Modal.Title>You’ve signed!</Modal.Title>
              <p className="fs-18 lh-32">
                Your signed documents will show under “Completed” tab once all
                parties have signed.
              </p>
              <button type="button" className="btn btn-success w-100">
                Done
              </button>
            </Modal.Body>
          </Modal>
          <Modal
            className="modal-full filtbyclient-modal"
            show={resendDocumnet}
            onHide={handleResendDocumnetClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Resend Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label className="form-label">Document Name</label>
                <span className="d-block mt-8">{selectRow?.email_subject}</span>
              </div>
              <div className="form-group">
                <label className="form-label">Sent Date</label>
                <span className="d-block mt-8">
                  {moment(selectRow?.sent_date_time).format("ll")}
                </span>
              </div>
              <div className="form-group">
                <label className="form-label">Client</label>
                <span className="d-block mt-8">
                  {selectRow?.recipients?.signers[0]?.name}
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-md-flex align-items-center">
                <button
                  className="btn btn-outline-secondary d-none d-md-block"
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleResendDocumnetClose();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success mt-0"
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    resendEnv(selectRow);
                    setResendDocumnet(false);
                  }}
                >
                  Resend
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default ActionRequired;
