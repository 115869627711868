// Tanner Fry
// tfry@monetagroup.com
// File contains interfaces for the Twilio Conversations integration.

/**
 * TwilioConversationsChatProps interface that contains the properties of the chat.
 * 
 * @interface TwilioConversationsChatProps
 * @property {string} created_at - The date and time in UTC when the resource was created specified in ISO 8601 format.
 * @property {string} modified_at - The date and time in UTC when the resource was last updated specified in ISO 8601 format.
 * @property {string} sid - A 34 character string that uniquely identifies this resource.
 * @property {string} account_sid - The unique ID of the Account responsible for this conversation.
 * @property {string} chat_service_sid - The unique ID of the Conversation Service this conversation belongs to.
 * @property {string} messaging_service_sid - The unique ID of the Messaging Service this conversation belongs to.
 * @property {string} friendly_name - The human-readable name of this conversation, limited to 256 characters. Optional.
 * @property {string} unique_name - An application-defined string that uniquely identifies the resource. It can be used to address the resource in place of the resource's sid in the URL.
 * @property {string} attributes - An optional string metadata field you can use to store any data you wish. The string value must contain structurally valid JSON if specified. Note that if the attributes are not set "{}" will be returned.
 * @property {string} state - Current state of this conversation. Can be either active, inactive or closed and defaults to active
 * @property {string} timers - Timer date values representing state update for this conversation.
 * @property {string} url - An absolute API resource URL for this conversation.
 * @property {string} links - Contains absolute URLs to access the participants, messages and webhooks of this conversation.
 * @property {string} links.messages - The URL of the Messages list resource.
 * @property {string} links.participants - The URL of the Participants list resource.
 * @property {string} links.webhooks - The URL of the Webhooks list resource.
 * 
 */
interface ITwilioConversationsConversationProps {
    uuid: string;
    sid: string;
    account_sid: string;
    chat_service_sid: string;
    messaging_service_sid: string;
    created_at: string;
    modified_at: string;
    is_active: boolean;
    is_deleted: boolean;
    friendly_name: string;
    unique_name: string;
    attributes: string;  // Custom attributes in JSON format
    type: ETwilioConversationsConversationTypeProps
    state: string;
    timers: string;  // JSON format
    url: string;
    has_text_message_binding_setup_with_participant?: boolean;
    last_message_timestamp?: string;
    participants: ITwilioConversationsParticipantProps[]
    messages?: ITwilioConversationsMessageProps[]
    links: {
        messages: string;
        participants: string;
        webhooks: string;
    }
    // bindings: string;
}

export enum ETwilioConversationsConversationTypeProps {
    CHAT = "chat",
    TEXT = "text"
}

interface ITwilioConversationsParticipantProps {
    is_active: boolean;
    is_deleted: boolean;
    created_at: string;
    modified_at: string;
    account_sid: string;
    attributes: string;
    conversation_sid: string;
    identity?: string;
    last_read_message_index: number;
    last_read_timestamp: string;
    opt_in_status: boolean;
    messaging_binding?: {
        address: string;
        proxy_address: string;
        type: string;
    };
    name: string;
    profile_image: string;
    role_sid: string;
    sid: string;
    url: string;
    uuid: string;
}

interface ITwilioConversationsMessageAttributesProps {
    text_user_opt_in_at_time_of_message?: boolean,
    is_unread?: boolean,
    read_by?: string[]
}

interface ITwilioConversationsMessageProps {
    uuid: string;
    created_at: string;
    modified_at: string;
    account_sid: string;
    conversation_sid: string;
    sid: string;
    index: number;
    author: string;
    body: string;
    media?: {
        content_type: string;
        filename: string;
        sid: string;
        size: number;
    }
    attributes: ITwilioConversationsMessageAttributesProps;
    participant_sid: string;
    date_created: string;
    date_updated: string;
    url: string;
    delivery: {
        total: number;
        sent: string;
        delivered: string;
        read: string;
        failed: string;
        undelivered: string;
    }
    links: {
        delivery_receipts: string;
        channel_metadata: string;
    }
    content_sid: string;
    profile_image?: string;
}

export type {
    ITwilioConversationsConversationProps,
    ITwilioConversationsMessageProps,
    ITwilioConversationsParticipantProps
};

