import {createStore, applyMiddleware, compose} from 'redux'
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import { createContext } from 'react';

export const ContextStore = createContext();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(
    thunkMiddleware,
  ))
);