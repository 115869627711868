export const docuSignConstants = {

  CHECK_DOCUSIGN_SUCCESS : 'CHECK_DOCUSIGN_SUCCESS',
  CHECK_DOCUSIGN_FAILURE : 'CHECK_DOCUSIGN_FAILURE',

  GET_PENDING_LIST_SUCCESS : 'GET_PENDING_LIST_SUCCESS',
  GET_PENDING_LIST_FAILURE : 'GET_PENDING_LIST_FAILURE',

  RESEND_DOCUMENT_SUCCESS : 'RESEND_DOCUMENT_SUCCESS',
  RESEND_DOCUMENT_FAILURE : 'RESEND_DOCUMENT_FAILURE',

  CREATE_SIGNATURE_SUCCESS : 'CREATE_SIGNATURE_SUCCESS',
  CREATE_SIGNATURE_FAILURE : 'CREATE_SIGNATURE_FAILURE',

  GET_SIGNATURE_LIST_SUCCESS : 'GET_SIGNATURE_LIST_SUCCESS',
  GET_SIGNATURE_LIST_FAILURE : 'GET_SIGNATURE_LIST_FAILURE',

  CREATE_SIGNATURE_FULL_SUCCESS : 'CREATE_SIGNATURE_FULL_SUCCESS',
  CREATE_SIGNATURE_FULL_FAILURE : 'CREATE_SIGNATURE_FULL_FAILURE',

  CREATE_SIGNATURE_INITIAL_SUCCESS : 'CREATE_SIGNATURE_INITIAL_SUCCESS',
  CREATE_SIGNATURE_INITIAL_FAILURE : 'CREATE_SIGNATURE_INITIAL_FAILURE',

  DELETE_SIGNATURE_SUCCESS : 'DELETE_SIGNATURE_SUCCESS',
  DELETE_SIGNATURE_FAILURE : 'DELETE_SIGNATURE_FAILURE',

  GET_EMBEDED_SIGN_SUCCESS : 'GET_EMBEDED_SIGN_SUCCESS',
  GET_EMBEDED_SIGN_FAILURE : 'GET_EMBEDED_SIGN_FAILURE',

  RESEND_ENV_SUCCESS : 'RESEND_ENV_SUCCESS',
  RESEND_ENV_FAILURE : 'RESEND_ENV_FAILURE',

  GET_SEARCH_SUCCESS : 'GET_SEARCH_SUCCESS',
  GET_SEARCH_FAILURE : 'GET_SEARCH_FAILURE',

  GET_SORTED_LIST_SUCCESS : 'GET_SORTED_LIST_SUCCESS',
  GET_SORTED_LIST_FAILURE : 'GET_SORTED_LIST_FAILURE',

  GET_CLIENT_LIST_SUCCESS : 'GET_CLIENT_LIST_SUCCESS',
  GET_CLIENT_LIST_FAILURE : 'GET_CLIENT_LIST_FAILURE',

  REMOVE_DOCUMNET_SUCCESS : 'REMOVE_DOCUMNET_SUCCESS',
  REMOVE_DOCUMNET_FAILURE : 'REMOVE_DOCUMNET_FAILURE',

  GET_COUNT_LIST_SUCCESS : 'GET_COUNT_LIST_SUCCESS',
  GET_COUNT_LIST_FAILURE : 'GET_COUNT_LIST_FAILURE',

  LOGOUT_DOCUSIGN_SUCCESS : 'LOGOUT_DOCUSIGN_SUCCESS',
  LOGOUT_DOCUSIGN_FAILURE : 'LOGOUT_DOCUSIGN_FAILURE',
};