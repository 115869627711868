import { commonConstant } from '../../constants/commonConstant'

const INITIAL_STATE = {
  loader_status: false,
}

const commonInfoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case commonConstant.SET_LOADER_STATUS:
    return { ...INITIAL_STATE, loader_status: action.data.loader_status }

  default:
    return state
  }
}

export default commonInfoReducer
