import React, { useEffect, useState } from "react";
import ShareFileLogin from "./ShareFileLogin";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import SharedTab from "./SharedTab";

import { useDispatch } from "react-redux";
import { shareFile } from "../../redux/actions/ShareFile/ShareFile";
import Loading from "../../components/Loader";
import { useAppDispatchJS } from "../../redux/hooks";

const ShareFile = () => {
  const dispatch = useAppDispatchJS();
  const [key, setKey] = useState("");
  const [loader, setLoader] = useState(false);
  const [loginView, setloginView] = useState(false);
  const [shareTab, setShareTab] = useState(false);
  const [isReverseProps, setIsReverseProps] = useState(false);
  const [allSharedFolder, setAllSharedFolder] = useState({});
  const [loginUrl, setLoginUrl] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );

  useEffect(() => {
    shareFileCheck();
  }, []);

  function shareFileCheck() {
    setLoader(true);
    //  e.preventDefault();
    dispatch(shareFile.shareFileCheck()).then((response) => {
      if (response.type === "CHECK_SHAREFILE_SUCCESS") {
        localStorage.setItem("shareFile", JSON.stringify(response.data));
        setLoader(false);
        setKey("1");
        setloginView(false);
        setShareTab(true);
      } else {
        setLoader(false);
        setloginView(true);
        setShareTab(false)
      }
    });
  }
  function pull_data(data) {
    setIsReverseProps(true);
    setKey(data);
  }

  function login() {
    window.location.href = loginUrl;
  }

  function logout(){
    setLoader(true);
    dispatch(shareFile.shareFileLogout()).then((response) => {
      setLoader(false);
      if (response.type === "LOGOUT_SHAREFILE_SUCCESS") {
        localStorage.removeItem("shareFile");
        localStorage.removeItem("shareFileUser");
        shareFileCheck();
        setKey("")
      }
    });
  }

  return (
    <div>
      {loader && <Loading active={loader} />}
      <div className="heading-wrap d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <span className="head-icon me-16">
            <em className="icon-folder-filled"></em>
          </span>
          <h4 className="m-0">Files</h4>
        </div>     
        <div className="d-flex align-items-center">
          <div className="d-flex search-area justify-content-center align-items-center">
            <button
              className="btn btn-outline-secondary d-none d-md-flex align-items-center ms-16"
              disabled={loginView}
              onClick={(e) => {
                e.preventDefault();
                setShowFilter(true);
                //setMySignature(true);
              }}
            >
              <em
                className="icon-search-outline"
              ></em>
            </button>
          </div>
          <div>
            <button
              className="btn btn-outline-secondary d-none d-md-flex align-items-center ms-16"
              disabled={loginView}
              onClick={(e) => {
                e.preventDefault();
                logout()
                //setMySignature(true);
              }}
            >
              <span className="icon-24 me-8">
                <em className="icon icon-logout-filled"></em>
              </span>
              Log Out of ShareFile
            </button>
            <button
              className="btn btn-outline-secondary btn-icon-only d-md-none me-16"
              onClick={(e) => {
                e.preventDefault();
                //handleActionShow(true);
              }}
            >
              <span className="icon-24">
                <em className="icon-search-outline"></em>
              </span>
            </button>
          </div>

        </div>
      </div>
      <div className="tab-wrap">
        {/* Tabs */}
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => {
            setKey(k);
            setIsReverseProps(false);
          }}
          className={loginView ? "tab-disabled" : ""}
        >
          <Tab
            eventKey={1}
            title={
              <span className="d-md-flex align-items-center justify-content-center">
                <span className="tab-icon">
                  <em className="icon-users-filled"></em>
                </span>{" "}
                <span className="tab-text">Shared</span>
              </span>
            }
          >
            {/* {setSharedTab(true)} */}
          </Tab>
          {userDetails?.user_type === "advisors" && (
            <Tab
              eventKey={3}
              title={
                <span className="d-md-flex align-items-center justify-content-center">
                  <span className="tab-icon">
                    <em className="icon-user-filled fs-14"></em>
                  </span>{" "}
                  <span className="tab-text">Personal</span>
                </span>
              }
            >
              {/* {setSharedTab(true)} */}
            </Tab>
          )}
          <Tab
            eventKey={2}
            title={
              <span className="d-md-flex align-items-center justify-content-center">
                <span className="tab-icon">
                  <em className="icon-star-filled fs-14"></em>
                </span>{" "}
                <span className="tab-text">Favorites</span>
              </span>
            }
          >
            {/* {setFavouriteTab(true)} */}
          </Tab>
          {userDetails?.user_type === "advisors" && (
            <Tab
              eventKey={4}
              title={
                <span className="d-md-flex align-items-center justify-content-center">
                  <span className="tab-icon">
                    <em className="icon-trash-filled fs-14"></em>
                  </span>{" "}
                  <span className="tab-text">Recycling</span>
                </span>
              }
            >
              {/* {setSharedTab(true)} */}
            </Tab>
          )}
        </Tabs>
      </div>
      <div className="content-area">
        {loginView && <ShareFileLogin></ShareFileLogin>}
        <div className="tab-content">
          {shareTab && (
            <SharedTab props={key} func={pull_data}  isReverseProps={false} 
              showFilter={showFilter} onClose={()=>{
                setShowFilter(false)
              }}/>
          )}
          {/* {key == 2 && !loginView && <FavouriteTab />} */}
        </div>
      </div>
    </div>
  );
};

export default ShareFile;
