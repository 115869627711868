import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { utility } from "../../components/Utility";
import { Checkbox } from "antd";

const ClientUserFilter = (props) => {
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const {
    UserStatus,
    relationshipLeadList,
    team_members,
    FilterSelectedTeamMemberType,
    FilterSelectedTeamLeadType,
    closeModal,
    applyFilter,
    resetFilter,
    isteam,
  } = props;

  const [currentUserStatus, setCurrentUserStatus] = useState(
    JSON.parse(JSON.stringify(UserStatus))
  );
  let [currentValue, setCurrentValue] = useState(FilterSelectedTeamMemberType);
  let [currentTeamLeadValue, setCurrentTeamLeadValue] = useState(
    FilterSelectedTeamLeadType
  );
  const [dropdownShown, setDropdownShown] = useState(false);
  const [memmerDropdownShown, setMemberDropdownShown] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [relationshipLeads, setRelationshipLeads] = useState(
    JSON.parse(JSON.stringify(relationshipLeadList))
  );
  const [teammembers, setTeamMembers] = useState(
    JSON.parse(JSON.stringify(team_members))
  );
  const selected = relationshipLeads?.some((r) => r?.isSelected === true);
  const memberSelected = teammembers?.some((r) => r?.isSelected === true);
  const teammeMbersCount =
    teammembers?.filter((r) => r?.isSelected === true).length - 2;
  const LeadCount =
    relationshipLeads?.filter((r) => r?.isSelected === true).length - 2;
  const resetDisabled =
    currentTeamLeadValue === "1" &&
    currentValue === "1" &&
    currentUserStatus.active !== true &&
    currentUserStatus.invite_sent !== true &&
    currentUserStatus.deactivated !== true &&
    currentUserStatus.invite_expired !== true;
  const [state, setState] = useState({
    searchText: "",
    memberSearchText: "",
  });
  const { searchText, memberSearchText } = state;

  return (
    <>
      <Modal
        show={true}
        className="modal-486 modal-full"
        onHide={() => {
          closeModal();
        }}
      >
        <Modal.Header closeButton className="border-bottom pb-md-24">
          <Modal.Title>Filter Client Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-24">
            <div>
              <label className="text-black fs-14 lh-20 fw-500 mb-16">
                {userDetails?.admin_type === "firm_admin"
                  ? "Team"
                  : "Relationship Lead"}
              </label>
              <div className="form-check mb-12">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Anyone"
                  id="Anyone"
                  value="1"
                  checked={currentValue === "1"}
                  onChange={(e) => {
                    teammembers.map((r) => (r.isSelected = false));
                    setTeamMembers(teammembers);
                    setCurrentValue(e.target.value),
                    setMemberDropdownShown(false);
                  }}
                />
                <label className="form-check-label" htmlFor="Anyone">
                  {userDetails?.admin_type === "firm_admin"?"Any Team":"Any Relationship Lead"}
                </label>
              </div>

              {userDetails?.admin_type !== "firm_admin" && (
                <div className="form-check mb-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="OnlyMe"
                    id="OnlyMe"
                    value="2"
                    checked={currentValue === "2"}
                    onChange={(e) => {
                      relationshipLeads.map((r) => (r.isSelected = false));
                      setRelationshipLeads(relationshipLeads);
                      setCurrentValue(e.target.value), setDropdownShown(false);
                    }}
                  />
                  <label className="form-check-label" htmlFor="OnlyMe">
                    Only Me (
                    {userDetails?.first_name + " " + userDetails?.last_name})
                  </label>
                </div>
              )}
              <div className="form-check mb-12">
                <input
                  className="form-check-input"
                  type="radio"
                  name="SelectedMember"
                  id="SelectedMember"
                  value="4"
                  checked={currentValue === "4"}
                  onChange={(e) => {
                    setCurrentValue(e.target.value);
                    setDropdownShown(false);
                  }}
                />
                <label
                  className="form-check-label fs-16 lh-20 fw-normal mb-16"
                  htmlFor="SelectedMember"
                >
                  {userDetails?.admin_type === "firm_admin"
                    ? "Selected Teams"
                    : "Selected Relationship Lead"}
                </label>
                <div className="position-relative">
                  <div
                    id="assgined_to_id"
                    className={
                      currentValue === "4"
                        ? "cursor-pointer"
                        : "cursor-pointer disabled-div"
                    }
                    onClick={(e) => {
                      memmerDropdownShown
                        ? setMemberDropdownShown(false)
                        : setMemberDropdownShown(true);
                      setDropdownShown(false);
                    }}
                  >
                    {!memberSelected ? (
                      <div className="form-select">
                        {userDetails?.admin_type === "firm_admin"
                          ? "Select teams"
                          : "Select Relationship Lead"}
                      </div>
                    ) : (
                      <div className="form-select d-flex align-items-center">
                        {memberSelected &&
                          teammembers
                            ?.filter((r) => r?.isSelected === true)
                            .map((client, index,row) =>
                              index <= 1 ? (
                                <React.Fragment key={index}>
                                  {userDetails?.admin_type !== "firm_admin" && <div className="user-photo icon-32 mx-8">
                                    {utility.getProfileImage(
                                      client?.profile_image,
                                      client?.first_name?.charAt(0),
                                      client?.last_name?.charAt(0)
                                    )}
                                  </div>}
                                  <label className="ms-8">
                                    {utility.ellipsify(
                                      `${client?.first_name} ${client?.last_name}`+(userDetails?.admin_type === "firm_admin"?(index + 1 !== row.length?",":""):""),
                                      20
                                    )}
                                  </label>
                                </React.Fragment>
                              ) : (
                                ""
                              )
                            )}
                        {teammeMbersCount > 0 && (
                          <label className="text-success ms-8">
                            {`+${teammeMbersCount}`}
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                  {memmerDropdownShown && (
                    <div className="dropdown-content mt-4">
                      <div className="px-24 py-md-8 py-12 border-mt-top">
                        <div className="search-area">
                          <input
                            className="form-control"
                            value={memberSearchText}
                            onChange={(e) => {
                              setState((s) => ({
                                ...s,
                                memberSearchText: e.target.value,
                              }));
                            }}
                          />
                          {memberSearchText === "" && (
                            <button className="search-btn icon-20">
                              <em className="icon-search-outline"></em>
                            </button>
                          )}
                          {memberSearchText !== "" && (
                            <button
                              className="close-search-btn icon-20"
                              onClick={(event) => {
                                setState((s) => ({
                                  ...s,
                                  memberSearchText: "",
                                }));
                              }}
                            >
                              <em className="icon-x-circle-filled"></em>
                            </button>
                          )}
                        </div>
                      </div>
                      <ul className="check-list list-unstyled custom-style-scroll">
                        {teammembers &&
                          teammembers?.map((client, indx) => (
                            <>
                              {(memberSearchText === "" ||
                                (
                                  client.first_name?.toLowerCase() +
                                  ", " +
                                  client.last_name?.toLowerCase()
                                ).includes(memberSearchText.toLowerCase())) && (
                                <li
                                  key={indx + client?.first_name}
                                  className={`${
                                    client?.isSelected ? "selected" : ""
                                  }`}
                                >
                                  <div className="form-check mb-0 d-flex align-items-center">
                                    <Checkbox
                                      checked={client?.isSelected}
                                      id={client?.first_name}
                                      onChange={(e) => {
                                        teammembers[indx].isSelected =
                                          e.target.checked;
                                        setTeamMembers(teammembers);
                                        setRefresh(!refresh);
                                      }}
                                    ></Checkbox>
                                    {userDetails?.admin_type !== "firm_admin" &&<div className="user-photo icon-32 mx-8">
                                      {utility.getProfileImage(
                                        client?.profile_image,
                                        client?.first_name?.charAt(0),
                                        client?.last_name?.charAt(0)
                                      )}
                                    </div>}
                                    <label
                                      className="form-check-label ms-8"
                                      htmlFor="filtclient2"
                                    >
                                      {memberSearchText
                                        ? utility.getHighlightedText(
                                          client?.first_name +
                                              " " +
                                              client?.last_name,
                                          memberSearchText
                                        )
                                        : client?.first_name +
                                          " " +
                                          client?.last_name}
                                    </label>
                                  </div>
                                </li>
                              )}
                            </>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              {currentValue === "4" && !memberSelected && (
                <div className="alert alert-danger d-flex align-items-center mt-16 mb-0">
                  <em className="icon icon-exclamation-circle-filled text-danger lh-24"></em>
                  <div className="flex-fill">
                    <h6 className="mb-0 text-danger">
                      {userDetails?.admin_type === "firm_admin"?"Please select a Team to apply this filter.":"Please select a Relationship Lead to apply this filter."}
                    </h6>
                  </div>
                </div>
              )}

              {userDetails?.admin_type === "firm_admin" && isteam==="1" && (
                <>
                  <label className="text-black fs-14 lh-20 fw-500 mb-16">
                    Relationship Lead
                  </label>
                  <div className="form-check mb-12">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="AnyRelationshipLead"
                      id="AnyRelationshipLead"
                      value="1"
                      checked={currentTeamLeadValue === "1"}
                      onChange={(e) => {
                        relationshipLeads.map((r) => (r.isSelected = false));
                        setRelationshipLeads(relationshipLeads);
                        setCurrentTeamLeadValue(e.target.value),
                        setDropdownShown(false);
                      }}
                    />
                    <label className="form-check-label" htmlFor="AnyRelationshipLead">
                      Any Relationship Lead
                    </label>
                  </div>

                  <div className="form-check mb-12">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="SelectedLead"
                      id="SelectedLead"
                      value="2"
                      checked={currentTeamLeadValue === "2"}
                      onChange={(e) => {
                        setCurrentTeamLeadValue(e.target.value);
                        setMemberDropdownShown(false);
                      }}
                    />
                    <label
                      className="form-check-label fs-16 lh-20 fw-normal mb-16"
                      htmlFor="SelectedLead"
                    >
                      Selected Relationship Leads
                    </label>
                    <div className="position-relative">
                      <div
                        id="assgined_to_id"
                        className={
                          currentTeamLeadValue === "2"
                            ? "cursor-pointer"
                            : "cursor-pointer disabled-div"
                        }
                        onClick={(e) => {
                          dropdownShown
                            ? setDropdownShown(false)
                            : setDropdownShown(true);
                          setMemberDropdownShown(false);
                        }}
                      >
                        {!selected ? (
                          <div className="form-select">
                            Select Relationship Leads
                          </div>
                        ) : (
                          <div className="form-select d-flex align-items-center">
                            {selected &&
                              relationshipLeads
                                ?.filter((r) => r?.isSelected === true)
                                .map((client, index) =>
                                  index <= 1 ? (
                                    <React.Fragment key={index}>
                                      <div className="user-photo icon-32 mx-8">
                                        {utility.getProfileImage(
                                          client?.profile_image,
                                          client?.first_name?.charAt(0),
                                          client?.last_name?.charAt(0)
                                        )}
                                      </div>
                                      <label>
                                        {utility.ellipsify(
                                          `${client?.first_name} ${client?.last_name}`,
                                          20
                                        )}
                                      </label>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )
                                )}
                            {LeadCount > 0 && (
                              <label className="text-success ms-8">
                                {`+${LeadCount}`}
                              </label>
                            )}
                          </div>
                        )}
                      </div>
                      {dropdownShown && (
                        <div className="dropdown-content mt-4">
                          <div className="px-24 py-md-8 py-12 border-mt-top">
                            <div className="search-area">
                              <input
                                className="form-control"
                                value={searchText}
                                onChange={(e) => {
                                  setState((s) => ({
                                    ...s,
                                    searchText: e.target.value,
                                  }));
                                }}
                              />
                              {searchText === "" && (
                                <button className="search-btn icon-20">
                                  <em className="icon-search-outline"></em>
                                </button>
                              )}
                              {searchText !== "" && (
                                <button
                                  className="close-search-btn icon-20"
                                  onClick={(event) => {
                                    setState((s) => ({
                                      ...s,
                                      searchText: "",
                                    }));
                                  }}
                                >
                                  <em className="icon-x-circle-filled"></em>
                                </button>
                              )}
                            </div>
                          </div>
                          <ul className="check-list list-unstyled custom-style-scroll">
                            {relationshipLeads &&
                              relationshipLeads?.map((client, indx) => (
                                <>
                                  {(searchText === "" ||
                                    (
                                      client.first_name?.toLowerCase() +
                                      ", " +
                                      client.last_name?.toLowerCase()
                                    ).includes(searchText.toLowerCase())) && (
                                    <li
                                      key={indx + client?.first_name}
                                      className={`${
                                        client?.isSelected ? "selected" : ""
                                      }`}
                                    >
                                      <div className="form-check mb-0 d-flex align-items-center">
                                        <Checkbox
                                          checked={client?.isSelected}
                                          id={client?.first_name}
                                          onChange={(e) => {
                                            relationshipLeads[indx].isSelected =
                                              e.target.checked;
                                            setRelationshipLeads(
                                              relationshipLeads
                                            );
                                            setRefresh(!refresh);
                                          }}
                                        ></Checkbox>
                                        <div className="user-photo icon-32 mx-8">
                                          {utility.getProfileImage(
                                            client?.profile_image,
                                            client?.first_name?.charAt(0),
                                            client?.last_name?.charAt(0)
                                          )}
                                        </div>
                                        <label
                                          className="form-check-label"
                                          htmlFor="filtclient2"
                                        >
                                          {searchText
                                            ? utility.getHighlightedText(
                                              client?.first_name +
                                                  " " +
                                                  client?.last_name,
                                              searchText
                                            )
                                            : client?.first_name +
                                              " " +
                                              client?.last_name}
                                        </label>
                                      </div>
                                    </li>
                                  )}
                                </>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {currentTeamLeadValue === "2" && !selected && (
              <div className="alert alert-danger d-flex align-items-center mt-16 mb-0">
                <em className="icon icon-exclamation-circle-filled text-danger lh-24"></em>
                <div className="flex-fill">
                  <h6 className="mb-0 text-danger">
                    Please select a Relationship Lead to apply this filter.
                  </h6>
                </div>
              </div>
            )}
          </div>
          <div className="mb-0">
            <div>
              <label className="text-black fs-14 lh-20 fw-500 mb-16">
                User Status
              </label>
              <div className="form-check mb-12">
                <Checkbox
                  id="rAnyone"
                  checked={currentUserStatus.active === true}
                  onChange={(e) => {
                    currentUserStatus.active = e.target.checked;
                    setCurrentUserStatus(currentUserStatus);
                    setRefresh(!refresh);
                  }}
                ></Checkbox>
                <label className="form-check-label" htmlFor="rAnyone">
                  Active
                </label>
              </div>
              <div className="form-check mb-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rOnlyMe"
                  checked={currentUserStatus.invite_sent === true}
                  onChange={(e) => {
                    currentUserStatus.invite_sent = e.target.checked;
                    setCurrentUserStatus(currentUserStatus);
                    setRefresh(!refresh);
                  }}
                />
                <label className="form-check-label" htmlFor="rOnlyMe">
                  Invite Sent
                </label>
              </div>
              <div className="form-check mb-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="SelectedClient"
                  checked={currentUserStatus.deactivated === true}
                  onChange={(e) => {
                    currentUserStatus.deactivated = e.target.checked;
                    setCurrentUserStatus(currentUserStatus);
                    setRefresh(!refresh);
                  }}
                />
                <label className="form-check-label" htmlFor="SelectedClient">
                  Deactivated
                </label>
              </div>
              <div className="form-check mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="InviteExpired"
                  checked={currentUserStatus.invite_expired === true}
                  onChange={(e) => {
                    currentUserStatus.invite_expired = e.target.checked;
                    setCurrentUserStatus(currentUserStatus);
                    setRefresh(!refresh);
                  }}
                />
                <label className="form-check-label" htmlFor="InviteExpired">
                  Invite Expired
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between border-top pt-24 flex-md-row-reverse">
          <div className="d-md-flex align-items-center">
            <button
              className="btn btn-outline-secondary d-none d-md-block"
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </button>
            <button
              disabled={
                (currentTeamLeadValue === "2" && !selected) ||
                (currentValue === "4" && !memberSelected)
              }
              className="btn btn-success mt-0"
              onClick={() => {
                applyFilter(
                  teammembers,
                  relationshipLeads,
                  currentValue,
                  currentTeamLeadValue,
                  currentUserStatus
                );
              }}
            >
              Apply
            </button>
          </div>
          <div className="text-center pt-16 pt-md-0 text-md-start">
            <a
              disabled={resetDisabled}
              href="#"
              className={`fw-500 ${resetDisabled ? "text-AAB1B7" : ""}`}
              onClick={() => {
                if (!resetDisabled) {
                  relationshipLeads.map((r) => (r.isSelected = false));
                  teammembers.map((r) => (r.isSelected = false));
                  currentUserStatus.active = null;
                  currentUserStatus.invite_sent = null;
                  currentUserStatus.deactivated = null;
                  currentUserStatus.invite_expired = null;
                  setCurrentUserStatus(currentUserStatus);
                  setRelationshipLeads(relationshipLeads);
                  setTeamMembers(teammembers);
                  currentValue = userDetails.admin_type==="firm_admin"?"1":"2";
                  currentTeamLeadValue = "1";
                  setCurrentValue(currentValue);
                  setCurrentTeamLeadValue(currentTeamLeadValue);
                  setDropdownShown(false);
                  setMemberDropdownShown(false);
                  resetFilter(
                    teammembers,
                    relationshipLeads,
                    currentValue,
                    currentTeamLeadValue,
                    currentUserStatus
                  );
                  setRefresh(!refresh);
                }
              }}
            >
              Reset
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientUserFilter;
