import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { auth } from '../../redux/actions/Auth/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Loading from '../../components/Loader';
import { useAppDispatchJS, useAppSelectorJS } from '../../redux/hooks';

const ResetPassword = () => {
  const [loader, setLoader] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [eightCharacter, setEightCharacter] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [capitalChar, setCapitalChar] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [samePassword, setSamePassword] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [nonFieldError,setNonFieldError] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [newConfirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [CurrentPasswordShown, setCurrentPasswordShown] = useState(false);

  const [validError, setValidError] = useState(false);
  const validErrorMsg = useAppSelectorJS(state => state.ResetDefaultPassword);

  const navigate = useNavigate();
  const dispatch = useAppDispatchJS();

  useEffect(() => {
    setSamePassword((confirmNewPassword === newPassword && confirmNewPassword.length > 0) ? true : false)
  }, [confirmNewPassword, newPassword]);

  function handleChangeNewPassword(e) {
    let newpass = e.target.value;
    setNewPassword(newpass);
    //Eight Character Check
    setEightCharacter(newpass.length >= 8 ? true : false);
    //One NUmber
    let oneNumberRegex = "(?=.*?[0-9])";
    setOneNumber(newpass.match(oneNumberRegex) ? true : false);
    //Capital letter
    let capitalCharRegex = "(?=.*?[A-Z])";
    setCapitalChar(newpass.match(capitalCharRegex) ? true : false);
    //Special Character
    let specialCharRegex = "(?=.*?[!@#$%])";
    setSpecialChar(newpass.match(specialCharRegex) ? true : false);
    //Same Password
    // setSamePassword(confirmNewPassword === newPassword ? true : false)
  }

  function handleChangeConfirmPassword(e) {
    let confirmPass = e.target.value;
    setConfirmNewPassword(confirmPass)
    setSamePassword(confirmPass === newPassword ? true : false)
  }
  function handleSubmit(e) {
    setLoader(true);
    e.preventDefault();
    setOnSubmit(true);
    let param = {
      new_password1: newPassword,
      new_password2: confirmNewPassword
    }
    if (!eightCharacter || !oneNumber || !capitalChar || !samePassword || !specialChar) {
      setLoader(false);
      return;
    }
    dispatch(auth.resetDefaultPassword(param)).then((response) => {
      setLoader(false);
      if (response.type === "RESET_DEFAULT_PASSWORD_SUCCESS") {
        refreshToken();
      } else {
        setValidError(true);
      }
    });
  }

  function refreshToken() {
    setLoader(true);
    dispatch(auth.refreshtoken()).then((response) => {
      setLoader(false);
      if (response.type === "RESET_REFRESH_TOKEN_SUCCESS") {
        localStorage.setItem("token", response?.data?.token);
        navigate("/dashboard");
      } else {
        localStorage.setItem("token",'');
        refreshToken();
      }
    });
  }



  return (
    <>
      { loader && <Loading active={loader}/> }
      <div>
        <form name="form">
          {/* <div className="form-group">
            <label className='form-label'>Current Password <sup>*</sup></label>
            <div className='password-field'>
              <input type={CurrentPasswordShown ? "text" : "password"} className='form-control' name="currentPassword" value={currentPassword} onChange={(e) =>{ setCurrentPassword(e.target.value); setValidError(false)}} />
              <em className={CurrentPasswordShown ? 'icon-eye-reveal-filled' : 'icon-eye-hide-filled'} onClick={(e) => { CurrentPasswordShown ? setCurrentPasswordShown(false) : setCurrentPasswordShown(true) }}></em>
            </div>
          </div>
          { validError && validErrorMsg?.error?.old_password?.length > 0 && 
                        <div className="invalid-feedback d-block">{validErrorMsg?.error?.old_password[0]  }</div>
          } */}
          <div className="form-group">
            <label className='form-label'>New Password <sup>*</sup></label>
            <div className='password-field'>
              <input type={newPasswordShown ? "text" : "password"} className='form-control' name="newPassword" value={newPassword} onChange={(e) => {handleChangeNewPassword(e);setValidError(false)}} />
              <em className={newPasswordShown ? 'icon-eye-reveal-filled' : 'icon-eye-hide-filled'} onClick={(e) => { newPasswordShown ? setNewPasswordShown(false) : setNewPasswordShown(true) }}></em>
            </div>
          </div>
          <div className="form-group">
            <label className='form-label'>Confirm New Password <sup>*</sup></label>
            <div className='password-field'>
              <input type={newConfirmPasswordShown ? "text" : "password"} className='form-control' name="newPassword" value={confirmNewPassword} onChange={(e) => {handleChangeConfirmPassword(e);setValidError(false)}} />
              <em className={newConfirmPasswordShown ? 'icon-eye-reveal-filled' : 'icon-eye-hide-filled'} onClick={(e) => { newConfirmPasswordShown ? setConfirmPasswordShown(false) : setConfirmPasswordShown(true) }}></em>
            </div>
          </div>
          <div className='pass-require'>
            <span className='d-block mb-8 fw-medium'>Your password requirements:</span>
            <div>
              <p><em className={eightCharacter ? 'icon-check-circle-filled' : 'icon-x-circle-filled'}></em> At least 8 characters</p>
              <p><em className={oneNumber ? 'icon-check-circle-filled' : 'icon-x-circle-filled'}></em> At least 1 number</p>
              <p><em className={capitalChar ? 'icon-check-circle-filled' : 'icon-x-circle-filled'}></em> At least 1 capital letter</p>
              <p><em className={specialChar ? 'icon-check-circle-filled' : 'icon-x-circle-filled'}></em> At least 1 special character (!@#$%)</p>
              <p><em className={samePassword ? 'icon-check-circle-filled' : 'icon-x-circle-filled'}></em> Passwords must match</p>
            </div>
          </div>
          <div className='mb-16'>
            <button className="btn btn-success w-100" onClick={(e)=>{handleSubmit(e)}}>Reset Password</button>
          </div>
          { validError && validErrorMsg?.error?.non_field_errors?.length > 0 && 
                        <div className="invalid-feedback d-block">{validErrorMsg?.error?.non_field_errors[0]  }</div>
          }

          <div className='text-center mb-8'>
            <Link to="/" onClick={(e) => {
              localStorage.removeItem("token");
              localStorage.removeItem('user');
              localStorage.removeItem('userDetails');
            }} className="btn-link fs-18 lh-32">Back to Login</Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default ResetPassword;
