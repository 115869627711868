import axiosInstance from "../../../config/axios";
import { shareFileConstants } from "../../constants/ShareFileConstant";
import config from "../../../config/Config";
import { utility } from "../../../components/Utility";

//import instance from "../../../config/axiosShareFile";
import axios from "axios";
export const shareFile = {
  Login,
  shareFileCheck,
  getShareFileToken,
  getHomeFolder,
  getSharedFolder,
  getSharedItemById,
  getDetailsById,
  deleteItemById,
  downloadById,
  CreateFolder,
  getBreadCrumb,
  UpdateNote,
  UpdateName,
  addToFavourite,
  getAccountInformation,
  deleteFromFavourite,
  getPersonalFolder,
  getDeteleFolder,
  getFavouriteFile,
  getTopFolders,
  CopyItem,
  MoveItem,
  GetChunkUrl,
  addAccessControls,
  getAccessControls,
  BulkDelete,
  BulkDownload,
  RestoreFiles,
  shareFileWebhook,
  ProtocolLinks,
  shareFileLogout,
  shareFileUploadDownloadReport,
  getShareFilePermissions,
  getFilesSearchList,
};
var subdomain = JSON.parse(localStorage.getItem("shareFile"))?.subdomain?JSON.parse(localStorage.getItem("shareFile"))?.subdomain.replace(/"/g, "").trim(): "moneta";
function Login(state) {
  let url =
    "https://secure.sharefile.com/oauth/authorize?response_type=code&client_id=" +
    process.env.REACT_APP_ClientId +
    "&redirect_uri=" +
    config.getHost() +
    "/user/sharefile/auth_complete&state=" +
    state;
  //let url ="https://monetagroup.my.idaptive.app/run?appkey=59f7ffcf-1f8b-4cf9-b8ea-b2a748ac88e4&customerID=AAS0766"

  window.location.href = url;
}

function shareFileCheck() {
  return (dispatch) => {
    return axiosInstance
      .get(`/user/sharefile/check`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.CHECK_SHAREFILE_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.CHECK_SHAREFILE_FAILURE, error };
  }
}

function shareFileWebhook(obj) {
  return (dispatch) => {
    return axiosInstance
      .post(`/user/sharefile/webhook`, obj)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  function success(data) {
    return { type: shareFileConstants.WEBHOOK_SHAREFILE_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.WEBHOOK_SHAREFILE_FAILURE, error };
  }
}

function getShareFileToken() {
  let code = JSON.parse(localStorage.getItem("shareFile"))
    ?.code.replace(/"/g, "")
    .trim();
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/oauth/token?grant_type=authorization_code&code=" +
          code +
          "&client_id=" +
          process.env.REACT_APP_ClientId +
          "&client_secret=" +
          process.env.REACT_APP_Secret
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.GET_SHAREFILE_TOKEN_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_SHAREFILE_TOKEN_FAILURE, error };
  }
}

function getHomeFolder() {
  // let code = JSON.parse(localStorage.getItem("shareFile"))?.code.replace(/"/g, "").trim();
  return (dispatch) => {
    return instance
      .get("https://" + subdomain + ".sf-api.com/sf/v3/Items")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.GET_SHAREFILE_TOKEN_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_SHAREFILE_TOKEN_FAILURE, error };
  }
}

function getSharedFolder(type) {
  subdomain = JSON.parse(localStorage.getItem("shareFile"))
    ?.subdomain.replace(/"/g, "")
    .trim();
  // subDomain = JSON.parse(localStorage.getItem("shareFile"))?.subdomain.replace(/"/g, "").trim() ;
  //setSubdomain(subDomain);
  //console.log(subDomain);
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          type +
          ")/Children?$select=Owner/Id,Creator/Id,Id,CreationDate,CreatorNameShort,Description,FileName,Name,FileCount,FileSizeBytes,HasMultipleVersions,PreviewStatus,State,VirusStatus,Uri,url,ESignatureDocument,ExpirationDate,MaxPreviewSize,StreamID,FileLockInfo,Info,Info/CanDeleteCurrentItem,Properties,Properties/*,Info/CanDownload,Info/CanSend,Info/CanManagePermissions,Info/CanAddNode,Info/CanCreateRequestList,Info/CanAddFolder,DlpInfo,DlpInfo/Status,HasPendingAsyncOp,Favorite,Favorite/*,Bundle,Bundle/*&$expand=ESignature,FileLockInfo,DlpInfo,Info,Favorite,Favorite/Item,Bundle,Owner,CreatorFirstName,CreatorLastName,Creator&$skip=0&$top=250&$orderBy=Name asc&includeDeleted=false&orderingMode=UseFolderOptions"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.GET_ALL_SHARED_FOLDER_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_ALL_SHARED_FOLDER_FAILURE, error };
  }
}

function getTopFolders() {
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Users/TopFolders?$select=Id,Name&$orderBy=Name"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.GET_TOPFOLDERS_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_TOPFOLDERS_FAILURE, error };
  }
}
function getSharedItemById(id) {
  // let code = JSON.parse(localStorage.getItem("shareFile"))?.code.replace(/"/g, "").trim();
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          id +
          ")/Children?$select=Owner/Id,Creator/Id,Id,CreationDate,CreatorNameShort,Description,FileName,Name,FileCount,FileSizeBytes,HasMultipleVersions,PreviewStatus,State,VirusStatus,Uri,url,ESignatureDocument,ExpirationDate,MaxPreviewSize,StreamID,FileLockInfo,Info,Info/CanDeleteCurrentItem,Properties,Properties/*,Info/CanDownload,Info/CanSend,Info/CanManagePermissions,Info/CanAddNode,Info/CanCreateRequestList,Info/CanAddFolder,DlpInfo,DlpInfo/Status,HasPendingAsyncOp,Favorite,Favorite/*,Bundle,Bundle/*&$expand=ESignature,FileLockInfo,DlpInfo,Info,Favorite,Favorite/Item,Bundle,Owner,CreatorFirstName,CreatorLastName,Creator&$skip=0&$top=250&$orderBy=Name asc&includeDeleted=false&orderingMode=UseFolderOptions"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.GET_SHARED_FOLDER_BY_ID_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_ALL_SHARED_FOLDER_FAILURE, error };
  }
}

function getBreadCrumb(id) {
  // let code = JSON.parse(localStorage.getItem("shareFile"))?.code.replace(/"/g, "").trim();
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          id +
          ")/Breadcrumbs?bubbleSharedPassthroughs=true"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {


        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.GET_BREAD_CRUMB_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_BREAD_CRUMB_FAILURE, error };
  }
}

function getAccountInformation() {
  // let code = JSON.parse(localStorage.getItem("shareFile"))?.code.replace(/"/g, "").trim();
  return (dispatch) => {
    return instance
      .get("https://" + subdomain + ".sf-api.com/sf/v3/Users")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.GET_ACCOUNT_INFO_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_ACCOUNT_INFO_FAILURE, error };
  }
}

function getDetailsById(id) {
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          id +
          ")?includeDeleted=false"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.GET_DETAILS_BY_ID_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_DETAILS_BY_ID_FAILURE, error };
  }
}

function RestoreFiles(ids) {
  return (dispatch) => {
    return instance
      .post("https://" + subdomain + ".sf-api.com/sf/v3/Items/BulkRestore", ids)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.RESTORE_FILE_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.RESTORE_FILE_FAILURE, error };
  }
}

function deleteItemById(id) {
  // let code = JSON.parse(localStorage.getItem("shareFile"))?.code.replace(/"/g, "").trim();
  return (dispatch) => {
    return instance
      .delete(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          id +
          ")?singleversion=false&forceSync=false"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: shareFileConstants.DELETE_ITEM_BY_ID_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GDELETE_ITEM_BY_ID_FAILURE, error };
  }
}

function BulkDelete(parentId, items) {
  return (dispatch) => {
    return instance
      .post(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          parentId +
          ")/BulkDelete?forceSync=true&deletePermanently=false",
        items
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: shareFileConstants.BULK_DELETE_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.BULK_DELETE_FAILURE, error };
  }
}

function BulkDownload(parentId, items) {
  return (dispatch) => {
    return instance
      .post(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          parentId +
          ")/BulkDownload?redirect=false",
        items
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: shareFileConstants.BULK_DOWNLOAD_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.BULK_DOWNLOAD_SUCCESS, error };
  }
}
function CreateFolder(folderName, parentId) {
  // let code = JSON.parse(localStorage.getItem("shareFile"))?.code.replace(/"/g, "").trim();
  return (dispatch) => {
    return instance
      .post(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          parentId +
          ")/Folder?overwrite=false&passthrough=false",
        { Name: folderName}
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {


        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: shareFileConstants.CREATE_FOLDER_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.CREATE_FOLDER_FAILURE, error };
  }
}

function UpdateNote(itemDetails) {
  return (dispatch) => {
    return instance
      .patch(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          itemDetails?.Id +
          ")",
        {
          Description: itemDetails.Description,
        }
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: shareFileConstants.UPDATE_NOTE_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.UPDATE_NOTE_FAILURE, error };
  }
}

function GetChunkUrl(parentId, FileItem) {
  return (dispatch) => {
    return instance
      .post(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          parentId +
          ")/Upload",
        {
          Tool: "html5",
          FileName: FileItem.name,
          FileSize: FileItem.size,
          Method: "CloudNativeStandard",
          BatchId: "2bf16c43df",
          BatchLast: true,
          Overwrite: false,
        }
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 
        ) {

        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: shareFileConstants.CUNKURL_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.CHUNKURL_FAILURE, error };
  }
}

function UpdateName(itemDetails) {
  return (dispatch) => {
    return instance
      .patch(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          itemDetails?.Id +
          ")",
        { Name: itemDetails.Name }
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: shareFileConstants.UPDATE_NAME_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.UPDATE_NAME_FAILURE, error };
  }
}

function CopyItem(itemId, targetId, isCopy) {
  return (dispatch) => {
    return instance
      .post(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          itemId +
          ")/Copy?targetid=" +
          targetId +
          "&overwrite=false"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: shareFileConstants.COPY_ITEM_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.COPY_ITEM_FAILURE, error };
  }
}

function MoveItem(itemId, targetId) {
  return (dispatch) => {
    return instance
      .post(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items/BulkMove?newParentUrl=https://codaldemo.sf-api.com/sf/v3/Items(" +
          targetId +
          ")",
        [config.getShareFileURL() + "/Items(" + itemId + ")"]
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: shareFileConstants.COPY_ITEM_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.COPY_ITEM_FAILURE, error };
  }
}

function downloadById(id) {
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          id +
          ")/Download?redirect=false&includeAllVersions=false&includeDeleted=false&forQuarantinedFiles=false"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.DOWNLOAD_ITEM_BY_ID_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.DOWNLOAD_ITEM_BY_ID_FAILURE, error };
  }
}

function ProtocolLinks(id) {
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          id +
          ")/ProtocolLinks(Web)?action=View"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.DOWNLOAD_ITEM_BY_ID_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.DOWNLOAD_ITEM_BY_ID_FAILURE, error };
  }
}

function addToFavourite(userId, param) {
  return (dispatch) => {
    return instance
      .post(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Users(" +
          userId +
          ")/Favorites",
        param
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.ADD_FAVOURITE_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.ADD_FAVOURITE_FAILURE, error };
  }
}

function deleteFromFavourite(userId, streamId) {
  return (dispatch) => {
    return instance
      .delete(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Favorites" +
          "(userid=" +
          userId +
          ",streamid=" +
          streamId +
          ")"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.DELETE_FAVOURITE_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.DELETE_ITEM_BY_ID_FAILURE, error };
  }
}

function getPersonalFolder() {
  // let code = JSON.parse(localStorage.getItem("shareFile"))?.code.replace(/"/g, "").trim();
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items/Children?$select=Owner/Id,Creator/Id,Id,CreationDate,CreatorNameShort,Description,FileName,Name,FileCount,FileSizeBytes,HasMultipleVersions,PreviewStatus,State,VirusStatus,Uri,url,ESignatureDocument,ExpirationDate,MaxPreviewSize,StreamID,FileLockInfo,Info,Info/CanDeleteCurrentItem,Properties,Properties/*,Info/CanDownload,Info/CanSend,Info/CanManagePermissions,Info/CanAddNode,Info/CanCreateRequestList,Info/CanAddFolder,DlpInfo,DlpInfo/Status,HasPendingAsyncOp,Favorite,Favorite/*,Bundle,Bundle/*&$expand=ESignature,FileLockInfo,DlpInfo,Info,Favorite,Favorite/Item,Bundle,Owner,CreatorFirstName,CreatorLastName,Creator&$skip=0&$top=250&$orderBy=Name asc&includeDeleted=false&orderingMode=UseFolderOptions"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.PERSONAL_FOLDER_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.PERSONAL_FOLDER_FAILURE, error };
  }
}

function getDeteleFolder() {
  // let code = JSON.parse(localStorage.getItem("shareFile"))?.code.replace(/"/g, "").trim();
  return (dispatch) => {
    return instance
      .get("https://" + subdomain + ".sf-api.com/sf/v3/Items/UserDeletedItems")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.RECYLCE_FOLDER_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.RECYLCE_FOLDER_FAILURE, error };
  }
}

function getFavouriteFile(userId) {
  let code = JSON.parse(localStorage.getItem("shareFile"))
    ?.subdomain.replace(/"/g, "")
    .trim();
  console.log(code, "for subdomain");
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Users(" +
          userId +
          ")/Favorites?$select=*,Item/*,Bundle,Bundle/*,Item/Parent/url&$expand=Item,Item/Owner,Item/Bundle,Item/Parent,Item/SemanticPath,Bundle"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.GET_FAVORITE_FOLDER_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_FAVORITE_FOLDER_FAILURE, error };
  }
}

function addAccessControls(Id, param) {
  // let code = JSON.parse(localStorage.getItem("shareFile"))?.code.replace(/"/g, "").trim();
  return (dispatch) => {
    return instance
      .patch(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          Id +
          ")/AccessControls?recursive=false",
        param
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.ADD_ACCESS_CONTROLS_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.ADD_ACCESS_CONTROLS_FAILURE, error };
  }
}

function getAccessControls(pId, itemId) {
  // let code = JSON.parse(localStorage.getItem("shareFile"))?.code.replace(/"/g, "").trim();
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/AccessControls(principalid=" +
          pId +
          ",itemid=" +
          itemId +
          ")"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.GET_ACCESS_CONTROLS_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_ACCESS_CONTROLS_FAILURE, error };
  }
}

function shareFileLogout() {
  return (dispatch) => {
    return axiosInstance
      .get(
        "/user/sharefile/logout"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: shareFileConstants.LOGOUT_SHAREFILE_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.LOGOUT_SHAREFILE_FAILURE, error };
  }
}

function shareFileUploadDownloadReport(obj) {
  return (dispatch) => {
    return axiosInstance
      .post(`/report/sharefile/upload_download_report`, obj)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  function success(data) {
    return { type: shareFileConstants.REPORT_CAPTURED_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.REPORT_CAPTURED_FAILURE, error };
  }
}

const instance = axios.create({
  // baseURL: getShareFileUrl(),
  timeout: 600000,
});

// function getShareFileUrl() {
//   //let subDomain = JSON.parse(localStorage.getItem("shareFile")) ? JSON.parse(localStorage.getItem("shareFile"))?.subdomain.replace(/"/g, "").trim() : "";
//   console.log(getSubdomain());
//   return "https://" + getSubdomain() + ".sf-api.com/sf/v3";
// }
// function setSubdomain(value) {
//   subdomain = value;
// }
// function getSubdomain() {
//   return subdomain;
// }

instance.interceptors.request.use(
  async (reqConfig) => {
    console.log(reqConfig);
    const accessToken = JSON.parse(localStorage.getItem("shareFile"))
      ?.access_token.replace(/"/g, "")
      .trim();
    let headers;
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }
    return { ...reqConfig, headers };
  },
  (error) => {
    return Promise.reject(error);
  }
);

function getShareFilePermissions(type) {
  subdomain = JSON.parse(localStorage.getItem("shareFile"))
    ?.subdomain.replace(/"/g, "")
    .trim();
  // subDomain = JSON.parse(localStorage.getItem("shareFile"))?.subdomain.replace(/"/g, "").trim() ;
  //setSubdomain(subDomain);
  //console.log(subDomain);
  return (dispatch) => {
    return instance
      .get(
        "https://" +
          subdomain +
          ".sf-api.com/sf/v3/Items(" +
          type +
          ")/Info"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {

        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: shareFileConstants.GET_ALL_SHARE_FILES_PERMISSIONS_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_ALL_SHARE_FILES_PERMISSIONS_FAILURE, error };
  }
}

function getFilesSearchList(data) {
  subdomain = JSON.parse(localStorage.getItem("shareFile"))
    ?.subdomain.replace(/"/g, "")
    .trim();
  return (dispatch) => {  
    return instance
      .post(
        `https://${subdomain}.sf-api.com/sf/v3/Items/AdvancedSimpleSearch`,data
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: shareFileConstants.GET_SEARCH_SUCCESS, data };
  }

  function failure(error) {
    return { type: shareFileConstants.GET_SEARCH_FAILURE, error };
  }
}


