const timeRange = [
  {
    value : "12:00 AM",
    index : 1
  },
  {
    value : "12:30 AM",
    index : 2
  },
  {
    value : "01:00 AM",
    index : 3
  },
  {
    value : "01:30 AM",
    index : 4
  },
  {
    value : "02:00 AM",
    index : 5
  },
  {
    value : "02:30 AM",
    index : 6
  },
  {
    value : "03:00 AM",
    index : 7
  },
  {
    value : "03:30 AM",
    index : 8
  },
  {
    value : "04:00 AM",
    index : 9
  },
  {
    value : "04:30 AM",
    index : 10
  },
  {
    value : "05:00 AM",
    index : 11
  },
  {
    value : "05:30 AM",
    index : 12
  },
  {
    value : "06:00 AM",
    index : 13
  },
  {
    value : "06:30 AM",
    index : 14
  },
  {
    value : "07:00 AM",
    index : 15
  },
  {
    value : "07:30 AM",
    index : 16
  },
  {
    value : "08:00 AM",
    index : 17
  },
  {
    value : "08:30 AM",
    index : 18
  },
  {
    value : "09:00 AM",
    index : 19
  },
  {
    value : "09:30 AM",
    index : 20
  },  
  {
    value : "10:00 AM",
    index : 21
  },
  {
    value : "10:30 AM",
    index : 22
  },
  {
    value : "11:00 AM",
    index : 23
  },
  {
    value : "11:30 AM",
    index : 24
  },
  {
    value : "12:00 PM",
    index : 25
  },
  {
    value : "12:30 PM",
    index : 26
  },
  {
    value : "01:00 PM",
    index : 27
  },
  {
    value : "01:30 PM",
    index : 28
  },
  {
    value : "02:00 PM",
    index : 29
  },
  {
    value : "02:30 PM",
    index : 30
  },
  {
    value : "03:00 PM",
    index : 31
  },
  {
    value : "03:30 PM",
    index : 32
  },
  {
    value : "04:00 PM",
    index : 33
  },
  {
    value : "04:30 PM",
    index : 34
  },
  {
    value : "05:00 PM",
    index : 35
  },
  {
    value : "05:30 PM",
    index : 36
  },
  {
    value : "06:00 PM",
    index : 37
  },
  {
    value : "06:30 PM",
    index : 38
  },
  {
    value : "07:00 PM",
    index : 39
  },
  {
    value : "07:30 PM",
    index : 40
  },
  {
    value : "08:00 PM",
    index : 41
  },
  {
    value : "08:30 PM",
    index : 42
  },
  {
    value : "09:00 PM",
    index : 43
  },
  {
    value : "09:30 PM",
    index : 44
  },  
  {
    value : "10:00 PM",
    index : 45
  },
  {
    value : "10:30 PM",
    index : 46
  },
  {
    value : "11:00 PM",
    index : 47
  },
  {
    value : "11:30 PM",
    index : 48
  },
  {
    value : "11:59 PM",
    index : 49
  },      
];

export default timeRange;
