/* eslint-disable indent */
import { Radio, Select, Switch } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Loading from "../../components/Loader";
import { clientUser } from "../../redux/actions/ClientUser/ClientUser";
import { useAppDispatchJS } from "../../redux/hooks";
import { useAppSelectorTS } from "../../redux/hooksTS";
import UpdateDefaultSettings from "./UpdateDefaultSettings";

const ClientUserSetting = (props) => {
  const { Option } = Select;
  const navigate = useNavigate();
  const dispatch = useAppDispatchJS();
  const { selectedTeam, setselectedTeam } = props;
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [showUpdateDefaultSetting, setUpdateDefaultSettingShow] =
    useState(false);

  const [houseHoldDetails, setHouseHoldDetails] = useState();

  const [userDetails, setUserDetails] = useState();
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState();
  const [selectedEmail, setSelectedEmail] = useState();
  const [selectedPreference, setSelectedPreference] = useState(null);

  // const [calendarNotification, setCalendarNotification] = useState([]);
  const [messageNotification, setMessageNotification] = useState([]);
  const [chatNotification, setChatNotification] = useState([]);
  const [docuSignNotification, setDocuSignNotification] = useState([]);
  const [fileNotification, setFileNotification] = useState([]);
  const [loader, setLoader] = useState(false);

  // const [calendarDefaultNotification, setCalendarDefaultNotification] =
  //   useState([]);
  const [messageDefaultNotification, setMessageDefaultNotification] = useState(
    []
  );
  const [chatDefaultNotification, setChatDefaultNotification] = useState([]);
  const [docuSignDefaultNotification, setDocuSignDefaultNotification] =
    useState([]);

  const [fileDefaultNotification, setFileDefaultNotification] = useState([]);

  const [showMessage, setShowMessage] = useState(true);
  const [showChat, setShowChat] = useState(true);
  // const [showCalendar, setShowCalendar] = useState(true);
  const [showDocuSign, setShowDocuSign] = useState(true);
  const [showFile, setShowFile] = useState(true);
  const [display, setDisplay] = useState(false);

  const [articleNotification, setArticleNotification] = useState([]);
  const [showArticle, setShowArticle] = useState(true);
  const [articleDefaultNotification, setArticleDefaultNotification] = useState([]);

  const currentUserTesterStatus = useAppSelectorTS(state => state.users.testerStatus);

  useEffect(() => {
    getNotificationPreference();
  }, []);

  function getNotificationPreference() {
    setLoader(true);

    dispatch(
      clientUser.getNotificationPreferencesByTeamId(selectedTeam.team_id)
    ).then((response) => {
      setDisplay(true);
      setLoader(false);
      setUpdateDefaultSettingShow(false);
      if (response.type === "GET_NOTIFICATION_SUCCESS") {
        let value = JSON.parse(JSON.stringify(response?.data));

        //data?.map((c) => (c.value = c.default_value));

        //Create Calendar Object
        // let calendar = value?.filter((c) => c?.preference_type === "calendar");
        // setCalendarNotification(calendar);

        //Create Message Object
        let message = value?.filter((c) => c?.preference_type === "message");
        setMessageNotification(message);

        // Create Chat Object
        let chat = value?.filter((c) => c?.preference_type === "chat");
        setChatNotification(chat);

        //Create docusign Object
        let docuSign = value?.filter((c) => c?.preference_type === "docusign");
        setDocuSignNotification(docuSign);

        //Create docusign Object
        let file = value?.filter((c) => c?.preference_type === "files");
        setFileNotification(file);

         //Create article Object
         let article = value?.filter((c) => c?.preference_type === "articles");
         setArticleNotification(article);

        //This is setting
        setNotificationValue(value);
      }
    });
  }

  // function setUserPreference(id, value) {
  //   let param = {
  //     preferences: [
  //       {
  //         preference_id: id,
  //         value: value,
  //       },
  //     ],
  //     user: userInfo?.uuid,
  //   };
  //   dispatch(clientUser.setUserPreference(param)).then((response) => {
  //     if (response.type === "SET_NOTIFICATION_SUCCESS") {
  //       calendarNotification
  //       // 21 is for Enable Meeting Request for the Client
  //       ?.filter((x) => x.id === 21)
  //       .map((x) => (x.value = response.preferences.value));
  //       //getNotificationPreference();
  //     }
  //   });
  // }

  function setNotificationValue(value) {
    let data = JSON.parse(JSON.stringify(value));

    //Setting the default value to value
    data?.map((c) => (c.value = c.default_value));

    //Create Calendar Object
    // let calendar = data?.filter((c) => c?.preference_type === "calendar");
    // setCalendarDefaultNotification(calendar);

    //Create Message Object
    let message = data?.filter((c) => c?.preference_type === "message");
    setMessageDefaultNotification(message);

    // Create Chat Object
    let chat = data?.filter((c) => c?.preference_type === "chat");
    setChatDefaultNotification(chat);

    //Create docusign Object
    let docuSign = data?.filter((c) => c?.preference_type === "docusign");
    setDocuSignDefaultNotification(docuSign);

    //Create docusign Object
    let file = data?.filter((c) => c?.preference_type === "files");
    setFileDefaultNotification(file);

    //Create article Object
    let article = data?.filter((c) => c?.preference_type === "articles");
    setArticleDefaultNotification(article);
  }

  function setUserPreferenceReset(preferenceType) {
    let value = [];
    if (preferenceType === "msg") {
      messageDefaultNotification?.map((c) => {
        value.push({
          preference_id: c?.id,
          value: c?.default_value,
        });
      });
    }
    else if (preferenceType === "chat") {
      chatDefaultNotification?.map((c) => {
        value.push({
          preference_id: c?.id,
          value: c?.default_value,
        });
      });
    }
    // else if (preferenceType === "calendar") {
    //   calendarDefaultNotification?.map((c) => {
    //     value.push({
    //       preference_id: c?.id,
    //       value: c?.default_value,
    //     });
    //   });
    // } 
    else if (preferenceType === "docu") {
      docuSignDefaultNotification?.map((c) => {
        value.push({
          preference_id: c?.id,
          value: c?.default_value,
        });
      });
    } else if (preferenceType === "file") {
      fileDefaultNotification?.map((c) => {
        value.push({
          preference_id: c?.id,
          value: c?.default_value,
        });
      });
    }else if (preferenceType === "article") {
      articleDefaultNotification?.map((c) => {
        value.push({
          preference_id: c?.id,
          value: c?.default_value,
        });
      });
    }
    let param = {
      preferences: value,
      user: userDetails?.uuid,
    };
    dispatch(clientUser.setUserPreference(param)).then((response) => {
      if (response.type === "SET_NOTIFICATION_SUCCESS") {
        //getNotificationPreference();
      }
    });
  }
  // function onCheckChange(name) {
  //   let temp_calendarNotification = JSON.parse(
  //     JSON.stringify(calendarNotification?.find((x) => x.id === 9)?.value)
  //   );
  //   let temp_calendar = JSON.parse(
  //     JSON.stringify(calendarNotification?.find((x) => x.id === 9))
  //   );
    

  //   if (!temp_calendarNotification.includes(name)) {
  //     temp_calendarNotification.push(name);
  //   } else {
  //     if(temp_calendarNotification.length>1)
  //     {
  //     var index = temp_calendarNotification.indexOf(name);
  //     if (index >= 0) {
  //       temp_calendarNotification.splice(index, 1);
  //     }
  //   }
  //   }
  //   setSelectedPreference({
  //     Title: "Calendar",
  //     Heading: "Default meeting attendees",
  //     Setting: "Default meeting attendees",
  //     Type: "Check",
  //     Preference_id: 9,
  //     CustomCount : temp_calendar?.custom_user_count,
  //     DefaultCount : temp_calendar?.default_user_count,
  //     Value: temp_calendarNotification,
  //     TeamId: selectedTeam.team_id,
  //     subSetting:
  //       "Pre-select for meeting requests. The client will still be able to choose from all options.",
  //   });
  //   setUpdateDefaultSettingShow(true);
  // }
  return (
    <>
      {loader && <Loading active={loader} />}
      <div className="card">
        {display && (
          <>
            {" "}
            <div className="card-header border-0">
              <h4 className="mb-8">
                {userInfo?.admin_type === "firm_admin"
                  ? selectedTeam.team_name
                  : "Team Default Settings"}
              </h4>
              <p className="m-0">
                {userInfo?.admin_type === "firm_admin"
                  ? "Default user settings for clients within the " +
                    selectedTeam.team_name
                  : "Default user settings for clients of my team."}
              </p>
              <span className="d-block text-muted fs-14 lh-20 text-7E8C9A mb-8">
                {userInfo?.admin_type !== "firm_admin"
                  ? `Last Update: ${moment(
                      selectedTeam?.last_modified_at
                    ).format("ll")} by ${
                      selectedTeam?.last_modified_by?.first_name
                    } ${selectedTeam?.last_modified_by?.last_name}`
                  : ""}
              </span>
              {userInfo?.admin_type === "firm_admin" && (
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a
                      className="overflow-text-ant"
                      onClick={(e) => {
                        e.preventDefault();
                        setselectedTeam();
                      }}
                    >
                      Client User Settings
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="overflow-text-ant">
                      {selectedTeam.team_name}
                    </span>
                  </li>
                </ol>
              )}
            </div>
            <div className="user-permission pb-24">
            <div className="profnotif-block profnotif-open">
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="mb-4 mb-md-0">
                      <h4 className="fs-18 lh-24 mb-md-4 mb-0">Articles</h4>
                      <p className="m-0 d-none d-md-block">
                        Article Notifications
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      {/* <span className="notif-status fs-18 lh-32 me-12">On</span> */}
                      <span
                        className="notif-status fs-18 lh-32 me-12 "
                        onClick={(e) => {
                          setArticleNotification(
                            JSON.parse(JSON.stringify(articleDefaultNotification))
                          );
                          setUserPreferenceReset("article");
                        }}
                      >
                        {showArticle
                          ? ""
                          : articleNotification?.find((x) => x.id === 22)?.value
                          ? "On"
                          : "Off"}
                      </span>
                      <span
                        className="cursor-pointer icon-32"
                        onClick={(e) => {
                          setShowArticle(!showArticle);
                        }}
                      >
                        <em className="icon icon-chevron-down-outline fs-16"></em>
                      </span>
                    </div>
                  </div>
                </div>
                {showArticle && (
                  <div className="pt-24">
                    <div className="notif-type d-flex align-items-center justify-content-between">
                      <h6 className="m-0 pe-8">
                        Allow push notifications for Articles
                      </h6>
                      <Switch
                        checked={
                          articleNotification?.find((x) => x.id === 22)?.value
                        }
                        onChange={(e) => {
                          setSelectedPreference({
                            Title: "Articles",
                            Heading: "",
                            Setting:
                              "Allow push notifications for Articles",
                            Type: "Switch",
                            Preference_id: 22,
                            CustomCount : articleNotification?.find((x) => x.id === 22)?.custom_user_count,
                            DefaultCount : articleNotification?.find((x) => x.id === 22)?.default_user_count,
                            Value: e,
                            TeamId: selectedTeam.team_id,
                            isBold: true,
                          });
                          setUpdateDefaultSettingShow(true);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {currentUserTesterStatus.twilio_conversations_chat && (
                <div className="profnotif-block profnotif-open">
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mb-4 mb-md-0">
                        <h4 className="fs-18 lh-24 mb-md-4 mb-0">Conversations (Beta)</h4>
                        <p className="m-0 d-none d-md-block">
                          Notifications.
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        {/* <span className="notif-status fs-18 lh-32 me-12">On</span> */}
                        <span
                          className="notif-status fs-18 lh-32 me-12 "
                          onClick={(e) => {
                            setChatNotification(
                              JSON.parse(
                                JSON.stringify(chatDefaultNotification)
                              )
                            );
                            setUserPreferenceReset("chat");
                          }}
                        >
                          {showChat
                            ? ""
                            : chatNotification?.find((x) => x.id === 27)?.value
                            ? "On"
                            : "Off"}
                        </span>
                        <span
                          className="cursor-pointer icon-32"
                          onClick={(e) => {
                            setShowChat(!showChat);
                          }}
                        >
                          <em className="icon icon-chevron-down-outline fs-16"></em>
                        </span>
                      </div>
                    </div>
                    <p className="m-0 d-md-none">Notifications.</p>
                  </div>
                  {showChat && (
                    <div className="pt-24">
                      <div className="notif-type d-flex align-items-center justify-content-between">
                        <h6 className="m-0 pe-8">
                          Enable Conversations (Beta) feature for this team
                        </h6>
                        <Switch
                          checked={
                            chatNotification?.find((x) => x.id === 27)?.value
                          }
                          onChange={(e) => {
                            setSelectedPreference({
                              Title: "Chat",
                              Heading: "",
                              Setting: "Enable Messages feature for this user",
                              Type: "Switch",
                              Preference_id: 27,
                              CustomCount : chatNotification?.find((x) => x.id === 27)?.custom_user_count,
                              DefaultCount : chatNotification?.find((x) => x.id === 27)?.default_user_count,
                              Value: e,
                              TeamId: selectedTeam.team_id,
                              isBold: true,
                            });
                            setUpdateDefaultSettingShow(true);
                          }}
                        />
                      </div>
                      {chatNotification?.find((x) => x.id === 27)?.value && (
                        <>
                          {/* <div className="notif-type d-flex justify-content-between">
                            <div className="mb-4 mb-md-0">
                              <h6 className="m-0 pe-8 fw-normal">
                                Send Read Receipts
                              </h6>
                              <p className="text-muted mb-20 fs-14 lh-20">
                              When this is on, people are notified when you have read their messages. 
                              This enables read receipts for all conversations.
                              </p>
                            </div>
                            <Switch
                              checked={
                                chatNotification?.find((x) => x.id === 24)
                                  ?.value
                              }
                              onChange={(e) => {
                                setSelectedPreference({
                                  Title: "Chat",
                                  Heading: "",
                                  Setting: "Send Read Receipts",
                                  Type: "Switch",
                                  CustomCount : chatNotification?.find((x) => x.id === 24)?.custom_user_count,
                                  DefaultCount : chatNotification?.find((x) => x.id === 24)?.default_user_count,
                                  Preference_id: 24,
                                  Value: e,
                                  TeamId: selectedTeam.team_id,
                                });
                                setUpdateDefaultSettingShow(true);
                              }}
                            />
                          </div> */}
                          <div className="notif-type">
                          <div className="typesof-not d-flex align-items-center justify-content-between">
                            <h6 className="mb-8">
                              Allow push notifications for messages
                            </h6>
                            <Switch
                                  checked={
                                    chatNotification?.find((x) => x.id === 25)
                                      ?.value
                                  }
                                  onChange={(e) => {
                                    setSelectedPreference({
                                      Title: "Chat",
                                      Heading: "Allow push notifications for messages",
                                      Setting: "Push",
                                      Type: "Switch",
                                      Preference_id: 25,
                                      CustomCount : chatNotification?.find((x) => x.id === 25)?.custom_user_count,
                                      DefaultCount : chatNotification?.find((x) => x.id === 25)?.default_user_count,
                                      Value: e,
                                      TeamId: selectedTeam.team_id,
                                    });
                                    setUpdateDefaultSettingShow(true);
                                  }}
                                />
                                </div>
                            <div>
                              <div className="typesof-not d-flex align-items-center justify-content-between">
                              </div>
                              <div className="typesof-not ">
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="lh-20">
                                    Enable email notifications for missed conversation messages
                                  </span>
                                  <Switch
                                    checked={
                                      chatNotification?.find((x) => x.id === 26)
                                        ?.value !== "none"
                                    }
                                    onChange={(e) => {
                                      let value = "Once every hour";
                                      if (
                                        chatNotification?.find(
                                          (x) => x.id === 26
                                        )?.value !== "none"
                                      ) {
                                        value = "none";
                                      }
                                      setSelectedPreference({
                                        Title: "Chat",
                                        Heading: "",
                                        Setting:
                                          "Enable email notifications for missed chat messages",
                                        Type: "Radio",
                                        Preference_id: 26,
                                        CustomCount : chatNotification?.find((x) => x.id === 26)?.custom_user_count,
                                        DefaultCount : chatNotification?.find((x) => x.id === 26)?.default_user_count,
                                        Value: value,
                                        TeamId: selectedTeam.team_id,
                                        items: [
                                          "Once every hour",
                                          "Once every 8 hours",
                                          "Once daily",
                                        ],
                                      });
                                      setUpdateDefaultSettingShow(true);
                                    }}
                                  />
                                </div>
                                <div className="pt-20">
                                  <Radio.Group
                                    className=""
                                    onChange={(e) => {
                                      setSelectedPreference({
                                        Title: "Chat",
                                        Heading: "",
                                        Setting:
                                          "Enable email notifications for missed chat messages",
                                        Type: "Radio",
                                        Preference_id: 26,
                                        CustomCount : chatNotification?.find((x) => x.id === 26)?.custom_user_count,
                                        DefaultCount : chatNotification?.find((x) => x.id === 26)?.default_user_count,
                                        Value: e?.target?.value,
                                        TeamId: selectedTeam.team_id,
                                        items: [
                                          "Once every hour",
                                          "Once every 8 hours",
                                          "Once daily",
                                        ],
                                      });
                                      setUpdateDefaultSettingShow(true);
                                    }}
                                    value={
                                      chatNotification?.find((x) => x.id === 26)
                                        ?.value
                                    }
                                  >
                                    <Radio value={"Once every hour"}>
                                      Once every hour
                                    </Radio>
                                    <Radio value={"Once every 8 hours"}>
                                      Once every 8 hours
                                    </Radio>
                                    <Radio value={"Once daily"}>Once daily</Radio>
                                  </Radio.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
              <div className="profnotif-block profnotif-open">
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="mb-4 mb-md-0">
                      <h4 className="fs-18 lh-24 mb-md-4 mb-0">Messages</h4>
                      <p className="m-0 d-none d-md-block">
                        Read receipts, notifications.
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      {/* <span className="notif-status fs-18 lh-32 me-12">On</span> */}
                      <span
                        className="notif-status fs-18 lh-32 me-12 "
                        onClick={(e) => {
                          setMessageNotification(
                            JSON.parse(
                              JSON.stringify(messageDefaultNotification)
                            )
                          );
                          setUserPreferenceReset("msg");
                        }}
                      >
                        {showMessage
                          ? ""
                          : messageNotification?.find((x) => x.id === 1)?.value
                          ? "On"
                          : "Off"}
                      </span>
                      <span
                        className="cursor-pointer icon-32"
                        onClick={(e) => {
                          setShowMessage(!showMessage);
                        }}
                      >
                        <em className="icon icon-chevron-down-outline fs-16"></em>
                      </span>
                    </div>
                  </div>
                  <p className="m-0 d-md-none">Read receipts, notifications.</p>
                </div>
                {showMessage && (
                  <div className="pt-24">
                    <div className="notif-type d-flex align-items-center justify-content-between">
                      <h6 className="m-0 pe-8">
                        Enable Messages feature for this user
                      </h6>
                      <Switch
                        checked={
                          messageNotification?.find((x) => x.id === 1)?.value
                        }
                        onChange={(e) => {
                          setSelectedPreference({
                            Title: "Messages",
                            Heading: "",
                            Setting: "Enable Messages feature for this user",
                            Type: "Switch",
                            Preference_id: 1,
                            CustomCount : messageNotification?.find((x) => x.id === 1)?.custom_user_count,
                            DefaultCount : messageNotification?.find((x) => x.id === 1)?.default_user_count,
                            Value: e,
                            TeamId: selectedTeam.team_id,
                            isBold: true,
                          });
                          setUpdateDefaultSettingShow(true);
                        }}
                      />
                    </div>
                    {messageNotification?.find((x) => x.id === 1)?.value && (
                      <>
                        <div className="notif-type d-flex justify-content-between">
                        <div className="mb-4 mb-md-0">
                          <h6 className="m-0 pe-8 fw-normal">
                            Send Read Receipts
                          </h6>
                          <p className="text-muted mb-20 fs-14 lh-20">
                          When this is on, people are notified when you have read their messages. 
                          This enables read receipts for all conversations.
                          </p>
                          </div>
                          <Switch
                            checked={
                              messageNotification?.find((x) => x.id === 5)
                                ?.value
                            }
                            onChange={(e) => {
                              setSelectedPreference({
                                Title: "Messages",
                                Heading: "",
                                Setting: "Send Read Receipts",
                                Type: "Switch",
                                CustomCount : messageNotification?.find((x) => x.id === 5)?.custom_user_count,
                                DefaultCount : messageNotification?.find((x) => x.id === 5)?.default_user_count,
                                Preference_id: 5,
                                Value: e,
                                TeamId: selectedTeam.team_id,
                              });
                              setUpdateDefaultSettingShow(true);
                            }}
                          />
                        </div>
                        <div className="notif-type">
                        <div className="typesof-not d-flex align-items-center justify-content-between">
                          <h6 className="mb-8">
                            Allow push notifications for messages
                          </h6>
                          <Switch
                                checked={
                                  messageNotification?.find((x) => x.id === 6)
                                    ?.value
                                }
                                onChange={(e) => {
                                  setSelectedPreference({
                                    Title: "Messages",
                                    Heading: "Allow push notifications for messages",
                                    Setting: "Push",
                                    Type: "Switch",
                                    Preference_id: 6,
                                    CustomCount : messageNotification?.find((x) => x.id === 6)?.custom_user_count,
                                    DefaultCount : messageNotification?.find((x) => x.id === 6)?.default_user_count,
                                    Value: e,
                                    TeamId: selectedTeam.team_id,
                                  });
                                  setUpdateDefaultSettingShow(true);
                                }}
                              />
                              </div>
                          <div>
                            <div className="typesof-not d-flex align-items-center justify-content-between">
                            </div>
                            <div className="typesof-not ">
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="lh-20">
                                  Enable email notifications for missed chat
                                  messages
                                </span>
                                <Switch
                                  checked={
                                    messageNotification?.find((x) => x.id === 7)
                                      ?.value !== "none"
                                  }
                                  onChange={(e) => {
                                    let value = "Once every hour";
                                    if (
                                      messageNotification?.find(
                                        (x) => x.id === 7
                                      )?.value !== "none"
                                    ) {
                                      value = "none";
                                    }
                                    setSelectedPreference({
                                      Title: "Messages",
                                      Heading: "",
                                      Setting:
                                        "Enable email notifications for missed chat messages",
                                      Type: "Radio",
                                      Preference_id: 7,
                                      CustomCount : messageNotification?.find((x) => x.id === 7)?.custom_user_count,
                                      DefaultCount : messageNotification?.find((x) => x.id === 7)?.default_user_count,
                                      Value: value,
                                      TeamId: selectedTeam.team_id,
                                      items: [
                                        "Once every hour",
                                        "Once every 8 hours",
                                        "Once daily",
                                      ],
                                    });
                                    setUpdateDefaultSettingShow(true);
                                  }}
                                />
                              </div>
                              <div className="pt-20">
                                <Radio.Group
                                  className=""
                                  onChange={(e) => {
                                    setSelectedPreference({
                                      Title: "Messages",
                                      Heading: "",
                                      Setting:
                                        "Enable email notifications for missed chat messages",
                                      Type: "Radio",
                                      Preference_id: 7,
                                      CustomCount : messageNotification?.find((x) => x.id === 7)?.custom_user_count,
                                      DefaultCount : messageNotification?.find((x) => x.id === 7)?.default_user_count,
                                      Value: e?.target?.value,
                                      TeamId: selectedTeam.team_id,
                                      items: [
                                        "Once every hour",
                                        "Once every 8 hours",
                                        "Once daily",
                                      ],
                                    });
                                    setUpdateDefaultSettingShow(true);
                                  }}
                                  value={
                                    messageNotification?.find((x) => x.id === 7)
                                      ?.value
                                  }
                                >
                                  <Radio value={"Once every hour"}>
                                    Once every hour
                                  </Radio>
                                  <Radio value={"Once every 8 hours"}>
                                    Once every 8 hours
                                  </Radio>
                                  <Radio value={"Once daily"}>Once daily</Radio>
                                </Radio.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              {/* <div className="profnotif-block profnotif-open">
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="mb-4 mb-md-0">
                      <h4 className="fs-18 lh-24 mb-md-4 mb-0">Calendar</h4>
                      <p className="m-0 d-none d-md-block">
                        Meeting duration, attendees, notifications.
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <span
                        className="notif-status fs-18 lh-32 me-12 "
                        onClick={(e) => {
                          setCalendarNotification(
                            JSON.parse(
                              JSON.stringify(calendarDefaultNotification)
                            )
                          );
                          setUserPreferenceReset("calendar");
                        }}
                      >
                        {showCalendar
                          ? ""
                          : calendarNotification?.find((x) => x.id === 2)?.value
                          ? "On"
                          : "Off"}
                      </span>
                      <span
                        className="cursor-pointer icon-32"
                        onClick={(e) => {
                          setShowCalendar(!showCalendar);
                        }}
                      >
                        <em className="icon icon-chevron-down-outline fs-16"></em>
                      </span>
                    </div>
                  </div>
                  <p className="m-0 d-md-none">
                    Meeting duration, attendees, notifications.
                  </p>
                </div>
                {showCalendar && (
                  <div className="pt-24">
                    <div className="notif-type">
                      <div className="d-flex align-items-center justify-content-between mb-20">
                        <h6 className="m-0 pe-8">
                          Enable Calendar feature for this account
                        </h6>
                        <Switch
                          checked={
                            calendarNotification?.find((x) => x.id === 2)?.value
                          }
                          onChange={(e) => {
                            setSelectedPreference({
                              Title: "Calendar",
                              Heading: "",
                              Setting:
                                "Enable Calendar feature for this account",
                              Type: "Switch",
                              Preference_id: 2,
                              CustomCount : calendarNotification?.find((x) => x.id === 2)?.custom_user_count,
                              DefaultCount : calendarNotification?.find((x) => x.id === 2)?.default_user_count,
                              Value: e,
                              TeamId: selectedTeam.team_id,
                              isBold: true,
                            });
                            if(!e){
                              //  21 is for Enable Meeting Request for the Client
                              calendarNotification
                              ?.filter((x) => x.id === 21)
                              .map((x) => (x.value = "false"));
                              setUserPreference(21, e?"true":"false");
                            }
                            setUpdateDefaultSettingShow(true);
                          }}
                        />
                      </div>
                      {( 
                        // 2 is for Enable Calendar feature for this account
                        (calendarNotification?.find((x) => x.id === 2)?.value)) &&(
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                        <h6 className="m-0 pe-8">
                            Request Meeting Option
                        </h6>
                        <p className="text-muted mb-20 fs-14 lh-20">
                          Select whether the client has the option to request a new meeting.
                        </p>
                        </div>
                        <Switch
                          checked={
                            calendarNotification?.find((x) => x.id === 2)?.value?calendarNotification?.find((x) => x.id === 21)?.value==="true"?true:false:false
                          }
                          onChange={(e) => {
                            setSelectedPreference({
                              Title: "Calendar",
                              Heading: "",
                              Setting:
                                "Enable Meeting request for the client",
                              Type: "Switch",
                              Preference_id: 21,
                              CustomCount : calendarNotification?.find((x) => x.id === 21)?.custom_user_count,
                              DefaultCount : calendarNotification?.find((x) => x.id === 21)?.default_user_count,
                              Value: e,
                              TeamId: selectedTeam.team_id,
                              isBold: true,
                            });
                            setUpdateDefaultSettingShow(true);
                          }}
                        />
                      </div>
                      )}
                      {( 
                        calendarNotification?.find((x) => x.id === 21)?.value === "true" &&
                        calendarNotification?.find((x) => x.id === 2)?.value) &&(
                        <div className="pt-24">
                          <h6 className="fw-normal m-0 text-1E3443">
                            Default meeting duration
                          </h6>
                          <p className="text-muted mb-20 fs-14 lh-20">
                            Pre-select for meeting requests. The client will
                            still be able to choose from all options.
                          </p>
                          <div>
                            <Radio.Group
                              className=""
                              onChange={(e) => {
                                setSelectedPreference({
                                  Title: "Calendar",
                                  Heading: "",
                                  Setting: "Default meeting duration",
                                  Type: "Radio",
                                  Preference_id: 8,
                                  CustomCount : calendarNotification?.find((x) => x.id === 8)?.custom_user_count,
                                  DefaultCount : calendarNotification?.find((x) => x.id === 8)?.default_user_count,
                                  Value: e?.target?.value,
                                  TeamId: selectedTeam.team_id,
                                  items: [
                                    "30 minutes",
                                    "60 minutes",
                                    "90 minutes",
                                  ],
                                  subSetting:
                                    "Pre-select for meeting requests. The client will still be able to choose from all options.",
                                });
                                setUpdateDefaultSettingShow(true);
                              }}
                              value={
                                calendarNotification?.find((x) => x.id === 8)
                                  ?.value
                              }
                            >
                              <Radio value={"30 minutes"}>30 minutes</Radio>
                              <Radio value={"60 minutes"}>60 minutes</Radio>
                              <Radio value={"90 minutes"}>90 minutes</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      )}
                    </div>
                    {(calendarNotification?.find((x) => x.id === 21)?.value === "true" &&
                    calendarNotification?.find((x) => x.id === 2)?.value) &&(
                      <>
                      <hr className="mt-24 mb-0"></hr>
                        <div className="pt-24">
                          <h6 className="fw-normal m-0 text-1E3443">
                            Default meeting attendees
                          </h6>
                          <p className="text-muted mb-12 fs-14 lh-20">
                            Pre-select for meeting requests. The client will
                            still be able to choose from all options.
                          </p>
                          <a
                            href="#"
                            className="mb-4 fs-14 d-inline-block"
                            onClick={(e) => {
                              setSelectedPreference({
                                Title: "Calendar",
                                Heading:
                                  "Pre-select for meeting requests. The client will still be able to choose from all options.",
                                Setting: "Default meeting attendees",
                                Type: "Check",
                                Preference_id: 9,
                                CustomCount : calendarNotification?.find((x) => x.id === 9)?.custom_user_count,
                                DefaultCount : calendarNotification?.find((x) => x.id === 9)?.default_user_count,
                                Value: [
                                  "Relationship Lead",
                                  "Partner",
                                  "Advisor",
                                  "Secondary Advisor",
                                  "CSM",
                                  "Secondary CSM",
                                ],
                                TeamId: selectedTeam.team_id,
                                subSetting:
                                  "Pre-select for meeting requests. The client will still be able to choose from all options.",
                              });
                              setUpdateDefaultSettingShow(true);
                            }}
                          >
                            {calendarNotification?.find((x) => x.id === 9)
                              ?.value.length === 6
                              ? ""
                              : "Select All"}
                          </a>
                          <div className="row">
                            <div className="col-md-8">
                              <div className="py-8">
                                <div className="row align-items-center">
                                  <div className="col-md-6">
                                    <div className="form-check mb-0">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="meeting-attend"
                                        checked={calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("Relationship Lead")}
                                        onChange={(e) => {
                                          onCheckChange("Relationship Lead");
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="meeting-attend"
                                      >
                                        Relationship Lead
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="py-8">
                                <div className="row align-items-center">
                                  <div className="col-md-6">
                                    <div className="form-check mb-0">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="meeting-attend"
                                        checked={calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("Partner")}
                                        onChange={(e) => {
                                          onCheckChange("Partner");
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="meeting-attend"
                                      >
                                        Partner
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="py-8">
                                <div className="row align-items-center">
                                  <div className="col-md-6">
                                    <div className="form-check mb-0">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="meeting-attend"
                                        checked={calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("Advisor")}
                                        onChange={(e) => {
                                          onCheckChange("Advisor");
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="meeting-attend"
                                      >
                                        Advisor
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="py-8">
                                <div className="row align-items-center">
                                  <div className="col-md-6">
                                    <div className="form-check mb-0">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="meeting-attend"
                                        checked={calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("Secondary Advisor")}
                                        onChange={(e) => {
                                          onCheckChange("Secondary Advisor");
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="meeting-attend"
                                      >
                                        Secondary Advisor
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="py-8">
                                <div className="row align-items-center">
                                  <div className="col-md-6">
                                    <div className="form-check mb-0">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="meeting-attend"
                                        checked={calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("CSM")}
                                        onChange={(e) => {
                                          onCheckChange("CSM");
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="meeting-attend"
                                      >
                                        CSM
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="py-8">
                                <div className="row align-items-center">
                                  <div className="col-md-6">
                                    <div className="form-check mb-0">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="meeting-attend"
                                        checked={calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("Secondary CSM")}
                                        onChange={(e) => {
                                          onCheckChange("Secondary CSM");
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="meeting-attend"
                                      >
                                        Secondary CSM
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {calendarNotification?.find((x) => x.id === 19)
                              ?.value && (
                              <div className="col-md-12">
                              <hr className="mt-24 mb-0"></hr>
                              <div className="pt-24">
                                <h6 className="fw-normal m-0 text-1E3443">
                                  Scheduling Conditions
                                </h6>
                                <p className="text-muted mb-20 fs-14 lh-20">
                                  Set the minimum number of days before a client can request a new meeting.
                                </p>
                                <h6 className="m-0 text-1E3443 lh-24">
                                  Number of Business Days
                                </h6>
                                <div className="row">
                                  <div className="col-xl-2 col-md-3">
                                    <Select
                                      className="d-none d-md-block"
                                      // defaultValue={selectedStartDate}
                                      value={
                                        calendarNotification?.find(
                                          (x) => x.id === 19
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        console.log(
                                          calendarNotification?.find(
                                            (x) => x.id === 19
                                          ).attribute.options
                                        );
                                        setSelectedPreference({
                                          Title: "Calendar",
                                          Heading: "",
                                          Setting: "Number of Days",
                                          Type: "Drop",
                                          Preference_id: 19,
                                          CustomCount : calendarNotification?.find((x) => x.id === 19)?.custom_user_count,
                                          DefaultCount : calendarNotification?.find((x) => x.id === 19)?.default_user_count,
                                          Value: e,
                                          TeamId: selectedTeam.team_id,
                                          items: calendarNotification?.find(
                                            (x) => x.id === 19
                                          ).attribute.options,
                                        });
                                        setUpdateDefaultSettingShow(true);
                                      }}
                                    >
                                      {calendarNotification
                                        ?.find((x) => x.id === 19)
                                        .attribute.options?.map(
                                          (value, index) => (
                                            <Option key={index} value={value}>
                                              {value}
                                            </Option>
                                          )
                                        )}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                              <hr class="mt-24 mb-0"></hr>
                              </div>
                              
                            )}
                            {calendarNotification?.find((x) => x.id === 20)
                              ?.value && (
                              <div className="col-md-12">
                              <div className="pt-24">
                                <p className="text-muted mb-20 fs-14 lh-20">
                                  Set the buffer time to be blocked before and
                                  after existing meetings.
                                </p>
                                <div className="mb-24">
                                  <Radio.Group
                                    className=""
                                    onChange={(e) => {
                                      setSelectedPreference({
                                        Title: "Calendar",
                                        Heading: "",
                                        Setting:
                                          "Set the buffer time to be blocked before and after existing meetings.",
                                        Type: "Radio",
                                        Preference_id: 20,
                                        CustomCount : calendarNotification?.find((x) => x.id === 20)?.custom_user_count,
                                        DefaultCount : calendarNotification?.find((x) => x.id === 20)?.default_user_count,
                                        Value: e?.target?.value,
                                        TeamId: selectedTeam.team_id,
                                        items: [
                                          "No buffer time",
                                          "30 minutes",
                                        ],
                                      });
                                      setUpdateDefaultSettingShow(true);
                                    }}
                                    value={
                                      calendarNotification?.find(
                                        (x) => x.id === 20
                                      )?.value
                                    }
                                  >
                                    <Radio value={"No buffer time"}>
                                      No buffer time
                                    </Radio>
                                    <Radio value={"30 minutes"}>
                                      30 minutes
                                    </Radio>
                                  </Radio.Group>
                                </div>
                              </div>
                              </div>
                            )}
                          </div>
                          <hr className="mt-0 mb-24"></hr>
                        </div>
                        </>
                        )}
                        {(calendarNotification?.find((x) => x.id === 2)?.value) &&(
                        <div className="notif-type">
                          <h6 className="mb-16">Allow push notifications for the Calendar feature</h6>
                          <div>
                            <div className="typesof-not d-flex align-items-center justify-content-between">
                              <span className="lh-20">Meeting invites</span>
                              <Switch
                                checked={
                                  calendarNotification?.find((x) => x.id === 10)
                                    ?.value
                                }
                                onChange={(e) => {
                                  setSelectedPreference({
                                    Title: "Calendar",
                                    Heading: "Push",
                                    Setting: "Meeting invites",
                                    Type: "Switch",
                                    Preference_id: 10,
                                    CustomCount : calendarNotification?.find((x) => x.id === 10)?.custom_user_count,
                                    DefaultCount : calendarNotification?.find((x) => x.id === 10)?.default_user_count,
                                    Value: e,
                                    TeamId: selectedTeam.team_id,
                                    isBold: false,
                                  });
                                  setUpdateDefaultSettingShow(true);
                                }}
                              />
                            </div>
                            <div className="typesof-not d-flex align-items-center justify-content-between">
                              <span className="lh-20">Attendee responses</span>
                              <Switch
                                checked={
                                  calendarNotification?.find((x) => x.id === 11)
                                    ?.value
                                }
                                onChange={(e) => {
                                  setSelectedPreference({
                                    Title: "Calendar",
                                    Heading: "Push",
                                    Setting: "Attendee responses",
                                    Type: "Switch",
                                    CustomCount : calendarNotification?.find((x) => x.id === 11)?.custom_user_count,
                                    DefaultCount : calendarNotification?.find((x) => x.id === 11)?.default_user_count,
                                    Preference_id: 11,
                                    Value: e,
                                    TeamId: selectedTeam.team_id,
                                    isBold: false,
                                  });
                                  setUpdateDefaultSettingShow(true);
                                }}
                              />
                            </div>
                            
                          </div>
                          
                          <div>

                          </div>
                        </div>
                        )}     
                  </div>
                )}
              </div> */}
              <div className="profnotif-block profnotif-open">
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="mb-4 mb-md-0">
                      <h4 className="fs-18 lh-24 mb-md-4 mb-0">DocuSign</h4>
                      <p className="m-0 d-none d-md-block">
                        Signature requests, signature responses.
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      {/* <span className="notif-status fs-18 lh-32 me-12">On</span> */}
                      <span
                        className="notif-status fs-18 lh-32 me-12 "
                        onClick={(e) => {
                          setDocuSignNotification(
                            JSON.parse(
                              JSON.stringify(docuSignDefaultNotification)
                            )
                          );
                          setUserPreferenceReset("docu");
                        }}
                      >
                        {showDocuSign
                          ? ""
                          : docuSignNotification?.find((x) => x.id === 3)?.value
                          ? "On"
                          : "Off"}
                      </span>
                      <span
                        className="cursor-pointer icon-32"
                        onClick={(e) => {
                          setShowDocuSign(!showDocuSign);
                        }}
                      >
                        <em className="icon icon-chevron-down-outline fs-16"></em>
                      </span>
                    </div>
                  </div>
                  <p className="m-0 d-md-none">
                    Signature requests, signature responses, signature
                    reminders.
                  </p>
                </div>
                {showDocuSign && (
                  <div className="pt-24">
                    <div className="notif-type d-flex align-items-center justify-content-between">
                      <h6 className="m-0 pe-8">
                        Enable DocuSign feature for this account
                      </h6>
                      <Switch
                        checked={
                          docuSignNotification?.find((x) => x.id === 3)?.value
                        }
                        onChange={(e) => {
                          setSelectedPreference({
                            Title: "DocuSign",
                            Heading: "",
                            Setting:
                              "Enable DocuSign feature for this account",
                            Type: "Switch",
                            CustomCount : docuSignNotification?.find((x) => x.id === 3)?.custom_user_count,
                            DefaultCount : docuSignNotification?.find((x) => x.id === 3)?.default_user_count,
                            Preference_id: 3,
                            Value: e,
                            TeamId: selectedTeam.team_id,
                            isBold: true,
                          });
                          setUpdateDefaultSettingShow(true);
                        }}
                      />
                    </div>
                    {docuSignNotification?.find((x) => x.id === 3)?.value && (
                      <>
                        <div className="notif-type">
                          <h6 className="mb-8">
                            Allow push notifications for DocuSign
                          </h6>
                        </div>
                        <div className="pt-8">
                          <div>
                            <div className="typesof-not d-flex align-items-center justify-content-between">
                              <span className="lh-20">Signature requests</span>
                              <Switch
                                checked={
                                  docuSignNotification?.find((x) => x.id === 16)
                                    ?.value
                                }
                                onChange={(e) => {
                                  setSelectedPreference({
                                    Title: "DocuSign",
                                    Heading: "Receive notifications for",
                                    Setting: "Signature requests",
                                    Type: "Switch",
                                    CustomCount : docuSignNotification?.find((x) => x.id === 16)?.custom_user_count,
                                    DefaultCount : docuSignNotification?.find((x) => x.id === 16)?.default_user_count,
                                    Preference_id: 16,
                                    Value: e,
                                    TeamId: selectedTeam.team_id,
                                    isBold: false,
                                  });
                                  setUpdateDefaultSettingShow(true);
                                }}
                              />
                            </div>
                            <div className="typesof-not d-flex align-items-center justify-content-between">
                              <span className="lh-20">Signature responses</span>
                              <Switch
                                checked={
                                  docuSignNotification?.find((x) => x.id === 17)
                                    ?.value
                                }
                                onChange={(e) => {
                                  setSelectedPreference({
                                    Title: "DocuSign",
                                    Heading: "Receive notifications for",
                                    Setting: "Signature responses",
                                    Type: "Switch",
                                    Preference_id: 17,
                                    CustomCount : docuSignNotification?.find((x) => x.id === 17)?.custom_user_count,
                                    DefaultCount : docuSignNotification?.find((x) => x.id === 17)?.default_user_count,
                                    Value: e,
                                    TeamId: selectedTeam.team_id,
                                    isBold: false,
                                  });
                                  setUpdateDefaultSettingShow(true);
                                }}
                              />
                            </div>

                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="profnotif-block profnotif-open">
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="mb-4 mb-md-0">
                      <h4 className="fs-18 lh-24 mb-md-4 mb-0">Files</h4>
                    </div>
                    <div className="d-flex align-items-center">
                      {/* <span className="notif-status fs-18 lh-32 me-12">On</span> */}
                      <span
                        className="notif-status fs-18 lh-32 me-12 "
                        onClick={(e) => {
                          setFileNotification(
                            JSON.parse(JSON.stringify(fileDefaultNotification))
                          );
                          setUserPreferenceReset("file");
                        }}
                      >
                        {showFile
                          ? ""
                          : fileNotification?.find((x) => x.id === 4)?.value
                          ? "On"
                          : "Off"}
                      </span>
                      <span
                        className="cursor-pointer icon-32"
                        onClick={(e) => {
                          setShowFile(!showFile);
                        }}
                      >
                        <em className="icon icon-chevron-down-outline fs-16"></em>
                      </span>
                    </div>
                  </div>
                </div>
                {showFile && (
                  <div className="pt-24">
                    <div className="notif-type d-flex align-items-center justify-content-between">
                      <h6 className="m-0 pe-8">
                        Enable ShareFile feature for this account
                      </h6>
                      <Switch
                        checked={
                          fileNotification?.find((x) => x.id === 4)?.value
                        }
                        onChange={(e) => {
                          setSelectedPreference({
                            Title: "Files",
                            Heading: "",
                            Setting:
                              "Enable ShareFile feature for this account",
                            Type: "Switch",
                            Preference_id: 4,
                            CustomCount : fileNotification?.find((x) => x.id === 4)?.custom_user_count,
                            DefaultCount : fileNotification?.find((x) => x.id === 4)?.default_user_count,
                            Value: e,
                            TeamId: selectedTeam.team_id,
                            isBold: true,
                          });
                          setUpdateDefaultSettingShow(true);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {showUpdateDefaultSetting && (
        <UpdateDefaultSettings
          selectedPreference={selectedPreference}
          closeModal={() => {
            setUpdateDefaultSettingShow(false);
          }}
          ApplySettings={() => {
            getNotificationPreference();
          }}
        ></UpdateDefaultSettings>
      )}
    </>
  );
};

export default ClientUserSetting;
