export const notificationConstants = {
  NOTIFICATION_LIST_SUCCESS: "NOTIFICATION_LIST_SUCCESS",
  NOTIFICATION_LIST_FAILURE: "NOTIFICATION_LIST_FAILURE",

  NOTIFICATION_SUCCESS: "NOTIFICATION_SUCCESS",
  NOTIFICATION_FAILURE: "NOTIFICATION_FAILURE",

  UNREAD_MESSAGE_COUNT_SUCCESS: "UNREAD_MESSAGE_COUNT_SUCCESS",
  UNREAD_MESSAGE_COUNT_FAILURE: "UNREAD_MESSAGE_COUNT_FAILURE",
};
