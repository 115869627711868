import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import { useSelector } from 'react-redux';
import './App.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import { useAppSelectorJS } from './redux/hooks';

const App = () => {


  const [user, setUser] = useState(false);
  const loggedIn = localStorage.getItem("user");
  const authentication = useAppSelectorJS(state => state.authentication);

  useEffect(() => {
    if (authentication?.data?.uuid?.length > 0) {
      setUser("true");
    } else if (loggedIn === "true") {
      setUser("true");
    } else if(localStorage.getItem("user") === "true"){
      setUser("true")
    }else {
      setUser("false")
    }

  }, [authentication,loggedIn]);

  return (
    <div className="">
      <BrowserRouter>
        <AppRoutes loggedIn={user} />
      </BrowserRouter>
    </div>
  )
}

export default App
