import { notificationConstants } from "../../constants/NotificationConstant";


const INITIAL_STATE = {
  data: {},
  error: {},
}

const NotificationCount = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case notificationConstants.NOTIFICATION_LIST_SUCCESS:
    return {...INITIAL_STATE , data: action.data };
  case notificationConstants.NOTIFICATION_FAILURE:
    return {...INITIAL_STATE , error: action.error };
  default:
    return state
  }
}

export default NotificationCount;