class config {
  /*
   * Return dynamic url based on host
   */
  getHost = () => {
    const host = window.location.host;
    const proto = window.location.protocol;
    // eslint-disable-next-line
    if (host.substring(host, 9) === "localhost") {
      if (process.env.REACT_APP_AUTH_DEVPORTAL) { 
        return `https://devportal.monetagroup.com/api`;  // Local FE utilizing dev live environment
      } else if (process.env.REACT_APP_AUTH_LOCAL_DEVPORTAL) {
        return `http://localhost:8000/api`;  // Local FE development
      } else {
        return `https://devportal.monetagroup.com/api`;  // Local FE utilizing dev live environment
      }
    } else {  
      return `https://${host}/api`;
    }
  };

  getShareFileURL = () => {
    //let subDomain = JSON.parse(localStorage.getItem("shareFile")) ? JSON.parse(localStorage.getItem("shareFile"))?.subdomain.replace(/"/g, "").trim() : "";
    return "https://moneta.sf-api.com/sf/v3";
  };

  getDocuSignURL = () => {
    // let subDomain = JSON.parse(localStorage.getItem("shareFile"))?.["sub-domain"].replace(/"/g, "").trim();
    return "https://demo.docusign.net/restapi/v2.1";
  };
}
export default new config();
