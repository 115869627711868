import React from 'react';

export const utility = {
  getUuid,
  getUserType,
  convertTime,
  tConvert,
  getProfileImage,
  ellipsify,
  getHighlightedText,
  handleAuthError,
  decodeEntity,
  getDescription
};

//Returns curent user UUid
function getUuid() {
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));
  return userDetails.uuid;
}


//Client type
function getUserType() {
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  return userDetails?.user_type;
}


//Convert Tome to 24 hour format
function convertTime(timeStr) {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
}

//Convert Tome to 12 hour format
function tConvert(time) {

  // Check correct time format and split into components
  time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time?.length > 1) { // If time format correct
    time = time.slice(1);  // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time[0] + time[1] + time[2] + " " + time[5]; // return adjusted time or original string
}

function getProfileImage(ImageUrl, FirstInitial, LastInitial) {
  return (ImageUrl !== "http://nothing/" && ImageUrl !== "" && ImageUrl !== null && ImageUrl !== undefined && ImageUrl !== "None") ? <img src={ImageUrl} alt="" /> : FirstInitial?.toUpperCase() + LastInitial?.toUpperCase()
}

function ellipsify(str, num) {
  if (str.length > num) {
    return str.substring(0, num) + "...";
  } else {
    return str;
  }
}

function getHighlightedText(text, highlight) {
  // Split on highlight term and include term into parts, ignore case
  if (highlight.trim() === "") {
    return text;
  }
  const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {" "}
      {parts?.map((part, i) => (
        <span
          key={i}
          className={
            part.toLowerCase() === highlight.toLowerCase() ? "mark" : ""
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
}

//Handle Error 401
function handleAuthError() {
  localStorage.setItem("user", false);
  window.location.assign("/");
}

function decodeEntity(inputStr) {
  var textarea = document.createElement("textarea");
  textarea.innerHTML = inputStr;
  return textarea.value;
}


function getDescription(text) {
  let htmlText = text.replace(/\\/g, '');
  return htmlText.replace(/(<([^>]+)>)/ig, '').trim();
}