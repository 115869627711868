import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { userConstants } from '../../redux/constants/user.constant';
import { auth } from '../../redux/actions/Auth/Auth';
import Loading from '../../components/Loader';
import { useAppDispatchJS, useAppSelectorJS } from '../../redux/hooks';
const ResetForgotPassword = () => {

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatchJS();
  const [resetPasswordToken, setResetPasswordToken] = useState('');
  const [onSubmit, setOnSubmit] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [eightCharacter, setEightCharacter] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [capitalChar, setCapitalChar] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [samePassword, setSamePassword] = useState(false);

  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [newConfirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const [validError, setValidError] = useState(false);
  const validErrorMsg = useAppSelectorJS(state => state.ForgortResetPassword);

  useEffect(() => {
    localStorage.setItem("user", false);
    let resetPasswordToken = JSON.parse(localStorage.getItem("reset-token"))?.reset_password_token;
    setResetPasswordToken(resetPasswordToken);
    setSamePassword((confirmNewPassword === newPassword && confirmNewPassword.length > 0) ? true : false)
  }, [confirmNewPassword, newPassword, validErrorMsg]);

  function handleChangeNewPassword(e) {
    let newpass = e.target.value;
    setNewPassword(newpass);
    //Eight Character Check
    setEightCharacter(newpass.length >= 8 ? true : false);
    console.log(eightCharacter)
    //One NUmber
    let oneNumberRegex = "(?=.*?[0-9])";
    setOneNumber(newpass.match(oneNumberRegex) ? true : false);
    //Capital letter
    let capitalCharRegex = "(?=.*?[A-Z])";
    setCapitalChar(newpass.match(capitalCharRegex) ? true : false);
    //Special Character
    let specialCharRegex = "(?=.*?[!@#$%])";
    setSpecialChar(newpass.match(specialCharRegex) ? true : false);
    //Same Password
    // setSamePassword(confirmNewPassword === newPassword ? true : false)
  }

  function handleChangeConfirmPassword(e) {
    let confirmPass = e.target.value;
    setConfirmNewPassword(confirmPass)
    setSamePassword(confirmPass === newPassword ? true : false)
  }

  function handleSubmit(e) {
    setLoader(true);
    e.preventDefault();
    setOnSubmit(true);
    const search =  new URLSearchParams(window.location.search);
    const tkn = new URLSearchParams(search).get('token');
    let param ;
    if(tkn?.length > 0){
      param = {
        token: tkn,
        new_password1: newPassword,
        new_password2: confirmNewPassword
      }
    }else{
      param = {
        token: resetPasswordToken,
        new_password1: newPassword,
        new_password2: confirmNewPassword
      }
    }

    if (!eightCharacter || !oneNumber || !capitalChar || !samePassword || !specialChar) {
      setLoader(false);
      return;
    }
    dispatch(auth.forgotResetPassword(param)).then((response) => {
      setLoader(false);

      if (response.type === "FORGOT_RESET_PASSWORD_SUCCESS") {
        //localStorage.removeItem("reset-token");
        let resetToken = JSON.parse(localStorage.getItem("reset-token"));
        if(resetToken !== null){
          resetToken["isPasswordReset"] = true;
          localStorage.setItem("reset-token" , JSON.stringify(resetToken));
          login();
        }else{
          navigate("/")
        }

      } else {
        setValidError(true);
      }
    });
  }

  function login() {
    setLoader(true);
    let devtoken = JSON.parse(
      localStorage.getItem("reset-token")
    )?.device_token;
    let email=localStorage.getItem("Email");
   

    let params = {
      email: email,
      password: newPassword,
      device_token: devtoken,
    };
    if (!newPassword) {
      setLoader(false);
      return;
    }
    dispatch(auth.login(params)).then((response) => {
      setLoader(false);
      if (response.type === "USERS_LOGIN_SUCCESS") {
        localStorage.removeItem("reset-token");
        localStorage.setItem("userDetails", JSON.stringify(response.data));
        localStorage.setItem("user", true);
        localStorage.setItem(
          "token",
          JSON.stringify(response.data.token).replace(/"/g, "").trim()
        );
        navigate("/dashboard");
      } else {
        setValidError(true);
      }
    });
  }

  return (
    <>
      { loader && <Loading active={loader}/> }
      <form name="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className='form-label'>New Password <sup>*</sup></label>
          <div className='password-field'>
            <input type={newPasswordShown ? "text" : "password"} className='form-control' name="newPassword" value={newPassword} onChange={(e) => handleChangeNewPassword(e)} />
            <em className={newPasswordShown ? 'icon-eye-reveal-filled' : 'icon-eye-hide-filled'} onClick={(e) => { newPasswordShown ? setNewPasswordShown(false) : setNewPasswordShown(true) }}></em>
          </div>
        </div>
        <div className="form-group">
          <label className='form-label'>Confirm New Password <sup>*</sup></label>
          <div className='password-field'>
            <input type={newConfirmPasswordShown ? "text" : "password"} className='form-control' name="newPassword" value={confirmNewPassword} onChange={(e) => handleChangeConfirmPassword(e)} />
            <em className={newConfirmPasswordShown ? 'icon-eye-reveal-filled' : 'icon-eye-hide-filled'} onClick={(e) => { newConfirmPasswordShown ? setConfirmPasswordShown(false) : setConfirmPasswordShown(true) }}></em>
          </div>
        </div>
        <div className='pass-require'>
          <span className='d-block mb-8 fw-medium'>Your password requirements:</span>
          <div>
            <p><em className={eightCharacter ? 'icon-check-circle-filled' : 'icon-x-circle-filled'}></em>At least 8 characters</p>
            <p><em className={oneNumber ? 'icon-check-circle-filled' : 'icon-x-circle-filled'}></em> At least 1 number</p>
            <p><em className={capitalChar ? 'icon-check-circle-filled' : 'icon-x-circle-filled'}></em> At least 1 capital letter</p>
            <p><em className={specialChar ? 'icon-check-circle-filled' : 'icon-x-circle-filled'}></em> At least 1 special character (!@#$%)</p>
            <p><em className={samePassword ? 'icon-check-circle-filled' : 'icon-x-circle-filled'}></em> Passwords must match</p>
          </div>
        </div>

        <div className='mb-16'>
          <button className="btn btn-success w-100">Reset Password</button>
        </div>
        { validError && 
                        <div className="invalid-feedback d-block">{validErrorMsg.error.token[0]}</div>
        }
        <div className='text-center mb-8'>
          <Link to="/" className="btn-link fs-18 lh-32">Back to Login</Link>
        </div>
      </form>
    </>
  );
}

export default ResetForgotPassword;
