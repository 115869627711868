import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { shareFile } from "../../redux/actions/ShareFile/ShareFile";
import { useDispatch, useSelector } from "react-redux";
import { useAppDispatchJS, useAppSelectorJS } from "../../redux/hooks";


const ShareFileLogin = () => {
  const dispatch = useAppDispatchJS();
  const authentication = useAppSelectorJS((state) => state.ShareFileCheck);
  useEffect(() => {}, [authentication]);

  function login() {}

  function LoginShareFile(e) {
    e.preventDefault();
    let uuid = JSON.parse(localStorage.getItem("userDetails"))?.uuid;
    let state = btoa(`False::${uuid.replace(/"/g, "").trim()}`);
    console.log(state, uuid.replace(/"/g, "").trim());
    console.log(authentication);
    window.location.href = authentication.error.auth;
    //dispatch(shareFile.Login(state));
  }

  return (
    <div className="share-login-block h-100 d-flex align-items-center justify-content-center">
      <div className="text-center mw-280">
        <p className="fs-18 mb-24">
          To view your files and folders, please log in to ShareFile.
        </p>
        <button
          type="button"
          onClick={LoginShareFile}
          className="btn btn-success"
        >
          Log In to ShareFile
        </button>
      </div>
    </div>
  );
};

export default ShareFileLogin;
