// Tanner Fry
// tfry@monetagroup.com
// Custom utilities to help with various tasks.

import { notification } from "antd";
import { IconType } from "antd/lib/notification";
import moment from "moment";

export enum notifyType {
    SUCCESS = "success",
    INFO = "info",
    WARNING = "warning",
    ERROR = "error"
}

// Transform first character of each word to uppercase
const capitalizeFirstLetter = (str: string): string => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

// Convert ISO 8601 date string to 12 hour format
const convertISOToTime = (isoDate: string, withDate: boolean = false): string => {
    const date = new Date(isoDate);
    if (withDate) {
        return date.toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true });
    } else {
        return date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
    }
}

// Helper function to copy text to clipboard
const copyToClipboard = async (text: string = "") => {
    const message = "Copied '" + text + "'.";
    try {
        await navigator.clipboard.writeText(text);
        notifyInApp(
            notifyType.SUCCESS,
            message,
            <em className="icon-check-outline"></em>
        );
    } catch (error) {
        notifyInAppError("Failed to copy '" + text + "'.")
    }
}

// Get initials from a name
const getInitials = (name: string): string => {
    const names = name.split(" ");
    const allInitialsFromSplitNames = names.map((name) => name.substring(0, 1).toUpperCase());
    
    // Make sure there"s no special characters
    const sanitizedInitials = allInitialsFromSplitNames.map((initial) => initial.replace(/[^a-zA-Z]/g, ""));

    // Set initials
    const initials = sanitizedInitials.join("");
    return initials;
}

const formatDateTimeInMessageAccordingToRegex = (
    message: string, 
    regex: RegExp = /^(\d{2}\/\d{2}\/\d{4} \d{2}:\d{2} [APM]{2}) - /, 
) => {
    // console.log("Message to format: ", message);
    
    // Function to strip the date from the message body
    const stripDateFromBody = (body: string): { date: string, cleanMessage: string } => {
        // Regular expression to match the date in MM/DD/YYYY HH:MM AM/PM format
        const dateRegex = regex;
        const match = body.match(dateRegex);

        if (match) {
            const date = match[1];  // Extracted date part
            const cleanMessage = body.replace(dateRegex, '');  // Remove date from body
            return { date, cleanMessage };
        }

        return { date: '', cleanMessage: body };  // Fallback if no date found
    };

    // Function to format the UTC date to the user's timezone
    // const formatDateTime = (utcDateString: string, timeZone: string): string => {
    //     console.log("UTC Date String: ", utcDateString, ", timezone: ", timeZone);
        
    //     const date = new Date(utcDateString);

    //     const options: Intl.DateTimeFormatOptions = {
    //         year: 'numeric',
    //         month: 'long',
    //         day: 'numeric',
    //         hour: 'numeric',
    //         minute: 'numeric',
    //         timeZone: timeZone,  // User's timezone or fallback to "UTC"
    //         timeZoneName: 'short',  // To include timezone abbreviation (e.g., "CT")
    //     };

    //     return new Intl.DateTimeFormat(undefined, options).format(date);
    // };

    // Step 1: Strip the date from the message body
    const { date, cleanMessage } = stripDateFromBody(message);
    // console.log("Extracted date: ", date);
    
    // Date format example: "MM/DD/YYYY HH:MM AM/PM"
    // Build new date object
    const month = Number(date.substring(0, 2));
    const day = Number(date.substring(3, 5));
    const year = Number(date.substring(6, 10));
    const ampm = date.substring(17, 19);
    // Base hour on am/pm
    const hour = ampm === "AM" ? Number(date.substring(11, 13)) : Number(date.substring(11, 13)) + 12;
    const minute = Number(date.substring(14, 16));
    const newDate = new Date(year, month - 1, day, hour, minute, 0, 0)
    // console.log("New date object from extracted date: ", newDate);

    // Step 2: Format the extracted date into the user's timezone
    let formattedDate = date;
    // console.log("UTC Offset: ", moment(newDate).utcOffset());
    // console.log("UTC Offset in hours: ", moment(newDate).utcOffset() / 60);
    
    
    const currentUserUtcOffsetWithPositiveOrNegative = moment(newDate).utcOffset() > 0 ? `+${moment(newDate).utcOffset()}` : moment(newDate).utcOffset();
    formattedDate = moment(newDate).utc(true).utcOffset(Number(currentUserUtcOffsetWithPositiveOrNegative)).format("MM/DD/YYYY h:mm A").toString();

    return { formattedDate, cleanMessage }
}

const notifyInApp = (type: IconType, message: string, icon: React.ReactNode, description?: string, duration?: number) => {
    notification[type]({
        message: message,
        description: description,
        duration: duration,  // Default is 4.5 = 4.5 seconds
        icon: icon,
        top: 65,
    });
};

const notifyInAppSuccess = (message: string) => {
    notification[notifyType.SUCCESS]({
        message: message,
        icon: <em className="icon-check-outline"></em>,
        top: 65,
    });
}

const notifyInAppSuccessWithOnClose = (message: string, onCloseCallback: () => void) => {
    notification[notifyType.SUCCESS]({
        message: message,
        icon: <em className="icon-check-outline"></em>,
        top: 65,
        onClose: onCloseCallback, // Pass the callback function here
    });
};


const notifyInAppInfo = (message: string) => {
    notification[notifyType.INFO]({
        message: message,
        icon: <em className="icon-info-circle-outline"></em>,
        top: 65,
    });
}

const notifyInAppWarning = (message: string) => {
    notification[notifyType.WARNING]({
        message: message,
        icon: <em className="icon-exclamation-outline"></em>,
        top: 65,
    });
}

const notifyInAppError = (message: string) => {
    notification[notifyType.ERROR]({
        message: message,
        icon: <em className="icon-subtract-circle-outline"></em>,
        top: 65,
    });
}

/**
 * Pretty prints a JSON string.
 * 
 * @param {any} json - The JSON string to be pretty-printed.
 * @param {string} [prependContent=""] - The content to prepend to the pretty-printed JSON.
 * @param {boolean} [outputToConsole=true] - Whether to output the pretty-printed JSON to the console.
 * @param {number} [spaceIndent=4] - The number of spaces to use for indentation.
 */
const ppJson = (prependContent: string = "", json: any, outputToConsole = true, spaceIndent: number = 4): string | void => {
    try {
        if (!json) {
            console.log("ppJson: json is null or undefined");
        }

        if (outputToConsole) {
            if (typeof json === "string") {
                console.log(prependContent + JSON.stringify(JSON.parse(json), null, spaceIndent));
            } else {
                console.log(prependContent + JSON.stringify(json, null, spaceIndent));
            }
        } else {
            if (typeof json === "string") {
                return prependContent + JSON.stringify(JSON.parse(json), null, spaceIndent);
            } else {
                return prependContent + JSON.stringify(json, null, spaceIndent);
            }
        }
    } catch (error) {
        console.error("ppJson error: ", error);
    }
}

interface SystemMessageProps {
    message: string;  // Entire message including date
}

const SystemMessage = ({ message }: SystemMessageProps) => {
    const formattedObject = formatDateTimeInMessageAccordingToRegex(message)

    return (
        <div className="system-message">
            <div>
                <span>System Message</span>
            </div>
            {/* Format the date string in the body from UTC to the user's timezone */}
            <span>{formattedObject.formattedDate} - {formattedObject.cleanMessage}</span>
        </div>
    );
};

interface ILoadingSpinner {
    isActive: boolean;
    additionalSpinnerStyle?: React.CSSProperties;
    additionalSpinnerContainerStyle?: React.CSSProperties;
    additionalBackgroundDimmerStyle?: React.CSSProperties;
}
const LoadingSpinner = ({
    isActive,
    additionalSpinnerStyle,
    additionalSpinnerContainerStyle,
    additionalBackgroundDimmerStyle,
}: ILoadingSpinner) => {

    return (
        isActive && (
            <div className="loading-spinner-container">
                <div className="background-dimmer" style={additionalBackgroundDimmerStyle} />
                <div className="loading-spinner">
                    <div className="spinner-container" style={additionalSpinnerContainerStyle}>
                        <div className="spinner" style={additionalSpinnerStyle}></div>
                    </div>
                </div>
            </div>
        )
    );
}
  

export {
    capitalizeFirstLetter,
    convertISOToTime,
    copyToClipboard,
    formatDateTimeInMessageAccordingToRegex,
    getInitials,
    LoadingSpinner,
    notifyInApp, notifyInAppError, notifyInAppInfo, notifyInAppSuccess,
    notifyInAppSuccessWithOnClose, notifyInAppWarning,
    ppJson,
    SystemMessage
};

