export const shareFileConstants = {

  CHECK_SHAREFILE_SUCCESS : 'CHECK_SHAREFILE_SUCCESS',
  CHECK_SHAREFILE_FAILURE : 'CHECK_SHAREFILE_FAILURE',

  GET_SHAREFILE_TOKEN_SUCCESS : 'GET_SHAREFILE_TOKEN_SUCCESS',
  GET_SHAREFILE_TOKEN_FAILURE : 'GET_SHAREFILE_TOKEN_FAILURE',

  GET_HOME_FOLDER_SUCCESS : 'GET_HOME_FOLDER_SUCCESS',
  GET_HOME_FOLDER_FAILURE : 'GET_HOME_FOLDER_FAILURE',

  GET_ALL_SHARED_FOLDER_SUCCESS : 'GET_ALL_SHARED_FOLDER_SUCCESS',
  GET_ALL_SHARED_FOLDER_FAILURE : 'GET_ALL_SHARED_FOLDER_FAILURE',

  GET_SHARED_FOLDER_BY_ID_SUCCESS : 'GET_SHARED_FOLDER_BY_ID_SUCCESS',
  GET_SHARED_FOLDER_BY_ID_FAILURE : 'GET_SHARED_FOLDER_BY_ID_FAILURE',

  GET_DETAILS_BY_ID_SUCCESS : 'GET_DETAILS_BY_ID_SUCCESS',
  GET_DETAILS_BY_ID_FAILURE : 'GET_DETAILS_BY_ID_FAILURE',

  DELETE_ITEM_BY_ID_SUCCESS : 'DELETE_ITEM_BY_ID_SUCCESS',
  DELETE_ITEM_BY_ID_FAILURE : 'GDELETE_ITEM_BY_ID_FAILURE',
    
  DOWNLOAD_ITEM_BY_ID_SUCCESS : 'DOWNLOAD_ITEM_BY_ID_SUCCESS',
  DOWNLOAD_ITEM_BY_ID_FAILURE : 'DOWNLOAD_ITEM_BY_ID_FAILURE',

  CREATE_FOLDER_SUCCESS : 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAILURE : 'CREATE_FOLDER_FAILURE',
    
  GET_BREAD_CRUMB_SUCCESS : 'GET_BREAD_CRUMB_SUCCESS',
  GET_BREAD_CRUMB_FAILURE : 'GET_BREAD_CRUMB_FAILURE',

  GET_ACCOUNT_INFO_SUCCESS : 'GET_ACCOUNT_INFO_SUCCESS',
  GET_ACCOUNT_INFO_FAILURE : 'GET_ACCOUNT_INFO_FAILURE',

  UPDATE_NOTE_SUCCESS : 'UPDATE_NOTE_SUCCESS',
  UPDATE_NOTE_FAILURE : 'UPDATE_NOTE_FAILURE',

  UPDATE_NAME_SUCCESS : 'UPDATE_NAME_SUCCESS',
  UPDATE_NAME_FAILURE : 'UPDATE_NAME_FAILURE',
  ADD_FAVOURITE_SUCCESS : 'ADD_FAVOURITE_SUCCESS',
  ADD_FAVOURITE_FAILURE : 'ADD_FAVOURITE_SUCCESS',

  DELETE_FAVOURITE_SUCCESS : 'DELETE_FAVOURITE_SUCCESS',
  DELETE_FAVOURITE_FAILURE : 'DELETE_FAVOURITE_SUCCESS',

    
  PERSONAL_FOLDER_SUCCESS : 'PERSONAL_FOLDER_SUCCESS',
  PERSONAL_FOLDER_FAILURE : 'PERSONAL_FOLDER_FAILURE',

  RECYLCE_FOLDER_SUCCESS : 'RECYLCE_FOLDER_SUCCESS',
  RECYLCE_FOLDER_FAILURE : 'RECYLCE_FOLDER_FAILURE',

  GET_FAVORITE_FOLDER_SUCCESS : 'GET_FAVORITE_FOLDER_SUCCESS',
  GET_FAVORITE_FOLDER_FAILURE : 'GET_FAVORITE_FOLDER_FAILURE',

  GET_TOPFOLDERS_SUCCESS : 'GET_TOPFOLDERS_SUCCESS',
  GET_TOPFOLDERS_FAILURE : 'GET_TOPFOLDERS_FAILURE',

  COPY_ITEM_SUCCESS : 'COPY_ITEM_SUCCESS',
  COPY_ITEM_FAILURE : 'COPY_ITEM_FAILURE',

  CUNKURL_SUCCESS : 'CUNKURL_SUCCESS',
  CHUNKURL_FAILURE : 'CHUNKURL_FAILURE',

  ADD_ACCESS_CONTROLS_SUCCESS : 'ADD_ACCESS_CONTROLS_SUCCESS',
  ADD_ACCESS_CONTROLS_FAILURE : 'ADD_ACCESS_CONTROLS_FAILURE',

  GET_ACCESS_CONTROLS_SUCCESS : 'GET_ACCESS_CONTROLS_SUCCESS',
  GET_ACCESS_CONTROLS_FAILURE : 'GET_ACCESS_CONTROLS_FAILURE',

  BULK_DELETE_SUCCESS : 'BULK_DELETE_SUCCESS',
  BULK_DELETE_FAILURE : 'BULK_DELETE_FAILURE',

    
  BULK_DOWNLOAD_SUCCESS : 'BULK_DOWNLOAD_SUCCESS',
  BULK_DOWNLOAD_FAILURE : 'BULK_DOWNLOAD_FAILURE',

  RESTORE_FILE_SUCCESS : 'RESTORE_FILE_SUCCESS',
  RESTORE_FILE_FAILURE : 'RESTORE_FILE_FAILURE',

  
  WEBHOOK_SHAREFILE_SUCCESS : 'WEBHOOK_SHAREFILE_SUCCESS',
  WEBHOOK_SHAREFILE_FAILURE : 'WEBHOOK_SHAREFILE_FAILURE',

  LOGOUT_SHAREFILE_SUCCESS : "LOGOUT_SHAREFILE_SUCCESS",
  LOGOUT_SHAREFILE_FAILURE : "LOGOUT_SHAREFILE_FAILURE",

  REPORT_CAPTURED_SUCCESS : "REPORT_CAPTURED_SUCCESS",
  REPORT_CAPTURED_FAILURE : "REPORT_CAPTURED_FAILURE",

  GET_ALL_SHARE_FILES_PERMISSIONS_SUCCESS : 'GET_ALL_SHARE_FILES_PERMISSIONS_SUCCESS',
  GET_ALL_SHARE_FILES_PERMISSIONS_FAILURE : 'GET_ALL_SHARE_FILES_PERMISSIONS_FAILURE',

  GET_SEARCH_SUCCESS : 'GET_SEARCH_SUCCESS',
  GET_SEARCH_FAILURE : 'GET_SEARCH_FAILURE',
};