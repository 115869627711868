import axiosInstance from "../../../config/axios";
import { userConstants } from "../../constants/user.constant";

export const auth = {
  login,
  validateEmail,
  sendOtp,
  forgotPasswordCode,
  validForgotPasswordCode,
  forgotResetPassword,
  resetDefaultPassword,
  globalNavPreferences,
  refreshtoken,
};

function globalNavPreferences() {
  return (dispatch) => {
    return axiosInstance
      .get(`/global-nav-preferences`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          dispatch(failure(error.toString()));
        }
      });
  };

  function success(data) {
    return { type: userConstants.GLOBAL_NAVIGATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.GLOBAL_NAVIGATION_FAILURE, error };
  }
}

function validateEmail(email, device_token) {
  const requestOptions = { email, device_token };
  return (dispatch) => {
    return axiosInstance
      .post(`/user/validate_email`, requestOptions)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: userConstants.VALIDATE_EMAIL_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.VALIDATE_EMAIL_FAILURE, error };
  }
}

function sendOtp(param) {
  return (dispatch) => {
    return axiosInstance
      .post(`/user/send_an_otp`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: userConstants.OTP_RECEIVED_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.OTP_FAILURE, error };
  }
}

function login(param) {
  return (dispatch) => {
    return axiosInstance
      .post(`/user/login`, param)
      .then((response) => {
        console.log(response.data);
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: userConstants.LOGIN_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function forgotPasswordCode(param) {
  return (dispatch) => {
    return axiosInstance
      .post(`/user/send_forgot_password_code`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: userConstants.FORGOT_OTP_RECEIVED_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.FORGOT_OTP_RECEIVED_FAILURE, error };
  }
}

function validForgotPasswordCode(param) {
  return (dispatch) => {
    return axiosInstance
      .post(`/user/validate_forgot_password_code`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: userConstants.FORGOT_OTP_VALID_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.FORGOT_OTP_VALID_FAILURE, error };
  }
}

function forgotResetPassword(param) {
  return (dispatch) => {
    return axiosInstance
      .post(`/user/reset_password`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: userConstants.FORGOT_RESET_PASSWORD_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.FORGOT_RESET_PASSWORD_FAILURE, error };
  }
}

function resetDefaultPassword(param) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/user/change_password`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          //  window.location.assign('/')
          //localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: userConstants.RESET_DEFAULT_PASSWORD_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.RESET_DEFAULT_PASSWORD_FAILURE, error };
  }
}

function refreshtoken() {
  let body = {
    device_token: JSON.parse(
      localStorage.getItem("userDetails")
    )?.device_token,
  };
  return (dispatch) => {
    return axiosInstance
      .post(`/user/refresh-token`, body)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          //  window.location.assign('/')
          //localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: userConstants.RESET_REFRESH_TOKEN_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.RESET_REFRESH_TOKEN_FAILURE, error };
  }
}