import { createSlice } from '@reduxjs/toolkit';
import { RootStateTS } from '../storeTS';

export interface IUsersSlice {
    isDarkMode: boolean;
    isGM: boolean;
    testerStatus: {
        twilio_conversations_chat: boolean;
        twilio_conversations_text: boolean;
    }
}

const initialState: IUsersSlice = {
    isDarkMode: false,
    isGM: false,
    testerStatus: {
        twilio_conversations_chat: false,
        twilio_conversations_text: false,
    }
};

// @ts-ignore
const usersSlice = createSlice({
    name: 'usersSlice',
    initialState,
    reducers: {
        reduxSetUserDarkMode: (state, action) => {
            state.isDarkMode = action.payload;
        },
        reduxSetUserGM: (state, action) => {
            state.isGM = action.payload;  
        },
        reduxSetTesterStatus: (state, action) => {
            state.testerStatus = {
                ...state.testerStatus,
                ...action.payload,
            };
        }
    },
});

export const { reduxSetUserDarkMode, reduxSetUserGM, reduxSetTesterStatus } = usersSlice.actions;

export const reduxSelectIsDarkMode = (state: RootStateTS) => state.users.isDarkMode;

export const reduxSelectIsGM = (state: RootStateTS) => state.users.isGM;

export const reduxSelectTesterStatus = (state: RootStateTS) => state.users.testerStatus;

export default usersSlice.reducer;