// Tanner Fry
// tfry@monetagroup.com
// File contains the header component for the Twilio Conversations dashboard.

import PlumbingIcon from '@mui/icons-material/Plumbing';
import { useAppSelectorTS } from "../../redux/hooksTS";
import { reduxSelectIsDarkMode } from "../../redux/slicesTS/UsersSlice";

interface IUnderConstruction {

}

const UnderConstruction = ({

} : IUnderConstruction) => {
    const isDarkMode = useAppSelectorTS(reduxSelectIsDarkMode);

    return (
        <div className="under-construction">
            <h4 className="m-0" style={{color: isDarkMode ? "#fff" : ""}}>Under Construction</h4>
            <PlumbingIcon />
        </div>
    );
}

export default UnderConstruction;