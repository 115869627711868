import axiosInstance from "../../../config/axios";
import { notificationConstants } from "../../constants/NotificationConstant";
import moment from "moment";
import { utility } from "../../../components/Utility";

export const notificationService = {
  notifications,
  markAllRead,
  delete_all,
  markSingleAsRead,
  delete_single,
  nextNotifications,
  unreadNotificationsCount,
  notification_preferences_set,
  complate_docusign_action,
  getUnreadMessageCount
};

function getUnreadMessageCount() {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/message/unread_message_count`
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: notificationConstants.UNREAD_MESSAGE_COUNT_SUCCESS, data };
  }

  function failure(error) {
    return { type: notificationConstants.UNREAD_MESSAGE_COUNT_FAILURE, error };
  }
}
function unreadNotificationsCount() {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/notification/get_unread_notification_count`
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: notificationConstants.NOTIFICATION_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: notificationConstants.NOTIFICATION_FAILURE, error };
  }
}

function notifications(notificationType) {
  return (dispatch) => {
    const notificationtype = notificationType.toLowerCase();
    return axiosInstance
      .get(
        `/notification?${
            "notification_filter__in=" + notificationtype + "&"
        }is_deleted=false&limit=20&offset=0`
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
      
        
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: notificationConstants.NOTIFICATION_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: notificationConstants.NOTIFICATION_FAILURE, error };
  }
}

function nextNotifications(next) {
  return (dispatch) => {
    return axiosInstance
      .get(next)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: notificationConstants.NOTIFICATION_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: notificationConstants.NOTIFICATION_FAILURE, error };
  }
}

function markAllRead() {
  return (dispatch) => {
    return axiosInstance
      .patch("/notification/mark_all_read_unread", {
        is_read: true,
      })
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: notificationConstants.NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: notificationConstants.NOTIFICATION_FAILURE, error };
  }
}

function markSingleAsRead(uuid) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/notification/${uuid}`, {
        is_read: true,
      })
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: notificationConstants.NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: notificationConstants.NOTIFICATION_FAILURE, error };
  }
}

function delete_all() {
  return (dispatch) => {
    return axiosInstance
      .delete("/notification/delete_all")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: notificationConstants.NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: notificationConstants.NOTIFICATION_FAILURE, error };
  }
}

function delete_single(uuid,isdelete) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/notification/${uuid}`,{
        "is_deleted": isdelete
      })
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: notificationConstants.NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: notificationConstants.NOTIFICATION_FAILURE, error };
  }
}

function complate_docusign_action(file_reference,uuid,username,item_name) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/user/docusign/complate_docusign_action`,{
        "file_reference": file_reference,
        "signer_user_id": uuid,
        "username": username,
        "item_name": item_name
      })
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: notificationConstants.NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: notificationConstants.NOTIFICATION_FAILURE, error };
  }
}

function notification_preferences_set(notification_filter) {
  return (dispatch) => {
    return axiosInstance
      .post(`/notification_preferences/set-type`,{
        "preference_types": [
          notification_filter
        ],
        "value": true
      })
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: notificationConstants.NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: notificationConstants.NOTIFICATION_FAILURE, error };
  }
}