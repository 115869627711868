import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Switch, Steps ,notification} from "antd";
import Avatar from "../../Assets/Images/Avatar.png";
import Modal from "react-bootstrap/Modal";
import { clientUser } from "../../redux/actions/ClientUser/ClientUser";
import { useAppDispatchJS } from "../../redux/hooks";

const ClientResetPassword = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatchJS();

  const [showSendResetPasswordLink, setSendResetPasswordLinkShow] =
    useState(false);
  const handleSendResetPasswordLinkClose = () => {
    setSendResetPasswordLinkShow(false);
  };
  const handleSendResetPasswordLinkShow = () =>
    setSendResetPasswordLinkShow(true);

  useEffect(() => {
    const { visible, closeModal, isReschedule } = props;
    setSendResetPasswordLinkShow(visible);
  }, []);

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  function resetPassword() {
    let param ={
      user_id: props?.client?.uuid,
    }
    dispatch(clientUser.resetPasswordLink(param)).then(
      (response) => {
        if (response.type === "RESET_PASSWORD_LINK_USER_SUCCESS") {
          //setHouseHoldDetails(response?.data);
          const { closeModal } = props;
          closeModal();
          Notify(
            "success",
            `A reset password link has been sent to ${props?.client?.first_name+" "+props?.client?.last_name} at ${props?.client?.email}. `,
            <em className="icon-check-outline"></em>
          );
        }
      }
    );
  }

  return (
    <>
      <Modal
        show={showSendResetPasswordLink}
        onHide={() => {
          handleSendResetPasswordLinkClose;
          const { closeModal } = props;
          closeModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Reset Password Link</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-md-24">
          <div className="fs-18 lh-32">
            <p className="mb-12">
              Are you sure you want to require a password reset for{" "}
              <b className="fw-500">{props?.client?.first_name+" "+props?.client?.last_name}?</b>
            </p>
            <p className="mb-0 mb-md-24">
              They will get an email at{" "}
              <b className="fw-500">{props?.client?.email}</b> with a link to create
              and confirm a new password.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={(e) => {
              handleSendResetPasswordLinkClose();
              const { closeModal } = props;
              closeModal();
            }}
          >
            Cancel
          </button>
          <button className="btn btn-success" onClick={(e)=>{resetPassword()}}>Send Link</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientResetPassword;
