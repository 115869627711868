import axiosInstance from "../../../config/axios";
import { calendarConstants } from "../../constants/CalendarConstant";
import moment from "moment";
import { utility } from "../../../components/Utility";

export const calendar = {
  calendarCheck,
  createEvent,
  getClientList,
  getEventList,
  getAvailability,
  getTimeZone,
  deleteEvent,
  eventDetails,
  updateEvent,
  getCopyLink,
  accpetOrDeclineMeeting,
  authoriseMicrosoftTeamsRequest,
  getRoomResources,
  getNextEventList,
};

function calendarCheck() {
  return (dispatch) => {
    return axiosInstance
      .get("/nylas/check_nylas_association?is_mobile=false")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: calendarConstants.CHECK_CALENDAR_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.CHECK_CALENDAR_FAILURE, error };
  }
}

function getEventList(selectedDay, isOnlyPending,FilteredclientList,startsafter,endsbefore,limit=20,offset) {
  return (dispatch) => {
    console.log(selectedDay,"selectedDayselectedDayselectedDayselectedDayselectedDay")
    return axiosInstance
      .get(
        "/event?start_time__gte=" +
        encodeURIComponent(selectedDay)+
          "&limit="+limit+"&offset="+offset+(isOnlyPending ? "&status=pending" : "")+"&ordering=start_time"+(FilteredclientList.length>0?"&event_participant__participant__uuid__in="+FilteredclientList.join(","):"")+
          "&starts_after="+startsafter+"&ends_before="+endsbefore
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: calendarConstants.GET_EVENT_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.GET_EVENT_LIST_FAILURE, error };
  }
}


function getNextEventList(next=null,selectedDay, isOnlyPending,FilteredclientList,startsafter,endsbefore,limit=20,offset) {
  return (dispatch) => {
    return axiosInstance
      .get(next?next:"/event?start_time__gte=" +
      encodeURIComponent(selectedDay)+
        "&limit="+limit+"&offset="+offset+(isOnlyPending ? "&status=pending" : "")+"&ordering=start_time"+(FilteredclientList.length>0?"&event_participant__participant__uuid__in="+FilteredclientList.join(","):"")+
        "&starts_after="+startsafter+"&ends_before="+endsbefore)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: calendarConstants.GET_EVENT_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.GET_EVENT_LIST_FAILURE, error };
  }
}



function createEvent(param) {
  return (dispatch) => {
    return axiosInstance
      .post("/event", param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: calendarConstants.EVENT_CREATE_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.EVENT_CREATE_FAILURE, error };
  }
}

function getClientList(onlyClients=false) {
  return (dispatch) => {
    return axiosInstance
      .get("/user/advisory_team"+(onlyClients?"?user_type=clients":""))
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: calendarConstants.GET_CLIENT_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.GET_CLIENT_LIST_FAILURE, error };
  }
}


function getCopyLink() {
  return (dispatch) => {
    return axiosInstance
      .get("/event/new_event_deeplink")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: calendarConstants.GET_COPY_LINK_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.GET_COPY_LINK_FAILURE, error };
  }
}
function getAvailability(param) {
  return (dispatch) => {
    return axiosInstance
      .post("/nylas/availability", param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: calendarConstants.GET_AVABILITY_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.GET_AVABILITY_FAILURE, error };
  }
}

function getTimeZone() {
  return (dispatch) => {
    return axiosInstance
      .get("/nylas/timezone")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: calendarConstants.GET_TIMEZONE_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.GET_TIMEZONE_FAILURE, error };
  }
}

function deleteEvent(id) {
  return (dispatch) => {
    return axiosInstance
      .delete("event/"+id)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: calendarConstants.DELETE_EVENT_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.DELETE_EVENT_FAILURE, error };
  }
}


function eventDetails(id) {
  return (dispatch) => {
    return axiosInstance
      .get("event/"+id)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: calendarConstants.GET_EVENT_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.GET_EVENT_FAILURE, error };
  }
}

function updateEvent(param , uuid) {
  return (dispatch) => {
    return axiosInstance
      .patch("/event/"+uuid, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: calendarConstants.UPDATE_EVENT_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.UPDATE_EVENT_FAILURE, error };
  }
}

function accpetOrDeclineMeeting(param,uuid) {
  return (dispatch) => {
    return axiosInstance
      .patch("/event/"+uuid+"/rsvp", param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: calendarConstants.ACCEPT_DECLINE_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.ACCEPT_DECLINE_FAILURE, error };
  }
}

function authoriseMicrosoftTeamsRequest() {
  return (dispatch) => {
    return axiosInstance
      .get("/nylas/authorise_microsoft_teams_request?is_mobile=false")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: calendarConstants.AUTH_TEAM_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.AUTH_TEAM_FAILURE, error };
  }
}

function getRoomResources(startTime , endTime) {
  return (dispatch) => {
    return axiosInstance
      .get(`/nylas/get_room_resources?start_time=${startTime}&end_time=${endTime}`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: calendarConstants.GET_ROOM_RESOURCE_SUCCESS, data };
  }

  function failure(error) {
    return { type: calendarConstants.GET_ROOM_RESOURCE_FAILURE, error };
  }
}