import React, { useEffect, useState } from "react";
import logo from "../../Assets/Images/logo.svg";
import { useNavigate , useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { clientUser } from "../../redux/actions/ClientUser/ClientUser";
import { Link } from "react-router-dom";
import { useAppDispatchJS } from "../../redux/hooks";


const EmailVerification = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatchJS();
  const [errorTitle, setErrorTitle] = useState();
  const [errorBody, setErrorBody] = useState();



  useEffect(() => {    
    verifyEmail()    
  }, []);

  function verifyEmail() {
    const search =  new URLSearchParams(window.location.search);
    const token = new URLSearchParams(search).get('token');
    const email = new URLSearchParams(search).get('email');
    const is_pwd_reset = new URLSearchParams(search).get('is_reset_pwd_verify');
    localStorage.setItem("Email" , email);
    localStorage.setItem("is_new_user" , true)
    let param = {
      "email": email,
      "token": token
    };
    if (is_pwd_reset === "true") {
      let param = {
        "email": email,
        "token": token,
        "is_reset_pwd_verify": "true"
      };
      dispatch(clientUser.verifyEmail(param)).then(
        (response) => {
          if (response.type === "VERIFY_USER_SUCCESS") {
            const search =  new URLSearchParams(window.location.search);
            search.delete("email")
            navigate({
              pathname: "/reset-forgot-password",
              search: `?token=${param["token"]}`,
            })
          }
          else {
            setErrorTitle("Reset Password Link Expired")
            setErrorBody("The email link has expired. To reset your password, enter your email on the Log In page and proceed with the \"Forgot Password\" steps. Please contact your advisory team if you have any questions.")
          }
        }
      );
    }
    else {
      dispatch(clientUser.verifyEmail(param)).then(
        (response) => {
          if (response.type === "VERIFY_USER_SUCCESS") {
            const search =  new URLSearchParams(window.location.search);
            search.delete("token");
            search.delete("email")
            navigate("/send-otp")
            setErrorTitle("Verifying Email Address")
          }
          else{
            if (response.error.error.includes("Confirm my email link is expired")){
              setErrorTitle("Confirm My Email Link Expired")
              setErrorBody("The email link has expired. Please contact your advisory team to send a new invite link for the Moneta App.")
            }
            else{
              setErrorTitle("Verifying Email Address")
            }
          }
        }
      );
    }
  }


  return (
    <>
      <div className="login-section">
        <div className='container'>
          <div className='login-center mx-auto'>
            <div className='text-center'>
              <a href='#' className='login-logo mb-52 mb-md-120'>
                <img src={logo} alt="" />
              </a>
            </div>
            <div className='login-card'>
              <div className='login-heading text-center mb-16'>
                <h2 className='mb-8'>{ errorTitle}</h2>   
              </div>
            </div>
            { errorTitle && errorBody &&
          <>
            <div className="text-center mb-16">{errorBody }</div>

            <div className='text-center'>
              <Link to="/" className="btn-link fs-18 lh-32">Back to Login</Link>
            </div>
          </>
            }
            <div> 
            </div>
          </div>
        </div>
      </div> 
    </>
  );
};

export default EmailVerification;
