import axiosInstance from "../../../config/axios";
import { profileNotificationSetting } from "../../constants/ProfileSettingConstant";
import { utility } from "../../../components/Utility";

export const profileSetting = {
  getNotificationPreferences,
  changeNotificationPreferences,
  getProfileInfo,
  sendOtp,
  validateCode,
  changePassword,
  notificationSetting,
  imageUpload,
  snoozeNotification,
  getSnoozeNotification,
  setWorkingHours
};

function getNotificationPreferences(url) {
  
  return (dispatch) => {
    return axiosInstance
      .get(url)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: profileNotificationSetting.GET_NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: profileNotificationSetting.GET_NOTIFICATION_FAILURE, error };
  }
}

function changeNotificationPreferences(param) {
  return (dispatch) => {
    return axiosInstance
      .post(`/notification_preferences/set`,param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: profileNotificationSetting.CHANGE_NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: profileNotificationSetting.CHANGE_NOTIFICATION_FAILURE, error };
  }
}


function getProfileInfo(uuid) {
  return (dispatch) => {
    return axiosInstance
      .get(`/user/${uuid}/profile`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: profileNotificationSetting.GET_PROFILE_INFO_SUCCESS, data };
  }

  function failure(error) {
    return { type: profileNotificationSetting.GET_PROFILE_INFO_FAILURE, error };
  }
}


function sendOtp(param) {

  return (dispatch) => {
    return axiosInstance
      .post(`/user/update-password/send-code`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data)
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: profileNotificationSetting.OTP_SUCCESS, data };
  }

  function failure(error) {
    return { type: profileNotificationSetting.OTP_FAILURE, error };
  }
}


function validateCode(param) {

  return (dispatch) => {
    return axiosInstance
      .post(`/user/update-password/verify-token`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401 ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data)
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: profileNotificationSetting.OTP_VALID_SUCCESS, data };
  }

  function failure(error) {
    return { type: profileNotificationSetting.OTP_VALID_FAILURE, error };
  }
}

function changePassword(param) {

  return (dispatch) => {
    return axiosInstance
      .post(`/user/update-password`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401 ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data)
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: profileNotificationSetting.RESET_PASSWORD_SUCCESS, data };
  }

  function failure(error) {
    return { type: profileNotificationSetting.RESET_PASSWORD_FAILURE, error };
  }
}

function notificationSetting(param) {

  return (dispatch) => {
    return axiosInstance
      .put(`/user/notification/setting`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401 ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data)
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: profileNotificationSetting.NOTIFICATION_SETTING_SUCCESS, data };
  }

  function failure(error) {
    return { type: profileNotificationSetting.NOTIFICATION_SETTING_FAILURE, error };
  }
}


function imageUpload(formData) {

  return (dispatch) => {
    return axiosInstance
      .patch(`/user/upload_image`, formData)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401 ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data)
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: profileNotificationSetting.IMAGE_UPLOAD_SUCCESS, data };
  }

  function failure(error) {
    return { type: profileNotificationSetting.IMAGE_UPLOAD_FAILURE, error };
  }
}

function snoozeNotification(param) {

  return (dispatch) => {
    return axiosInstance
      .post(`/notification_preferences/snooze-notification`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401 ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data)
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: profileNotificationSetting.NOTIFICATION_SNOOZE_SUCCESS, data };
  }

  function failure(error) {
    return { type: profileNotificationSetting.NOTIFICATION_SNOOZE_FAILURE, error };
  }
}

function getSnoozeNotification() {
  return (dispatch) => {
    return axiosInstance
      .get(`/notification_preferences/snooze-notification`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401 ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data)
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: profileNotificationSetting.GET_NOTIFICATION_SNOOZE_SUCCESS, data };
  }

  function failure(error) {
    return { type: profileNotificationSetting.GET_NOTIFICATION_SNOOZE_FAILURE, error };
  }
}


function setWorkingHours(param) {
  return (dispatch) => {
    return axiosInstance
      .put(`/user/set_working_hours`,param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401 ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data)
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: profileNotificationSetting.WORKING_HOUR_SUCCESS, data };
  }

  function failure(error) {
    return { type: profileNotificationSetting.WORKING_HOUR_FAILURE, error };
  }
}