import React, { useEffect, useState } from "react";
import {notification } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../redux/actions/Auth/Auth";
import Loading from "../../components/Loader";
import ReactCodeInput from "react-verification-code-input";
import { useAppDispatchJS, useAppSelectorJS } from "../../redux/hooks";
const LoginCodeVerify = () => {
  const [loader, setLoader] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [isCodeInValid, setIsCodeInValid] = useState(false);
  const authentication = useAppSelectorJS((state) => state.authentication);
  const location = useLocation();
  const dispatch = useAppDispatchJS();
  const navigate = useNavigate();

  useEffect(() => {
    setEmail(localStorage.getItem("Email"));
    setPassword(location?.state?.password);
    //localStorage.setItem("user", false);
  });
  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  function handleSubmit(e) {
    setLoader(true);
    e.preventDefault();
    setSubmitted(true);
    let params = { email: email, password: password, otp: verificationCode };
    if (!email || !password || !verificationCode) {
      setLoader(false);
      return;
    }
    dispatch(auth.login(params)).then((response) => {
      setLoader(false);
      if (response.type === "USERS_LOGIN_SUCCESS") {
        localStorage.setItem("user", true);
        localStorage.setItem("userDetails", JSON.stringify(response.data));
        localStorage.setItem(
          "token",
          JSON.stringify(response.data.token).replace(/"/g, "").trim()
        );
        
        if (response.data.is_first_login) {
          navigate("/reset-password");
        } else {
          navigate("/dashboard");
        }
        setIsCodeInValid(false);
      } else {
        setIsCodeInValid(true);
        setLoader(false);
      }
    });
  }

  function reSendOTP(e) {
    setLoader(true);
    e.preventDefault();
    let params = { email: email, password: password };
    if (!email || !password) {
      setLoader(false);
      return;
    }
    dispatch(auth.sendOtp(params)).then((response) => {
      setLoader(false);
      if (response.type === "OTP_RECEIVED_SUCCESS") {
        Notify(
          "success",
          `One-Time Passcode (OTP) resent successfully.`
          // <em className="icon-folder-download-filled"></em>
        );
      } else {
      }
    });
  }

  return (
    <>
      {loader && <Loading active={loader} />}
      <form name="form" onSubmit={handleSubmit}>
        <div
          className={`form-group ${
            submitted && !verificationCode ? "has-error" : ""
          }  `}
        >
          <label className="form-label">
            Verification Code <sup>*</sup>
          </label>

          <ReactCodeInput
            values={[...verificationCode]}
            fields={8}
            onChange={(newValue) => {
              setVerificationCode(newValue);
              setSubmitted(false);
              setIsCodeInValid(false);
            }}
          />
          {submitted && !verificationCode && (
            <div className="invalid-feedback d-block">
              Verification Code is required
            </div>
          )}
          {submitted && verificationCode && isCodeInValid && (
            <div className="invalid-feedback d-block">
              {authentication.error.detail}
            </div>
          )}
        </div>
        <div className="mb-16">
          <button className="btn btn-success w-100">Verify Code</button>
        </div>
        <div className="text-center mb-8">
          <Link
            to="/forgot-password-verify"
            className="btn-link fs-18 lh-32"
            onClick={reSendOTP}
          >
            Resend Code
          </Link>
        </div>
      </form>
    </>
  );
};

export default LoginCodeVerify;
