// Tanner Fry
// tfry@monetagroup.com
// File contains the header component for the Twilio Conversations dashboard.

import { capitalizeFirstLetter, copyToClipboard } from "../../components/UtilitiesTS";
import { ChatType } from "../../interfaces/General";
import { useAppSelectorTS } from "../../redux/hooksTS";
import { reduxSelectIsDarkMode } from "../../redux/slicesTS/UsersSlice";
import TCUtilities from "./TwilioConversationUtilities";

interface ITwilioHeaderProps {
    chatType: string;
    advisor_or_staff_number: string;
}

const TwilioHeader = ({
    chatType,
    advisor_or_staff_number,
} : ITwilioHeaderProps) => {
    const isDarkMode = useAppSelectorTS(reduxSelectIsDarkMode);

    return (
        <div>
            <div className="d-flex align-items-center">
                <span className="head-icon me-16">
                    <em className="icon-chat-alt-2-filled"></em>
                </span>
                <h4 className="m-0" style={{color: isDarkMode ? "#fff" : ""}}>{ capitalizeFirstLetter(chatType) }</h4>
            </div>

            {advisor_or_staff_number && advisor_or_staff_number != "" && chatType == ChatType.TEXT && (
                <div className="advisor-number">
                    Your assigned text number: {TCUtilities.formatPhoneNumber(advisor_or_staff_number)}
                    <button 
                        className="btn-icon-info-plain"
                        style={{color: "#fff", paddingRight: 'unset'}}
                        onClick={() => {
                            copyToClipboard(advisor_or_staff_number)
                        }}
                    >
                        <em className="icon-clipboard-copy-outline"/>
                    </button>
                </div>
            )}
            {/* <GlobalSearch
                userId={userId}
                redirectToMessage={(channel, message_id) => {
                    setchannel(channel);
                    setchannelUrl(channel?.channel_url);
                    setHighlightedMessage(message_id);
                }}
            /> */}
            {/* <div className="d-flex">
                <span className="icon-32">
                    <em className="icon-search-outline fs-24"></em>
                </span>
            </div> */}
        </div>
    );
}

export default TwilioHeader;