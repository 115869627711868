// Tanner Fry
// tfry@monetagroup.com
// File contains utilities for Twilio Conversations.

import { Client, State } from '@twilio/conversations';
import { convertISOToTime } from "../../components/UtilitiesTS";
import { ITwilioConversationsMessageProps } from '../../interfaces/TwilioConversations';

interface DateTimeFormatOptions {
    localeMatcher?: "best fit" | "lookup" | undefined;
    weekday?: "long" | "short" | "narrow" | undefined;
    era?: "long" | "short" | "narrow" | undefined;
    year?: "numeric" | "2-digit" | undefined;
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
    day?: "numeric" | "2-digit" | undefined;
    hour?: "numeric" | "2-digit" | undefined;
    minute?: "numeric" | "2-digit" | undefined;
    second?: "numeric" | "2-digit" | undefined;
    timeZoneName?: "short" | "long" | "shortOffset" | "longOffset" | "shortGeneric" | "longGeneric" | undefined;
    formatMatcher?: "best fit" | "basic" | undefined;
    hour12?: boolean | undefined;
    timeZone?: string | undefined;
}

// General Conversation Utilities

const TCUtilities = {
    // Get the Twilio client and set it up
    getTwilioClient: (token: string): Promise<Client> => {
        return new Promise((resolve, reject) => {
            const client: Client = new Client(token.replace(/^b'/, '').replace(/'$/, ''));
            client.on('stateChanged', (state: State) => {
                if (state === 'failed') {
                    reject(new Error('Twilio client failed to initialize'));
                }

                if (state === 'initialized') {
                    resolve(client);
                }
            });
        });
    },

    // Add target blank for chat links
    addTargetBlankToLinks: (htmlLink: string): string => {
        return htmlLink.replace(/<a /g, '<a target="_blank" ');
    },
    
    // Escape HTML to prevent injection
    escapeHtml: (unsafe: string): string => {
        // Not sure if this really does much
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    },

    // Format given string phone number
    // Desired format: +1 (123) 456-7890
    // +[country code] ([area code]) [local number]
    formatPhoneNumber: (phoneNumber: string): string => {
        // Check if the phone number is a US number. If not, return and don't format 
        if (!phoneNumber) {
            return '';
        } else if (!phoneNumber.startsWith('+1')) {
            return phoneNumber;
        }

        // Format number
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = match[1] ? '+1 ' : '';
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }

        return phoneNumber;
    },
    
    // Return a date separator if the current message doesn't have the same date as the previous message
    getDateSeparator: (currentMessage: ITwilioConversationsMessageProps, previousMessage?: ITwilioConversationsMessageProps): string => {
        const currentMessageDate = new Date(currentMessage.created_at);
        const previousMessageDate = previousMessage ? new Date(previousMessage.created_at) : null;
    
        // Helper function to check if two dates are the same day
        const isSameDay = (date1: Date, date2: Date) => {
            return date1.getDate() === date2.getDate() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getFullYear() === date2.getFullYear();
        };
    
        // Helper function to format date
        const formatDate = (date: Date) => {
            return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
        };
    
        // If there is no previous message, return the current message date
        if (!previousMessageDate || !isSameDay(currentMessageDate, previousMessageDate)) {
            // Check if the current message date is today
            const currentDate = new Date();
            if (isSameDay(currentMessageDate, currentDate)) {
                return 'Today';
            } else if (isSameDay(currentMessageDate, new Date(currentDate.setDate(currentDate.getDate() - 1)))) {
                return 'Yesterday';
            } else {
                return formatDate(currentMessageDate);
            }
        }
    
        return "";
    },
    
    // Return the last four digits of a phone number
    getLastFourOfPhoneNumber: (number:string): string | null => {
        if (number == "") {
            return null
        }

        return number.slice(-4);
    },
    
    
    // Return the time of the message in 12 hour format if the message is from the current day. Otherwise return
    // yesterday or the date of the message. 
    getMessageTime: (
        messageDateTime: string, 
        dateTimeFormatOptions: DateTimeFormatOptions = {
            month: "long",
            day: "numeric",
            year: "numeric"
        }
    ): string => {
        const messageDate = new Date(messageDateTime);
        const currentDate = new Date();
        const yesterday = new Date(currentDate.getDate() - 1);
        
        if (messageDate.getDate() === currentDate.getDate() &&
            messageDate.getMonth() === currentDate.getMonth() &&
            messageDate.getFullYear() === currentDate.getFullYear()) {
            return convertISOToTime(messageDateTime);
        } else if (messageDate.getDate() === yesterday.getDate() &&
            messageDate.getMonth() === yesterday.getMonth() &&
            messageDate.getFullYear() === yesterday.getFullYear()) {
            return 'Yesterday';
        } else {
            return messageDate.toLocaleDateString('en-US', { month: dateTimeFormatOptions.month, day: dateTimeFormatOptions.day, year: dateTimeFormatOptions.year });
        }
    },
    
    // Check if the message is from the current user
    isMessageFromCurrentUser: (message: ITwilioConversationsMessageProps, currentUserParticipantSid: string): boolean => {
        return message.participant_sid === currentUserParticipantSid;
    },
    
    // Preserve special characters in Twilio message with HTML elements
    preserveSpecialCharactersInTwilioMessageWithHTMLElements: (message: string): string => {
        // Escape HTML to prevent injection
        message = TCUtilities.escapeHtml(message);
        // Newline
        message = message.replace(/\n/g, '<br>');
        // URL
        message = message.replace(/(https?:\/\/[^\s<]+)/g, '<a href="$1" target="_blank">$1</a>');
    
        return message;
    },
}

export default TCUtilities;