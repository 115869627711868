import { shareFileConstants } from '../../constants/ShareFileConstant';

const INITIAL_STATE = {
  data: {},
  error: {},
}

const AddtoFavourite = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case shareFileConstants.ADD_FAVOURITE_SUCCESS:
    return {...INITIAL_STATE , data: action.data };
  case shareFileConstants.ADD_FAVOURITE_FAILURE:
    return {...INITIAL_STATE , error: action.error };
  default:
    return state
  }
}

export default AddtoFavourite;