import axiosInstance from "../../../config/axios";
import { clientUserConstants } from "../../constants/ClientUserConstant";
import { utility } from "../../../components/Utility";

export const clientUser = {
  getHouseHoldList,
  getClientHouseHoldList,
  getHouseHoldDetails,
  getHouseHoldClientDetails,
  getNextHouseHoldList,
  getNotificationPreferences,
  createUser,
  setUserPreference,
  getClientList,
  getUserDetails,
  deactivateUser,
  resendUserLink,
  reactivateUser,
  resetPasswordLink,
  updateUserDetails,
  getNotificationPreferencesById,
  verifyEmail,
  getFilterData,
  getNextClientList,
  getHouseHoldSearchList,
  getNotificationPreferencesByTeamId,
  setTeamPreference,
  get_team_details,
  teamList,
  checkEmailMobile,
  getInvestmentToggleButtonPermission,
  createToggleOfInvestmentTab,
};
function getClientList(querryParameters) {
  return (dispatch) => {
    return axiosInstance
      .get(`/household/client-users` + querryParameters)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_CLIENT_USER_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_CLIENT_USER_LIST_FAILURE, error };
  }
}

function getNextClientList(next) {
  return (dispatch) => {
    return axiosInstance
      .get(next)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_CLIENT_USER_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_CLIENT_USER_LIST_FAILURE, error };
  }
}
function getFilterData() {
  return (dispatch) => {
    return axiosInstance
      .get(`/household/filter-data`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_FILTER_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_FILTER_LIST_FAILURE, error };
  }
}

function getHouseHoldList() {
  return (dispatch) => {
    return axiosInstance
      .get(`/household`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_HOUSE_HOLD_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_HOUSE_HOLD_LIST_FAILURE, error };
  }
}

function getHouseHoldSearchList(value) {
  return (dispatch) => {
    return axiosInstance
      .get(`/household?search=`+value)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_HOUSE_HOLD_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_HOUSE_HOLD_LIST_FAILURE, error };
  }
}

function getNextHouseHoldList(next) {
  return (dispatch) => {
    return axiosInstance
      .get(next)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_HOUSE_HOLD_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_HOUSE_HOLD_LIST_FAILURE, error };
  }
}

function getClientHouseHoldList(uuid) {
  return (dispatch) => {
    return axiosInstance
      .get(`/household/${uuid}/clients`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return {
      type: clientUserConstants.GET_CLIENT_HOUSE_HOLD_LIST_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: clientUserConstants.GET_CLIENT_HOUSE_HOLD_LIST_FAILURE,
      error,
    };
  }
}

function getHouseHoldDetails(uuid) {
  return (dispatch) => {
    return axiosInstance
      .get(`/household/${uuid}`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return {
      type: clientUserConstants.GET_HOUSE_HOLD_DETAILS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: clientUserConstants.GET_HOUSE_HOLD_DETAILS_FAILURE,
      error,
    };
  }
}

function getHouseHoldClientDetails(uuid) {
  return (dispatch) => {
    return axiosInstance
      .get(`/household/client-details/${uuid}`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return {
      type: clientUserConstants.GET_HOUSE_HOLD_CLIENT_DETAILS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: clientUserConstants.GET_HOUSE_HOLD_CLIENT_DETAILS_FAILURE,
      error,
    };
  }
}

function getNotificationPreferences(id) {
  return (dispatch) => {
    return axiosInstance
      .get(`/user-preferences?team_id=`+id)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_NOTIFICATION_FAILURE, error };
  }
}

function createUser(uuid, param) {
  return (dispatch) => {
    return axiosInstance
      .post(`/household/${uuid}/create-client`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.CREATE_USER_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.CREATE_USER_FAILURE, error };
  }
}

function setUserPreference(param,isReset=false) {
  if(isReset)
  {
    param?.preferences?.map((c) => {
      c.reset_default=isReset;
    });
  }
  return (dispatch) => {
    return axiosInstance
      .post(`/user-preferences/set`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.SET_NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.SET_NOTIFICATION_FAILURE, error };
  }
}

function setTeamPreference(param,preference_id) {
  return (dispatch) => {
    return axiosInstance
      .post(`/user-preferences/${preference_id}/set-team-preference`, param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.SET_NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.SET_NOTIFICATION_FAILURE, error };
  }
}
function getUserDetails(uuid) {
  return (dispatch) => {
    return axiosInstance
      .get(`/household/user-details?user_id=${uuid}`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return {
      type: clientUserConstants.GET_USER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: clientUserConstants.GET_USER_FAILURE,
      error,
    };
  }
}

function deactivateUser(param) {
  return (dispatch) => {
    return axiosInstance
      .post("/household/deactivate-client", param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return {
      type: clientUserConstants.DEACTIVATE_USER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: clientUserConstants.DEACTIVATE_USER_FAILURE,
      error,
    };
  }
}

function resendUserLink(param) {
  return (dispatch) => {
    return axiosInstance
      .post("/household/resend-invite-link", param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return {
      type: clientUserConstants.RESEND_LINK_USER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: clientUserConstants.RESEND_LINK_USER_FAILURE,
      error,
    };
  }
}

function reactivateUser(param) {
  return (dispatch) => {
    return axiosInstance
      .post("/household/reactivate-client", param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return {
      type: clientUserConstants.ACTIVATE_USER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: clientUserConstants.ACTIVATE_USER_FAILURE,
      error,
    };
  }
}

function resetPasswordLink(param) {
  return (dispatch) => {
    return axiosInstance
      .post("/household/reset-password-link", param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return {
      type: clientUserConstants.RESET_PASSWORD_LINK_USER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: clientUserConstants.RESET_PASSWORD_LINK_USER_FAILURE,
      error,
    };
  }
}

function updateUserDetails(param) {
  return (dispatch) => {
    return axiosInstance
      .post("/household/update-client", param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return {
      type: clientUserConstants.UPDATE_USER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: clientUserConstants.UPDATE_USER_FAILURE,
      error,
    };
  }
}

function get_team_details() {
  return (dispatch) => {
    return axiosInstance
      .get(`/user-preferences/get-team-details`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_TEAM_DETAILS_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_TEAM_DETAILS_FAILURE, error };
  }
}

function getNotificationPreferencesByTeamId(uuid) {
  return (dispatch) => {
    return axiosInstance
      .get(`/user-preferences?team_id=` + uuid)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_NOTIFICATION_FAILURE, error };
  }
}

function getNotificationPreferencesById(uuid) {
  return (dispatch) => {
    return axiosInstance
      .get(`/user-preferences?user_id=` + uuid)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_NOTIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_NOTIFICATION_FAILURE, error };
  }
}

function verifyEmail(param) {
  return (dispatch) => {
    return axiosInstance
      .post("/household/verify-client", param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.VERIFY_USER_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.VERIFY_USER_FAILURE, error };
  }
}

function teamList() {
  return (dispatch) => {
    return axiosInstance
      .get("/team")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_TEAM_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_TEAM_LIST_FAILURE, error };
  }
}

function checkEmailMobile(payload, id) {
  return (dispatch) => {
    return axiosInstance
      .post(`/household/`+id+`/verify-client-email`, payload)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.POST_CHECK_EMAILMOBILE_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.POST_CHECK_EMAILMOBILE_FAILURE, error };
  }
}

function getInvestmentToggleButtonPermission(salesforce_id, user_uuid=null) {
  return (dispatch) => {
    return axiosInstance
      .get(user_uuid?`/client-investment/fetch-client-investment?salesforce_id=${salesforce_id}&user_uuid=${user_uuid}`:
      `/client-investment/fetch-client-investment?salesforce_id=${salesforce_id}`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.GET_INVESTMENT_TOGGLE_BUTTON_PERMISSION_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.GET_INVESTMENT_TOGGLE_BUTTON_PERMISSION_FAILURE, error };
  }
}

function createToggleOfInvestmentTab(payload) {
  return (dispatch) => {
    return axiosInstance
      .post(`/client-investment/client_investment_toggle`, payload)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: clientUserConstants.CREATE_INVESTMENT_TOGGLE_ENTRY_SUCCESS, data };
  }

  function failure(error) {
    return { type: clientUserConstants.CREATE_INVESTMENT_TOGGLE_ENTRY_FAILURE, error };
  }
}
