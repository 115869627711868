import React, { useState } from "react";
import Avatar from "react-avatar";
import { utility } from "../../components/Utility";

const FilterDropdown = ({
  dropdowndata,
  setadvisorTeamData,
  setSelected,
  selected,
  dropDownAssignedto,
  setDropDownAssignedto,
  setRemoved,
}) => {
  const [state, setState] = useState({
    searchText: "",
    filterList: dropdowndata,
  });
  const { searchText, filterList } = state;

  return (
    <div className="dropdown-content mt-4">
      <div className="px-24 py-md-8 py-12 border-mt-top">
        <div className="search-area">
          <input
            className="form-control"
            value={searchText}
            onChange={(e) => {
              let lst = dropdowndata?.filter(
                (c) =>
                  e.target.value === "" ||
                  (
                    c.last_name?.toLowerCase() +
                    ", " +
                    c.first_name?.toLowerCase()
                  ).includes(e.target.value?.toLowerCase())
              );
              setState((s) => ({
                ...s,
                searchText: e.target.value,
                filterList: lst,
              }));
            }}
          />
          {searchText === "" && (
            <button className="search-btn icon-20">
              <em className="icon-search-outline"></em>
            </button>
          )}
          {searchText !== "" && (
            <button
              className="close-search-btn icon-20"
              onClick={(event) => {
                let lst = dropdowndata;
                setState((s) => ({
                  ...s,
                  searchText: "",
                  filterList: lst,
                }));
              }}
            >
              <em className="icon-x-circle-filled"></em>
            </button>
          )}
        </div>
      </div>
      <ul className="check-list list-unstyled custom-style-scroll">
        {filterList &&
          filterList?.map((client, indx) => (
            <li
              key={indx + client?.last_name}
              className={`${client.isSelected ? "selected" : ""}`}
            >
              <div className="form-check mb-0 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="Checkbox"
                  checked={client.isSelected}
                  id={client.last_name}
                  onChange={(e) => {
                    let indx = dropdowndata?.findIndex(
                      (c) => c.uuid === client.uuid
                    );
                    dropdowndata[indx].isSelected = e?.target?.checked;
                    setadvisorTeamData(dropdowndata);
                    if (dropdowndata[indx].isSelected === true) {
                      setDropDownAssignedto([
                        ...dropDownAssignedto,
                        {"name":(dropdowndata[indx]?.last_name !== null? dropdowndata[indx]?.last_name + ", ":"") +
                          " " +
                          dropdowndata[indx]?.first_name,"image":dropdowndata[indx]?.profile_image, "uuid":dropdowndata[indx]?.user_id},
                      ]);
                      setSelected([
                        ...selected,
                        {
                          value: dropdowndata[indx]?.user_id,
                          label:
                            (dropdowndata[indx]?.last_name !== null? dropdowndata[indx]?.last_name + ", ":"") +
                            " " +
                            dropdowndata[indx]?.first_name,
                        },
                      ]);
                      setRemoved("");
                    } else {
                      var toRemove =
                      (dropdowndata[indx]?.last_name !== null? dropdowndata[indx]?.last_name + ", ":"") +
                        " " +
                        dropdowndata[indx]?.first_name;
                      // var index = dropDownAssignedto.indexOf(toRemove);
                      var index = dropDownAssignedto.findIndex(item => item.name === toRemove);
                      if (index > -1) {
                        //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                        const removed = dropDownAssignedto.splice(index, 1);
                        setRemoved(removed[0]);
                        setDropDownAssignedto([...dropDownAssignedto]);
                      }
                      const filtered = selected.filter((filter) => {
                        return filter.value !== dropdowndata[indx].user_id;
                      });
                      setSelected(filtered);
                    }
                    indx = filterList?.findIndex((c) => c.uuid === client.uuid);
                    filterList[indx].isSelected = e?.target?.checked;
                    setState((s) => ({
                      ...s,
                      filterList: filterList,
                    }));
                  }}
                />
                <div className="user-photo icon-32 mx-8">
                  {client.profile_image !== "" && client.profile_image !== null ? (
                        <img
                          src={client.profile_image}
                          alt=""
                        />
                    ) : (
                      <Avatar
                        color="lightgrey"
                        name={client?.first_name + " " + (client?.last_name !== null? client?.last_name:"")}
                        size={40}
                      />

                  )}
                </div>
                <label className="form-check-label" htmlFor="filtclient2">
                  {searchText
                    ? utility.getHighlightedText(
                      (client?.last_name !== null? client?.last_name + ", ":"") + " " + client?.first_name,
                      searchText
                    )
                    : (client?.last_name !== null? client?.last_name + ", ":"") + " " + client?.first_name}
                </label>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default FilterDropdown;
