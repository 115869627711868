// Tanner Fry
// tfry@monetagroup.com
// File contains redux actions for Twilio Conversations.

import { Dispatch } from "react";
import { utility } from "../../../components/Utility";
import axiosInstance from "../../../config/axiosTS";
import { twilioConversationsConstants } from "../../constants/TwilioConversationsConstant";

function GetAdvisorList() {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.get("/user/advisory_team")
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log(error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.GET_ADVISOR_LIST_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.GET_ADVISOR_LIST_FAILURE, error };
	}
}

function PostNewChatConversation(conversationName: string, conversationIdentities: string[]) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/new-chat-conversation", { conversation_name: conversationName, conversation_identities: conversationIdentities })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("PostNewChatConversation error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.POST_NEW_CHAT_CONVERSATION_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.POST_NEW_CHAT_CONVERSATION_FAILURE, error };
	}
}

function PostNewTextConversation(conversationName: string, textNumber: string, advisorIdentity: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/new-text-conversation", { conversation_name: conversationName, text_number: textNumber, identity: advisorIdentity }) 
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response.status === 409) {
					console.log("PostNewTextConversation conflict: ", error.response.data, " \nmake sure you handle this!");
					return dispatch(conflict(error.response.data));
				}
				if (error.response) {
					console.log("PostNewTextConversation error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.POST_NEW_TEXT_CONVERSATION_SUCCESS, data };
	}

	function conflict(data: any) {
		return { type: twilioConversationsConstants.POST_NEW_TEXT_CONVERSATION_CONFLICT, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.POST_NEW_TEXT_CONVERSATION_FAILURE, error };
	}
}

function PostDeleteTextParticipantFromConversation(conversationSid: string, participantSid: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/delete-text-participant-from-conversation", { conversation_sid: conversationSid, participant_sid: participantSid })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("PostDeleteTextParticipantFromConversation error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.POST_DELETE_TEXT_PARTICIPANT_FROM_CONVERSATION_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.POST_DELETE_TEXT_PARTICIPANT_FROM_CONVERSATION_FAILURE, error };
	}
}

function GetUserChatConversations(identity: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/get-user-conversations", { identity: identity })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("GetUserChatConversations error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.GET_USER_CONVERSATIONS_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.GET_USER_CONVERSATIONS_FAILURE, error };
	}
}

function PostNewConversationMessage(conversationId: string, message: string, participantSid: string, participantName: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/new-conversation-message", { conversation_id: conversationId, conversation_message: message, participant_sid: participantSid, participant_name: participantName })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					if (error.response.status === 409) {
						console.log("PostNewConversationMessage conflict: ", error.response.data, " \nmake sure you handle this!");
						return dispatch(conflict(error.response.data));
					}
					console.log("PostNewConversationMessage error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.POST_NEW_CONVERSATION_MESSAGE_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.POST_NEW_CONVERSATION_MESSAGE_FAILURE, error };
	}

	function conflict(data: any) {
		return { type: twilioConversationsConstants.POST_NEW_CONVERSATION_MESSAGE_CONFLICT, data };
	}
}

function GetUserConversationMessages(conversationId: string, page: number = 1, limit: number = 20) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/get-conversation-messages", { conversation_id: conversationId, page: page, limit: limit })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("GetUserConversationMessages error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE, error };
	}
}

function PostLeaveUserChatConversation(conversationId: string, participantSid: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/leave-conversation", { conversation_id: conversationId, participant_sid: participantSid })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("PostLeaveUserChatConversation error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.POST_LEAVE_CONVERSATION_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.POST_LEAVE_CONVERSATION_FAILURE, error };
	}
}

function PostMuteUserChatConversation(conversationId: string, identity: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/mute-conversation", { conversation_id: conversationId, identity: identity })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("PostMuteUserChatConversation error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.POST_MUTE_CONVERSATION_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.POST_MUTE_CONVERSATION_FAILURE, error };
	}
}

function UpdateUserChatConversationName(conversationId: string, conversationName: string, identity: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/change-conversation-name", { conversation_id: conversationId, new_conversation_name: conversationName, identity: identity })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("UpdateUserChatConversationName error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.UPDATE_CONVERSATION_NAME_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.UPDATE_CONVERSATION_NAME_FAILURE, error };
	}
}

function PostKickChatUserFromConversation(conversationId: string, participantSid: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/kick-chat-participant-from-conversation", { conversation_id: conversationId, participant_sid: participantSid })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("PostKickChatUserFromConversation error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.POST_KICK_CHAT_USER_FROM_CONVERSATION_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.POST_KICK_CHAT_USER_FROM_CONVERSATION_FAILURE, error };
	}
}

function PostKickSMSUserFromConversation(conversationId: string, participantSid: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/kick-sms-participant-from-conversation", { conversation_id: conversationId, participant_sid: participantSid })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("PostKickSMSUserFromConversation error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.POST_KICK_SMS_USER_FROM_CONVERSATION_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.POST_KICK_SMS_USER_FROM_CONVERSATION_FAILURE, error };
	}
}

function PostUserReadConversation(conversationId: string, participantSid: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/read-conversation", { conversation_id: conversationId, participant_sid: participantSid })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("PostUserReadConversation error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.POST_USER_READ_CONVERSATION_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.POST_USER_READ_CONVERSATION_FAILURE, error };
	}
}

function GetUserTotalUnreadMessageCounts(identity: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/unread-message-count-totals", { twilio_identity: identity })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("GetUserTotalUnreadMessageCounts error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.GET_USER_UNREAD_MESSAGE_COUNT_TOTALS_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.GET_USER_UNREAD_MESSAGE_COUNT_TOTALS_FAILURE, error };
	}
}

function UpdateUserMessageReadBy(message_sid: string, identity: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/unread-message-update-read-by", { message_sid: message_sid, twilio_identity: identity })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("UpdateUserMessageReadyBy error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.UPDATE_USER_MESSAGE_READ_BY_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.UPDATE_USER_MESSAGE_READ_BY_FAILURE, error };
	}
}

function GetAllowedTextUsers() {
    return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.get("/twilio/allowed-tc-text-users")
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("GetAllowedTextUsers error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.GET_ALLOWED_TC_TEXT_USERS_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.GET_ALLOWED_TC_TEXT_USERS_FAILURE, error };
	}
}

function GetAllowedChatUsers() {
    return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.get("/twilio/allowed-tc-chat-users")
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("GetAllowedChatUsers error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.GET_ALLOWED_TC_CHAT_USERS_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.GET_ALLOWED_TC_CHAT_USERS_FAILURE, error };
	}
}

function PostValidateLegitimacyOfPhoneNumber(phoneNumber: string) {
	return (dispatch: Dispatch<any>): Promise<void> => {
		return axiosInstance
			.post("/twilio/validate-legitimacy-of-phone-number", { phone_number: phoneNumber })
			.then((response) => {
				return dispatch(success(response.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 401 ||
					error?.response?.status === 403
				) {
					utility.handleAuthError();
				}
				if (error.response) {
					console.log("PostValidateLegitimacyOfPhoneNumber error: ", error.response.data);
					return dispatch(failure(error.response.data));
				}
			});
	};
	function success(data: any) {
		return { type: twilioConversationsConstants.POST_VALIDATE_LEGITIMACY_OF_PHONE_NUMBER_SUCCESS, data };
	}

	function failure(error: any) {
		return { type: twilioConversationsConstants.POST_VALIDATE_LEGITIMACY_OF_PHONE_NUMBER_FAILURE, error };
	}
}

export const TwilioConversationsRedux = {
	GetAdvisorList,
	PostNewChatConversation,
	PostNewTextConversation,
	PostDeleteTextParticipantFromConversation,
	GetUserChatConversations,
	PostNewConversationMessage,
	GetUserConversationMessages,
	PostLeaveUserChatConversation,
	PostMuteUserChatConversation,
	UpdateUserChatConversationName,
	PostKickChatUserFromConversation,
	PostKickSMSUserFromConversation,
	PostUserReadConversation,
	GetUserTotalUnreadMessageCounts,
	UpdateUserMessageReadBy,
	GetAllowedTextUsers,
	GetAllowedChatUsers,
	PostValidateLegitimacyOfPhoneNumber,
}