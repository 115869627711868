import { createSlice } from '@reduxjs/toolkit';

interface IAdvisorList {
    data: any[];
}

export interface ITwilioConversationsSlice {
    advisorList: {
        data: {
            docusign_data: {

            },
            email: string,
            first_name: string,
            household: number,
            household_sf_id: string,
            international_calling_codes: string,
            is_active: boolean,
            is_selected: boolean,
            last_name: string,
            phone_number: string,
            profile_image: string,
            sendbird_user_id: string,
            twilio_conversations_user_identity: string,
            twilio_conversations_user_token: string,
            user_id: string,
            user_type: string,
            uuid: string,
        }[]
    };
    loadedConversationsPreSendMessage: {
        conversation_sid: string,
        message: string,
    }[]
}

const initialState: ITwilioConversationsSlice = {
    advisorList: {
        data: []
    },
    loadedConversationsPreSendMessage: []
};

// @ts-ignore
const twilioConversationsSlice = createSlice({
    name: 'twilioConversationsSlice',
    initialState,
    reducers: {
        reduxSetAdvisorList: (state, action) => {
            state.advisorList = action.payload;
        },
        reduxSetLoadedConversationsPreSendMessage: (state, action) => {
            state.loadedConversationsPreSendMessage = action.payload;
        }
    },
});

export const { reduxSetAdvisorList, reduxSetLoadedConversationsPreSendMessage } = twilioConversationsSlice.actions;

export default twilioConversationsSlice.reducer;