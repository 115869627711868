import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation, Navigate} from 'react-router-dom'
import axiosInstance from "../../config/axios";

const BlankRoute = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const useQuery= () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  useEffect(() => {
    localStorage.setItem("token",query.get("token"));
    localStorage.setItem("uid",query.get("uid"));
    localStorage.setItem("user",true);
    handleSubmit();
         
  }, []);


  function handleSubmit() {
    axiosInstance.get(`/user/` +localStorage.getItem("uid") + `?sso_login=`+true)
      .then((response) => {
        localStorage.setItem("userDetails",JSON.stringify(response.data)); 
        // setUser(response.data);
        
        navigate("/dashboard");
          
      })
      .catch((error) => {
        //window.location.assign('/')
      });
  }

  return (
    <>
      {/* <div>This is Blank Route</div> */}
    </>
  );
}

export default BlankRoute;
