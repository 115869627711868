export const clientUserConstants = {
  GET_HOUSE_HOLD_LIST_SUCCESS: "GET_HOUSE_HOLD_LIST_SUCCESS",
  GET_HOUSE_HOLD_LIST_FAILURE: "GET_HOUSE_HOLD_LIST_FAILURE",

  GET_CLIENT_HOUSE_HOLD_LIST_SUCCESS: "GET_CLIENT_HOUSE_HOLD_LIST_SUCCESS",
  GET_CLIENT_HOUSE_HOLD_LIST_FAILURE: "GET_CLIENT_HOUSE_HOLD_LIST_FAILURE",

  GET_HOUSE_HOLD_DETAILS_SUCCESS: "GET_HOUSE_HOLD_DETAILS_SUCCESS",
  GET_HOUSE_HOLD_DETAILS_FAILURE: "GET_HOUSE_HOLD_DETAILS_FAILURE",

  
  GET_HOUSE_HOLD_CLIENT_DETAILS_SUCCESS: "GET_HOUSE_HOLD_CLIENT_DETAILS_SUCCESS",
  GET_HOUSE_HOLD_CLIENT_DETAILS_FAILURE: "GET_HOUSE_HOLD_CLIENT_DETAILS_FAILURE",

  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILURE: "GET_NOTIFICATION_FAILURE",

  GET_TEAM_DETAILS_SUCCESS: "GET_TEAM_DETAILS_SUCCESS",
  GET_TEAM_DETAILS_FAILURE: "GET_TEAM_DETAILS_FAILURE",

  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

  SET_NOTIFICATION_SUCCESS: "SET_NOTIFICATION_SUCCESS",
  SET_NOTIFICATION_FAILURE: "SET_NOTIFICATION_FAILURE",

  GET_CLIENT_USER_LIST_SUCCESS: "GET_CLIENT_USER_LIST_SUCCESS",
  GET_CLIENT_USER_LIST_FAILURE: "GET_CLIENT_USER_LIST_FAILURE",

  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",

  
  DEACTIVATE_USER_SUCCESS: "DEACTIVATE_USER_SUCCESS",
  DEACTIVATE_USER_FAILURE: "DEACTIVATE_USER_FAILURE",
  
  ACTIVATE_USER_SUCCESS: "ACTIVATE_USER_SUCCESS",
  ACTIVATE_USER_FAILURE: "ACTIVATE_USER_FAILURE",

  RESEND_LINK_USER_SUCCESS: "RESEND_LINK_USER_SUCCESS",
  RESEND_LINK_USER_FAILURE: "RESEND_LINK_USER_FAILURE",

  RESET_PASSWORD_LINK_USER_SUCCESS: "RESET_PASSWORD_LINK_USER_SUCCESS",
  RESET_PASSWORD_LINK_USER_FAILURE: "RESET_PASSWORD_LINK_USER_FAILURE",

  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  VERIFY_USER_SUCCESS: "VERIFY_USER_SUCCESS",
  VERIFY_USER_FAILURE: "VERIFY_USER_FAILURE",

  GET_FILTER_LIST_SUCCESS: "GET_FILTER_LIST_SUCCESS",
  GET_FILTER_LIST_FAILURE: "GET_FILTER_LIST_FAILURE",

  GET_TEAM_LIST_SUCCESS: "GET_TEAM_LIST_SUCCESS",
  GET_TEAM_LIST_FAILURE: "GET_TEAM_LIST_FAILURE",
  POST_CHECK_EMAILMOBILE_SUCCESS: "POST_CHECK_EMAILMOBILE_SUCCESS",
  POST_CHECK_EMAILMOBILE_FAILURE: "POST_CHECK_EMAILMOBILE_FAILURE",

  GET_INVESTMENT_TOGGLE_BUTTON_PERMISSION_SUCCESS: "GET_INVESTMENT_TOGGLE_BUTTON_PERMISSION_SUCCESS",
  GET_INVESTMENT_TOGGLE_BUTTON_PERMISSION_FAILURE: "GET_INVESTMENT_TOGGLE_BUTTON_PERMISSION_FAILURE",
  CREATE_INVESTMENT_TOGGLE_ENTRY_SUCCESS: "CREATE_INVESTMENT_TOGGLE_ENTRY_SUCCESS",
  CREATE_INVESTMENT_TOGGLE_ENTRY_FAILURE: "CREATE_INVESTMENT_TOGGLE_ENTRY_FAILURE",
};
