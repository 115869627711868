import './custom.js'
import './data-dog'
import './Assets/Css/style.scss';
import 'antd/dist/antd.min.css';

import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ContextStore, store } from './redux/store';
import { ContextStoreTS, storeTS } from './redux/storeTS.tsx';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store} context={ContextStore}>
      <Provider store={storeTS} context={ContextStoreTS}>
        <App />
      </Provider>
    </Provider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);
