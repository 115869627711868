// Tanner Fry
// tfry@monetagroup.com
// File contains the interface for listing conversations.

import { Dropdown } from "antd";
import type { MenuProps } from "antd/";
import { useEffect, useState } from "react";
import { formatDateTimeInMessageAccordingToRegex, getInitials, notifyInAppError } from "../../components/UtilitiesTS";
import { ChatType, IUserDetailsProps } from "../../interfaces/General";
import { ETwilioConversationsConversationTypeProps, ITwilioConversationsConversationProps, ITwilioConversationsParticipantProps } from "../../interfaces/TwilioConversations";
import TCUtilities from "./TwilioConversationUtilities";

interface ITwilioConversationListProps {
    userConversations: ITwilioConversationsConversationProps[];
    handleNewConversationClicked: () => void;
    handleConversationClicked: (conversation: ITwilioConversationsConversationProps) => void;
    handleConversationMuteClicked: (conversation: ITwilioConversationsConversationProps) => void;
    handleConversationLeaveClicked: (conversation: ITwilioConversationsConversationProps) => void;
    setShowModalError: (show: boolean) => void;
    setShowModalErrorText: (text: string) => void;
    activeConversation: ITwilioConversationsConversationProps | null;
    chatType: string;
    userDetails: IUserDetailsProps;
}

const TwilioConversationList = ({
    userConversations, 
    handleNewConversationClicked,
    handleConversationClicked,
    handleConversationMuteClicked,
    handleConversationLeaveClicked,
    setShowModalError,
    setShowModalErrorText,
    activeConversation,
    chatType,
    userDetails,
}: ITwilioConversationListProps) => {
    const [chatConversationProfileImages, setChatConversationProfileImages] = useState<string[]>([]);
    const [textConversationProfileImages, setTextConversationProfileImages] = useState<string[]>([]);
    const [chatConversationUnreadCountsForCurrentUser, setChatConversationUnreadCountsForCurrentUser] = useState<number[]>([]);
    const [textConversationUnreadCountsForCurrentUser, setTextConversationUnreadCountsForCurrentUser] = useState<number[]>([]);
    const [currentHoveredConversation, setCurrentHoveredConversation] = useState<ITwilioConversationsConversationProps | null>(null);
    const [conversationsWithoutMessageBindingParticipants, setConversationsWithoutMessageBindingParticipants] = useState<ITwilioConversationsConversationProps[]>([]);
    const [conversationsWithMessageBindingParticipants, setConversationsWithMessageBindingParticipants] = useState<ITwilioConversationsConversationProps[]>([]);
    const conversationItemOptions: MenuProps['items'] = [
        {
            key: '1',
            onClick: (e) => {
                if (currentHoveredConversation) {
                    handleConversationMuteClicked(currentHoveredConversation);
                } else {
                    notifyInAppError("Error: No conversation was found to mute.")
                }
            },
            label: 'Mute conversation',
            disabled: true
        },
        {
            key: '2',
            onClick: (e) => {
                if (currentHoveredConversation) {
                    handleConversationLeaveClicked(currentHoveredConversation);
                } else {
                    notifyInAppError("Error: No conversation found to leave.");
                }
            },
            label: 'Leave Conversation'
        }
    ];

    useEffect(() => {
        if (userConversations.length > 0) {
            // Grab a profile image for each conversation
            let chatProfileImages: string[] = []
            let textProfileImages: string[] = []
            userConversations.map((conversation: ITwilioConversationsConversationProps) => {
                // Return the first profile image found in the conversations participants that has a profile image url that isn't empty
                const participantWithImage = conversation.participants.find((participant: any) => (                    
                    participant.profile_image && participant.profile_image !== "" && participant.profile_image !== null
                ));
                const profileImage = participantWithImage ? participantWithImage.profile_image : "";
                
                if (conversation.type === ETwilioConversationsConversationTypeProps.CHAT) {
                    chatProfileImages.push(profileImage);
                } else {
                    textProfileImages.push(profileImage);
                }
            });
            
            setChatConversationProfileImages(chatProfileImages);
            setTextConversationProfileImages(textProfileImages);

            // Count unread messages for each conversation
            let chatUnreadCounts: number[] = [];
            let textUnreadCounts: number[] = [];
            userConversations.map((conversation) => {
                let unread_count_for_conversation = 0;

                conversation.messages?.map((message) => {
                    if (!message.attributes.read_by?.includes(userDetails.twilio_conversations_user_identity)) {
                        unread_count_for_conversation += 1;
                    }
                })

                if (conversation.type === ETwilioConversationsConversationTypeProps.CHAT) {
                    chatUnreadCounts.push(unread_count_for_conversation);
                } else {
                    textUnreadCounts.push(unread_count_for_conversation);
                }
            })

            setChatConversationUnreadCountsForCurrentUser(chatUnreadCounts);
            setTextConversationUnreadCountsForCurrentUser(textUnreadCounts);

            // Filter conversations by has_text_message_binding_setup_with_participant = True
            const conversationsWithTextMessageBindingParticipants = userConversations.filter((conversation: ITwilioConversationsConversationProps) => conversation.has_text_message_binding_setup_with_participant);
            setConversationsWithMessageBindingParticipants(conversationsWithTextMessageBindingParticipants);

            // Filter conversations by has_text_message_binding_setup_with_participant = False
            const conversationsWithoutTextMessageBindingParticipants = userConversations.filter((conversation: ITwilioConversationsConversationProps) => !conversation.has_text_message_binding_setup_with_participant);
            setConversationsWithoutMessageBindingParticipants(conversationsWithoutTextMessageBindingParticipants);
        }
    }, [userConversations]);

    const handleMouseEnterConversationItem = (conversation: ITwilioConversationsConversationProps) => {
        setCurrentHoveredConversation(conversation);
    }

    const handleMouseLeaveConversationItem = (conversation: ITwilioConversationsConversationProps) => {
        setCurrentHoveredConversation(null);
    }

    const getConversationParticipantWithNumber = (conversation: ITwilioConversationsConversationProps): ITwilioConversationsParticipantProps | null => {
        let participantWithNumber: ITwilioConversationsParticipantProps | null = null;
        conversation.participants.map(participant => {
            if (participant.messaging_binding && participant.messaging_binding.address != null && participant.messaging_binding.address != "") {
                participantWithNumber = participant
            }
        })

        return participantWithNumber
    }

    return (
        <div className='twilio-conversations-list d-flex position-relative' style={{width: '320px'}}>
            <div className="conversations-header d-none d-md-flex align-items-center justify-content-between">
                <h5 className="m-0">
                    Conversations
                </h5>
                <button
                    className="create-channel-btn icon-32"
                    onClick={() => {
                        handleNewConversationClicked();
                    }}
                >
                    {" "}
                </button>
            </div>
            <div className='conversations-list flex-grow-1'>
                {userConversations.length > 0 ? (
                    <>
                        {chatType === ChatType.CHAT ? (
                            conversationsWithoutMessageBindingParticipants.map((conversation: ITwilioConversationsConversationProps, index) => {
                                
                                return (
                                    <div 
                                        key={index} 
                                        className={'conversation-item ' + (activeConversation && activeConversation.sid === conversation.sid ? 'active' : '')}
                                        onClick={() => handleConversationClicked(conversation)}
                                        onMouseEnter={() => handleMouseEnterConversationItem(conversation)}
                                        onMouseLeave={() => handleMouseLeaveConversationItem(conversation)}
                                    >
                                        <div className='conversation-item-image'>
                                            {chatConversationProfileImages.length > 0 && chatConversationProfileImages[index] != "" ? (
                                                <img src={chatConversationProfileImages[index]} alt="Profile" />
                                            ) : (
                                                <div className='conversation-item-image-initials'>
                                                    {getInitials(conversation.friendly_name)}
                                                </div>
                                            )}
                                        </div>
                                        <div className='conversation-item-details'>
                                            <h6>{conversation.friendly_name}</h6>
                                            {currentHoveredConversation && currentHoveredConversation.sid === conversation.sid ? (
                                                <div
                                                    className="dropdown ms-8"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <Dropdown
                                                        trigger={["click"]}
                                                        placement="bottomRight"
                                                        menu={{
                                                            items: conversationItemOptions,
                                                        }}
                                                    >
                                                        <span className="icon-32 cursor-pointer">
                                                            <em className="icon-dots-vertical-outline"></em>
                                                        </span>
                                                    </Dropdown>
                                                </div>
                                            ) : (
                                                <div className="message-time">
                                                    {
                                                        conversation.last_message_timestamp 
                                                        ? TCUtilities.getMessageTime(conversation.last_message_timestamp, {month: "short", day: "numeric", year: "numeric"}) 
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                            <div className="last-message-content">
                                                {conversation.messages && conversation.messages[conversation.messages.length - 1] 
                                                && conversation.messages[conversation.messages.length - 1].body ? (
                                                    conversation.messages[conversation.messages.length - 1].body
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            {chatConversationUnreadCountsForCurrentUser[index] > 0 && (
                                                <div className="unread-message-count">
                                                    {chatConversationUnreadCountsForCurrentUser[index]}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            conversationsWithMessageBindingParticipants.length > 0 ? (
                                conversationsWithMessageBindingParticipants.map((conversation: ITwilioConversationsConversationProps, index) => {
                                    return (
                                        <div 
                                            key={index} 
                                            className={'conversation-item ' + (activeConversation && activeConversation.sid === conversation.sid ? 'active' : '')}
                                            onClick={() => handleConversationClicked(conversation)}
                                            onMouseEnter={() => handleMouseEnterConversationItem(conversation)}
                                            onMouseLeave={() => handleMouseLeaveConversationItem(conversation)}
                                        >
                                            <div className='conversation-item-image'>
                                                {textConversationProfileImages.length > 0 && textConversationProfileImages[index] != "" ? (
                                                    <img src={textConversationProfileImages[index]} alt="Profile" />
                                                ) : (
                                                    <div className='conversation-item-image-initials'>
                                                        {conversation.has_text_message_binding_setup_with_participant ? (
                                                            TCUtilities.getLastFourOfPhoneNumber(
                                                                getConversationParticipantWithNumber(conversation)?.messaging_binding?.address ?? ""
                                                            ) ?? "<Undefined>"
                                                        ) : (
                                                            getConversationParticipantWithNumber(conversation)?.uuid ?? "<Undefined>"
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className='conversation-item-details'>
                                                <h6>{conversation.friendly_name}</h6>
                                                {currentHoveredConversation && currentHoveredConversation.sid === conversation.sid ? (
                                                    <div
                                                        className="dropdown ms-8"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <Dropdown
                                                            trigger={["click"]}
                                                            placement="bottomRight"
                                                            menu={{
                                                                items: conversationItemOptions,
                                                            }}
                                                        >
                                                            <span className="icon-32 cursor-pointer">
                                                                <em className="icon-dots-vertical-outline"></em>
                                                            </span>
                                                        </Dropdown>
                                                    </div>
                                                ) : (
                                                    <div className="message-time">
                                                        {
                                                            conversation.last_message_timestamp 
                                                            ? TCUtilities.getMessageTime(conversation.last_message_timestamp, {month: "short", day: "numeric", year: "numeric"}) 
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                                <div className="last-message-content">
                                                    {conversation.messages && conversation.messages[conversation.messages.length - 1] 
                                                    && conversation.messages[conversation.messages.length - 1].body ? (
                                                        <>
                                                            {conversation.messages[conversation.messages.length - 1].author == "system" ? (
                                                                <>
                                                                    {(() => {
                                                                        const formattedData = formatDateTimeInMessageAccordingToRegex(
                                                                            conversation.messages[conversation.messages.length - 1].body,
                                                                        )

                                                                        return formattedData.formattedDate + " - " + formattedData.cleanMessage
                                                                    })()}
                                                                </>
                                                            ) : (
                                                                <>{conversation.messages[conversation.messages.length - 1].body}</>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                {textConversationUnreadCountsForCurrentUser[index] > 0 && (
                                                    <div className="unread-message-count">
                                                        {textConversationUnreadCountsForCurrentUser[index]}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className='no-conversations'>
                                    <h5>No text conversations found</h5>
                                    <button
                                        className="create-channel-btn icon-32"
                                        onClick={() => {
                                            handleNewConversationClicked();
                                        }}
                                    >
                                        {" "}
                                    </button>
                                </div>
                            )
                        )}
                    </>
                ) : (
                    <div className='no-conversations'>
                        <h5>No conversations found</h5>
                        <button
                            className="create-channel-btn icon-32"
                            onClick={() => {
                                // getClientList();
                            }}
                        >
                            {" "}
                        </button>
                    </div>   
                )}
            </div>
        </div>
    );
}

export default TwilioConversationList;