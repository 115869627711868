import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { profileSetting } from "../../redux/actions/ProfileSetting/ProfileSetting";
import { notification } from "antd";
import { useAppDispatchJS } from "../../redux/hooks";

const RemoveProfilePhoto = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatchJS();

  const [showProfRemove, setProfRemoveShow] = useState(false);

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  useEffect(() => {
    const { visible, closeModal } = props;
    setProfRemoveShow(visible);
  }, [props]);

  const handleProfRemoveClose = () => {
    setProfRemoveShow(false);
  };

  function removeImageSave() {
    let param={}
    dispatch(profileSetting.imageUpload(param)).then((response) => {
      // setLoader(false);
      if (response.type === "IMAGE_UPLOAD_SUCCESS") {
        let userDetails = JSON.parse(localStorage.getItem("userDetails"));
        userDetails.profile_image=null;
        localStorage.setItem("userDetails",JSON.stringify(userDetails)); 
        Notify(
          "success",
          `Removed profile photo`,
          <em className="icon-check-outline"></em>
        );
        const { closeModal } = props;
        closeModal();
      }
    });
  }

  return (
    <>
      {" "}
      {/* Profile Remove Model */}
      <Modal
        show={showProfRemove}
        onHide={() => {
          handleProfRemoveClose;
          const { closeModal } = props;
          closeModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Remove Profile Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fs-18 lh-32 mb-md-8 mb-0 text-black">
            Are you sure you want to remove your profile photo?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            variant="secondary"
            onClick={() => {
              handleProfRemoveClose;
              const { closeModal } = props;
              closeModal();
            }}
          >
            Cancel
          </button>
          <button className="btn btn-danger" variant="primary" onClick={(e)=>{removeImageSave()}}>
            Remove
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveProfilePhoto;
