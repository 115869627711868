// Tanner Fry
// tfry@monetagroup.com
// File contains the overall interface for the compliance integration.

import { Divider, Tab, Tabs, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { Dropdown, MenuProps } from 'antd';
import { ArcElement, Chart, Tooltip as ChartTooltip, Legend } from 'chart.js';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Tooltip } from 'react-tooltip';
import dingCorrect from '../../Assets/sounds/ding-correct.mp3';
import { ModalGeneralError } from '../../components/GeneralModals';
import SoundPlayer from '../../components/SoundPlayer';
import { LoadingSpinner, notifyInAppError, notifyInAppSuccess, SystemMessage } from '../../components/UtilitiesTS';
import { IComplianceAlert, IComplianceAlertContext, IComplianceAlertWithContext } from '../../interfaces/Compliance';
import { IUserDetailsProps } from '../../interfaces/General';
import { ITwilioConversationsMessageProps } from '../../interfaces/TwilioConversations';
import { ComplianceRedux } from '../../redux/actions/Compliance/Compliance';
import { useAppDispatchTS, useAppSelectorTS } from '../../redux/hooksTS';
import { reduxSelectActiveAlerts, reduxSelectActiveAlertsWithContexts, reduxSelectTotalMessageCount, reduxSelectUniqueActiveAlerts, reduxSetActiveAlerts, reduxSetActiveAlertsContexts, reduxSetActiveAlertsWithContexts, reduxSetComplianceData, reduxSetTotalMessageCount } from '../../redux/slicesTS/ComplianceSlice';
import { reduxSelectIsDarkMode } from '../../redux/slicesTS/UsersSlice';
import TCUtilities from '../TwilioConversations/TwilioConversationUtilities';
import UnderConstruction from '../UnderConstruction/UnderConstruction';
import ComplianceHeader from './ComplianceHeader';

interface IComplianceDashboard {
    
}

interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    className?: any;
    style?: React.CSSProperties;
}

function CustomTabPanel(props: ITabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ComplianceDashboard = ({
    
}: IComplianceDashboard) => {
    // Setup
    const [complianceContentHeight, setComplianceContentHeight] = useState(0);
    const refComplianceContainer = useRef(null);
    const refComplianceContainerHeader = useRef(null);
    const [complianceTabsHeight, setComplianceTabsHeight] = useState(0);
    const refComplianceTabs = useRef(null);
    const [componentRefreshed, forceComponentRefresh] = useReducer(x => x + 1, 0);
    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const dispatch = useAppDispatchTS();
    // Themeing
    const isDarkMode = useAppSelectorTS(reduxSelectIsDarkMode);
    // Modals 
    const [showModalTwilioNewConversation, setShowModalTwilioNewConversation] = useState(false);
    const [showModalTwilioDeleteParticipantFromConversation, setShowModalTwilioDeleteParticipantFromConversation] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [showModalErrorText, setShowModalErrorText] = useState("");
    // Details
    const [userDetails, setUserDetails] = useState<IUserDetailsProps>(
        JSON.parse(localStorage.getItem("userDetails") || "")
    );
    // Switches
    const [noResponseFromServer, setNoResponseFromServer] = useState(false)
    const soundPlayerRef = useRef<{ play: () => void; pause: () => void }>(null);

    const playSoundDingCorrect = () => {
        if (soundPlayerRef.current) {
            soundPlayerRef.current.play();
        }
    };

    const pauseSoundDingCorrect = () => {
        if (soundPlayerRef.current) {
            soundPlayerRef.current.pause();
        }
    };

    // KPIs
    const allActiveAlerts = useAppSelectorTS(reduxSelectActiveAlerts);
    const allActiveAlertsWithContexts = useAppSelectorTS(reduxSelectActiveAlertsWithContexts);
    const uniqueActiveAlerts = useAppSelectorTS(reduxSelectUniqueActiveAlerts);
    const totalAlertCount = useAppSelectorTS(reduxSelectTotalMessageCount);
    const [percentOfCompliantTexts, setPercentOfCompliantTexts] = useState(0);

    // Alert functionality
    const [activeAlert, setActiveAlert] = useState<IComplianceAlertWithContext | null>(null);
    const [activeAlertMessagesTemp, setActiveAlertMessagesTemp] = useState<ITwilioConversationsMessageProps[]>([]);
    const [currentHoveredAlert, setCurrentHoveredAlert] = useState<IComplianceAlertWithContext | null>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const alertItemOptions: MenuProps['items'] = [
        {
            key: '1',
            onClick: (e) => {
                if (currentHoveredAlert) {
                    // TODO: Add "archive" functionality, granted we just set is_active to false. Maybe look into archiving functionality (another bool?)
                    // handleArchiveAlert(currentHoveredAlert);
                } else {
                    notifyInAppError("Error: No alert was found to archive.")
                }
            },
            label: 'Archive Alert',
            disabled: true
        },
        {
            key: '2',
            onClick: (e) => {
                if (currentHoveredAlert) {
                    // TODO: Add "delete" functionality, granted we just set is_active to false and is_deleted to true
                    handleAlertDelete(currentHoveredAlert);
                } else {
                    notifyInAppError("Error: No alert found to delete.");
                }
            },
            label: 'Delete Alert',
        }
    ];

    const handleAlertClicked = (alert: IComplianceAlertWithContext) => {
        if (activeAlert && activeAlert.uuid === alert.uuid) {
            setActiveAlert(null);
            setActiveAlertMessagesTemp([]);
            setSearchValue('');
        } else {
            setActiveAlert(alert);
            // Set active alert messages temp variable
            setActiveAlertMessagesTemp(alert.context.messages);
        }
    }

    const handleAlertDelete = (alert: IComplianceAlertWithContext) => {
        dispatch(ComplianceRedux.DeleteAlert(alert.uuid)).then((response: any) => {
            console.log("Alert deleted: ", response);
            notifyInAppSuccess("Alert deleted successfully.");
            // If deleted alert is the active alert, set it to null
            if (activeAlert && activeAlert.uuid === alert.uuid) {
                setActiveAlert(null);
                setActiveAlertMessagesTemp([]);
                setSearchValue('');
            }

            // Update state by removing the current alert
            dispatch(reduxSetActiveAlerts(allActiveAlerts.filter((alertItem: IComplianceAlert) => alertItem.uuid !== alert.uuid)));
            dispatch(reduxSetActiveAlertsWithContexts(allActiveAlertsWithContexts.filter((alertItem: IComplianceAlertWithContext) => alertItem.uuid !== alert.uuid)));
        }).catch((response: any) => {
            console.log("Error deleting alert: ", response.error.error);
            notifyInAppError("Error deleting alert: " + response.error.error);
            // setShowModalError(true);
            // setShowModalErrorText("Error deleting alert.");
        });
    }

    const handleMouseEnterAlertItem = (alert: IComplianceAlertWithContext) => {
        setCurrentHoveredAlert(alert);
    }

    const handleMouseLeaveAlertItem = () => {
        setCurrentHoveredAlert(null);
    }

    // Calculate height for twilio-conversations-content
    useEffect(() => {
        // TODO: Fix the layout so we don't have to calculate the height of the content and waste resources...
        const interval = setInterval(() => {
            if (refComplianceContainer.current && refComplianceContainerHeader.current && refComplianceTabs.current) {
                const twilioConversationsContainerHeight = refComplianceContainer.current['offsetHeight'];
                const twilioConversationsHeaderHeight = refComplianceContainerHeader.current['offsetHeight'];
                setComplianceContentHeight(twilioConversationsContainerHeight - twilioConversationsHeaderHeight);
                setComplianceTabsHeight(refComplianceTabs.current['offsetHeight']);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [userDetails]);

    // Update percentOfCompliantTexts when uniqueActiveAlerts or totalAlertCount changes
    useEffect(() => {
        // Make sure new values are different from current values
        if (percentOfCompliantTexts !== 100 - (uniqueActiveAlerts.length / totalAlertCount) * 100) {
            console.log("Updating percentOfCompliantTexts: ", 100 - (uniqueActiveAlerts.length / totalAlertCount) * 100);
            
            setPercentOfCompliantTexts(100 - (uniqueActiveAlerts.length / totalAlertCount) * 100);
        }
    }, [uniqueActiveAlerts, totalAlertCount]);

    // Load initial data by building all compliance objects together
    useEffect(() => {
        Chart.register(ArcElement, ChartTooltip, Legend);
        setIsRefreshing(true);
        // TODO: Setup pulling inactive alerts (alerts already read by the user) so we can allow previous alert viewing
        // Load initial compliance data
        // Load initial conversations
        dispatch(ComplianceRedux.GetAllInitialConversationData()).then((response: any) => {
            console.log("Initial conversation data: ", response);
            dispatch(reduxSetComplianceData(response.data));
            // Load active alerts
            dispatch(ComplianceRedux.GetActiveAlerts()).then((activeAlertsResponse: any) => {
                console.log("Active alerts: ", activeAlertsResponse);
                dispatch(reduxSetActiveAlerts(activeAlertsResponse.data));
                // Load total message count
                dispatch(ComplianceRedux.GetTotalMessageCount()).then(async (response: any) => {
                    console.log("Total message count: ", response);
                    dispatch(reduxSetTotalMessageCount(response.data));
                    setIsRefreshing(false);
                    setIsLoading(false);

                    // Load alert contexts
                    await Promise.all(activeAlertsResponse.data.map(async (alert: IComplianceAlert) => {
                        return await dispatch(ComplianceRedux.GetAlertContext(alert.uuid)).then((alertContextResponse: any) => {
                            // Order messages by created_at date in descending order
                            let newAlertContext: IComplianceAlertContext = {
                                alert_message: alert,
                                conversation: alertContextResponse.data.conversation,
                                participants: alertContextResponse.data.participants,
                                messages: alertContextResponse.data.messages.sort((a: any, b: any) => {
                                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                                })
                            };

                            return newAlertContext;
                        });
                    })).then((alertContextsResponse: IComplianceAlertContext[]) => {
                        console.log("Alert contexts: ", alertContextsResponse);
                        dispatch(reduxSetActiveAlertsContexts(alertContextsResponse));

                        // Create alerts with contexts and set them in the state
                        let allActiveAlertsWithContexts = activeAlertsResponse.data.map((alert: IComplianceAlert) => {
                            return {
                                ...alert,
                                context: alertContextsResponse.find((alertContext: IComplianceAlertContext) => alertContext.alert_message.uuid === alert.uuid)
                            };
                        });
                        console.log("All active alerts with contexts: ", allActiveAlertsWithContexts);
                        dispatch(reduxSetActiveAlertsWithContexts(allActiveAlertsWithContexts));
                    }).catch((response: any) => {
                        console.log("Error getting alert contexts: ", response.error.error);
                        notifyInAppError("Error getting alert contexts: " + response.error.error);
                    });

                    notifyInAppSuccess("Compliance data loaded successfully.");
                }).catch((response: any) => {
                    console.log("Error getting total message count: ", response.error.error);
                    notifyInAppError("Error getting total message count: " + response.error.error);
                    setIsRefreshing(false);
                    setIsLoading(false);
                });
            }).catch((response: any) => {
                console.log("Error getting active alerts: ", response.error.error);
                notifyInAppError("Error getting active alerts: " + response.error.error);
                setIsRefreshing(false);
                setIsLoading(false);
            });
        }).catch((response: any) => {
            console.log("Error getting initial conversation data: ", response.error.error);
            notifyInAppError("Error getting initial conversation data: " + response.error.error);
            setIsRefreshing(false);
            setIsLoading(false);
        });

    }, [componentRefreshed]);

    // Tab panel helpers and state
    const [tabPanelValue, setTabPanelValue] = useState(0);

    const handleTabPanelChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabPanelValue(newValue);
    };

    // Helper functions
    const findAndReplaceLexiconTermInMessage = (message: string, lexiconTerm: string): JSX.Element => {
        // Find the lexicon term in the message and replace it with a highlighted version
        // NOTE: the matching should work with upper and lowercase in the message
        const regex = new RegExp(lexiconTerm, "gi");

        // Break up the message into 3 parts: before the lexicon term, the lexicon term, and after the lexicon term
        const messageParts = message.split(regex);
        // Keep the found lexicon term in its original case
        const originalLexiconTerm = message.match(regex);
        return (
            <>
                {messageParts[0]}
                <span className='highlighted-lexicon-term'>{originalLexiconTerm}</span>
                {messageParts[1]}
            </>
        );
    }

    return (
        <div 
            className='compliance-container h-100 overflow-auto position-relative' 
            ref={refComplianceContainer} 
            style={{
                // Only apply background if dark mode is enabled
                backgroundColor: isDarkMode ? "#15222b" : "",
            }}
        >
            <div 
                className={"compliance-header heading-wrap d-flex align-items-center justify-content-between px-16 px-md-24"} 
                ref={refComplianceContainerHeader}
                style={{
                    // Only apply background if dark mode is enabled
                    backgroundColor: isDarkMode ? "#15222b" : "",
                }}
            >
                <ComplianceHeader refreshFunction={forceComponentRefresh} isRefreshing={isRefreshing} />
            </div>
            {isLoading ? (
                <LoadingSpinner isActive={isLoading} />
            ) : (
                <>
                    <div className='compliance-content d-flex w-100 position-relative' style={{height: complianceContentHeight}}>
                        <Box 
                            sx={{ 
                                borderBottom: 1, 
                                borderColor: 'divider',
                                boxShadow: '10px 20px 40px rgba(0, 0, 0, 0.5)',
                            }}
                            ref={refComplianceTabs}
                        >
                            <Tabs 
                                value={tabPanelValue} 
                                onChange={handleTabPanelChange} 
                                aria-label="compliance-tabs" 
                            >
                                <Tab label="Analytics and Alerts" {...a11yProps(0)} />
                                <Tab label="Conversations" {...a11yProps(1)} />
                                <Tab label="Lexicon" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel 
                            value={tabPanelValue} 
                            index={0} 
                            className={'simple-tabpanel compliance-analytics-and-alerts'}
                            style={{
                                maxHeight: complianceContentHeight - complianceTabsHeight,
                            }}
                        >
                            <div className='compliance-kpis'>
                                {/* <h5 className='compliance-kpi-title m-0'>KPIs</h5> */}
                                <div className='percent-of-compliant-texts-wrapper'>
                                    <div className='percent' data-tooltip-id='tooltip-percent-of-compliant-texts'>{percentOfCompliantTexts.toFixed(0)}%</div>
                                    <Doughnut
                                        id='chart-percent-of-compliant-texts-1'
                                        about='Compliant ranges'
                                        aria-label='Compliant ranges'
                                        // redraw={true}
                                        data={{
                                            labels: ['Poor', 'Average', 'Good'],
                                            datasets: [{
                                                label: 'Compliant range',
                                                data: [33, 33, 33],
                                                backgroundColor: [
                                                    'rgba(231, 76, 60, 1)',
                                                    'rgba(255, 164, 46, 1)',
                                                    'rgba(46, 204, 113, 1)'
                                                ],
                                                borderColor: [
                                                    'rgba(255, 255, 255 ,1)',
                                                    'rgba(255, 255, 255 ,1)',
                                                    'rgba(255, 255, 255 ,1)'
                                                ],
                                                borderWidth: 5,
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            rotation: -90,
                                            circumference: 180,
                                            cutout: '90%',
                                            plugins: {
                                                tooltip: {
                                                    callbacks: {
                                                        label: function(context) {
                                                            return context.raw + '%';
                                                        }
                                                    }
                                                },
                                                legend: {
                                                    display: false
                                                }
                                            }
                                        }}
                                    />
                                    <Doughnut
                                        id='chart-percent-of-compliant-texts-2'
                                        about='Percent of compliant texts'
                                        aria-label='Percent of compliant texts'
                                        // redraw={true}
                                        data={{
                                            labels: ['Poor', 'Average', 'Good'],
                                            datasets: [{
                                                // NOTE: If the first bar has a value of 88.5 and the second bar has a value of 
                                                // NOTE: 1 and the third bar has a value of 10.5 you will have effectively put 
                                                // NOTE: the second bar at 89%, with a width of 1% (88.5 + 1 + 10.5 = 100).
                                                data: [percentOfCompliantTexts-2, 4, 100-percentOfCompliantTexts-2],
                                                label: 'Percent of compliant texts',
                                                backgroundColor: [
                                                    "rgba(0,0,0,0)",
                                                    "rgba(255,255,255,1)",
                                                    "rgba(0,0,0,0)",
                                                ],
                                                borderColor: [
                                                    'rgba(0, 0, 0 ,0)',
                                                    percentOfCompliantTexts <= 33.33 ? 'rgba(231, 76, 60, 1)' : percentOfCompliantTexts <= 66.66 ? 'rgba(255, 164, 46, 1)' : 'rgba(46, 204, 113, 1)',
                                                    'rgba(0, 0, 0 ,0)'
                                                ],
                                                borderWidth: 3
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            rotation: -90,
                                            circumference: 180,
                                            cutout: '85%',
                                            plugins: {
                                                tooltip: {
                                                    callbacks: {
                                                        label: function(context: any) {
                                                            if (context?.dataIndex === 1) {
                                                                return ' ' + percentOfCompliantTexts.toFixed(2) + '% of messages are compliant';
                                                            } else {
                                                                return '';
                                                            }
                                                        },
                                                        title: function(context: any) {
                                                            // Check which bar is being hovered over
                                                            if (context[0]?.dataIndex === 1) {
                                                                return percentOfCompliantTexts <= 33.33 ? 'Poor' : percentOfCompliantTexts <= 66.66 ? 'Average' : 'Good';
                                                            } else {
                                                                return '';
                                                            }
                                                        },
                                                    },
                                                    filter: function (tooltipItem) { 
                                                        return tooltipItem.dataIndex === 1;
                                                    },

                                                },
                                                legend: {
                                                    display: false
                                                }
                                            }
                                        }}
                                    />
                                    <div 
                                        className='percent' 
                                        data-tooltip-id='tooltip-percent-of-compliant-texts'
                                        // Make transparent to allow for the chart tooltips to show through
                                        style={{
                                            backgroundColor: 'rgba(0,0,0,0)',
                                            color: 'rgba(0,0,0,0)',
                                        }}
                                    >
                                        {percentOfCompliantTexts.toFixed(0)}%
                                    </div>
                                    <Tooltip id='tooltip-percent-of-compliant-texts' place='top'>
                                        <span>Percent of compliant texts</span>
                                    </Tooltip>
                                </div>
                            </div>
                            <Divider 
                                sx={{
                                    borderColor: '#E9EAEE',
                                    opacity: 1,
                                    marginLeft: '-24px',
                                    marginRight: '-24px',
                                }}
                            />
                            <div className='compliance-main'>
                                <div className='compliance-alerts-wrapper'>
                                    {activeAlert ? (
                                        <h5>Selected Alert</h5>
                                    ) : (
                                        <h5>Active Alerts</h5>
                                    )}
                                    {activeAlert && (
                                        <>
                                            <div className='compliance-active-alert'>
                                                <div 
                                                    className='compliance-alert active'
                                                    onClick={() => handleAlertClicked(activeAlert)}
                                                    onMouseEnter={() => handleMouseEnterAlertItem(activeAlert)}
                                                    onMouseLeave={() => handleMouseLeaveAlertItem()}
                                                >
                                                    <div className='compliance-alert-header'>
                                                        <span className='compliance-alert-title'>
                                                            {/* NOTE: This will need to be modified in the future based on the alert. Since we're only just looking for triggered words based */}
                                                            {/* NOTE: on the lexicon, this is fine for now. */}
                                                            {"Found \"" + activeAlert.alert_message.split('lexicon term: ')[1] + "\" in a text message"}
                                                        </span>
                                                        {currentHoveredAlert && currentHoveredAlert.uuid === activeAlert.uuid ? (
                                                            <div
                                                                className="dropdown"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <Dropdown
                                                                    trigger={["click"]}
                                                                    placement="bottomRight"
                                                                    menu={{
                                                                        items: alertItemOptions,
                                                                    }}
                                                                >
                                                                    <span className="icon-32 cursor-pointer">
                                                                        <em className="icon-dots-vertical-outline"></em>
                                                                    </span>
                                                                </Dropdown>
                                                            </div>
                                                        ) : (
                                                            <div className="message-time">
                                                                {
                                                                    activeAlert.created_at
                                                                    ? TCUtilities.getMessageTime(activeAlert.created_at, {month: "short", day: "numeric", year: "numeric"}) 
                                                                    : ""
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='compliance-alert-body'>
                                                            <div className='compliance-alert-message'>
                                                                {/* Format the lexicon term in the message body so that it's highlighted */}
                                                                {findAndReplaceLexiconTermInMessage(
                                                                    activeAlert.context.messages.find((message) => message.uuid === activeAlert.message_uuid)?.body || "", 
                                                                    activeAlert.alert_message.split('lexicon term: ')[1]
                                                                )}
                                                            </div>
                                                            <div className='compliance-alert-actions'>
                                                            
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                            <h5>Other Active Alerts</h5>
                                        </>
                                    )}
                                    <div className='compliance-alerts'>
                                        <ul>
                                            {allActiveAlertsWithContexts.map((alert: IComplianceAlertWithContext, index: number) => (
                                                <li 
                                                    className={'compliance-alert ' + (activeAlert && activeAlert.uuid === alert.uuid ? 'active' : '')}
                                                    onClick={() => handleAlertClicked(alert)}
                                                    onMouseEnter={() => handleMouseEnterAlertItem(alert)}
                                                    onMouseLeave={() => handleMouseLeaveAlertItem()}
                                                    key={index}
                                                >
                                                    <div className='compliance-alert-header'>
                                                        <span className='compliance-alert-title'>
                                                            {/* NOTE: This will need to be modified in the future based on the alert. Since we're only just looking for triggered words based */}
                                                            {/* NOTE: on the lexicon, this is fine for now. */}
                                                            {"Found \"" + alert.alert_message.split('lexicon term: ')[1] + "\" in a text message"}
                                                        </span>
                                                        {currentHoveredAlert && currentHoveredAlert.uuid === alert.uuid ? (
                                                            <div
                                                                className="dropdown"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <Dropdown
                                                                    trigger={["click"]}
                                                                    placement="bottomRight"
                                                                    menu={{
                                                                        items: alertItemOptions,
                                                                    }}
                                                                >
                                                                    <span className="icon-32 cursor-pointer">
                                                                        <em className="icon-dots-vertical-outline"></em>
                                                                    </span>
                                                                </Dropdown>
                                                            </div>
                                                        ) : (
                                                            <div className="message-time">
                                                                {
                                                                    alert.created_at
                                                                    ? TCUtilities.getMessageTime(alert.created_at, {month: "short", day: "numeric", year: "numeric"}) 
                                                                    : ""
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='compliance-alert-body'>
                                                        <div className='compliance-alert-message'>
                                                            {/* Format the lexicon term in the message body so that it's highlighted */}
                                                            {findAndReplaceLexiconTermInMessage(
                                                                alert.context.messages.find((message) => message.uuid === alert.message_uuid)?.body || "", 
                                                                alert.alert_message.split('lexicon term: ')[1]
                                                            )}
                                                        </div>
                                                        <div className='compliance-alert-actions'>
                                                        
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className='compliance-alert-context-wrapper'>
                                    <h5>
                                        Alert Context
                                        <TextField
                                            id="compliance-alert-context-search outlined-basic"
                                            label={activeAlert ? "Search alert messages" : "Select an alert to filter messages"}
                                            variant="outlined"
                                            size="small"
                                            className={"compliance-alert-context-search" + (searchValue ? " has-search-value" : "")}
                                            onChange={(e) => {
                                                // Filter the activeAlertMessagesTemp by the search value
                                                if (activeAlert) {
                                                    setSearchValue(e.target.value);
                                                    setActiveAlertMessagesTemp(
                                                        activeAlert.context.messages.filter((message) => {
                                                            return message.body.toLowerCase().includes(e.target.value.toLowerCase());
                                                        })
                                                    );
                                                }
                                            }}
                                            disabled={!activeAlert}
                                        />
                                    </h5>
                                    <div className='compliance-alert-context'>
                                        {activeAlert && activeAlert.uuid ? (
                                            <>
                                                <div className='compliance-alert-context-header'>
                                                    <h6 className='conversation-name'>
                                                        Conversation Name: {activeAlert.context.conversation.friendly_name}
                                                    </h6>
                                                </div>
                                                <div className='compliance-alert-context-body'>
                                                    <div className='compliance-alert-context-messages'>
                                                        <h6>Messages</h6>
                                                        <ul>
                                                            {activeAlertMessagesTemp.map((message, index) => {
                                                                if (!activeAlertMessagesTemp) return null;
                                                                const previousMessage = index > 0 ? activeAlertMessagesTemp[index - 1] : undefined;
                                                                const dateSeparator = TCUtilities.getDateSeparator(message, previousMessage);

                                                                return (
                                                                    <li key={index}>
                                                                        {dateSeparator && (
                                                                            <div className='date-separator'>
                                                                                <div className='line'></div>
                                                                                <span>{dateSeparator}</span>
                                                                                <div className='line'></div>
                                                                            </div>
                                                                        )}
                                                                        {message.author === 'system' ? (
                                                                            <SystemMessage message={message.body} />
                                                                        ) : (
                                                                            <div 
                                                                                // Highlight the message if it's the one that triggered the alert
                                                                                // Also, add 'advisor-user' if the message.author is not a phone number (Ex: +11234567890)
                                                                                className={
                                                                                    'compliance-alert-context-message ' 
                                                                                    + (message.uuid === activeAlert.message_uuid ? 'highlighted' : '')
                                                                                    + (!message.author.startsWith('+') ? ' advisor-user' : '')
                                                                                }
                                                                            >
                                                                                <div className='compliance-alert-context-message-details'>
                                                                                    <div className='compliance-alert-context-message-author'>
                                                                                        <h6>
                                                                                            {message.author.startsWith('+') ? (
                                                                                                TCUtilities.formatPhoneNumber(message.author)
                                                                                            ) : (
                                                                                                message.author
                                                                                            )}
                                                                                        </h6>
                                                                                        {!message.author.startsWith('+') && message.attributes.text_user_opt_in_at_time_of_message === false && (
                                                                                            <div className="message-not-opted-in">
                                                                                                Not opted in to conversation
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className='compliance-alert-context-message-content'>
                                                                                        {!message.author.startsWith('+') && (
                                                                                            <span className='compliance-alert-context-message-timestamp'>
                                                                                                {TCUtilities.getMessageTime(message.created_at, {month: "short", day: "numeric", year: "numeric"})}
                                                                                            </span>
                                                                                        )}
                                                                                        <div className='compliance-alert-context-message-body-wrapper'>
                                                                                            <div 
                                                                                                className='compliance-alert-context-message-body'
                                                                                                // TODO: Convert current integration into preserving links as well
                                                                                                // Sanitize and preserve special characters in Twilio message with HTML elements. 
                                                                                                // Also, allow us to modify the message body to add target="_blank" to links.
                                                                                                // dangerouslySetInnerHTML={{
                                                                                                //     __html: TCUtilities.addTargetBlankToLinks(DOMPurify.sanitize(
                                                                                                //         TCUtilities.preserveSpecialCharactersInTwilioMessageWithHTMLElements(
                                                                                                //             message.body
                                                                                                //         )
                                                                                                //     ))
                                                                                                // }}
                                                                                            >
                                                                                                {/* Highlight the lexicon term in the message body */}
                                                                                                {findAndReplaceLexiconTermInMessage(message.body, activeAlert.alert_message.split('lexicon term: ')[1])}
                                                                                            </div>
                                                                                        </div>
                                                                                        {message.author.startsWith('+') && (
                                                                                            <span className='compliance-alert-context-message-timestamp'>
                                                                                                {TCUtilities.getMessageTime(message.created_at, {month: "short", day: "numeric", year: "numeric"})}
                                                                                            </span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className='compliance-alert-context-header'>
                                                    <span className='compliance-alert-context-title'>
                                                        No alert selected
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel 
                            value={tabPanelValue} 
                            index={1} 
                            className={'simple-tabpanel compliance-conversations'}
                            style={{
                                maxHeight: complianceContentHeight - complianceTabsHeight,
                            }}
                        >
                            <UnderConstruction />
                            {/* <span>Conversations</span> */}
                        </CustomTabPanel>
                        <CustomTabPanel 
                            value={tabPanelValue} 
                            index={2} 
                            className={'simple-tabpanel compliance-lexicon'}
                            style={{
                                maxHeight: complianceContentHeight - complianceTabsHeight,
                            }}
                        >
                            {/* <span>Lexicon</span> */}
                            <UnderConstruction />
                        </CustomTabPanel>
                    </div>
                    <ModalGeneralError 
                        isShowing={showModalError}
                        setIsShowing={setShowModalError}
                        errorMessage={showModalErrorText}
                        setErrorMessage={setShowModalErrorText}
                    />
                    <SoundPlayer ref={soundPlayerRef} url={dingCorrect} />
                </>
            )}
        </div>
    );
}

export default ComplianceDashboard;