import React, { useEffect, useMemo, useState } from "react";

import { message } from "../../redux/actions/Message/Message";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useAppDispatchJS } from "../../redux/hooks";

const GlobalSearch = ({ userId, redirectToMessage }) => {
  const dispatch = useAppDispatchJS();
  const [globalSearchText, setGlobalSearchText] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (globalSearchText <= 2) {
      setMessageList([]);
    } else {
      debounceSearch(globalSearchText);
    }
  }, [globalSearchText]);

  let timer;
  const debounceSearch = (searchTerm) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      globalSearch(searchTerm);
    }, 300);
  };

  const globalSearch = (searchTerm) => {
    dispatch(message.GlobalSearch(searchTerm, userId)).then((response) => {
      if (response.type === "GLOBAL_SEARCH_SUCCESS") {
        setMessageList(response?.data?.results);
      }
      if (response.type === "GLOBAL_SEARCH_FAILURE") {
        setError(response?.error);
        console.log(response?.error);
      }
    });
  };

  const getChannelName = (members) => {
    return [...members]
      .filter(m => m.userId !== userId)
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(m => m.nickname).join(", ");
  }

  const getHighlightedText = (text, highlight) => {
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {" "}
        {parts?.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === highlight.toLowerCase() ? "mark" : ""
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };

  return (
    <div className="position-relative d-none d-md-block">
      <div className="search-area message-search-area">
        {error && <div className="error-message">{error}</div>}
        <input
          placeholder="Search messages..."
          className="form-control"
          value={globalSearchText}
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              debounceSearch(event.target.value);
            }
          }}
          onChange={(event) => {
            setGlobalSearchText(event.target.value);
          }}
        />
        <button
          className="search-btn icon-20"
          onClick={(event) => {
            debounceSearch(globalSearchText);
          }}
        >
          <em className="icon-search-outline"></em>
        </button>
        {globalSearchText !== "" && (
          <button
            className="close-search-btn icon-20"
            onClick={(event) => {
              setGlobalSearchText("")
            }}
          >
            <em className="icon-x-circle-filled"></em>
          </button>
        )}
      </div>
      {messageList.length > 0 && (
        <div className="message-dropdown dropdown-menu d-block custom-style-scroll">
          <ul
            className="message-search-list list-unstyled m-0"
            style={{ cursor: "pointer" }}
          >
            {messageList?.map((element, index) => (
              <li
                key={index}
                onClick={(event) => {
                  setGlobalSearchText("")
                  redirectToMessage(element.channel, element?.message_id)
                }}
              >
                <div className="d-flex align-items-center justify-content-between mb-md-8 mb-4">
                  <span className="fs-12 lh-16 mb-4 d-block">{`Channel: ${element?.channel?.name === "Group Channel"
                    ? getChannelName(element?.channel?.members)
                    : element?.channel?.name
                    }`}</span>
                  <span className="mesg-time ">
                    {moment(element?.created_at).format("YYYY-MM-DD") ===
                      moment().format("YYYY-MM-DD")
                      ? moment(element?.created_at).format("hh:mm A")
                      : moment(element?.created_at).format("ll hh:mm A")}{" "}
                  </span>
                </div>
                <div className="d-flex text-break">
                  {element?.type === "FILE" && (
                    <>
                      {(element?.file?.type.includes("video") ||
                        element?.file?.type.includes("audio")) && (
                          <span className="icon-24">
                            <em className="icon-play-filled text-success"></em>
                          </span>
                        )}
                      {element?.file?.type.includes("image") && (
                        <span className="icon-24">
                          <em className="icon-image-outline text-success"></em>
                        </span>
                      )}
                      {element?.file?.type.includes("application") && (
                        <span className="icon-24">
                          <em className="icon-document-text-filled text-success "></em>
                        </span>
                      )}
                    </>
                  )}
                  {element?.messageType !== "file"
                    ? getHighlightedText(
                      element?.type !== "FILE"
                        ? element?.message
                        : element?.file?.name,
                      globalSearchText
                    )
                    : element?.name}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default GlobalSearch;
