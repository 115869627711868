import axiosInstance from "../../../config/axios";
import { docuSignConstants } from "../../constants/DocuSignConstant";
import config from "../../../config/Config";
import { utility } from "../../../components/Utility";

export const docuSign = {
  Login,
  docuSignCheck,
  getFileList,
  resendDocumnetForSign,
  createSignature,
  getListOfSignature,
  createSignatureFull,
  createSignatureInitial,
  deleteSignature,
  getEmbededSignUrl,
  resendDocumnet,
  getSearchList,
  getFileListSorted,
  getClientList,
  removeDocumnet,
  getCount,
  docuSignLogout
};

function Login(state) {
  //let url = "https://secure.sharefile.com/oauth/authorize?response_type=code&client_id=" + process.env.REACT_APP_ClientId + "&redirect_uri=" + config.getHost() + "/user/sharefile/auth_complete&state=" + state;
  let url =
    "https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature&client_id=" +
    process.env.REACT_APP_ClientId_DocuSign +
    "&state=" +
    state +
    "&redirect_uri=" +
    config.getHost() +
    "/user/docusign/oauth_complete";
  window.location.href = url;
}

function docuSignCheck() {
  return (dispatch) => {
    return axiosInstance
      .get(`/user/docusign/check?is_mobile=false`)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          console.log(error.response.data);
          return dispatch(failure(error.response.data));
        }
      });
  };

  function success(data) {
    return { type: docuSignConstants.CHECK_DOCUSIGN_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.CHECK_DOCUSIGN_FAILURE, error };
  }
}

function getFileList(userId, fileType) {
  return (dispatch) => {
    return axiosInstance
      .get(
        "/user/docusign/envelopes?user_id=" +
          userId +
          "&folder_ids=" +
          fileType +
          "&order_by=created&include=recipients,powerform,folders,delegations,workflow"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.GET_PENDING_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.GET_PENDING_LIST_FAILURE, error };
  }
}


function getEmbededSignUrl(envelope_id) {
  return (dispatch) => {
    return axiosInstance
      .post(
        "/user/docusign/envelopes/embedded_signing/"+envelope_id,{"return_url":window.location.href+"?state=1"}
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.GET_EMBEDED_SIGN_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.GET_EMBEDED_SIGN_FAILURE, error };
  }
}
function resendDocumnetForSign(accoutId, documentId) {
  return (dispatch) => {
    return axiosInstance
      .put(
        "accounts/" +
          accoutId +
          "/envelopes/" +
          documentId +
          "?resend_envelope=true"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.RESEND_DOCUMENT_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.RESEND_DOCUMENT_FAILURE, error };
  }
}

function createSignature(param) {
  return (dispatch) => {
    return axiosInstance
      .post("/user/docusign/create_signatures" , param)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.CREATE_SIGNATURE_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.CREATE_SIGNATURE_FAILURE, error };
  }
}

function getListOfSignature() {
  return (dispatch) => {
    return axiosInstance
      .get("user/docusign/list_signatures")
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.GET_SIGNATURE_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.GET_SIGNATURE_LIST_FAILURE, error };
  }
}

function createSignatureFull(signature_id,image) {

  return (dispatch) => {
    return axiosInstance
      .put("user/docusign/update_signature_image/"+signature_id+"/signature_image",image.split(',')[1])
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.CREATE_SIGNATURE_FULL_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.CREATE_SIGNATURE_FULL_FAILURE, error };
  }
}


function createSignatureInitial(signature_id,image) {
  return (dispatch) => {
    return axiosInstance
      .put("user/docusign/update_signature_image/"+signature_id+"/initials_image",image.split(',')[1])
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.CREATE_SIGNATURE_INITIAL_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.CREATE_SIGNATURE_INITIAL_FAILURE, error };
  }
}

function deleteSignature(signatureId) {
  return (dispatch) => {
    return axiosInstance
      .delete("/user/docusign/delete_signature/"+signatureId)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.DELETE_SIGNATURE_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.DELETE_SIGNATURE_FAILURE, error };
  }
}

function resendDocumnet(envId) {
  return (dispatch) => {
    return axiosInstance
      .put("user/docusign/envelopes/"+envId+"?resend_envelope=true",{"envelope_id":envId})
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.RESEND_ENV_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.RESEND_ENV_FAILURE, error };
  }
}

function getSearchList(userId, searchValue) {
  return (dispatch) => {
    return axiosInstance
      .get(
        "user/docusign/envelopes?from_date=01%2F01%2F2000&include=recipients%2Cfolders&search_text="+searchValue+"&folder_types=normal%2Cinbox%2Csentitems&is_mobile=false"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.GET_SEARCH_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.GET_SEARCH_FAILURE, error };
  }
}
function getFileListSorted(userId, fileType , orderColumn , order ) {
  return (dispatch) => {
    return axiosInstance
      .get(
        "/user/docusign/envelopes?user_id=" +
          userId +
          "&folder_ids=" +
          fileType +
          "&order="+order+"&order_by="+orderColumn+"&include=recipients,powerform,folders,delegations,workflow"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.GET_SORTED_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.GET_SORTED_LIST_FAILURE, error };
  }
}

function getClientList() {
  return (dispatch) => {
    return axiosInstance
      .get(
        "/user/advisory_team?user_type=clients"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.GET_CLIENT_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.GET_CLIENT_LIST_FAILURE, error };
  }
}


function removeDocumnet(param) {
  return (dispatch) => {
    return axiosInstance
      .put(
        "/user/docusign/move_envelopes/recyclebin",param
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.REMOVE_DOCUMNET_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.REMOVE_DOCUMNET_FAILURE, error };
  }
}

function getCount() {
  return (dispatch) => {
    return axiosInstance
      .get(
        "/user/docusign/envelopes_count"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.GET_COUNT_LIST_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.GET_COUNT_LIST_FAILURE, error };
  }
}

function docuSignLogout() {
  return (dispatch) => {
    return axiosInstance
      .get(
        "/user/docusign/logout"
      )
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          utility.handleAuthError();
        }
        if (error.response) {
          return dispatch(failure(error.response.data));
        }
      });
  };
  function success(data) {
    return { type: docuSignConstants.LOGOUT_DOCUSIGN_SUCCESS, data };
  }

  function failure(error) {
    return { type: docuSignConstants.LOGOUT_DOCUSIGN_FAILURE, error };
  }
}