import React from 'react'

const ReadBookmark = ({handleReadStatusArticle,shareArticleAction,handleBookmarkStatusArticle,item}) => {
  return (
    <ul className="ftr-btn d-flex align-items-center">
      <li>
        <span className="icon-24">
          {item.article_actions && item.article_actions[0]?.is_read ? (
            <em
              className="icon icon-check-circle-filled text-success"
              onClick={(e) => {
                e.preventDefault();
                handleReadStatusArticle(item);
              }}
            ></em>
          ) : (
            <em
              className="icon icon-check-circle-outline text-success"
              onClick={(e) => {
                e.preventDefault();
                handleReadStatusArticle(item);
              }}
            ></em>
          )}
        </span>
      </li>
      {item?.is_published === true && <li>
        <div className="upload-file" onClick={() => shareArticleAction(item)}>
          <span className="icon-24">
            <em className="icon icon-upload-filled"></em>
          </span>
          <input type="file" id="ftr-file1" />
        </div>
        {/* : 
        <div className="upload-file opacity-50 disabled">
          <span className="icon-24">
            <em className="icon icon-upload-filled"></em>
          </span>
          <input type="file" id="ftr-file1" />
        </div> */}
      </li>
      }
      <li>
        <span className="icon-24">
          {item.article_actions && item.article_actions[0]?.is_bookmark ? (
            <em
              className="icon icon-bookmark-filled text-success"
              onClick={(e) => {
                e.preventDefault();
                handleBookmarkStatusArticle(item);
              }}
            ></em>
          ) : (
            <em
              className="icon icon-bookmark-outline text-success"
              onClick={(e) => {
                e.preventDefault();
                handleBookmarkStatusArticle(item);
              }}
            ></em>
          )}
        </span>
      </li>
    </ul>
  );
}

export default ReadBookmark
