export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    
  VALIDATE_EMAIL_SUCCESS : 'EMAIL_VALID_SUCCESS',
  VALIDATE_EMAIL_FAILURE : 'EMAIL_VALID_FAILURE',

  OTP_RECEIVED_SUCCESS : 'OTP_RECEIVED_SUCCESS',
  OTP_FAILURE : 'OTP_FAILURE',

  FORGOT_OTP_RECEIVED_SUCCESS : 'FORGOT_OTP_RECEIVED_SUCCESS',
  FORGOT_OTP_RECEIVED_FAILURE : 'FORGOT_OTP_RECEIVED_FAILURE',

  FORGOT_OTP_VALID_SUCCESS : 'FORGOT_OTP_VALID_SUCCESS',
  FORGOT_OTP_VALID_FAILURE : 'FORGOT_OTP_VALID_FAILURE',

  FORGOT_RESET_PASSWORD_SUCCESS : 'FORGOT_RESET_PASSWORD_SUCCESS',
  FORGOT_RESET_PASSWORD_FAILURE : 'FORGOT_RESET_PASSWORD_FAILURE',

  RESET_DEFAULT_PASSWORD_SUCCESS : 'RESET_DEFAULT_PASSWORD_SUCCESS',
  RESET_DEFAULT_PASSWORD_FAILURE : 'RESET_DEFAULT_PASSWORD_FAILURE',

  GLOBAL_NAVIGATION_SUCCESS : 'GLOBAL_NAVIGATION_SUCCESS',
  GLOBAL_NAVIGATION_FAILURE : 'GLOBAL_NAVIGATION_FAILURE',

  RESET_REFRESH_TOKEN_SUCCESS : 'RESET_REFRESH_TOKEN_SUCCESS',
  RESET_REFRESH_TOKEN_FAILURE : 'RESET_REFRESH_TOKEN_FAILURE',

};