// Tanner Fry
// tfry@monetagroup.com
// File contains modals for Twilio Conversations.

import { Client } from "@twilio/conversations";
import React, { useEffect, useState } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import validator from "validator";
import { LoadingSpinner, notifyInAppError, notifyInAppSuccess, notifyInAppWarning } from "../../components/UtilitiesTS";
import { utility } from "../../components/Utility";
import { ChatType, IUserDetailsProps } from "../../interfaces/General";
import { ITwilioConversationsConversationProps } from "../../interfaces/TwilioConversations";
import { TwilioConversationsRedux } from "../../redux/actions/TwilioConversations/TwilioConversations";
import { twilioConversationsConstants } from "../../redux/constants/TwilioConversationsConstant";
import { useAppDispatchTS, useAppSelectorTS } from "../../redux/hooksTS";

const ModalTwilio = ({
    modalClassName,
    modalShow,
    modalOnHide,
    modalTitle,
    modalHeaderContent,
    modalBody,
    modalFooter,
    modalSize,
    isLoading = false,
}: {
    modalClassName: string;
    modalShow: boolean;
    modalOnHide: () => void;
    modalTitle: React.ReactNode;
    modalHeaderContent: React.ReactNode | string;
    modalBody: React.ReactNode | string;
    modalFooter: React.ReactNode | string;
    modalSize?: ModalProps["size"];
    isLoading?: boolean;
}) => {

    return (
        <Modal 
            className={modalClassName}
            show={modalShow}
            onHide={() => {
                modalOnHide();
            }}
            size={modalSize}
        >
            {isLoading && (
                <LoadingSpinner isActive={isLoading} additionalBackgroundDimmerStyle={{borderRadius: "8px"}} />
            )}
            <Modal.Header
                closeButton={true}
            >
                <Modal.Title>
                    {modalTitle}
                </Modal.Title>
                {modalHeaderContent}
            </Modal.Header>
            <Modal.Body className="ps-0 pe-0 pt-0 pt-md-16">
                {modalBody}
            </Modal.Body>
            <Modal.Footer>
                {modalFooter}
            </Modal.Footer>
        </Modal>
    );
}

// ### Example Modal ###
// interface ModalTwilioExampleProps {
//     activeChat: ITwilioConversationsChatProps;
//     setActiveConversation: (conversation: ITwilioConversationsChatProps) => void;
//     userConversations: ITwilioConversationsChatProps[];
//     setUserConversations: (conversations: ITwilioConversationsChatProps[]) => void;
//     userDetails: IUserDetailsProps;
//     isShowing: boolean;
//     handleClose: () => void;
//     setShowModalError: (show: boolean) => void;
//     setShowModalErrorText: (text: string) => void;
// }
// const ModalTwilioExample = ({
//     activeChat,
//     setActiveConversation,
//     userConversations,
//     setUserConversations,
//     userDetails,
//     isShowing,
//     handleClose,
//     setShowModalError,
//     setShowModalErrorText,
// }: ModalTwilioExampleProps) => {
//     const [currentChatName, setCurrentChatName] = useState(activeChat.friendly_name);
//     const dispatch = useAppDispatchTS();

//     return (
//         <ModalTwilio
//             modalClassName="modal-twilio modal-twilio-conversation-change-name"
//             modalShow={isShowing}
//             modalOnHide={() => {handleClose()}}
//             modalTitle="Change Chat Name"
//             // @ts-ignore
//             modalSize="md"
//             modalHeaderContent={
//                 <>
//                     {/* Header */}
//                 </>
//             }
//             modalBody={
//                 <div className="modal-twilio-body">
//                     {/* Input for new conversation name */}
//                     <div className="px-24 py-12 border-md-top">
//                         <input 
//                             type="text" 
//                             className="form-control" 
//                             placeholder="Chat Name"
//                             value={currentChatName}
//                             onChange={(e) => {
//                                 setCurrentChatName(e.target.value);
//                             }}
//                         />
//                     </div>
//                 </div>
//             }
//             modalFooter={
//                 <div className="d-md-flex align-items-center">
//                     <button
//                         className="btn btn-outline-secondary"
//                         onClick={(e) => {
//                         e.preventDefault();
//                             handleClose();
//                         }}
//                     >
//                         Cancel
//                     </button>
//                     <button
//                         className="btn btn-success"
//                         disabled={false}
//                         onClick={() => {
//                             dispatch(TwilioConversationsRedux.PostUserChatConversationNameUpdate(activeChat.sid, currentChatName, userDetails.twilio_conversations_user_identity)).then((response: any) => {
//                                 if (response.type === twilioConversationsConstants.POST_CHAT_NAME_UPDATE_SUCCESS) {
//                                     // Stuff here
//                                     handleClose();
//                                 } else if (response.type === twilioConversationsConstants.POST_CHAT_NAME_UPDATE_FAILURE) {
//                                     // Error handling
//                                     setShowModalError(true);
//                                     setShowModalErrorText('Failed to update conversation name. Error: ' + response.error.error);
//                                 }
//                             });
//                         }}
//                     >
//                         Save
//                     </button>
//                 </div>
//             }
//         />
//     );
// }
// ### End Example Modal ###

interface IModalTwilioNewConversationProps {
    userDetails: IUserDetailsProps;
    isShowing: boolean;
    handleClose: () => void;
    chatType: string;
}

const ModalTwilioNewConversation = ({
    userDetails,
    isShowing,
    handleClose,
    chatType,
}: IModalTwilioNewConversationProps) => {
    const [twilioClient, setTwilioClient] = useState<Client | null>(null);
    const [advisorList, setAdvisorList] = useState(useAppSelectorTS((state) => state.twilioConversations.advisorList));
    const [searchState, setSearchState] = useState({
        valueFilter: "",
        filterList: advisorList?.data,
        numberSelected: 0,
        selectedHousehold: 0,
        isMandatoryMemberSelected: false,
    });
    const [newConversationName, setNewConversationName] = useState("");
    const [newConversationNameValidationText, setNewConversationNameValidationText] = useState("");
    const [textNumber, setTextNumber] = useState("");
    const [textNumberCountryCode, setTextNumberCountryCode] = useState("+1");
    const [textNumberValidationText, setTextNumberValidationText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem("userDetails") || "");
    const userDetailsUuid = userInfo?.uuid.trim();
    const userId = userInfo?.sendbird_user_id.trim();
    const isAdvisor = userInfo?.user_type === "advisors";
    const isClient = userInfo?.user_type === "clients";
    const dispatch = useAppDispatchTS();

    // const getTwilioClient = async () => {
    //     setTwilioClient(await TwilioUtilities.getTwilioClient(userDetails.twilio_conversations_user_token));
    // }

    // useEffect(() => {
    //     if (isShowing) {
    //         getTwilioClient();
    //         getAdvisorList();
    //     }
    // }, [isShowing]);

    const validatePhoneNumber = (number: string) => {        
        const isValidPhoneNumber = validator.isMobilePhone(number)
        console.log("Is phone number valid: ", isValidPhoneNumber);
        
        return (isValidPhoneNumber)
    }

    const validateNumber = (number: string) => {
        const isValidNumber = !isNaN(Number(number)) && isFinite(Number(number))

        return isValidNumber
    }

    useEffect(() => {
        // Check if advisor list is already loaded
        if (!isShowing && searchState.filterList.length == 0 && chatType === ChatType.CHAT) {
            setIsLoading(true);
            console.log("Getting advisor list via useEffect");
            dispatch(TwilioConversationsRedux.GetAdvisorList()).then((response: any) => {
                setIsLoading(false);
                if (response.type === "GET_ADVISOR_LIST_SUCCESS") {            
                    setAdvisorList(response.data);
                    setSearchState((s) => ({
                        ...s,
                        filterList: response.data.data,
                    }));
                } else {
                    console.log("Error getting advisor list: ", response);
                }
            });
        }

        // Reset fields
        resetChatConversationFields();
        resetTextConversationFields();
    }, []);

    const resetChatConversationFields = () => {
        setNewConversationName("");
        setNewConversationNameValidationText("");
        advisorList?.data?.map((c: any) => (c.is_selected = false));
        setAdvisorList(advisorList);
        setSearchState((s) => ({
            valueFilter: "",
            filterList: advisorList?.data,
            numberSelected: 0,
            selectedHousehold: 0,
            isMandatoryMemberSelected: false,
        }));
    }

    const resetTextConversationFields = () => {
        setNewConversationName("");
        setNewConversationNameValidationText("");
        setTextNumber("");
        setTextNumberCountryCode("+1");
        setTextNumberValidationText("");
    }
    
    // const getAdvisorList = () => {
    //     // if (!loader) {
    //     //   setLoader(true);
    //     console.log("Getting advisor list");
        
    //     dispatch(TwilioConversationsRedux.GetAdvisorList()).then((response: any) => {
    //         // setLoader(false);
    //         if (response.type === "GET_ADVISOR_LIST_SUCCESS") {
    //             console.log("Setting advisor list: ", response.data);
    //             setAdvisorList(response.data);
    //             setSearchState((s) => ({
    //                 ...s,
    //                 filterList: response.data.data,
    //             }));
    //         //   setShowFilter(true);
    //         //   setLoader(false);
    //         } else {
    //             console.log("Error getting advisor list: ", response)
    //         }
    //         // } else {
    //         //   setLoader(false);
    //         // }
    //     });
    //     // }
    // };

    function selectMember(client: any) {
        let indx = advisorList?.data?.findIndex((c: any) => c.uuid === client.uuid);
        // Flip the is_selected value
        console.log("Switch is_selected: ", advisorList?.data[indx].is_selected);
        
        advisorList.data[indx].is_selected = !advisorList.data[indx].is_selected;
        console.log("Switch is_selected: ", advisorList?.data[indx].is_selected);

        if (isAdvisor) {
            const isClientSelected = advisorList?.data?.some(
                (c: any) => c.user_type === "clients" && c.is_selected
            );
            if (!isClientSelected) {
                advisorList?.data
                ?.filter((c: any) => c.user_type !== "clients")
                .map((c: any) => (c.is_selected = false));
            }
        }
        setAdvisorList(advisorList);
        const numberOfSelectedClients = advisorList?.data?.filter((c: any) => c.is_selected);
  
        const temp = advisorList?.data?.some(
            (c: any) =>
                (isAdvisor
                ? c.user_type === "clients"
                : c.user_type !== "clients") && c.is_selected
        );
        if (client?.user_type !== "advisors") {
          setSearchState((s) => ({
                ...s,
                selectedHousehold: temp ? client.household : 0,
                isMandatoryMemberSelected: temp,
                numberSelected: numberOfSelectedClients?.length,
            }));
        } else {
            setSearchState((s) => ({
                ...s,
                isMandatoryMemberSelected: temp,
                numberSelected: numberOfSelectedClients?.length,
            }));
        }
    }

    useEffect(() => {
        // console.log("Advisor list: ", advisorList);
        // console.log("Search state: ", searchState);
        
        // searchState.filterList.map((c) => {
        //     console.log("Advisor list advisor: ", c);
        // });
    }, [searchState, advisorList]);

    return (
        <ModalTwilio
            isLoading={isLoading}
            modalClassName="modal-twilio modal-twilio-new-conversation"
            modalShow={isShowing}
            modalOnHide={() => {handleClose()}}
            modalTitle="New Conversation"
            // @ts-ignore
            modalSize="md"
            modalHeaderContent={
                <>
                    {/* Header */}
                </>
            }
            modalBody={
                <div className="modal-twilio-body">
                    {/* Input for new conversation name */}
                    <div className="px-24 py-12 border-md-top">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Conversation Name"
                            value={newConversationName}
                            onChange={(e) => {
                                // Make sure conversation name is between 1 and 255 characters
                                if (e.target.value.length > 255) {
                                    setNewConversationNameValidationText("Conversation name must be between 1 and 255 characters.");
                                    return;
                                } else {
                                    setNewConversationNameValidationText("");
                                }

                                setNewConversationName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="text-danger px-24">{newConversationNameValidationText}</div>
                    {/* Search field for filtering users */}
                    {chatType === ChatType.CHAT ? (
                        <>
                            <div className="search-area px-24 py-12 border-md-top">
                                <input
                                    placeholder="Search Users..."
                                    className="form-control"
                                    value={searchState.valueFilter}
                                    onChange={(e) => {
                                        let lst = advisorList?.data?.filter(
                                        (c: any) =>
                                            e.target.value === "" ||
                                            (
                                            c.first_name?.toLowerCase() +
                                            " " +
                                            c.last_name?.toLowerCase()
                                            ).includes(e.target.value?.toLowerCase())
                                        );
                                        setSearchState((s) => ({
                                            ...s,
                                            valueFilter: e.target.value,
                                            filterList: lst,
                                        }));
                                    }}
                                />
                                <button className="search-btn icon-20">
                                    <em className="icon-search-outline"></em>
                                </button>
                                {searchState.valueFilter !== "" && (
                                    <button
                                        className="close-search-btn icon-20"
                                        onClick={(event) => {
                                            const value: string = "";
                                            const lst = advisorList?.data?.filter(
                                                (c: any) =>
                                                value === "" ||
                                                (
                                                    c.first_name?.toLowerCase() +
                                                    " " +
                                                    c.last_name?.toLowerCase()
                                                ).includes(value && value.toLowerCase())
                                            );
                                            setSearchState((s) => ({
                                                ...s,
                                                valueFilter: value,
                                                filterList: lst,
                                            }));
                                        }}
                                    >
                                        <em className="icon-x-circle-filled"></em>
                                    </button>
                                )}
                            </div>
                            {/* List of users */}
                            <div className="twilio-users border-md-top">
                                <div className="selected-client fw-500 text-success border-top">
                                    {searchState.numberSelected} selected
                                </div>
                                <div className="message-check-main custom-style-scroll pr-8">
                                    <div className="ps-24 pe-8">
                                        <div>
                                        <h6 className="fs-14 lh-20 text-uppercase mb-4 ">
                                            {advisorList?.data?.filter((x: any) => x.user_type === "clients")?.length > 0 ? (isAdvisor
                                            ? "MY CLIENTS"
                                            : "MY HOUSEHOLD") : ""}
                                        </h6>
                                        <ul className="message-check-list list-unstyled mb-8">
                                            {searchState.filterList
                                            ?.filter((x: any) => (
                                                (searchState.selectedHousehold === 0 || x.household === searchState.selectedHousehold) && x.user_type === "clients")
                                            )
                                            ?.map((client: any, index: any) => (
                                                <li key={index} onClick={() => selectMember(client)}>
                                                    <div className="d-flex align-items-center">
                                                        <span className="m-client-photo icon-32">
                                                            {utility.getProfileImage(
                                                                client?.profile_image,
                                                                client?.first_name?.charAt(0),
                                                                client?.last_name?.charAt(0)
                                                            )}
                                                        </span>
                                                        <label
                                                            className="form-check-label fs-16 lh-24"
                                                            htmlFor="filtclient2"
                                                        >
                                                            {client.first_name + " " + client.last_name}
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-0 no-text-form-check">
                                                        {client?.is_selected && (
                                                            <input
                                                                className="form-check-input"
                                                                type="Checkbox"
                                                                id={client.uuid}
                                                                checked={true}
                                                                onChange={(e) => {
                                                                    // selectMember(client);
                                                                }}
                                                            />
                                                        )}
                                                        {!client?.is_selected && (
                                                            <input
                                                                className="form-check-input"
                                                                type="Checkbox"
                                                                id={client.uuid}
                                                                checked={false}
                                                                onChange={(e) => {
                                                                    // selectMember(client);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        </div>
                                        {(searchState.isMandatoryMemberSelected || !isAdvisor) && (
                                            <div>
                                                <h6 className="fs-14 lh-20 text-uppercase mb-4 ">
                                                    {advisorList?.data?.filter((x: any) => x.user_type !== "clients")?.length > 0 ? (isAdvisor
                                                    ? "MY TEAM"
                                                    : "MY ADVISORY TEAM") : ""}
                                                </h6>
                                                <ul className="message-check-list list-unstyled mb-8">
                                                    {searchState.filterList
                                                    ?.filter((x: any) => x.user_type !== "clients")
                                                    ?.map((client: any, index: any) => (
                                                        <li key={index} onClick={() => selectMember(client)}>
                                                            <div className="d-flex align-items-center">
                                                                <span className="m-client-photo icon-32">
                                                                    {utility.getProfileImage(
                                                                        client?.profile_image,
                                                                        client?.first_name?.charAt(0),
                                                                        client?.last_name?.charAt(0)
                                                                    )}
                                                                </span>
                                                                <label
                                                                    className="form-check-label fs-16 lh-24"
                                                                    htmlFor="filtclient2"
                                                                >
                                                                    {client.first_name + " " + client.last_name}
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-0 no-text-form-check">
                                                                {client?.is_selected && (
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="Checkbox"
                                                                        id={client.uuid}
                                                                        checked={true}
                                                                        onChange={(e) => {
                                                                            // selectMember(client);
                                                                        }}
                                                                    />
                                                                )}
                                                                {!client?.is_selected && (
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="Checkbox"
                                                                        id={client.uuid}
                                                                        checked={false}
                                                                        onChange={(e) => {
                                                                            // selectMember(client);
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!searchState.isMandatoryMemberSelected && (
                                    <div className="px-24">
                                        <div className="alert alert-warning d-flex align-items-center justify-content-center text-center mt-16 mb-0">
                                            <div className="flex-fill">
                                                <h6 className="mb-0">
                                                    {isAdvisor
                                                    ? "Please add at least one client to proceed"
                                                    : "Please add at least one advisory team member to proceed"}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            {/* Input for new conversation name */}
                            <div className="phone-number-container px-24 py-12 border-md-top d-flex">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="+1"
                                    value={textNumberCountryCode}
                                    onChange={(e) => {
                                        // Validate input is a number
                                        if (!validateNumber(e.target.value)) {
                                            setTextNumberCountryCode("");
                                            setTextNumberValidationText("Please enter a valid number for the country code");
                                        } else {
                                            // Make sure country code is US country code as we don't accept others at this time
                                            if (e.target.value !== "1") {
                                                setTextNumberCountryCode("");
                                                setTextNumberValidationText("Please enter a valid US country code");
                                            } else {
                                                setTextNumberValidationText("");
                                                setTextNumberCountryCode("+" + e.target.value);
                                            }
                                        }
                                    }}
                                />
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Client/Prospect Number"
                                    value={textNumber}
                                    onChange={(e) => {
                                        // Validate input is a number
                                        if (!validateNumber(e.target.value)) {
                                            setTextNumber("");
                                            setTextNumberValidationText("Please enter a valid number for the phone number");
                                        } else {
                                            // Make sure number is no longer than 10 characters as we only accept US numbers at this time
                                            if (e.target.value.length > 10) {
                                                setTextNumber(textNumber);
                                                setTextNumberValidationText("Please enter a valid US number for the phone number");
                                            } else {
                                                setTextNumberValidationText("");
                                                setTextNumber(e.target.value);
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div className="text-danger px-24">{textNumberValidationText}</div>
                        </>
                    )}
                </div>
            }
            modalFooter={
                <>
                    <div className="d-md-flex align-items-center">
                        <button
                            className="btn btn-outline-secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                setNewConversationName("");
                                setTextNumber("");
                                handleClose();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-success"
                            disabled={
                                (chatType === ChatType.CHAT && !searchState.isMandatoryMemberSelected) ||
                                (chatType === ChatType.TEXT && textNumber.length < 10)
                            }
                            onClick={() => {
                                if (newConversationName.length == 0 || newConversationName.length > 255) {
                                    setNewConversationNameValidationText("Conversation name must be between 1 and 255 characters.");
                                    return;
                                }
                                if (chatType === ChatType.CHAT) {
                                    if (searchState.isMandatoryMemberSelected) {
                                        const conversationIdentities = advisorList?.data?.filter((c: any) => c.is_selected).map((c: any) => c.twilio_conversations_user_identity);
                                        conversationIdentities.push(userDetails.twilio_conversations_user_identity);
                                        console.log("Creating chat conversation with name: ", newConversationName, " and identities: ", conversationIdentities);
                                        
                                        setIsLoading(true);
                                        dispatch(TwilioConversationsRedux.PostNewChatConversation(newConversationName, conversationIdentities)).then((response: any) => {
                                            setIsLoading(false);
                                            if (response.type === twilioConversationsConstants.POST_NEW_CHAT_CONVERSATION_SUCCESS) {
                                                notifyInAppSuccess("New conversation has been created!");
                                                console.log("Successfully created new chat conversation: ", response.data);
                                                resetChatConversationFields();
                                                handleClose();
                                            } else {
                                                notifyInAppError("Error creating new conversation: " + JSON.stringify(response));
                                                console.log("Error creating new chat conversation: ", response);
                                            }
                                        });
                                    }
                                } else if (chatType === ChatType.TEXT) {
                                    // Validate phone number field is a phone number
                                    if (validatePhoneNumber(textNumber)) {
                                        setIsLoading(true);
                                        console.log("Creating text conversation with name: ", newConversationName, " and phone number: ", textNumber);
                                        let completeTextNumber = textNumberCountryCode + textNumber;

                                        // https://www.twilio.com/docs/lookup/v2-api
                                        dispatch(TwilioConversationsRedux.PostValidateLegitimacyOfPhoneNumber(completeTextNumber)).then((response: any) => {
                                            if (response.type === twilioConversationsConstants.POST_VALIDATE_LEGITIMACY_OF_PHONE_NUMBER_SUCCESS) {
                                                console.log("Successfully validated phone number: ", response.data);
                                                dispatch(TwilioConversationsRedux.PostNewTextConversation(
                                                    newConversationName, 
                                                    completeTextNumber, 
                                                    userDetails.twilio_conversations_user_identity
                                                )).then((response: any) => {
                                                    setIsLoading(false);
                                                    if (response.type === twilioConversationsConstants.POST_NEW_TEXT_CONVERSATION_SUCCESS) {
                                                        notifyInAppSuccess("New conversation has been created with '" + completeTextNumber + "'!");
                                                        console.log("Successfully created new text conversation: ", response.data);
                                                        setNewConversationName("");
                                                        setTextNumber("");
                                                        resetTextConversationFields();
                                                        handleClose();
                                                    } else if (response.type === twilioConversationsConstants.POST_NEW_TEXT_CONVERSATION_CONFLICT) {
                                                        notifyInAppWarning("Conflict: Remote phone number is already in use. Either leave the previous conversation, kick the number from it, or use a different number.")
                                                        setTextNumberValidationText("Phone number is already in use. Either leave the previous conversation, kick the number from it, or use a different number.");
                                                    } else {
                                                        notifyInAppError("Error creating new text conversation: " + JSON.stringify(response))
                                                        setTextNumberValidationText("Error creating new text conversation. Please reach out to support.");
                                                        console.log("Error creating text conversation: ", response);
                                                    }
                                                })
                                            } else {
                                                notifyInAppError("Error validating the legitimacy of the phone number. Please try again.");
                                                console.log("Error validating phone number: ", response);
                                                console.log("Phone number: ", completeTextNumber);
                                                setIsLoading(false);
                                            }
                                        });
                                    }
                                }
                            }}
                        >
                            Create
                        </button>
                    </div>
                </>
            }
        />
    );
}

interface IModalTwilioDeleteParticipantFromConversationProps {
    isShowing: boolean;
    handleClose: () => void;
    presetActiveConversationSid?: string;
    presetParticipantSid?: string;
}

const ModalTwilioDeleteTextParticipantFromConversation = ({
    isShowing,
    handleClose,
    presetActiveConversationSid,
    presetParticipantSid,
}: IModalTwilioDeleteParticipantFromConversationProps) => {
    const [conversationSid, setConversationSid] = useState(presetActiveConversationSid || "");
    const [conversationSidValidationText, setConversationSidValidationText] = useState("");
    const [participantSid, setParticipantSid] = useState(presetParticipantSid || "");
    const [participantSidValidationText, setParticipantSidValidationText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatchTS();

    useEffect(() => {
        if (presetActiveConversationSid) {
            setConversationSid(presetActiveConversationSid);
        }
    }, [presetActiveConversationSid]);

    return (
        <ModalTwilio
            isLoading={isLoading}
            modalClassName="modal-twilio modal-twilio-new-conversation"
            modalShow={isShowing}
            modalOnHide={() => {handleClose()}}
            modalTitle="Delete Text Participant From Conversation"
            // @ts-ignore
            modalSize="md"
            modalHeaderContent={
                <>
                    {/* Header */}
                </>
            }
            modalBody={
                <div className="modal-twilio-body">
                    {/* Input for conversation sid */}
                    <div className="px-24 py-12 border-md-top">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Conversation SID"
                            value={conversationSid}
                            onChange={(e) => {
                                setConversationSid(e.target.value);
                            }}
                        />
                    </div>
                    <div className="text-danger">{conversationSidValidationText}</div>
                    {/* Input for participant sid */}
                    <div className="px-24 py-12 border-md-top">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Participant SID"
                            value={participantSid}
                            onChange={(e) => {
                                setParticipantSid(e.target.value);
                            }}
                        />
                    </div>
                    <div className="text-danger">{participantSidValidationText}</div>
                </div>
            }
            modalFooter={
                <>
                    <div className="d-md-flex align-items-center">
                        <button
                            className="btn btn-outline-secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                setConversationSid("");
                                setParticipantSid("");
                                handleClose();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-success"
                            disabled={false}
                            onClick={() => {
                                if (conversationSid != "" && participantSid != "") {
                                    setIsLoading(true);
                                    dispatch(TwilioConversationsRedux.PostDeleteTextParticipantFromConversation(conversationSid, participantSid)).then((response: any) => {
                                        setIsLoading(false);
                                        if (response.type === twilioConversationsConstants.POST_DELETE_TEXT_PARTICIPANT_FROM_CONVERSATION_SUCCESS) {
                                            notifyInAppSuccess("Successfully removed text participant from conversation!");
                                            console.log("Successfully deleted text participant from conversation: ", response.data);
                                            setConversationSid("");
                                            setParticipantSid("");
                                            handleClose();
                                        } else {
                                            notifyInAppError("Error when deleting text participant from conversation: " + JSON.stringify(response));
                                            console.log("Error deleting text participant from conversation: ", response);
                                        }
                                    })
                                }
                            }}
                        >
                            Execute
                        </button>
                    </div>
                </>
            }
        />
    );
}

interface IModalTwilioInfoGeneralPopupProps {
    isShowing: boolean;
    handleClose: () => void;
    infoTitle: string;
    infoText: string | React.ReactNode | void;
    modalSize?: "sm" | "md" | "lg" | "xl";
}

const ModalTwilioInfoGeneralPopup = ({
    isShowing,
    handleClose,
    infoTitle,
    infoText,
    modalSize,
}: IModalTwilioInfoGeneralPopupProps) => {

    return (
        <ModalTwilio
            modalClassName="modal-twilio modal-twilio-info-popup"
            modalShow={isShowing}
            modalOnHide={() => {handleClose()}}
            modalTitle={infoTitle}
            // @ts-ignore
            modalSize={modalSize || "xl"}
            modalHeaderContent={
                <>
                    {/* Header */}
                </>
            }
            modalBody={
                <div className="modal-twilio-body">
                    <pre className="px-24 py-12 border-md-top" style={{wordBreak: "break-word"}}>
                        {infoText || ""}
                    </pre>
                </div>
            }
            modalFooter={
                <>
                    <div className="d-md-flex align-items-center">
                        <button
                            className="btn btn-outline-secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                handleClose();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-success"
                            disabled={false}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            Okay
                        </button>
                    </div>
                </>
            }
        />
    );
}

interface IModalTwilioConversationInfoPopupProps {
    isShowing: boolean;
    handleClose: () => void;
    infoTitle: string;
    infoText: string | React.ReactNode | void;
}

const ModalTwilioConversationInfoPopup = ({
    isShowing,
    handleClose,
    infoTitle,
    infoText,
}: IModalTwilioConversationInfoPopupProps) => {

    return (
        <ModalTwilio
            modalClassName="modal-twilio modal-twilio-info-popup"
            modalShow={isShowing}
            modalOnHide={() => {handleClose()}}
            modalTitle={infoTitle}
            // @ts-ignore
            modalSize="xl"
            modalHeaderContent={
                <>
                    {/* Header */}
                </>
            }
            modalBody={
                <div className="modal-twilio-body">
                    <pre className="px-24 py-12 border-md-top" style={{wordBreak: "break-word"}}>
                        {infoText || ""}
                    </pre>
                </div>
            }
            modalFooter={
                <>
                    <div className="d-md-flex align-items-center">
                        <button
                            className="btn btn-outline-secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                handleClose();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-success"
                            disabled={false}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            Okay
                        </button>
                    </div>
                </>
            }
        />
    );
}

// interface IModalTwilioChatListChatOptionsProps {
//     userDetails: IUserDetailsProps;
//     isShowing: boolean;
//     handleClose: () => void;
// }

// const ModalTwilioChatListChatOptions = ({
//     userDetails,
//     isShowing,
//     handleClose,
// }: IModalTwilioChatListChatOptionsProps) => {

//     return (
//         <ModalTwilio
//             modalClassName="modal-twilio modal-twilio-conversation-list-conversation-options"
//             modalShow={isShowing}
//             modalOnHide={() => {handleClose()}}
//             modalTitle="New Conversation"
//             // @ts-ignore
//             modalSize="md"
//             modalHeaderContent={
//                 <>
//                     {/* Header */}
//                 </>
//             }
//             modalBody={
//                 <></>
//             }
//             modalFooter={
//                 <></>
//             }
//         />
//     );
// }

interface IModalTwilioConversationChangeNameProps {
    activeConversation: ITwilioConversationsConversationProps;
    setActiveConversation: (conversation: ITwilioConversationsConversationProps) => void;
    userConversations: ITwilioConversationsConversationProps[];
    setUserConversations: (conversations: ITwilioConversationsConversationProps[]) => void;
    userDetails: IUserDetailsProps;
    isShowing: boolean;
    handleClose: () => void;
    setShowModalError: (show: boolean) => void;
    setShowModalErrorText: (text: string) => void;
}

const ModalTwilioConversationChangeName = ({
    activeConversation,
    setActiveConversation,
    userConversations,
    setUserConversations,
    userDetails,
    isShowing,
    handleClose,
    setShowModalError,
    setShowModalErrorText,
}: IModalTwilioConversationChangeNameProps) => {
    const [currentConversationName, setCurrentConversationName] = useState(activeConversation.friendly_name);
    const [validationText, setValidationText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatchTS();

    return (
        <ModalTwilio
            isLoading={isLoading}
            modalClassName="modal-twilio modal-twilio-conversation-change-name"
            modalShow={isShowing}
            modalOnHide={() => {handleClose()}}
            modalTitle="Change Conversation Name"
            // @ts-ignore
            modalSize="md"
            modalHeaderContent={
                <>
                    {/* Header */}
                </>
            }
            modalBody={
                <div className="modal-twilio-body">
                    {/* Input for new conversation name */}
                    <div className="px-24 py-12 border-md-top">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Conversation Name"
                            value={currentConversationName}
                            onChange={(e) => {
                                // Make sure conversation name is less than 255 characters and more than 0 characters
                                if (e.target.value.length > 255 || e.target.value.length == 0) {
                                    setCurrentConversationName(currentConversationName);
                                    setValidationText("Conversation must be between 1 and 255 characters");
                                } else {
                                    setValidationText("");
                                    setCurrentConversationName(e.target.value);
                                }
                            }}
                        />
                    </div>
                    <div className="text-danger px-24">{validationText}</div>
                </div>
            }
            modalFooter={
                <div className="d-md-flex align-items-center">
                    <button
                        className="btn btn-outline-secondary"
                        onClick={(e) => {
                        e.preventDefault();
                            handleClose();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-success"
                        disabled={false}
                        onClick={() => {
                            setIsLoading(true);
                            dispatch(TwilioConversationsRedux.UpdateUserChatConversationName(activeConversation.sid, currentConversationName, userDetails.twilio_conversations_user_identity)).then((response: any) => {
                                setIsLoading(false);
                                if (response.type === twilioConversationsConstants.UPDATE_CONVERSATION_NAME_SUCCESS) {
                                    notifyInAppSuccess("Successfully updated conversation name!");
                                    setActiveConversation({
                                        ...activeConversation,
                                        friendly_name: currentConversationName,
                                    });
                                    setUserConversations(userConversations.map((conversation) => {
                                        if (conversation.sid === activeConversation.sid) {
                                            return {
                                                ...conversation,
                                                friendly_name: currentConversationName,
                                            };
                                        } else {
                                            return conversation;
                                        }
                                    }));
                                    handleClose();                                    
                                } else if (response.type === twilioConversationsConstants.UPDATE_CONVERSATION_NAME_FAILURE) {
                                    // Error handling
                                    notifyInAppError("Error when updating conversation name: " + JSON.stringify(response));
                                    // setShowModalError(true);
                                    // setShowModalErrorText('Failed to update conversation name. Error: ' + response.error.error);
                                }
                            });
                        }}
                    >
                        Save
                    </button>
                </div>
            }
        />
    );
}

export {
    ModalTwilio, ModalTwilioConversationChangeName, ModalTwilioConversationInfoPopup,
    // ModalTwilioChatListChatOptions,
    ModalTwilioDeleteTextParticipantFromConversation, ModalTwilioInfoGeneralPopup, ModalTwilioNewConversation
};

