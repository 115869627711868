import React from 'react';
import { Link } from 'react-router-dom';
const ForgotEmailAddress = () => {


  return ( 
    <>
      <div className='login-card'>
        {/* <div className='login-heading text-center mb-16'>
                    <h2 className='mb-16'>Forgot Email Address</h2>
                </div> */}
      </div>
      <div>
        <div className='alert alert-secondary'>
          <em className='icon-info-circle-filled'></em>
          <div>
            Please contact your advisory team if you need assistance with your Moneta Portal account or if you need an account.
          </div>
        </div>
        <div className='text-center mb-8'>
          <Link to="/" className="btn-link fs-18 lh-32">Back To Login</Link>
        </div>
      </div>
    </>
  );
}

export default ForgotEmailAddress
