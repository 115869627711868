import { combineReducers } from 'redux';
import authentication from './Auth/auth.reducer';
import ValidEmail  from './Auth/EmailValid';
import SendOTP from './Auth/SendOTP';
import ValidForgotPasswordOTP from './Auth/ForgotPasswordValidOTP';
import ForgortResetPassword from './Auth/ForgotResetPassword';
import ResetDefaultPassword from "./Auth/ResetDefaultPassword";
import ShareFileCheck from './SharedFile/ShareFileCheck';
import ShareFileToken from './SharedFile/ShareFileToken';
import GetSharedFolderById from './SharedFile/GetSharedFolderById';
import GetBreadCrumb from './SharedFile/GetBreadCrumb';
import AddtoFavourite from './SharedFile/AddtoFavourite';
import DeleteFromFavourite from './SharedFile/DeleteFromFavourite';
import AddAccessControl from './SharedFile/AddAccessControl';
import GetAccessControl from './SharedFile/GetAccessControl';
import GetPendingList from './DocuSign/GetPendingList';
import ResendDocumnet from './DocuSign/ResendDocument';
import CreateSignature from './DocuSign/CreateSignature';
import GetSignatureList from './DocuSign/GetSignatureList';
import DocuSignCheck from './DocuSign/DocuSignCheck';
import CalendarCheck from './Calendar/CalendarCheck';
import NotificationCount from './Notification/NotificationCount';
import UnreadMessageCount from './Notification/UnreadMessageCount';
import articleInfoReducer from './Article/articleReducer';
import commonInfoReducer from './common/commonReducer';
import ImageUpload from './ProfileSetting/ImageUpload';
import ProfileInfo from './ProfileSetting/ProfileInfo';
const rootReducer = combineReducers({
  authentication,
  ValidEmail,
  SendOTP,
  ValidForgotPasswordOTP,
  ForgortResetPassword,
  ResetDefaultPassword,
  ShareFileCheck,
  ShareFileToken,
  GetSharedFolderById,
  GetBreadCrumb,
  AddtoFavourite,
  DeleteFromFavourite,
  AddAccessControl,
  GetAccessControl,
  GetPendingList,
  ResendDocumnet,
  CreateSignature,
  GetSignatureList,
  DocuSignCheck,
  CalendarCheck,
  NotificationCount,
  UnreadMessageCount,
  articleInfoReducer,
  commonInfoReducer,
  ImageUpload,
  ProfileInfo,
});

export default rootReducer;