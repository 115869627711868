import { Action, configureStore } from '@reduxjs/toolkit';
import { Context, createContext } from 'react';
import { ReactReduxContextValue } from 'react-redux';
import ComplianceSlice from './slicesTS/ComplianceSlice';
import TwilioConversationsSlice from './slicesTS/TwilioConversationsSlice';
import UsersSlice from './slicesTS/UsersSlice';

export const ContextStoreTS: Context<ReactReduxContextValue<any, Action>> = createContext(null as any);

export const storeTS = configureStore({
  reducer: {
    compliance: ComplianceSlice,
    twilioConversations: TwilioConversationsSlice,
    users: UsersSlice,
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootStateTS = ReturnType<typeof storeTS.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatchTS = typeof storeTS.dispatch